import React,{ useState, useEffect,useRef }  from 'react'
import classes from './web.module.css'
import { Form, Input, Button,Row,Col,message,Modal,List,Tabs} from 'antd'
import { MobileOutlined ,KeyOutlined} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setLogin } from '../store/loginSlice'
import { setDept} from '../store/deptSlice'
import { useLoginCodeMutation,  useUserLoginMutation, useUserMmLoginMutation, } from '../store/ReactWebSiteApi'
import TaTop from './TaTop'
import Foot from './Foot'
function Login() {
    //获取已登录用户数据
    const prevUserPhone = JSON.parse(localStorage.getItem('eu_ba_phone'));
    //绑定手机号
    const [adminPhone, setAdminPhone] = useState(prevUserPhone);
    const adminPhoneInput = (e) => {
        setAdminPhone(prevState => e.target.value);
    }
    //绑定验证码
    const [adminCode, setAdminCode] = useState();
    const adminCodeInput = (e) => {
        setAdminCode(prevState => e.target.value);
    }
    //验证码倒计时
    const [isNote, setIsNote] = useState(false);
    const [time, setTime] = useState(null);
    const timeRef = useRef();
    useEffect(() => {
        if (time && time !== 0) {
            timeRef.current = setTimeout(() => {
                setTime((time) => time - 1);
            }, 1000);
        } else {
            setIsNote(false);
        }
        return () => {
            clearInterval(timeRef.current);
        };
    }, [time]);
  
    //获取验证码数据
    const [getCode] = useLoginCodeMutation();
    //获取验证码
    const getCodeBtn = () => {
        setTime(60);
        setIsNote(true);
        getCode({
            phone:adminPhone,
        }).then((res) => {
            console.log(res);
            if(res.data.Status==='success'){
                setTime(60);
                setIsNote(true);
            }
            if (res.data.Status === 'fail') {
                message.error('该手机号码未注册！'+ res.data.Message)    
            }
        })    
    }
    //数据上传
    const [login] = useUserLoginMutation();    
    //分发
    const dispatch = useDispatch();
    //跳转
    const navi = useNavigate();
    //短信验证码登录，提交表单
    const onFinish = (values) => {
        //  console.log('Success:', values);
        login({
            'ba_phone': adminPhone,
            'phone_code':adminCode
        }).then(res => {
            console.log(res);     
            if (res.data.Status === "success") {   

               
                //如果只有一个企业                
                if(res.data.Result.length===1){      
                                  
                    dispatch(setLogin({
                        token: res.data.Result[0].eu_token,
                        eu_ba_phone: +res.data.Result[0].eu_ba_phone,
                        eu_ba_id: +res.data.Result[0].eu_ba_id,
                        eu_name:res.data.Result[0].eu_name,
                        eu_role:+res.data.Result[0].eu_role,
                        e_id:+res.data.Result[0].e_id,
                        e_name:res.data.Result[0].e_name,
                        ed_id:+res.data.Result[0].ed_id,
                        ed_name:res.data.Result[0].ed_name,
                        eu_id: +res.data.Result[0].eu_id,
                        eu_menu_id:res.data.Result[0].eu_menu_id,
                        eu_operate_id:res.data.Result[0].eu_operate_id,
                        eu_esv_id:res.data.Result[0].eu_esv_id,
                        ess_name:res.data.Result[0].ess_name,
                        esv:res.data.Result[0].esv+'',
                        eu_dept_id:res.data.Result[0].eu_dept_id,
                        ess_menu_id:res.data.Result[0].ess_menu_id,                        
                        islog: true
                    }));
                    dispatch(setDept({
                        cur_dept_id:+res.data.Result[0].ed_id,
                        cur_dept_name:res.data.Result[0].ed_name
                    })) 
                                   
                    // (location.state.prelocation.pathname === '/register') ? navi('/',{replace:true}) : navi(location.state.prelocation.pathname, { replace: true });
                    navi('/TaIndex', { replace: true });
                }
                //如果有多个企业
                if (res.data.Result.length > 1) {
                    setIsModalOpen(true)
                    console.log(res.data.Result);
                    const listData = res.data.Result.map(item => {
                        const {e_name:title,e_soft_name,ess_menu_id,eu_dept_id,ess_name,esv,eu_token,eu_ba_phone,eu_ba_id,eu_name,eu_role,e_id:key,e_id,e_name,ed_id,ed_name,eu_id,eu_menu_id,eu_operate_id,eu_esv_id} = item
                        return item = { title,e_soft_name,ess_menu_id,eu_dept_id,ess_name,esv,eu_token, eu_ba_phone, eu_ba_id, eu_name, eu_role, key,e_id, e_name, ed_id, ed_name, eu_id,eu_menu_id,eu_operate_id,eu_esv_id};
                    })
                    setFirmList(listData);
                }
            } else if (res.data.Status === 'fail') {
                message.error(res.data.Message);             
            }
        })        
    };
    //提交表单（失败）  
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    //企业列表
    const [firmList, setFirmList] = useState();
    //对话框
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {setIsModalOpen(false);};
    const handleCancel = () => {setIsModalOpen(false);};   
    const ModalSelect = <Modal title="选择企业" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
    <List               
        itemLayout="horizontal"
        dataSource={firmList}
        renderItem={(item) => (
        <List.Item>
            <List.Item.Meta title={<a onClick={() => {                    
                    dispatch(setLogin({
                        token: item.eu_token,
                        eu_ba_phone: +item.eu_ba_phone,
                        eu_ba_id: +item.eu_ba_id,
                        eu_name:item.eu_name,
                        eu_role:+item.eu_role,
                        e_id:+item.e_id,
                        e_name:item.e_name,
                        ed_id:+item.ed_id,
                        ed_name:item.ed_name,
                        eu_id: +item.eu_id,
                        eu_menu_id:item.eu_menu_id,                                            
                        eu_operate_id:item.eu_operate_id,                                            
                        eu_esv_id:item.eu_esv_id, 
                        ess_name:item.ess_name,
                        esv:item.esv+'',
                        eu_dept_id:item.eu_dept_id,
                        e_soft_name:item.e_soft_name,
                        ess_menu_id:item.ess_menu_id,
                        islog: true,
                    }));
                    dispatch(setDept({cur_dept_id:+item.ed_id,cur_dept_name:item.ed_name}))
                    navi('/TaIndex', { replace: true }); 
                    
                }}
                    key={item.key}
                >{item.title}</a>} />
        </List.Item>
        )}
    />
</Modal>
    
    //密码登录
    const [passwordLoginInfo,setPasswordLoginInfo] = useState({ba_phone:prevUserPhone})
    const [passwordLoginSubmit] = useUserMmLoginMutation()
    const passwordLogin = <div>
                            <Form
                                name="mmlogin"
                                className="login-form"
                                initialValues={{remember: true,}}
                                onFinish={() => {
                                    passwordLoginSubmit({
                                        ba_phone:passwordLoginInfo.ba_phone,
                                        ba_pwd:passwordLoginInfo.ba_pwd,
                                    }).then(res => {
                                        console.log(res);     
                                        if (res.data.Status === "success") {                   
                                            if(res.data.Result.length===1){
                                                dispatch(setLogin({
                                                    token: res.data.Result[0].eu_token,
                                                    eu_ba_phone: +res.data.Result[0].eu_ba_phone,
                                                    eu_ba_id: +res.data.Result[0].eu_ba_id,
                                                    eu_name:res.data.Result[0].eu_name,
                                                    eu_role:+res.data.Result[0].eu_role,
                                                    e_id:+res.data.Result[0].e_id,
                                                    e_name:res.data.Result[0].e_name,
                                                    ed_id:+res.data.Result[0].ed_id,
                                                    ed_name:res.data.Result[0].ed_name,
                                                    eu_id: +res.data.Result[0].eu_id,
                                                    eu_menu_id:res.data.Result[0].eu_menu_id,
                                                    eu_operate_id:res.data.Result[0].eu_operate_id,
                                                    eu_esv_id:res.data.Result[0].eu_esv_id,
                                                    ess_name:res.data.Result[0].ess_name,
                                                    esv:res.data.Result[0].esv+'',
                                                    eu_dept_id:res.data.Result[0].eu_dept_id,
                                                    e_soft_name:res.data.Result[0].e_soft_name,
                                                    ess_menu_id:res.data.Result[0].ess_menu_id,
                                                    islog: true
                                                }));
                                                dispatch(setDept({cur_dept_id:+res.data.Result[0].ed_id,cur_dept_name:res.data.Result[0].ed_name}))                  
                                                // (location.state.prelocation.pathname === '/register') ? navi('/',{replace:true}) : navi(location.state.prelocation.pathname, { replace: true });
                                                navi('/TaIndex', { replace: true }); 
                                            }
                                            if (res.data.Result.length > 1) {
                                                setIsModalOpen(true)
                                                // console.log(res.data.Result);
                                                const listData = res.data.Result.map(item => {
                                                    const {e_name:title,eu_dept_id,ess_menu_id,e_soft_name,ess_name,esv,eu_token,eu_ba_phone,eu_ba_id,eu_name,eu_role,e_id:key,e_id,e_name,ed_id,ed_name,eu_id,eu_menu_id,eu_operate_id,eu_esv_id} = item
                                                    return item = { title,eu_dept_id,ess_menu_id,e_soft_name,ess_name,esv,eu_token, eu_ba_phone, eu_ba_id, eu_name, eu_role, key,e_id, e_name, ed_id, ed_name, eu_id,eu_menu_id,eu_operate_id,eu_esv_id};
                                                })
                                                setFirmList(listData);
                                            }
                                        } else if (res.data.Status === 'fail') {
                                            message.error(res.data.Message);             
                                        }
                                    })        
                                }}   
                            >
                                <Form.Item
                                    name="userPhone"
                                    validateTrigger="onBlur"
                                    rules={[{
                                        required: true,
                                        message: '请填写手机号!',
                                    },
                                    {
                                    pattern: /^1[3-9]\d{9}$/,
                                    message: '请输入正确的手机号'
                                        }]}
                                    initialValue={prevUserPhone}
                                    
                                >
                                    <Input 
                                        prefix={<MobileOutlined className="site-form-item-icon" />} 
                                        placeholder="请输入手机号" 
                                        value={passwordLoginInfo.ba_phone} 
                                        onChange={(e) => {setPasswordLoginInfo(prevState=>({...prevState,ba_phone:e.target.value}))}} 
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="mm"
                                    validateTrigger="onBlur"    
                                    rules={[
                                        {
                                            required: true,
                                            message: '请填写登录密码!',
                                        }]}
                                    >
                                        <Input 
                                            size="large" 
                                            placeholder='请输入登录密码' 
                                            prefix={<KeyOutlined className="site-form-item-icon" />} 
                                            value={passwordLoginInfo.ba_pwd} 
                                            onChange={(e) => {setPasswordLoginInfo(prevState=>({...prevState,ba_pwd:e.target.value}))}} 
                                            type='password'
                                        />
                                </Form.Item>                            
                                <Form.Item>
                                    <Button type='primary' htmlType="submit" block size="large">登录</Button>                                
                                </Form.Item>
                                {/* <div className={classes.loginTips}>
                                    <a onClick={() => {setOpenAdd(true)}}>还没有注册？请使用手机注册</a>
                                    <a onClick={() => {navi('/')}}>返回首页</a>
                                </div> */}
                            </Form>
                        </div>
    //短信验证码登录
    const messageLogin = <div>
                            <Form
                                name="normal_login"
                                className="login-form"
                                initialValues={{remember: true,}}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}   
                            >
                                <Form.Item
                                    name="username"
                                    validateTrigger="onBlur"
                                    rules={[{
                                        required: true,
                                        message: '请填写手机号!',
                                    },
                                    {
                                    pattern: /^1[3-9]\d{9}$/,
                                    message: '请输入正确的手机号'
                                        }]}
                                    initialValue={prevUserPhone}                                    
                                >
                                    <Input 
                                        prefix={<MobileOutlined className="site-form-item-icon" />} 
                                        placeholder="请输入手机号" 
                                        value={adminPhone} 
                                        onChange={adminPhoneInput} 
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Row gutter={5}>
                                        <Col span={14}>
                                            <Form.Item
                                                name="captcha"
                                                validateTrigger="onBlur"    
                                                noStyle
                                                rules={[
                                                        {
                                                            required: true,
                                                            message: '请输入短信验证码!',
                                                        },
                                                        {
                                                            pattern: new RegExp('^\\d{6}$'),
                                                            message: '请输入正确的6位数字验证码!',
                                                        },
                                                    ]}
                                                >
                                                    <Input 
                                                        size="large" 
                                                        placeholder='请输入短信验证码' 
                                                        prefix={<KeyOutlined className="site-form-item-icon" />} 
                                                        value={adminCode} 
                                                        onChange={adminCodeInput} 
                                                    />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            {isNote ? <Button disabled size="large" >{time}秒后重新发送</Button> : <Button size="large"  onClick={getCodeBtn}>获取验证码</Button>}
                                        </Col>
                                    </Row>
                                </Form.Item>                            
                                <Form.Item>
                                    <Button type='primary' htmlType="submit" block size="large">登录</Button>                                
                                </Form.Item>
                                {/* <div className={classes.loginTips}>
                                    <a onClick={() => {setOpenAdd(true)}}>还没有注册？请使用手机注册</a>
                                    <a onClick={() => {navi('/')}}>返回首页</a>
                                </div> */}
                            </Form>
                        </div>
    const onChange = (key) => {
        console.log(key);
      };
    const items = [
        {
            key: '1',
            label: '密码登录',
            children: <>{passwordLogin}</>,
        },
        {
            key: '2',
            label: '短信验证码登录',
            children: <>{messageLogin}</>,
        },    
    ];
    return (  
        <>
            <TaTop activeKey='3'/>
            <div className={classes.loginBack}>
                <div className={classes.loginCenter}>
                    <div className={classes.loginLeft}>
                        <div>旅当家</div>
                        <div className={classes.loginTitle}>旅行社管理系统</div>
                    </div>
                    <div className={classes.loginRight}>
                        <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> 
                        <div style={{textAlign:'center',lineHeight:"38px",fontSize:"13px"}}>
                            <a onClick={() => {navi('/Register')}}  className={classes.footLink}>还没有账号?免费注册</a>                            
                        </div>
                    </div>
                </div>     
                {ModalSelect}
            </div> 
            <Foot />
        </>       
    )
}

export default Login