import React, { useEffect, useState } from 'react'
import { message, Space, } from 'antd'
import TableCheck from '../../../cpn/TableCheck'
import OpFileNotesAdd from './FileNotesAdd'
import { useDijieBaoJiaDanZhuYiShiXiangDelMutation, useDijieBaoJiaDanZhuYiShiXiangListQuery } from '../../../store/ReactWebSiteApi'
import ModalDel from '../../../cpn/ModalDel'
import FileNotesEdit from './FileNotesEdit'

function FileNotes(props) {
    const [list,setList] = useState()
    const {data,isSuccess} = useDijieBaoJiaDanZhuYiShiXiangListQuery({bjdzysx_bjd_id:props.bjdzysx_bjd_id},{refetchOnFocus:true})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                // console.log(data);
                const arr = data.Result.map((item) => {
                    const {bjdzysx_id:key,bjdzysx_name,bjdzysx_content,bjdzysx_ctime} = item
                    return item = {key,bjdzysx_name,bjdzysx_content,bjdzysx_ctime}
                })
                setList(arr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDijieBaoJiaDanZhuYiShiXiangDelMutation()
    const column = [        
        {
            key:'1',
            title:'标题',
            dataIndex:'bjdzysx_name',
            width:'120px'
        },                           
        {
            key:'2',
            title:'描述',
            dataIndex:'bjdzysx_content'
        },                           
        {
            key:'5',
            title:'操作',
            render:(_,record) => {
                return<Space>
                    <FileNotesEdit
                        bjdzysx_id={record.key}
                        bjdzysx_bjd_id={props.bjdzysx_bjd_id}
                    />
                    <ModalDel onOk={() => {
                        del({bjdzysx_id:record.key}).then((res) => {
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                </Space>
            },
            width:'120px',
        },        
    ]
    if(props.noAdd || props.preview){
        column.pop()
    }
    return (
        <TableCheck 
                columns={column}
                dataSource={list}
                style={{marginTop:"10px"}}
                title='注意事项'
                titleRight={(!props.noAdd && !props.preview) && <OpFileNotesAdd 
                    ltbjd_dept_id={props.ltbjd_dept_id}
                    bjdzysx_bjd_id={props.bjdzysx_bjd_id}
                />}
                size={props.customType==='1'?'middle':'small'}
            />
    )
}

export default FileNotes