import React from 'react'
import { useSelector } from 'react-redux'
import {Navigate,Outlet} from 'react-router-dom'

function LoginGate(props) {
    //获取登录状态
    const auth = useSelector(state => state.login);     
    // console.log(auth);
    return (
        <>
            {auth.isLog ? <Outlet></Outlet> :<Navigate to={"/Login"} replace />}
            {/* <Navigate to={"/Notice"}></Navigate> */}
        </>
    )
}

export default LoginGate