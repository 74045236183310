import React, { useEffect, useState }  from 'react'
import {Flex,Space,Descriptions,Spin} from 'antd'
import LedgerBalanceList from './LedgerBalanceList';
import { useBusinessNumUpdateQuery } from '../../store/ReactWebSiteApi';
import LedgerBalanceAdd from './LedgerBalanceAdd';
import LedgerEdit from './LedgerEdit';
import LedgerDel from './LedgerDel';
import LedgerAudit from './LedgerAudit';

function LedgerDesc(props) {
    const [info,setInfo] = useState({
        profit:'',
        cost:'',
        ebn_update_ctime:"",
        ebn_update_uname:"",
        guigeInfo:'',
        ec_name:'',
        ed_name:'',
        eg_name:'',
        ebn_date:'',
        ebn_uname:'',
        ebn_content:'',
        ebn_ctime:'',
        ebn_audit_uname:'',
        ebn_audit_ctime:'',
        ebn_audit2_ctime:'',
        ebn_audit2_uname:'',                    
        ebn_yw_name:'',
        ebn_team_num:'',
    })
    const {data,isSuccess} = useBusinessNumUpdateQuery({ebn_id:props.ebn_id},{refetchOnFocus:true,skip:!props.ebn_id})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                let audit
                if(data.Result.ebn_audit_ctime){
                    audit = true
                }else{
                    audit = false
                }
                 // console.log(data);
                 let profit =data.Result.profit.toFixed(2)
                 let arr = []
                 if(data.Result.guige){
                        console.log(data.Result.guige,'222');
                      arr = data.Result.guige.map((item) => {
                         return <span key={item.egg_id} egg_name={item.egg_name}>{item.egg_name + ' × ' +item.guigeNum}</span>
                     })
                 }
                setInfo(
                    {
                        ebn_audit_ctime:data.Result.ebn_audit_ctime,
                        ebn_dept_id:data.Result.ebn_dept_id,
                        audit:audit,
                        ebn_eg_id:data.Result.ebn_eg_id,
                        ebn_team_num:data.Result.ebn_team_num,                        
                        eg_name:data.Result.eg_name,                        
                        guige:data.Result.guige, 
                        ec_name:data.Result.ec_name, 
                        ebn_client_id:data.Result.ebn_client_id, 
                        ebn_yw_id:data.Result.ebn_yw_id,                                                
                        ebn_yw_name:data.Result.ebn_yw_name,  
                        ed_name:data.Result.ed_name,
                        ebn_date:data.Result.ebn_date,
                        ebn_uname:data.Result.ebn_uname,
                        ebn_content:data.Result.ebn_content,
                        ebn_ctime:data.Result.ebn_ctime,
                        ebn_update_ctime:data.Result.ebn_update_ctime,
                        ebn_update_uname:data.Result.ebn_update_uname,
                        ebn_audit_uname:data.Result.ebn_audit_uname,
                        ebn_audit2_ctime:data.Result.ebn_audit2_ctime,
                        ebn_audit2_uname:data.Result.ebn_audit2_uname,    
                        guigeInfo:arr,                      
                        profit,                                                   
                    }
                )

            }
        }
    },[data,isSuccess])
    const items = [
        
        {
            key: '1',
            label: '团号',
            children: info.ebn_team_num,            
        },
        {
            key: '2',
            label: '客户',
            children: info.ec_name,
        },
        {
            key: '8',
            label: '操作员',
            children:  info.ebn_ctime + info.ebn_uname,
        },
        {
            key: '3',
            label: '出发日期',
            children: info.ebn_date,
        },
        {
            key: '4',
            label: '产品',
            children: info.eg_name,
        },    
        {
            key: '10',
            label: '审核',
            children: <>{info.ebn_audit_ctime && <>{info.ebn_audit_ctime + info.ebn_audit_uname}</>}</>,
        },    
        {
            key: '12',
            label: '部门',
            children: info.ed_name,
        },
        {
            key: '5',
            label: '备注',
            children: info.ebn_content,
        },
        {
            key: '11',
            label: '复核',
            children:<>{info.ebn_audit2_ctime && <>{info.ebn_audit2_ctime + info.ebn_audit2_uname}</>}</>,
        },
        {
            key: '7',
            label: '销售员',
            children: info.ebn_yw_name,
        },
        {
            key: '6',
            label: '利润',
            children: info.profit,
        },
        {
            key: '13',
            label: '数量',
            children: <Space direction='vertical'>{info.guigeInfo}</Space>
        },        
        {
            key: '12',
            label: '操作',
            children:   <Space>                            
                            <LedgerEdit 
                                ebn_id={props.ebn_id}  
                                ebn_dept_id={info.ebn_dept_id}
                                ebn_audit_ctime={info.ebn_audit_ctime} 
                                fromLedger
                            />
                            <LedgerDel  
                                ebn_id={props.ebn_id}
                                ebn_audit_ctime={info.ebn_audit_ctime} 
                            />
                            <LedgerAudit 
                                ebn_audit2_ctime={info.ebn_audit2_ctime}
                                ebn_audit_ctime={info.ebn_audit_ctime} 
                                ebn_id={props.ebn_id}
                            />
                        </Space>,
        },
    ]
    console.log(info.guigeInfo);
    return (
        <div style={{border:'2px solid #eee',margin:"10px"}}>
            <Flex justify='center'>
                <h2>团队报账单</h2>
            </Flex>
            {info.ec_name ?<Descriptions
                                    bordered
                                    size='small' 
                                    items={items} 
                                />:<Spin  tip="团队报账单生成中..." size='large'/>}
            <div style={{marginTop:"15px"}}>
                <LedgerBalanceAdd style={{marginLeft:"15px"}} 
                    ebn_audit_ctime={info.ebn_audit_ctime}
                    eie_type_id='1' 
                    title='团队收入单'
                    ebn_dept_id={info.ebn_dept_id}
                    eie_ebn_id={props.ebn_id}
                    eie_eg_id={info.ebn_eg_id}
                    eg_name={info.eg_name}
                    guige={info.guigeInfo}
                    ebn_client_id={info.ebn_client_id}
                    ec_name={info.ec_name}
                    eie_jbr_uid={info.ebn_yw_id}
                    ebn_yw_id={info.ebn_yw_id}
                    ebn_yw_name={info.ebn_yw_name}
                />            
            </div>
            <LedgerBalanceList 
                eie_type_id='1'//主类型id
                title='团队收入单'
                action='search'
                ebn_dept_id={info.ebn_dept_id}
                eie_ebn_id={props.ebn_id}
                pagination={{position:['none'],defaultPageSize:100}}
                audit={info.audit}
                ebn_eg_id={info.ebn_eg_id}
                eg_name={info.eg_name}
                guige={info.guigeInfo}
                fromLedger
            />
            <div style={{marginTop:"15px"}}>
                <LedgerBalanceAdd style={{marginLeft:"15px"}} 
                    ebn_audit_ctime={info.ebn_audit_ctime}
                    eie_type_id='2' 
                    title='团队支出单'
                    ebn_dept_id={info.ebn_dept_id}
                    eie_ebn_id={props.ebn_id}
                    eie_eg_id={info.ebn_eg_id}
                    eie_jbr_uid={info.ebn_yw_id}
                />  
            </div>
            <LedgerBalanceList 
                eie_type_id='2'//主类型id
                title='团队支出单'
                eie_esv_id='14'
                action='search'
                ebn_dept_id={info.ebn_dept_id}
                eie_ebn_id={props.ebn_id}
                pagination={{position:['none'],defaultPageSize:100}}
                audit={info.audit}
                ebn_eg_id={info.ebn_eg_id}
                ebn_yw_id={info.ebn_yw_id}
                ebn_yw_name={info.ebn_yw_name}
            />
        </div>
    )
}

export default LedgerDesc