import React, { useEffect, useRef, useState } from 'react'
import { useDijieTemplateKeChengUpdateQuery, useDijieTemplateKeChengUpdateSaveMutation,  } from '../../../../store/ReactWebSiteApi';
import { Form, Input, message, Modal } from 'antd';
import GetUserDept from '../../../../cpn/GetUserDept';
import UploadOne from '../../../../cpn/UploadOne';
import MyEditor from '../../../../cpn/MyEditor';
const { TextArea } = Input;
function OpCourseEdit(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState();
    const editForm = useRef()
    const {data,isSuccess} = useDijieTemplateKeChengUpdateQuery({ltkc_id:props.ltkc_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                let img = data.Result.ltkc_image.map((item,index) => {
                    return {
                        uid:index,
                        url:item,
                        thumbUrl:item,
                        status: 'done',
                    }
                })  
                editForm.current.setFieldsValue({
                    ltkc_name:data.Result.ltkc_name,
                    ltkc_dept_name:data.Result.ltkc_dept_name,
                    ltkc_dept_id: data.Result.ltkc_dept_id,
                    ltkc_didian: data.Result.ltkc_didian,
                    ltkc_guimo: data.Result.ltkc_guimo,
                    ltkc_image: data.Result.ltkc_image,
                    ltkc_leirong: data.Result.ltkc_leirong,
                    ltkc_liucheng: data.Result.ltkc_liucheng,
                    ltkc_mubiao: data.Result.ltkc_mubiao,
                    ltkc_shichang: data.Result.ltkc_shichang,
                    ltkc_shizi: data.Result.ltkc_shizi,
                    ltkc_xueduan: data.Result.ltkc_xueduan,
                    ltkc_yongju: data.Result.ltkc_yongju,
                    pic:img,
                })
                setInfo(prevState=>({...prevState,
                    ltkc_name:data.Result.ltkc_name,
                    ltkc_dept_name:data.Result.ltkc_dept_name,
                    ltkc_dept_id: data.Result.ltkc_dept_id,
                    ltkc_didian: data.Result.ltkc_didian,
                    ltkc_guimo: data.Result.ltkc_guimo,
                    ltkc_image: data.Result.ltkc_image,
                    ltkc_leirong: data.Result.ltkc_leirong,
                    ltkc_liucheng: data.Result.ltkc_liucheng,
                    ltkc_mubiao: data.Result.ltkc_mubiao,
                    ltkc_shichang: data.Result.ltkc_shichang,
                    ltkc_shizi: data.Result.ltkc_shizi,
                    ltkc_xueduan: data.Result.ltkc_xueduan,
                    ltkc_yongju: data.Result.ltkc_yongju,                                                 
                }))
            }
        }
    },[data,isSuccess])
    const [submitUpdateSave] = useDijieTemplateKeChengUpdateSaveMutation()
    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                open={open}
                title='修改课程'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateEdit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitUpdateSave({
                        ltkc_dept_id: info.ltkc_dept_id,
                        ltkc_dept_name: info.ltkc_dept_name,
                        ltkc_didian: info.ltkc_didian,
                        ltkc_e_id: localStorage.getItem('e_id'),
                        ltkc_e_name: localStorage.getItem('e_name'),
                        ltkc_guimo: info.ltkc_guimo,
                        ltkc_image: info.ltkc_image,
                        ltkc_leirong: info.ltkc_leirong,
                        ltkc_liucheng: info.ltkc_liucheng,
                        ltkc_mubiao: info.ltkc_mubiao,
                        ltkc_name: info.ltkc_name,
                        ltkc_shichang: info.ltkc_shichang,
                        ltkc_shizi: info.ltkc_shizi,
                        ltkc_update_uid: localStorage.getItem('eu_id'),
                        ltkc_update_uname:localStorage.getItem('eu_name'),
                        ltkc_xueduan: info.ltkc_xueduan,
                        ltkc_yongju: info.ltkc_yongju,
                        ltkc_id:props.ltkc_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')   
                            setOpen(false)                             
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                     {!props.ltbjd_dept_id && <GetUserDept 
                        label='部门' 
                        name='ltkc_dept_name'
                        required 
                        wrapperCol={{span:10}}
                        getUserDept={(e,f) => {
                            console.log(e,f);
                            setInfo(prevsState=>({...prevsState,ltkc_dept_id:e,ltkc_dept_name:f}))
                        }}
                    />}
                    <Form.Item name="ltkc_name" label='课程名称' wrapperCol={{span:10}} required>
                        <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_name:e.target.value}))}}/>
                    </Form.Item>
                    <UploadOne 
                        name='pic'
                        label='课程图片' 
                        fileStr={(e) => {setInfo(prevsState=>({...prevsState,ltkc_image:e}))}}
                    />
                    <Form.Item name="ltkc_leirong" label='课程内容' wrapperCol={{span:20}}>
                        <TextArea  
                            onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_leirong:e.target.value}))}}
                            autoSize={{
                                minRows: 3,
                                }}
                        />
                    </Form.Item>
                    <Form.Item name="ltkc_mubiao" label='课程目标' wrapperCol={{span:20}}>
                        <TextArea  
                            onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_mubiao:e.target.value}))}}
                            autoSize={{
                                minRows: 3,
                                }}
                        />
                    </Form.Item>
                    <Form.Item name="ltkc_shichang" label='课程时长' wrapperCol={{span:5}}>
                        <Input 
                            onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_shichang:e.target.value}))}}
                        />
                    </Form.Item>
                    <Form.Item name="ltkc_xueduan" label='适用学段' wrapperCol={{span:20}}>
                        <Input 
                            onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_xueduan:e.target.value}))}}
                        />
                    </Form.Item>
                    <Form.Item name="ltkc_didian" label='研学地点' wrapperCol={{span:20}}>
                        <Input 
                            onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_didian:e.target.value}))}}
                        />
                    </Form.Item>
                    <Form.Item name="ltkc_guimo" label='接待规模' wrapperCol={{span:20}}>
                        <Input 
                            onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_guimo:e.target.value}))}}
                        />
                    </Form.Item>
                    <Form.Item name="ltkcltkc_shizi_didian" label='配备师资' wrapperCol={{span:20}}>
                        <Input 
                            onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkcltkc_shizi_didian:e.target.value}))}}
                        />
                    </Form.Item>
                    <Form.Item name="ltkc_yongju" label='研学用具' wrapperCol={{span:20}}>
                        <Input 
                            onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_yongju:e.target.value}))}}
                        />
                    </Form.Item>
                    <Form.Item name="ltkc_liucheng" label='研学流程' wrapperCol={{span:20}}>
                        <MyEditor 
                            onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkc_liucheng:e}))}}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default OpCourseEdit