import {Row} from 'antd'
import React from 'react'
import Folder from '../../../cpn/Folder';
import Main from '../../../cpn/Main'
function StudyTourTemplateIndex() {
    return (
        <Main t1='计调' n1='/TaOpIndex' t2='研学旅行' n2='/StudyTourIndex' t3='模板'>
            <Row>
                <Folder title='客户' link='/StudyTourTemplateClient'/> 
                <Folder title='页眉页脚' link='/StudyTourTemplatePage'/>
                <Folder title='线路名称' link='/StudyTourTemplateLineName'/>
                <Folder title='人群类型' link='/StudyTourTemplateCrowd'/>
                <Folder title='课程安排' link='/StudyTourTemplateCourse'/>
                <Folder title='接待标准' link='/StudyTourTemplateStandard'/>
                <Folder title='收费明细' link='/StudyTourTemplatePrice'/>
                <Folder title='费用不含' link='/StudyTourTemplateSelf'/>
                <Folder title='注意事项' link='/StudyTourTemplateNotes'/>                          
                <Folder title='导游' link='/StudyTourTemplateGuide'/>          
            </Row>
        </Main>
    )
}

export default StudyTourTemplateIndex