import React,{useRef, useState}from 'react'
import { Form,Input,message,  Space} from 'antd';
import { useDijieTemplateTaiTouAddMutation,} from '../../../store/ReactWebSiteApi';
import ModalAdd from '../../../cpn/ModalAdd';


function CustomGoodsLineAdd() {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        lttt_e_id:localStorage.getItem('e_id'),
        lttt_uid:localStorage.getItem('eu_id'),
        lttt_uname:localStorage.getItem('eu_name'),
    });
    const [submitAdd] = useDijieTemplateTaiTouAddMutation()
    const [open,setOpen] = useState(false)
    const editForm = useRef()
    return (
        <ModalAdd 
            title='线路'
            onOk={(e) => {
                submitAdd(info).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,lttt_dept_id:'',lttt_image:'',lttt_phone:''}))
                    form.resetFields(); 
                }
            }}
        >
            <Form
                ref={editForm}
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
            >   
                <Form.Item name="remark" label='线路名称' wrapperCol={{span:20}} required>
                    <Space >
                        <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,lttt_phone:e.target.value}))}} style={{width:"500px"}}/>
                        <a>线路模板</a>
                    </Space>
                </Form.Item>             
                <Form.Item name="remark" label='备注说明' wrapperCol={{span:20}}>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,lttt_phone:e.target.value}))}} style={{width:"500px"}}/>
                </Form.Item>             
            </Form>
        </ModalAdd>
    )
}

export default CustomGoodsLineAdd