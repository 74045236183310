import React from 'react'
import Main from '../../cpn/Main'

import GoodsTypeList from '../goods/GoodsTypeList'

function FinanceGoodsType() {
    return (
        <Main  t1='财务' n1='/TaFinanceIndex' t2='设置' n2='/TaFinanceSetIndex' t3='产品类型'>
            <GoodsTypeList />
        </Main>
    )
}

export default FinanceGoodsType