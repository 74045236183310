import { Select,Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useCapitalAccountListQuery, } from '../../store/ReactWebSiteApi';

function GetAccount(props) {
    const [list,setList] = useState();
    const {data, isSuccess} = useCapitalAccountListQuery({eca_e_id:localStorage.getItem('e_id')});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                // console.log(data);
                const newArr = data.Result.map((item) => {
                    const {eca_id,eca_bank_kh,eca_bank_zh,eca_bank_crad,eca_type_id,eca_wx_phone,eca_wx_account,eca_alipay_phone,eca_alipay_account}=item;
                    let name
                    if(eca_type_id==='1' ||eca_type_id==='0' ){
                        name=eca_bank_kh + '/' + eca_bank_zh + '/' +eca_bank_crad
                    }else if(eca_type_id==='2'){
                        name= '微信' + eca_wx_phone + '/' + eca_wx_account
                    }else{
                        name= '支付宝' + eca_alipay_phone + '/' + eca_alipay_account
                    }
                    return item = {value:eca_id,label:name}
                })
                setList(newArr)
            }
        }
    },[data,isSuccess])
    
    return (
        <>
            <Form.Item 
                label={props.label} 
                wrapperCol={props.wrapperCol} 
                required={props.required}      
                name={props.name}          
                style={props.FormItemStyle}      
            >
                <Select 
                    options={list} 
                    onChange={(_,f) => {
                        // console.log(f);
                        props.getAccount(f.value,f.label)
                    }}
                    placeholder={props.placeholder}
                    style={props.style}
                />
               
            </Form.Item>
        </>
    )
}

export default GetAccount