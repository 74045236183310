import React from 'react'
import Main from '../../../../cpn/Main'
import Route from './Route'
function CustomRoute() {
    return (
        <Main t1='定制游' n1='/CustomIndex' t2='模板' n2='/CustomTempIndex' t3='行程安排'>
            <Route />
        </Main>
    )
}

export default CustomRoute