import React,{useEffect, useRef, useState}from 'react'
import { Modal,message,Form,InputNumber, TimePicker, Image, Space,} from 'antd';
import { useDijieBaoJiaDanXingChengAddMutation,  } from '../../../store/ReactWebSiteApi';
import dayjs from 'dayjs'
import {imgSrcWhole } from '../../../cpn/imgSrc';
import typeName from './typeName';
import CustomRoute from '../temp/route/CustomRoute';
function FileRouteAdd(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const editForm = useRef();
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        bjdxc_name:'', 
        bjdxc_type_id:'',
        bjdxc_image:'',
        bjdxc_content:''
    });
    const [submitAdd] = useDijieBaoJiaDanXingChengAddMutation()
    useEffect(() => {
        if(editForm.current){
            editForm.current.setFieldsValue({
                bjdxc_type_id:info.bjdxc_type_id,
                bjdxc_name:info.bjdxc_name,
                pic:[{
                    uid:'1',
                    url:imgSrcWhole+info.bjdxc_image,
                    thumbUrl:imgSrcWhole+info.bjdxc_image,
                    status: 'done',
                    name:info.bjdxc_image
                }],
                bjdxc_content:info.bjdxc_content
            })
        }
    },[info.bjdxc_name, info.bjdxc_type_id,info.bjdxc_image,info.bjdxc_content])
    //模版
    const [openTemplate,setOpenTemplate] = useState(false)
    const getTemplate = <>
            <Form.Item  label='请选择' required>
                <a onClick={() => {setOpenTemplate(true)}}>行程安排模板</a>               
            </Form.Item>        
            <Modal
                open={openTemplate}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenTemplate(false);}}
                onOk={() => {}}
                footer={null}
                width={1000}
                title='行程安排模板'
            >
                <CustomRoute
                    get={true}
                    ltbjd_dept_id={props.ltbjd_dept_id}
                    getRoute={(e) => {
                        const type = typeName(e.ltxc_type_id)
                        setInfo(prevState=>({
                            ...prevState,
                            bjdxc_name:e.ltxc_name,
                            bjdxc_image:e.ltxc_image,
                            bjdxc_content:e.ltxc_content,
                            bjdxc_type_id:e.ltxc_type_id,
                            bjdxc_daoyoujihua:e.ltxc_daoyoujihua,
                            type,
                        }))
                    }}
                    getOpen={(e) => {setOpenTemplate(e)}}
                />
            </Modal>
        </>
    console.log(info);
    return (
        <>
            <a onClick={() => {setOpen(true)}} >新增</a>
            <Modal
                open={open}
                title='新增行程安排'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        bjdxc_bjd_id:props.bjdxc_bjd_id,
                        bjdxc_day_num:info.bjdxc_day_num,
                        bjdxc_ck_time:info.bjdxc_ck_time,
                        bjdxc_type_id:info.bjdxc_type_id,
                        bjdxc_name:info.bjdxc_name,
                        bjdxc_image:info.bjdxc_image,
                        bjdxc_content:info.bjdxc_content,                        
                        bjdxc_daoyoujihua:info.bjdxc_daoyoujihua,                        
                        bjdxc_uid:localStorage.getItem('eu_id'),
                        bjdxc_uname:localStorage.getItem('eu_name'),

                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    ref={editForm}
                >                                           
                    <Form.Item name="day" label='天数' wrapperCol={{span:10}} required>
                        <InputNumber  onChange={(e) => {setInfo(prevState=>({...prevState,bjdxc_day_num:e}))}}/>
                    </Form.Item>
                    <Form.Item name="time" label='参考时间' wrapperCol={{span:10}} required>
                        <TimePicker 
                            onChange={(e) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    bjdxc_ck_time:dayjs(e).format('HH:mm')
                                }))}}
                            format='HH:mm'
                        />
                    </Form.Item>
                    {getTemplate}                    
                    <Form.Item                         
                        label='类型' 
                        wrapperCol={{span:20}}                          
                    >
                        {info.type}                     
                    </Form.Item>
                    <Form.Item  label='名称' wrapperCol={{span:10}} >
                        {info.bjdxc_name}
                    </Form.Item>
                    <Form.Item label='图片' wrapperCol={{span:10}} >                        
                        <Space>
                            {info.bjdxc_image && info.bjdxc_image.map((item) => {
                                return <Image src={item} alt='' width={60}/>
                            })}
                        </Space>
                    </Form.Item>
                    <Form.Item label='描述' wrapperCol={{span:15}} >
                        {info.bjdxc_content}
                    </Form.Item>
                    <Form.Item label='导游计划' wrapperCol={{span:15}} >
                        {info.bjdxc_daoyoujihua}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default FileRouteAdd