import React from 'react'
import Main from '../../cpn/Main'
import {Row} from 'antd'
import LedgerTableDept from './LedgerTableDept'
import LedgerTableClient from './LedgerTableClient'
import LedgerTableClientType from './LedgerTableClientType'
import LedgerTableGoods from './LedgerTableGoods'
import LedgerTableGoodsType from './LedgerTableGoodsType'
import LedgerTableOperator from './LedgerTableOperator'
import LedgerTableSalesman from './LedgerTableSalesman'
import LedgerTableArapDept from './LedgerTableArapDept'
import LedgerTableArapClient from './LedgerTableArapClient'
import LedgerTableArapStatement from './LedgerTableArapStatement'

function LedgerTable() {    
    return (
        <Main  t1='财务' n1='/TaFinanceIndex' t2='团队收支' n2='/LedgerIndex' t3='报表'>
            <Row>                
                <LedgerTableDept />        
                <LedgerTableClient />            
                <LedgerTableClientType />            
                <LedgerTableGoods />                     
                <LedgerTableGoodsType />                              
                <LedgerTableOperator />                              
                <LedgerTableSalesman />                              
                <LedgerTableArapDept />                              
                <LedgerTableArapClient />                              
                <LedgerTableArapStatement />                                       
            </Row>
        </Main>
    )
}

export default LedgerTable