import React, { useState } from 'react'
import ModalAdd from '../../../../cpn/ModalAdd';
import { Form, Input, message,Button, Space } from 'antd';
import GetUserDept from '../../../../cpn/GetUserDept';
import { useDingzhiyouyijiandanTemplateAddMutation } from '../../../../store/ReactWebSiteApi';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
function FeedbackAdd() {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState();
    console.log(info);
    const [open,setOpen] = useState(false)
    const [add]  = useDingzhiyouyijiandanTemplateAddMutation()    
    return (
        <>
            <ModalAdd
                title='意见单模板'
                onOk={() => {
                    form
                    .validateFields()
                    .then((values) => {                       
                        console.log(values);     
                        const arr = values.users.map((item) => {
                            return item.first
                        })
                        add({                        
                            "item" : arr,
                            "ltyjd_dept_id" : info.ltyjd_dept_id,
                            "ltyjd_dept_name" : info.ltyjd_dept_name,
                            "ltyjd_e_id" : localStorage.getItem('e_id'),
                            "ltyjd_e_name" : localStorage.getItem('e_name'),
                            "ltyjd_name" : info.ltyjd_name,
                            "ltyjd_uid" : localStorage.getItem('eu_id'),
                            "ltyjd_uname" : localStorage.getItem('eu_name'),
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('新增成功！') 
                                setOpen(false)                                                 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('新增失败！'+res.data.Message)
                            }
                        })
                    })
                }}
                open={open}
                getOpen={(e) => {
                    setOpen(prevsState=>e)
                    if(e){
                        setInfo(prevState=>({...prevState,lttt_dept_id:'',lttt_image:'',lttt_phone:''}))
                        form.resetFields(); 
                    }
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >   
                    <GetUserDept
                        label='部门' 
                        name='dept'
                        required 
                        wrapperCol={{span:18}}
                        getUserDept={(e,f) => {setInfo(prevsState=>({...prevsState,ltyjd_dept_id:e,ltyjd_dept_name:f}))}}
                    />
                    <Form.Item name="remark" label='模板名称' wrapperCol={{span:18}} required>
                        <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltyjd_name:e.target.value}))}} />  
                    </Form.Item>             
                    <Form.Item
                        label='评价项目'
                        required
                        wrapperCol={{span:18}}
                    >
                        <Form.List 
                            name="users"
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space
                                            key={key}
                                            align="baseline"
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'first']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请填写评价项目！',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="填写评价项目" />
                                            </Form.Item>                                   
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button 
                                            type="dashed" 
                                            onClick={() => add()} block icon={<PlusOutlined />}
                                        >增加评价项目</Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>       
                    </Form.Item>
                </Form>
            </ModalAdd>
        </>
    )
}

export default FeedbackAdd