import React from 'react'
import Main from '../../cpn/Main'

import Client from '../client/Client'

function FinanceClient() {
    return (
        <Main  t1='财务' n1='/TaFinanceIndex' t2='设置' n2='/TaFinanceSetIndex' t3='客户'>
            <Client />
        </Main>
    )
}

export default FinanceClient