import { Descriptions, Flex, Modal, Space } from 'antd'
import React, { useState } from 'react'
import FileRoute from '../../file/FileRoute';
import FileStandard from '../../file/FileStandard';
import GuidePlanAgent from './GuidePlanAgent';

function GuidePlanCheck(props) {
    const [open, setOpen] = useState(false);    
    //基本信息
    const items = [        
        {
            key: '1',
            label: '旅行社',
            children: localStorage.getItem('e_name'),
            labelStyle:{width:'140px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '2',
            label: '接团时间',
            children: '',
            labelStyle:{width:'140px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '3',
            label: '接团地点',
            children:'',
            labelStyle:{width:'140px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '4',
            label: '导游',
            children: props.guideName,
            labelStyle:{width:'140px'},
            contentStyle:{fontSize:"16px"}
        },   
        {
            key: '5',
            label: '送团时间',
            children: '',
            labelStyle:{width:'140px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '6',
            label: '送团地点',
            children:'',
            labelStyle:{width:'140px'},
            contentStyle:{fontSize:"16px"}
        },                          
                
        {
            key: '9',
            label: '线路名称',
            children: '大小三峡三日游',
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '8',
            label: '分车号',
            children: '1',
            labelStyle:{width:'140px'},
            contentStyle:{fontSize:"16px"}
        },            
        {
            key: '6',
            label: '编号',
            contentStyle:{fontSize:"16px"},
            children:<Space></Space>,
        },
        {
            key: '7',
            label: '接团说明',
            children: '接团时间：；接团地点：；送团时间：；送团地点：；领队姓名：；领队联系方式：；',
            labelStyle:{width:'140px'},
            contentStyle:{fontSize:"16px"},
            span:3
        },
        {
            key: '8',
            label: '导服费',
            children: '300',
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '9',
            label: '导服费说明',
            children: '2024年3月21日',
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },                        
        {
            key: '10',
            label: '操作',
            contentStyle:{fontSize:"16px"},
            children:<Space>
            </Space>,
        },
        {
            key: '11',
            label: '附件',
            contentStyle:{fontSize:"16px"},
            children:<Space></Space>,
        },
    ]
    return (
        <>
            <a  onClick={() => {
                setOpen(true);
                }} >张三 13972008078</a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1200}
                footer={null}
            >
                <div style={{border:'2px solid #eee',margin:"10px"}}>
                    <Flex 
                        justify='center' 
                        style={{fontSize:'30px',lineHeight:"88px",fontWeight:'bold'}}
                    >导游计划单</Flex>
                    <Descriptions
                        items={items} 
                        bordered 
                        size='small' 
                        labelStyle={{fontSize:"16px"}}
                    />
                    <FileRoute/>
                    <FileStandard/>
                    <GuidePlanAgent/>
                </div>
            </Modal>
        </>
    )
}

export default GuidePlanCheck