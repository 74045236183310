import React, { useRef, useState } from 'react'
import ModalAdd from '../../cpn/ModalAdd';
import { Form, Input } from 'antd';

function PhoneShopGoodsAdd() {
    const [open,setOpen] = useState(false)
    const [form] = Form.useForm();  
    const [info,setInfo] = useState();
    console.log(info);
    const editForm = useRef()
    return (
        <ModalAdd 
            title='商品'
            onOk={(e) => {
              
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,lttt_dept_id:'',lttt_image:'',lttt_phone:''}))
                    form.resetFields(); 
                }
            }}
        >
            <Form
                ref={editForm}
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 6 }}
            >   
                <Form.Item name="remark" label='商品编号' wrapperCol={{span:18}} required>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,sp_name:e.target.value}))}}/>     
                </Form.Item>
            </Form>
        </ModalAdd>
    )
}

export default PhoneShopGoodsAdd