import React,{useState,useEffect} from 'react'
import { Table,Space,DatePicker,} from 'antd'
import { useIncomeExpenditureDeptStatisticsQuery, } from '../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import IndexTable from '../../cpn/IndexTable';
const {RangePicker} = DatePicker
function LedgerTableArapDept() {
    const [query,setQuery] = useState({
        date_s:'',
        date_e:'',
        e_id:localStorage.getItem('e_id')
    })    
    const {data,isSuccess} = useIncomeExpenditureDeptStatisticsQuery(query,{refetchOnMountOrArgChange:1,skip:!query.date_e})    
    const [list, setList] = useState();    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {ed_id:key,ed_name,expenditure_num,income_num,} = item;
                    return item = {key,ed_name,expenditure_num,income_num,}
                })                
                setList(newArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])       
    const columns = [
        {
            key: 'id',
            title: '部门',
            dataIndex: 'ed_name'
        },         
        {
            key: 'ar',
            title: '团队收入单待下账金额之和',
            dataIndex: 'income_num',
        },             
        {
            key: 'ap',
            title: '团队支出单待下账金额之和',
            dataIndex: 'expenditure_num',
        },             
        {
            key: 'profit',
            title: '差额',
            render:(_,record) => {
                return <>{(+record.income_num + +record.expenditure_num).toFixed(2)}</>
            }
        },             
    ]
    
    
    return (
        <IndexTable 
            title='待下账部门表'
            remark='按团队报账单的出发日期，统计每个部门的团队收入单待下账金额之和、团队支出单待下账金额之和、差额。'
        >
             <Space style={{marginBottom:"10px"}}>
                <span>团队报账单的出发日期：</span>
                <RangePicker 
                    onChange={(e) => {
                        if(e){
                            setQuery(prevState=>({...prevState,date_s:dayjs(e[0]).format('YYYY-MM-DD'),date_e:dayjs(e[1]).format('YYYY-MM-DD')})) 
                        }else{
                            setQuery(prevState=>({...prevState,date_s:'',date_e:''}))
                        }
                    }}
                />                
            </Space>    
            <Table 
                    columns={columns} 
                    dataSource={list} 
                    pagination={{position:['none']}}
                    summary={(pageData) => {
                        console.log(pageData);
                        let totalincome_num = 0;
                        let totalnexpenditure_num = 0;
                        pageData.forEach(({ebn_num, income_num,expenditure_num, }) => {                        
                            totalincome_num += +income_num;
                            totalnexpenditure_num += +expenditure_num;
                        });
                        return <Table.Summary>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>{(totalincome_num).toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>{(totalnexpenditure_num).toFixed(2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>{(totalincome_num+totalnexpenditure_num).toFixed(2)}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </Table.Summary>
                    }}
                />
        </IndexTable>
    )
}

export default LedgerTableArapDept