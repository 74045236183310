import React, { useEffect, useState } from 'react'
import { message, Space, Table, } from 'antd'
import { useDijieBaoJiaDanDaoYouFeiYongDelMutation, useDijieBaoJiaDanDaoYouFeiYongListQuery, } from '../../../../store/ReactWebSiteApi'
import ModalDel from '../../../../cpn/ModalDel'
import GuidePlanAgentAdd from './GuidePlanAgentAdd'
import guidePayType from '../../file/guidePayType'
import GuidePlanAgentEdit from './GuidePlanAgentEdit'
import classes from '../../file/meta.module.css'
function GuidePlanAgent(props) {
    const [list,setList] = useState()
    const {data,isSuccess} = useDijieBaoJiaDanDaoYouFeiYongListQuery({bjddyfy_bjd_id:props.bjddyfy_bjd_id},{refetchOnFocus:true})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const arr = data.Result.map((item) => {
                    const {bjddyfy_id:key,bjddyfy_amount,bjddyfy_type,bjddyfy_ctime,bjddyfy_content} = item

                    return item = {key,bjddyfy_amount,bjddyfy_type,bjddyfy_ctime,bjddyfy_content}
                })
                setList(arr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDijieBaoJiaDanDaoYouFeiYongDelMutation()
    const column = [        
        {
            key:'type',
            title:'类型',
            dataIndex:'bjddyfy_type',
            width:'120px',
            render:(text) => {
                const type = guidePayType(text)
                return type
            },
            className:classes.tableFont,
        },                           
        {
            key:'1',
            title:'金额',
            dataIndex:'bjddyfy_amount',
            width:'120px',
            className:classes.tableFont,
        },                           
        {
            key:'2',
            title:'费用说明',
            dataIndex:'bjddyfy_content',
            className:classes.tableFont,
        },                           
        {
            key:'5',
            title:'操作',
            render:(_,record) => {
                return<Space>                    
                    <GuidePlanAgentEdit
                        bjddyfy_id={record.key}
                        bjddyfy_bjd_id={props.bjddyfy_bjd_id}
                    />
                    <ModalDel onOk={() => {
                        del({bjddyfy_id:record.key}).then((res) => {
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                </Space>
            },
            width:'120px',
        },        
    ]
    if(props.noAdd){
        column.pop()
    }
    return (        
        <Table
            title={() => <Space><span style={{fontWeight:'600'}}>代收代付</span> {(!props.noAdd && !props.preview) && <GuidePlanAgentAdd
                ltbjd_dept_id={props.ltbjd_dept_id}
                bjddyfy_bjd_id={props.bjddyfy_bjd_id}
            />}</Space>}
            columns={column}
            dataSource={list}
            style={{marginTop:"10px",}}
            pagination={{position:['none']}}
            size={props.customType==='1'?'middle':'small'}
            summary={(pageData) => {
                // console.log(pageData);
                let totalPrice = 0;
                pageData.forEach(({bjddyfy_amount}) => {
                    totalPrice += +bjddyfy_amount;
                });
                
                // console.log(mergedArray,'GuigePrice');
                return <Table.Summary>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{totalPrice}元</Table.Summary.Cell>
                                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                <Table.Summary.Cell index={3}></Table.Summary.Cell>                                
                                
                            </Table.Summary.Row>
                        </Table.Summary>
            }}
        />
    )
}

export default GuidePlanAgent