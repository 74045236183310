import React,{useState,useEffect, useRef}from 'react'
import { Table,Space,Modal,message, Form} from 'antd';
import { useCapitalAccountConfirmMutation, useCapitalAccountLogDelMutation, useCapitalAccountLogListQuery } from '../../store/ReactWebSiteApi';
import Main from '../../cpn/Main'
import GetAccount from './GetAccount';
import FundCheck from './FundCheck';
import { indexFinance } from '../balance/balanceType';
import FundAdd from './FundAdd';
function FundList() {
    const [id,setId] = useState();
    const {data, isSuccess} = useCapitalAccountLogListQuery({ecal_eca_id:id});    
    const [list, setList] = useState();
    const [lastData,setLastData] = useState()    
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    let obj = {...item}
                    return obj
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ecal_ctime;
                    let bTimeString = b.ecal_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
                const [max] = [...newArr].sort((a, b) => b.ecal_id - a.ecal_id)
                //console.log(max.key);
                setLastData(max.ecal_id)
            }else{
                setList('')
            }
        }
    }, [data, isSuccess])
    //删除
    const {confirm} = Modal; 
    const [delAccount] = useCapitalAccountLogDelMutation()
    //对账
    const [capitalAccountConfirm] = useCapitalAccountConfirmMutation()
    const columns = [      
        {
            key: 'id',
            title: '转账凭证',
            dataIndex: 'ecal_id',
            render:(_,record) => {
                return  <FundCheck  
                            ecal_id={record.ecal_id} 
                            ecal_ec_name={record.ecal_ec_name} 
                            ecal_amount={record.ecal_amount}
                            ecal_uname={record.ecal_uname}
                            ecal_ctime={record.ecal_ctime}
                            ecal_bank_detail_date={record.ecal_bank_detail_date}
                            ecal_remain_amount={record.ecal_remain_amount}
                            eie_bank_date={record.eie_bank_date}
                            eie_bank_log_id={record.eie_bank_log_id}
                            eca_bank_kh={record.eca_bank_kh}
                            eca_bank_crad={record.eca_bank_crad}
                            eca_bank_zh={record.eca_bank_zh}
                            eca_type_id={record.eca_type_id}
                            eca_alipay_account={record.eca_alipay_account}
                            eca_alipay_content={record.eca_alipay_content}
                            eca_alipay_phone={record.eca_alipay_phone}
                            eca_wx_account={record.eca_wx_account}
                            eca_wx_phone={record.eca_wx_phone}
                            eca_wx_content={record.eca_wx_content}
                        />
            }   
        },
        {
            key: 'time',
            title: '转账日期',            
            dataIndex:'ecal_bank_detail_date',
        },       
        {
            key: 'min',
            title: '转账金额',
            dataIndex: 'ecal_amount',
            render:(text) => {
                if(text>0){
                    return <>
                        <span style={{color:'red',fontWeight:'bold'}}>{text}</span>
                    </>
                }else{
                    return <>
                        <span style={{color:'green',fontWeight:'bold'}}>{text}</span>
                    </>
                }
            }
        },
        {
            key: 'remain',
            title: '账户余额',
            dataIndex:'ecal_remain_amount'
        },
        {
            key: 'client',
            title: '客户',
            dataIndex:'ecal_ec_name'
        },
        
        {
            key: 'state',
            title: '状态',            
            dataIndex:'ecal_state',
            render:(text,record) => {
                if(text==='0'){
                    return<>未锁定</>
                }else{
                    return <>
                        <div>已锁定：</div>
                        {record.ecal_dz_uname && <div>{record.ecal_dz_ctime + ',' + record.ecal_dz_uname.replace(/"/g,'')}</div>}
                    </>
                }
            }
        },
        {
            key: 'time',
            title: '新增时间',
            render: (_,record) => {
                return <>
                    <div>{record.ecal_ctime+ ',' + record.ecal_uname.replace(/"/g,'')}</div>
                </>
            }
        },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space direction='vertical'>
                        {(record.ecal_id===lastData && record.ecal_state==='0') && <a onClick={() => {
                            confirm({
                                title: '确定删除吗?',
                                content: '',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    delAccount({ecal_id:record.ecal_id,ecal_eca_id:id}).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('删除成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('删除失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>删除</a>}
                        {(record.ecal_id===lastData && record.ecal_state==='0') && <a onClick={() => {
                            confirm({
                                title: '确定审核吗？',
                                content: '点击确定后，系统将审核之前的转账账单，无法取消，请仔细核对转账记录和账户余额。该操作无法撤销。',
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    capitalAccountConfirm({
                                        ecal_id:record.ecal_id,
                                        ecal_dz_uid:localStorage.getItem('eu_id'),
                                        ecal_dz_uname:localStorage.getItem('eu_name'),
                                        ecal_e_id:localStorage.getItem('e_id'),
                                    }).then((res) => {
                                        if (res.data.Status === 'success') {
                                            message.info('审核成功！');
                                        }
                                        if (res.data.Status === "fail") {
                                            message.error('审核失败！'+res.data.Message)
                                        }
                                    })
                                },
                                onCancel() {
                                console.log('Cancel');
                                },
                            });
                        }}>审核</a>}
                    </Space>
                </>
            }
        },
    ]
    const editForm = useRef();
    useEffect(() => {
        editForm.current.setFieldsValue({
            account:id
        })
    },[id])
      
    return (
        <Main t1='财务' n1={indexFinance} t2='转账' n2='/FundIndex' t3='转账凭证' >
            <Space>
                <FundAdd 
                    getAccount={(e) => {setId(e)}}
                />
                <Form 
                    ref={editForm}                    
                >
                    <GetAccount 
                        label='资金账户' 
                        style={{width:'1000px'}}
                        getAccount={(e) => {setId(e)}}
                        name='account'
                        placeholder='请选择资金账户'
                        FormItemStyle={{marginBottom:'0'}}
                    />
                </Form>
            </Space>
            <Table 
                columns={columns} 
                style={{marginTop:'20px'}} 
                dataSource={list}
                pagination={{
                    showSizeChanger:true,
                    showTotal:(total) => `共${total}条`,
                    defaultPageSize:50
                }}
            />
        </Main>
    )
}

export default FundList