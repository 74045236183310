import {Modal, Space } from 'antd'
import React, { useState } from 'react'

function GuidePublicBtn() {
    const [open,setOpen] = useState(false)
    return (
        <>
            <a 
                key='6'
                type='primary' 
                onClick={() => {
                    setOpen(true)
                }}
            >如何添加导游？</a>
            <Modal
                open={open}
                onCancel={() => {setOpen(false)}}
                footer={null}
                width={800}
            >
                <Space direction='vertical'>
                    <div style={{fontSize:"20px"}}>导游请长按二维码到“导当家”公众号注册！</div>
                    <img src='../../img/index/guidePublic.png' alt='' />
                </Space>
            </Modal>
        </>
    )
}

export default GuidePublicBtn