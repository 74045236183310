import React from 'react'
import { message,Modal} from 'antd'
import {useBusinessNumDelMutation,} from '../../store/ReactWebSiteApi';

function LedgerDel(props) {
    const {confirm} = Modal; 
    const [submitDel] = useBusinessNumDelMutation()   
    return (
        <>
            {
                !props.ebn_audit_ctime && <a onClick={() => {
                    confirm({
                        title: '确定删除吗?',
                        content: '删除后不能恢复',
                        okText: '确认',
                        okType: 'danger',
                        cancelText: '取消',
                        onOk() {
                            submitDel({ebn_id:props.ebn_id}).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.info('删除成功！');
                                }
                                if (res.data.Status === "fail") {
                                    message.error('删除失败！'+res.data.Message)
                                }
                            })
                        },
                        onCancel() {
                            console.log('Cancel');
                        },
                    }); 
                }}>删除</a>
            }
        </>
    )
}

export default LedgerDel