import React from 'react'
import Main from '../../cpn/Main'
import FundAccount from '../fund/FundAccount'

function FundAccountIndex() {
    return (
        <Main  t1='财务' n1='/FinanceIndex' t2='转账' n2='/FundIndex' t3='资金账户'>
            <FundAccount />
        </Main>
    )
}

export default FundAccountIndex