import React,{useState,useRef,useEffect} from 'react'
import { useIncomeExpenditureChangeTeamMutation, useIncomeExpenditureUpdateQuery } from '../../store/ReactWebSiteApi';
import { Modal,message,Form,} from 'antd'
import GetGroupNum from './GetGroupNum';
function LedgerBalanceChange(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        // console.log('Received values of form: ', values);
        setOpen(false);
    };    
    const [info,setInfo] = useState({eie_id:'',eie_image_name:'',eie_image_file:'',eie_content:'',eie_count:1,ec_name:'',eie_ebn_id:props.eie_ebn_id});
    const editForm = useRef()
    
    // console.log('props.eie_id',info.ec_name);
    const {data,isSuccess} = useIncomeExpenditureUpdateQuery({eie_id:info.eie_id},{skip:!info.eie_id,refetchOnMountOrArgChange:1,})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                editForm.current.setFieldsValue({
                    ebn_team_num:data.Result.ebn_team_num,            
                })
            }
        }
    },[data, isSuccess, open, props.guige])
    const [submitUpdateSave] = useIncomeExpenditureChangeTeamMutation()
    return (
        <>
            {(!props.eie_audit1_ctime && !props.eie_hx_ctime) && <a 
                key='update'
                onClick={() => {                    
                    setOpen(true);
                    setInfo(prevState=>({...prevState,eie_id:props.eie_id}))                    
                }}
                disabled={props.disabled}
            >转移</a>}
            <Modal
                open={open}
                title={`转移${props.title}`}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {                            
                            onCreateEdit(values);                            
                            submitUpdateSave({
                                e_id:localStorage.getItem('e_id'),
                                ebn_team_num:info.ebn_team_num,
                                eie_id:props.eie_id,
                               
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('转团成功！')   
                                    setOpen(false)                             
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('转团失败！'+res.data.Message)
                                }
                            })
                            form.resetFields();   
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        });
                    // console.log('111',info.eie_image_name+'',info.eie_image_file+'');
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                    ref={editForm}
                >
                    <GetGroupNum 
                        label='目标团号'
                        name='ebn_team_num'
                        required
                        getGroupNum={(e) => {setInfo(prevState=>({...prevState,ebn_team_num:e}))}}
                    />               
                </Form>
            </Modal>
        </>
    )
}

export default LedgerBalanceChange