import React, { useEffect, useState } from 'react'
import { Image, message, Space, Table } from 'antd'
import { useDijieTemplateXingChengDelMutation, useDijieTemplateXingChengListQuery } from '../../../../store/ReactWebSiteApi'
import RouteEdit from './RouteEdit'
import ModalDel from '../../../../cpn/ModalDel'
import { imgSrc } from '../../../../cpn/imgSrc'
import { localCostType } from '../../customType'
import RouteAdd from './RouteAdd'
import RouteQuery from './RouteQuery'
function Route(props) {
    const [list,setList] = useState()
    const [query,setQuery] = useState({ltxc_e_id:localStorage.getItem('e_id')})
    const {data, isSuccess} = useDijieTemplateXingChengListQuery(query,{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            // console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {ltxc_id:key,ltxc_name,ltxc_uname,ltxc_ctime,ltxc_daoyoujihua,ed_name,ltxc_image,ltxc_content,ltxc_type_id} = item;     
                    let arr = localCostType.filter((item) => item.value===ltxc_type_id)
                    let type = arr[0].label 
                    let img = ltxc_image.map((item) => {
                        return imgSrc + item
                    })                                 
                    return item = {key,ltxc_name,ltxc_uname,ltxc_daoyoujihua,ltxc_ctime,ed_name,img,ltxc_content,type,ltxc_type_id}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltxc_ctime;
                    let bTimeString = b.ltxc_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //删除
    const [del] = useDijieTemplateXingChengDelMutation()

    const column = [             
        {
            key:'2',
            title:'行程安排模板',
            dataIndex:'ltxc_name',            
        }, 
        {
            key:'21',
            title:'类型',
            dataIndex:'type',
        },   
        {
            key:'3',
            title:'线路图片',
            dataIndex:'img',
            render:(text) => {
                return  <Image.PreviewGroup
                            items={text}
                        >
                            <Image
                                width={60}
                                src={text[0]}
                            />
                        </Image.PreviewGroup>
            }
        },   
        {
            key:'4',
            title:'线路描述',
            dataIndex:'ltxc_content'
        },   
        {
            key:'14',
            title:'导游计划',
            dataIndex:'ltxc_daoyoujihua'
        },   
        {
            key:'1',
            title:'部门',
            dataIndex:'ed_name'
        },          
        {
            key:'6',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <RouteEdit ltxc_id={record.key} />
                    <ModalDel onOk={() => {
                        del({ltxc_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                    {props.get && <a onClick={() => {                        
                        props.getRoute({
                            ltxc_name:record.ltxc_name,
                            ltxc_image:record.img,
                            ltxc_content:record.ltxc_content,
                            ltxc_type_id:record.ltxc_type_id,
                            ltxc_daoyoujihua:record.ltxc_daoyoujihua,
                        })                        
                        props.getOpen(false)                        
                        }}>选择</a>}
                </Space>
            }
        },
    ]
    return (
        <>
            <Space>
                <RouteAdd />
                <RouteQuery
                    getQuery={(e) => {
                        console.log(e);
                        setQuery(prevState=>({
                            ...prevState,
                            ltxc_content:e.ltxc_content,
                            ltxc_type_id:e.ltxc_type_id,
                            ltxc_name:e.ltxc_name,
                            ltxc_dept_id:e.ltxc_dept_id,
                        }))
                    }}
                />
            </Space>
            <Table columns={column} dataSource={list} style={{marginTop:"10px"}}/>
        </>
    )
}

export default Route