import React  from 'react'
import Main from '../cpn/Main'
import {Row } from 'antd'
import Folder from '../cpn/Folder'
function LocalIndex() {
    return (
        <Main t1='国内地接'>
            <Row>                
                <Folder title='定制游' link='/CustomIndex'/> 
            </Row>
        </Main>
    )
}

export default LocalIndex