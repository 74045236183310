import React, { useEffect, useState } from 'react'
import { message, Space, Table } from 'antd'
import SelfAdd from './SelfAdd'
import { useDijieTemplateFeiYongBuHanDelMutation, useDijieTemplateFeiYongBuHanListQuery } from '../../../../store/ReactWebSiteApi'
import ModalDel from '../../../../cpn/ModalDel'
import { localCostType } from '../../customType'
import SelfEdit from './SelfEdit'
import SelfQuery from './SelfQuery'
function Self(props) {
    const [list,setList] = useState()
    const [query,setQuery] = useState({ltfybh_e_id:localStorage.getItem('e_id')})
    const {data, isSuccess} = useDijieTemplateFeiYongBuHanListQuery(query,{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {ltfybh_id:key,ltfybh_name,ltfybh_uname,ltfybh_ctime,ed_name,ltfybh_type_id} = item;     
                    let arr = localCostType.filter((item) => item.value===ltfybh_type_id)
                    let type = arr[0].label                       
                    return item = {key,ltfybh_name,ltfybh_uname,ltfybh_ctime,ed_name,type,ltfybh_type_id}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltfybh_ctime;
                    let bTimeString = b.ltfybh_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //删除
    const [del] = useDijieTemplateFeiYongBuHanDelMutation()
    const column = [       
        {
            key:'11',
            title:'费用不含模板',
            dataIndex:'ltfybh_name'
        },  
        {
            key:'12',
            title:'类型',
            dataIndex:'type'
        },      
        {
            key:'11',
            title:'部门',
            dataIndex:'ed_name'
        },                
        {
            key:'6',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <SelfEdit ltfybh_id={record.key} />
                    <ModalDel onOk={() => {
                        del({ltfybh_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                    {props.get && <a onClick={() => {
                        props.getStandard({
                            bjdfybh_name:record.ltfybh_name,
                            bjdfybh_type_id:record.ltfybh_type_id
                        })
                        props.getOpen(false)     
                        }}>选择</a>}
                </Space>
            }
        },
    ]
    return (
        <>            
            <Space>
                <SelfAdd />
                <SelfQuery
                    getQuery={(e) => {
                        console.log(e);
                        setQuery(prevState=>({
                            ...prevState,
                            ltfybh_type_id:e.ltfybh_type_id,
                            ltfybh_dept_id:e.ltfybh_dept_id,
                            ltfybh_name:e.ltfybh_name,
                        }))
                    }}
                />
            </Space>
            <Table columns={column} dataSource={list} style={{marginTop:"10px"}}/>                       
        </>
    )
}

export default Self