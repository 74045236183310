import {Row} from 'antd'
import React from 'react'
import Folder from '../../../cpn/Folder';
import Main from '../../../cpn/Main'
function CustomFileTempIndex() {
    return (
        <Main  t1='国内地接' n1='/LocalIndex' t2='定制游' n2='/CustomIndex' t3='模板'>
            <Row>
                <Folder title='客户' link='/CustomClient'/>
                <Folder title='页面和确认章' link='/CustomPage'/>
                <Folder title='线路名称' link='/CustomLineName'/>
                <Folder title='人群类型' link='/CustomCrowd'/>
                <Folder title='行程安排' link='/CustomRoute'/>
                <Folder title='接待标准' link='/CustomStandard'/>
                <Folder title='收费明细' link='/CustomPrice'/>
                <Folder title='费用不含' link='/CustomSelf'/>
                <Folder title='注意事项' link='/CustomNotes'/>                          
                <Folder title='意见单' link='/CustomFeedback'/>                         
            </Row>
        </Main>
    )
}

export default CustomFileTempIndex