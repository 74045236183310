import React,{useState,}from 'react'
import { Modal, Space,} from 'antd';
import {FileTextOutlined} from '@ant-design/icons';
import ArapDesc from './ArapDesc';
function ArapCheck(props) {   
    const [open,setOpen] = useState();
    return (
        <>
            <a onClick={() => {setOpen(true)}}>
                <Space><FileTextOutlined/> xzpz{props.eie_hx_number}</Space>
            </a>
            <Modal                 
                open={open} 
                onOk={() => {setOpen(true)}} 
                onCancel={() => {setOpen(false)}} 
                okText='关闭' 
                width={1280}
                footer={null}
            >
               <ArapDesc 
                    eie_hx_number = {props.eie_hx_number}
               />    
            </Modal>
        </>
    )
}

export default ArapCheck