import React from 'react'
import Main from '../../cpn/Main'
import {Row} from 'antd'
import { indexFinance } from '../../finance/balance/balanceType'
import ArapTableDept from './ArapTableDept'
import ArapTableClient from './ArapTableClient'
import ArapTableClientType from './ArapTableClientType'
import ArapTableGoods from './ArapTableGoods'
import ArapTableGoodsType from './ArapTableGoodsType'
import ArapTableOperator from './ArapTableOperator'
import ArapTableSalesman from './ArapTableSalesman'

function ArapTable() {    
    return (
        <Main t1='财务' n1={indexFinance} t2='下账' n2='/ArapIndex' t3='报表'>
            <Row>                                
                <ArapTableDept />      
                <ArapTableClient />            
                <ArapTableClientType />            
                <ArapTableGoods  />            
                <ArapTableGoodsType />            
                <ArapTableOperator />            
                <ArapTableSalesman />            
            </Row>
        </Main>
    )
}

export default ArapTable