import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../../cpn/ModalEdit'
import { Form, Input,message, Radio } from 'antd'
import GetUserDept from '../../../../cpn/GetUserDept';
import {useDijieTemplateXingChengUpdateQuery, useDijieTemplateXingChengUpdateSaveMutation } from '../../../../store/ReactWebSiteApi';
import UploadOne from '../../../../cpn/UploadOne';
import { imgSrcWhole } from '../../../../cpn/imgSrc';
import { localCostType } from '../../customType';
const { TextArea } = Input;
function RouteEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        ltxc_e_id:localStorage.getItem('e_id'),
        ltxc_update_uid:localStorage.getItem('eu_id'),
        ltxc_update_uname:localStorage.getItem('eu_name'),
        ltxc_id:props.ltxc_id,
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useDijieTemplateXingChengUpdateQuery({ltxc_id:props.ltxc_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                editForm.current.setFieldsValue({
                    ltxc_dept_id:data.Result.ltxc_dept_id,                  
                    ltxc_name:data.Result.ltxc_name,
                    ltxc_content:data.Result.ltxc_content,
                    ltxc_type_id:data.Result.ltxc_type_id,                    
                    ltxc_daoyoujihua:data.Result.ltxc_daoyoujihua,                    
                    pic:[{
                        uid:'1',
                        url:imgSrcWhole+data.Result.ltxc_image,
                        thumbUrl:imgSrcWhole+data.Result.ltxc_image,
                        status: 'done',
                        name:data.Result.ltxc_image
                    }],

                })
                setInfo(prevsState=>({...prevsState,
                    ltxc_dept_id:data.Result.ltxc_dept_id,
                    ltxc_name:data.Result.ltxc_name,
                    ltxc_content:data.Result.ltxc_content,
                    ltxc_image:data.Result.ltxc_image,
                    ltxc_type_id:data.Result.ltxc_type_id,    
                    ltxc_daoyoujihua:data.Result.ltxc_daoyoujihua,    
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useDijieTemplateXingChengUpdateSaveMutation()
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                updateSave(info).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('修改成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('修改失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            title='行程安排模板'
            width={800}
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
                style={{backgroundColor:"#fff",padding:"20px",width:"600px"}}
                ref={editForm}
            >                                           
                <GetUserDept 
                    label='部门' 
                    required 
                    name='ltxc_dept_id'
                    wrapperCol={{span:10}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,ltxc_dept_id:e}))}}
                />
                <Form.Item 
                    name="ltxc_type_id" 
                    label='类型' 
                    wrapperCol={{span:20}} 
                    required 
                    onChange={(e) => {setInfo(prevsState=>({...prevsState,ltxc_type_id:e.target.value}))}}
                >
                    <Radio.Group optionType="button" options={localCostType}></Radio.Group>
                </Form.Item>
                <Form.Item name="ltxc_name" label='名称' wrapperCol={{span:10}} required>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltxc_name:e.target.value}))}}/>
                </Form.Item>
                <UploadOne 
                    label='线路图片' 
                    fileStr={(e) => {setInfo(prevsState=>({...prevsState,ltxc_image:e}))}}
                    name='pic'
                />
                <Form.Item name="ltxc_content" label='线路描述' wrapperCol={{span:21}}>
                    <TextArea 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltxc_content:e.target.value}))}}
                        autoSize={{
                            minRows: 3,
                            }}
                    />
                </Form.Item>
                <Form.Item name="ltxc_daoyoujihua" label='导游计划' wrapperCol={{span:20}}>
                    <TextArea  
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltxc_daoyoujihua:e.target.value}))}}
                        autoSize={{
                            minRows: 3,
                            }}
                    />
                </Form.Item>
            </Form>
        </ModalEdit>
    )
}

export default RouteEdit