import React,{useRef, useState}from 'react'
import { Form,Input,InputNumber,message, Radio} from 'antd';
import {  useDijieTemplateFeiYongMingXiAddMutation,} from '../../../../store/ReactWebSiteApi';
import ModalAdd from '../../../../cpn/ModalAdd';
import GetUserDept from '../../../../cpn/GetUserDept';
import { localCostType } from '../../customType';
function PriceAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        ltfymx_dept_id:'',   
        ltfymx_price:'',
        ltfymx_unit:'',     
    });
    const [submitAdd] = useDijieTemplateFeiYongMingXiAddMutation()
    const [open,setOpen] = useState(false)
    const editForm = useRef()
    console.log('props',props);
    return (
        <ModalAdd 
            title='费用明细模板'
            onOk={(e) => {
                submitAdd({
                    ltfymx_e_id:localStorage.getItem('e_id'),
                    ltfymx_uid:localStorage.getItem('eu_id'),
                    ltfymx_uname:localStorage.getItem('eu_name'),
                    ltfymx_dept_id:props.ltbjd_dept_id?props.ltbjd_dept_id:info.ltfymx_dept_id,
                    ltfymx_type_id:info.ltfymx_type_id,
                    ltfymx_name:info.ltfymx_name,
                    ltfymx_price:info.ltfymx_price,
                    ltfymx_unit:info.ltfymx_unit,
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,ltfymx_dept_id:'',ltfymx_name:'',}))
                    form.resetFields(); 
                }
            }}
            width={800}
        >
            <Form
                ref={editForm}
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
            >                                           
                {!props.ltbjd_dept_id && <GetUserDept 
                    label='部门' 
                    name='dept'
                    required 
                    wrapperCol={{span:5}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,ltfymx_dept_id:e}))}}
                />}
                <Form.Item 
                    name="type" 
                    label='类型' 
                    wrapperCol={{span:20}} 
                    required 
                >
                    <Radio.Group 
                        optionType="button" 
                        options={localCostType}
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltfymx_type_id:e.target.value}))}}
                    />
                </Form.Item>
                <Form.Item name="remark" label='费用名称' wrapperCol={{span:20}} required>
                    <Input 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltfymx_name:e.target.value}))}}
                    />
                </Form.Item>
                <Form.Item name="price" label='单价' wrapperCol={{span:20}} >
                    <InputNumber 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltfymx_price:e}))}}
                    />
                </Form.Item>
                <Form.Item name="unit" label='单位' wrapperCol={{span:8}}  extra='例如：元/人，元/间房'>
                    <Input 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltfymx_unit:e.target.value}))}}
                    />
                </Form.Item>
            </Form>
        </ModalAdd>
    )
}

export default PriceAdd