import { Form, Modal, Space } from 'antd';
import React, { useState } from 'react'
import OpClient from './Client';

function ClientForm(props) {
    const [open,setOpen] = useState(false)    
    return (
        <Form.Item
            label='客户'                    
            required                    
        >
            <Space align='center'>
                <div>{props.ltbjd_client_name}</div>                     
                <a onClick={() => {setOpen(true)}}>请选择</a>
                <Modal
                    open={open}
                    okText="提交"
                    cancelText="取消"
                    onCancel={() => {setOpen(false);}}
                    onOk={() => {}}
                    footer={null}
                    width={800}
                    title='选择客户'
                >
                    <OpClient 
                        type='select'
                        getClient={(e) => {
                            props.getClient({
                                ltbjd_ltkh_id:e.ltkh_id,
                                ltbjd_client_name:e.ltkh_name,
                                ltbjd_kehu_type_id:e.ltkh_type_id,
                            })                            
                        }}
                        getOpen={(e) => {setOpen(false)}}
                    />
                </Modal> 
            </Space>
        </Form.Item>   
    )
}

export default ClientForm