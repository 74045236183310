import React from 'react'
import Main from '../../../../cpn/Main'
import Crowd from './Crowd'
function CustomCrowd() {
    return (
        <Main t1='国内地接' n1='/LocalIndex' t2='定制游' n2='/CustomIndex' t3='模板' n3='/CustomTempIndex' t4='人群类型'>
            <Crowd />
        </Main>
    )
}

export default CustomCrowd