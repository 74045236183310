import React,{useRef, useState}from 'react'
import { Form,Input,message, Radio} from 'antd';
import {  useDijieTemplateFeiYongBuHanAddMutation,} from '../../../../store/ReactWebSiteApi';
import ModalAdd from '../../../../cpn/ModalAdd';
import GetUserDept from '../../../../cpn/GetUserDept';
import { localCostType } from '../../customType';
function SelfAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState();
    const [submitAdd] = useDijieTemplateFeiYongBuHanAddMutation()
    const [open,setOpen] = useState(false)
    const editForm = useRef()
    return (
        <ModalAdd 
            title='费用不含模板'
            onOk={(e) => {
                console.log(props);
                submitAdd({
                    ltfybh_e_id:localStorage.getItem('e_id'),
                    ltfybh_uid:localStorage.getItem('eu_id'),
                    ltfybh_uname:localStorage.getItem('eu_name'),
                    ltfybh_dept_id:props.ltbjd_dept_id?props.ltbjd_dept_id:info.ltfybh_dept_id,
                    ltfybh_type_id:info.ltfybh_type_id,
                    ltfybh_name:info.ltfybh_name,
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,ltfybh_dept_id:'',ltfybh_name:'',}))
                    form.resetFields(); 
                }
            }}
            width={800}
        >
            <Form
                ref={editForm}
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
            >                                           
                {!props.ltbjd_dept_id&&<GetUserDept 
                    label='部门' 
                    name='dept'
                    required 
                    wrapperCol={{span:5}}
                    getUserDept={(e) => {setInfo(prevsState=>({...prevsState,ltfybh_dept_id:e}))}}
                />}
                <Form.Item 
                    name="type" 
                    label='类型' 
                    wrapperCol={{span:20}} 
                    required
                >
                    <Radio.Group 
                        optionType="button" 
                        options={localCostType}
                        onChange={(e) => {
                            setInfo(prevState=>({...prevState,ltfybh_type_id:e.target.value}))
                        }}
                    ></Radio.Group>
                </Form.Item>
                <Form.Item name="remark" label='费用不含' wrapperCol={{span:20}} required>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltfybh_name:e.target.value}))}}/>
                </Form.Item>
            </Form>
        </ModalAdd>
    )
}

export default SelfAdd