import React,{useState,useRef,useEffect} from 'react'
import { useIncomeExpenditureUpdateQuery,useIncomeExpenditureUpdateSaveMutation } from '../../store/ReactWebSiteApi';
import { Modal,message,Form,Input,Button,InputNumber,Upload,Space} from 'antd';
import { UploadOutlined} from '@ant-design/icons';
import GetClient from '../client/GetClient';
import dayjs from 'dayjs';
import GetBalanceType from './GetBalanceType';
import useInAndOut from './useInAndOut';
import GetUserDept from '../../cpn/GetUserDept';
import GetUser from '../../cpn/GetUser';
import LedgerBalanceDesc from '../ledger/LedgerBalanceDesc';
const { TextArea } = Input;

function BalanceElseEdit(props) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const onCreateEdit = (values) => {
        // console.log('Received values of form: ', values);
        setOpen(false);
    };    
    const [info,setInfo] = useState({
        eie_id:'',
        eie_image_name:'',
        eie_image_file:'',
        eie_content:'',
        eie_count:1,
        ec_name:'',
        eie_yw_name:''
    });
    const editForm = useRef()
    const inAndOut = useInAndOut(props.efst_finance_type_id)
    //上传图片
    const [defaultPic,setDefaultPic] = useState();
    const handleChangeEdit = (e) => {
        // console.log(e);
        if (e.file.status === 'done'||e.file.status==='removed') {
            const fileArr = e.fileList.map((item) => {
                const { response } = item
                return response.Result
            })
            // console.log(e);
            let fileStr = fileArr + "";  
            if(fileStr === null){
                fileStr = ''
            }
            const nameArr = e.fileList.map((item) => {
                const { name} = item
                return name
            })
            let nameStr = nameArr + ""
            if(nameStr === null){
                nameStr = ''
            }
            setInfo(prevState=>({...prevState,eie_image_name:nameStr,eie_image_file:fileStr}))
        }
    }
    // console.log('props.eie_id',info.ec_name);
    const {data,isSuccess} = useIncomeExpenditureUpdateQuery({eie_id:info.eie_id},{skip:!open,refetchOnMountOrArgChange:1,})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data); 
                // console.log(data.Result.eie_arrive_year + '-' + data.Result.eie_arrive_month + '-' + data.Result.eie_arrive_day);               
                //默认图片
                if (data.Result.eie_image_file && data.Result.eie_image_file[0] !== 'null') {
                    let options = [];
                    let size = data.Result.eie_image_file.length;
                    for (let i = 0; i < size; i++) {
                        let a = {};
                        a.uid = i;
                        a.url = `/api/assets/images/application/${data.Result.eie_image_file[i]}`
                        a.response = {Result:data.Result.eie_image_file[i]}
                        options.push(a);
                    }
                    //console.log(options);
                    setDefaultPic(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        defaultFileList={options}
                        className="upload-list-inline"
                        onChange={handleChangeEdit}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }else{
                    setDefaultPic(<Upload
                        name="image_file"
                        action="/api/ReactApi/zd_single_upload_image"
                        listType="picture"
                        className="upload-list-inline"
                        onChange={handleChangeEdit}
                        >
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>)
                }
                let obj = {}
                if(props.guige){
                    const arr = props.guige.map((item,index) => {
                        return <Form.Item key={item.egg_id} name={`eg${item.egg_id}`} >
                            <InputNumber addonBefore={item.egg_name} />
                        </Form.Item>
                    })
                    setInfo(prevState=>({...prevState,guigeForm:arr}))
                    obj = props.guige.reduce((accumulator, currentValue,index) => {
                        const name = 'eg'+ currentValue.egg_id
                        accumulator[name] = data.Result.eie_egg_value[index];
                        return accumulator;
                      }, {});
                }
                editForm.current.setFieldsValue({
                    eie_price:data.Result.eie_price,
                    eie_dept_id:data.Result.eie_dept_id,
                    ec_name:data.Result.ec_name,
                    eie_count:data.Result.eie_count,
                    eie_amount:data.Result.eie_amount,
                    eie_content:data.Result.eie_content,
                    eie_client_id:[data.Result.ec_name],
                    eie_eg_id:[data.Result.eie_eg_id],
                    eg_egt_id:[data.Result.eg_egt_id],
                    eie_jbr_uid:data.Result.eie_jbr_uid, 
                    eie_yw_name:data.Result.eie_yw_name, 
                    eie_yw_id:data.Result.eie_yw_id, 
                    eie_finance_sub_type_id:[data.Result.eie_finance_sub_type_id],
                    eie_year:dayjs(data.Result.eie_arrive_year + '-' +data.Result.eie_arrive_month + '-' + data.Result.eie_arrive_day),
                    ...obj,
                })
                setInfo(prevState=>({...prevState,
                    eie_price:data.Result.eie_price,
                    eie_ebn_id:data.Result.eie_ebn_id,
                    eie_eci_id:data.Result.eie_eci_id,
                    eie_eg_id:data.Result.eie_eg_id,
                    eie_dept_id:data.Result.eie_dept_id,
                    eie_count:data.Result.eie_count,
                    eie_content:data.Result.eie_content,        
                    eie_client_id:data.Result.eie_client_id,                            
                    eie_jbr_uid:data.Result.eie_jbr_uid,        
                    eie_jbr_name:data.Result.eie_jbr_name,        
                    eie_image_file:data.Result.eie_image_file,        
                    eie_image_name:data.Result.eie_image_name,        
                    eie_finance_sub_type_name:data.Result.eie_finance_sub_type_name,        
                    eie_finance_sub_type_id:data.Result.eie_finance_sub_type_id,        
                    ec_name:data.Result.ec_name,        
                    eie_audit1_ctime:data.Result.eie_audit1_ctime,        
                    eie_egg_value:data.Result.eie_egg_value,        
                    eie_year:data.Result.eie_arrive_year + '-' +data.Result.eie_arrive_month + '-' + data.Result.eie_arrive_day, 
                    eie_yw_name:data.Result.eie_yw_name?data.Result.eie_yw_name:'', 
                    eie_yw_id:data.Result.eie_yw_id,      
                }))
                
            }
        }
    },[data, isSuccess, open, props.guige])
    const [submitUpdateSave] = useIncomeExpenditureUpdateSaveMutation()
    //返回详情
    const [openCheck, setopenCheck] = useState({id:'',open:false})
    return (
        <>
            {(!props.eie_audit1_ctime && !props.eie_hx_ctime) && <a 
                key='update'
                onClick={() => {                    
                    setOpen(true);
                    setInfo(prevState=>({...prevState,eie_id:props.eie_id}))                    
                }}
                disabled={props.disabled}
            >修改</a>}
            <Modal
                open={open}
                title='修改销售收入'
                okText="提交"
                cancelText="取消"
                width={1000}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            
                            onCreateEdit(values);
                            let arr = []
                            for (let key in values) {
                                if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                    if(key.includes('eg')){
                                        arr.push(values[key])
                                    }
                                }
                            }                            
                            if(props.eie_ebn_id){
                                if(!props.eie_eg_id){
                                    message.error('修改失败，没有产品id！');
                                    return false;
                                }                                    
                            }
                            submitUpdateSave({
                                eie_e_id:localStorage.getItem('e_id'),
                                eie_dept_id:info.eie_dept_id,
                                eie_client_id:info.eie_client_id,
                                eie_eci_id:'',
                                eie_eg_id:info.eie_eg_id,
                                eie_ebn_id:'',
                                eie_price:+info.eie_price,
                                eie_count:info.eie_count,
                                eie_amount:info.eie_price*info.eie_count*inAndOut,
                                eie_jbr_uid:info.eie_jbr_uid,
                                eie_content:info.eie_content?info.eie_content:"",
                                eie_update_uid:localStorage.getItem('eu_id'),
                                eie_update_uname:localStorage.getItem('eu_name'),
                                eie_image_name:(info.eie_image_name+''==='null')?'':info.eie_image_name,
                                eie_image_file:(info.eie_image_file+''==='null')?'':info.eie_image_file,
                                eie_type_id:props.efst_finance_type_id,
                                eie_finance_sub_type_id:info.eie_finance_sub_type_id,
                                eie_finance_sub_type_name:info.eie_finance_sub_type_name,
                                eie_id:props.eie_id,
                                eie_year:info.eie_year, 
                                eie_egg_value:'',       
                                eie_yw_name:info.eie_yw_name, 
                                eie_yw_id:info.eie_yw_id,                             
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('修改成功！')   
                                    setOpen(false)       
                                    if(!props.fromDesc){
                                        setopenCheck({id:props.eie_id,open:true})  
                                    }                                  
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('修改失败！'+res.data.Message)
                                }
                            })
                            form.resetFields();   
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        });
                    // console.log('111',info.eie_image_name+'',info.eie_image_file+'');
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client_edit"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    ref={editForm}
                >
                    {props.eie_jbr_uname && <Form.Item label={props.jbr_label}>{props.eie_jbr_uname}</Form.Item>}                    
                    <GetUserDept 
                        label='部门' 
                        getUserDept={(e,f) => {
                            setInfo(prevState=>({...prevState,eie_dept_id:e}))
                        }}
                        wrapperCol={{span:10}}
                        required
                        name='eie_dept_id'
                    />
                    <GetBalanceType 
                        label='类型' 
                        wrapperCol={{span:10}}
                        efst_finance_type_id={props.efst_finance_type_id}
                        required 
                        name='eie_finance_sub_type_id'
                        getBalanceType={(e,f) => {
                            setInfo(prevState=>({
                                    ...prevState,
                                    eie_finance_sub_type_id:e,
                                    eie_finance_sub_type_name:f,
                                }))
                            }}
                        title={props.main_type_name}
                    />
                    <GetClient 
                        label='客户' 
                        getClient={(e,f) => {
                            setInfo(prevState=>({...prevState,eie_client_id:e}))
                        }} 
                        name='eie_client_id' 
                        required
                        wrapperCol={{span:15}}
                        style={{width:"200px"}}
                        clientAdd='true'
                    />
                    <Form.Item label='金额' wrapperCol={{span:15}} required style={{marginBottom:"0"}}>
                        <Space>
                            <Form.Item name="eie_price" wrapperCol={{span:24}} required>
                                <InputNumber 
                                    addonBefore='单价'
                                    min={0.01}
                                    onChange={(e) => {                            
                                        setInfo(prevState=>({...prevState,eie_price:e}))                           
                                    }}
                                    
                                />
                            </Form.Item>
                            <Form.Item name="eie_count" wrapperCol={{span:24}} required>
                                <InputNumber 
                                    addonBefore='数量'
                                    min={1}
                                    onChange={(e) => {                            
                                        setInfo(prevState=>({...prevState,eie_count:e}))                           
                                    }}
                                />
                            </Form.Item>
                            <Form.Item  wrapperCol={{span:24}} required>
                                <InputNumber 
                                    addonBefore='合计'
                                    min={0.01}
                                    value={info.eie_price*info.eie_count}
                                    readOnly
                                />
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    {info.guigeForm && <Form.Item label='产品数量' style={{marginBottom:'0'}} wrapperCol={{span:15}} >
                            <Space>{info.guigeForm}</Space>
                        </Form.Item>}                          
                    <Form.Item name="eie_content" label='备注' wrapperCol={{span:15}}>
                        <TextArea rows={4}  onChange={(e) => {setInfo(prevState=>({...prevState,eie_content:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item label="附件"                             
                        valuePropName='fileList'
                        getValueFromEvent={e => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                        >
                        {defaultPic}
                    </Form.Item>
                    <GetUser 
                        wrapperCol={{span:5}}  
                        label='经办人' 
                        name='eie_yw_id' 
                        required={false} 
                        getUser={(e,f) => {
                            console.log(e,f);
                            setInfo(prevState=>({...prevState,eie_yw_id:e,eie_yw_name:f}))
                        }}
                    />                             
                </Form>
            </Modal>
            <Modal 
                open={openCheck.open} 
                onOk={() => {}} 
                onCancel={() => {setopenCheck({open:false})}}
                width={1200}
                cancelText='关闭'
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>                       
                        <CancelBtn />
                    </>
                )}
            >
                <LedgerBalanceDesc 
                    eie_id={openCheck.id}
                    title={props.main_type_name}
                />
            </Modal> 
        </>
    )
}

export default BalanceElseEdit