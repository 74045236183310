import React from 'react'
import { Flex} from 'antd'
function DocTitle(props) {
  return (
            <Flex 
                justify='center'
                style={{fontSize:'30px',marginBottom:"20px",fontWeight:'bold',position:'relative'}}
            >
                {props.name}
                <span
                    style={{fontSize:"14px",position:"absolute",right:"0"}}
                >No.{props.num}</span>
            </Flex>
  )
}

export default DocTitle