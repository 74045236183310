import { Form, Select,} from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetCityQuery, } from '../../../store/ReactWebSiteApi';

function GetCity(props) {
    const [list,setList] = useState();
    const {data, isSuccess} = useGetCityQuery({province_id:props.province_id});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                const newArr = data.Result.map((item) => {
                    const {ctaci_id,ctaci_name,}=item;
                    return item = {value:ctaci_id,label:ctaci_name}
                })
                setList(newArr)
            }
        }
    },[data,isSuccess])
    return (
        <>
            <Form.Item 
                label={props.label} 
                wrapperCol={props.wrapperCol} 
                required={props.required} 
                name={props.name}           
            >
                <Select
                    options={list} 
                    onChange={(_,f) => {
                        // console.log(f);
                        props.getCity(f.value,f.label)
                    }}
                    defaultValue={props.defaultId}
                    placeholder={props.defaultId}
                    style={props.style}
                />
            </Form.Item>
        </>
    )
}

export default GetCity