import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react'

function GuideQuery(props) {    
    const [form] = Form.useForm();      
    const [open,setOpen] = useState(false);     
    return (
        <>
            <Button 
                onClick={() => {setOpen(true);}}
                type='primary'
            >查询</Button>
            <Modal
                open={open}
                title='查询导游'
                okText="提交"
                cancelText="取消"
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {                                        
                            form.resetFields();
                            props.getGuery({tgu_phone:'',tgu_name:''})
                            setOpen(false)
                            }}>重置</Button>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            console.log(values);
                            props.getGuery({tgu_phone:values.tgu_phone,tgu_name:values.tgu_name})
                            setOpen(false)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_in_modal"
                    initialValues={{tgu_phone:'',tgu_name:''}}
                    labelCol={{ span: 5 }}
                >
                    <Form.Item name="tgu_name" label='姓名' wrapperCol={{span:10}}>
                        <Input />
                    </Form.Item>                                
                    <Form.Item name="tgu_phone" label='手机号码' wrapperCol={{span:10}}>
                        <Input />
                    </Form.Item>                                
                </Form>
            </Modal>    
        </>
    )
}

export default GuideQuery