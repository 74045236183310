import { Descriptions, Flex, Modal, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import GuidePlanPay from './GuidePlanPay';
import { useDaoyoujihuadanUpdateQuery } from '../../../../store/ReactWebSiteApi';
import GuidePlanRoute from './GuidePlanRoute';
import GuidePlanEdit from './GuidePlanEdit';
import { guidePlanState } from '../../customType';
import GuidePlanState from './GuidePlanState';
import GuidePlanCopy from './GuidePlanCopy';
import GuidePlanCrowd from './GuidePlanCrowd';
import { imgSrc } from '../../../../cpn/imgSrc';

function GuidePlanCheck(props) {
    const [open, setOpen] = useState(false);   
    const [info,setInfo] = useState({
        dyjhd_tgu_name:''
    });
    //获取
    const {data,isSuccess} = useDaoyoujihuadanUpdateQuery({dyjhd_id:props.dyjhd_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){        
                setInfo(prevsState=>({...prevsState,
                    dyjhd_ltdy_name:data.Result.dyjhd_ltdy_name,                         
                    dyjhd_jietuanshijian:data.Result.dyjhd_jietuanshijian,                         
                    dyjhd_songtuanshijian:data.Result.dyjhd_songtuanshijian,                   
                    dyjhd_jietuandidian:data.Result.dyjhd_jietuandidian,                   
                    dyjhd_songtuandidian:data.Result.dyjhd_songtuandidian,       
                    dyjhd_jietuanshuoming:data.Result.dyjhd_jietuanshuoming,       
                    dyjhd_daofufei:data.Result.dyjhd_daofufei,       
                    dyjhd_daofufeishuoming:data.Result.dyjhd_daofufeishuoming,       
                    dyjhd_lth_num:data.Result.dyjhd_lth_num,       
                    dyjhd_fenche:data.Result.dyjhd_fenche,       
                    dyjhd_xianlu:data.Result.dyjhd_xianlu,       
                    dyjhd_ltdy_phone:data.Result.dyjhd_ltdy_phone,       
                    dyjhd_state:data.Result.dyjhd_state,       
                    dyjhd_querenzhang_image:data.Result.dyjhd_querenzhang_image,       
                    "dyjhd_lth_zhu_name" : data.Result.dyjhd_lth_zhu_name,
                    "dyjhd_lth_zhu_type" : data.Result.dyjhd_lth_zhu_type,
                    "dyjhd_lth_ci_type" : data.Result.dyjhd_lth_ci_type,
                    "dyjhd_lth_ci_type_name" : data.Result.dyjhd_lth_ci_type_name,
                    "dyjhd_lth_zi_type" : data.Result.dyjhd_lth_zi_type,
                    "dyjhd_lth_zi_type_name" : data.Result.dyjhd_lth_zi_type_name,    
                    "dyjhd_dept_name" : data.Result.dyjhd_dept_name,    
                    "dyjhd_uname" : data.Result.dyjhd_uname,    
                    "dyjhd_uphone" : data.Result.dyjhd_uphone,    
                    "dyjhd_zhiwu_name" : data.Result.dyjhd_zhiwu_name,    
                    "dyjhd_yemei_image" : data.Result.dyjhd_yemei_image,    
                }))
            }
        }
    },[data,isSuccess,])
    //基本信息
    const items = [        
        {
            key: '1',
            label: '旅行社',
            children: localStorage.getItem('e_name'),
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '2',
            label: '部门/计调',
            children: info.dyjhd_dept_name + ' - ' + info.dyjhd_uname + ' - ' + info.dyjhd_uphone,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '3',
            label: '导游',
            children: info.dyjhd_ltdy_name + ' - ' + info.dyjhd_ltdy_phone,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },   
        {
            key: '4',
            label: '接团时间',
            children: info.dyjhd_jietuanshijian,
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '5',
            label: '接团地点',
            children:info.dyjhd_jietuandidian,
            labelStyle:{width:'120px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '6',
            label: '线路名称',
            children: info.dyjhd_xianlu,
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '7',
            label: '送团时间',
            children: info.dyjhd_songtuanshijian,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '8',
            label: '送团地点',
            children:info.dyjhd_songtuandidian,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },         
        {
            key: '9',
            label: '分车号',
            children: info.dyjhd_fenche,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"}
        },           
       
        {
            key: '7',
            label: '接团说明',
            children: info.dyjhd_jietuanshuoming,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"},
            span:2
        },
        {
            key: '10',
            label: '团号',
            children:info.dyjhd_lth_num,
            contentStyle:{fontSize:"16px"},
        },
        {
            key: '10',
            label: '人数',
            children: <GuidePlanCrowd jhdgg_jhd_id={props.dyjhd_id} preview={info.preview}/>,
            span:3,
            contentStyle:{fontSize:"16px"}
        },         
        {
            key: '8',
            label: '职务',
            children: info.dyjhd_lth_zhu_name + ' - ' + info.dyjhd_lth_ci_type_name + ' - ' + info.dyjhd_lth_zi_type_name+' - '+info.dyjhd_zhiwu_name,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px"},
        },
        {
            key: '9',
            label: '导服费',
            children: info.dyjhd_daofufei,
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },          
        {
            key: '10',
            label: '导服说明',
            children: info.dyjhd_daofufeishuoming,
            labelStyle:{width:'100px'},
            contentStyle:{fontSize:"16px"}
        },                        
        {
            key: '11',
            label: '状态',
            contentStyle:{fontSize:"16px"},
            children:guidePlanState(info.dyjhd_state),
        },
        {
            key: '12',
            label: '附件',
            contentStyle:{fontSize:"16px"},
            children:<Space></Space>,            
        },
        {
            key: '13',
            label: '确认章',
            contentStyle:{fontSize:"16px"},
            children:(info.dyjhd_state !== '1' && <div style={{position:'relative'}}>
                                                        <img    
                                                            src={imgSrc+info.dyjhd_querenzhang_image} 
                                                            alt=''
                                                            style={{
                                                                position:"absolute",
                                                                top:'-50px',
                                                                zIndex:'999',
                                                                left:"-50px",
                                                                width:"200px",
                                                                height:"200px",
                                                            }}
                                                        />
                                                  </div>)
        },
        {
            key: '14',
            label: '操作',
            contentStyle:{fontSize:"16px"},
            children:<Space>
                <GuidePlanEdit
                     dyjhd_id={props.dyjhd_id}
                     dyjhd_state={info.dyjhd_state}
                />
                <GuidePlanState
                    dyjhd_state={info.dyjhd_state}
                    dyjhd_id={props.dyjhd_id}
                />
                <GuidePlanCopy
                    dyjhd_id={props.dyjhd_id}
                />
            </Space>,
            span:2
        },
        {
            key: '15',
            label: '编号',
            contentStyle:{fontSize:"16px"},
            children:props.dyjhd_id,            
        },
    ]
    return (
        <>
            <a  onClick={() => {
                setOpen(true);
                }} >{props.dyjhd_ltdy_name + ' - ' + props.dyjhd_ltdy_phone}</a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1200}
                footer={null}
            >
                <div style={{border:'2px solid #eee',margin:"10px"}}>
                    <img src={imgSrc+info.dyjhd_yemei_image}  width='100%' alt=''/>
                    <Flex 
                        justify='center' 
                        style={{fontSize:'30px',lineHeight:"88px",fontWeight:'bold'}}
                    >导游计划单</Flex>
                    <Descriptions
                        items={items} 
                        bordered 
                        size='small' 
                        labelStyle={{fontSize:"16px"}}
                    />
                    <GuidePlanRoute 
                        dyjhdxc_jhd_id={props.dyjhd_id}
                        dyjhd_state={info.dyjhd_state}
                    />
                    <GuidePlanPay 
                        dyjhdxc_jhd_id={props.dyjhd_id}
                        dyjhd_state={info.dyjhd_state}
                    />
                </div>
            </Modal>
        </>
    )
}

export default GuidePlanCheck