import React from 'react'
import { useDaoyoujihuadanStateMutation } from '../../../../store/ReactWebSiteApi'
import { message, Space } from 'antd'

function GuidePlanState(props) {
    const [add] = useDaoyoujihuadanStateMutation()
    return (
        <>
            {props.dyjhd_state==='1' && <a onClick={() => {
                add({
                    "dyjhd_id" : props.dyjhd_id,
                    "dyjhd_state" : '2',
                    "dyjhd_uid" : localStorage.getItem('eu_id'),
                    "dyjhd_uname" : localStorage.getItem('eu_name'),
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('发送成功！')                                        
                    }
                    if (res.data.Status === 'fail') {
                        message.error('发送失败！'+res.data.Message)
                    }
                })               
            }}>发送</a>}
            {props.dyjhd_state==='2' && <a onClick={() => {
                add({
                    "dyjhd_id" : props.dyjhd_id,
                    "dyjhd_state" : '1',
                    "dyjhd_uid" : localStorage.getItem('eu_id'),
                    "dyjhd_uname" : localStorage.getItem('eu_name'),
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('取消成功！')                                        
                    }
                    if (res.data.Status === 'fail') {
                        message.error('取消失败！'+res.data.Message)
                    }
                })               
            }}>取消发送</a>}
            {props.dyjhd_state==='3' && <Space>
                                            <a onClick={() => {
                                                add({
                                                    "dyjhd_id" : props.dyjhd_id,
                                                    "dyjhd_state" : '4',
                                                    "dyjhd_uid" : localStorage.getItem('eu_id'),
                                                    "dyjhd_uname" : localStorage.getItem('eu_name'),
                                                }).then((res) => {
                                                    console.log(res);
                                                    if (res.data.Status === 'success') {
                                                        message.success('确认成功！')                                        
                                                    }
                                                    if (res.data.Status === 'fail') {
                                                        message.error('确认失败！'+res.data.Message)
                                                    }
                                                })               
                                            }}>确认完成</a>
                                            <a onClick={() => {
                                                add({
                                                    "dyjhd_id" : props.dyjhd_id,
                                                    "dyjhd_state" : '5',
                                                    "dyjhd_uid" : localStorage.getItem('eu_id'),
                                                    "dyjhd_uname" : localStorage.getItem('eu_name'),
                                                }).then((res) => {
                                                    console.log(res);
                                                    if (res.data.Status === 'success') {
                                                        message.success('取消成功！')                                        
                                                    }
                                                    if (res.data.Status === 'fail') {
                                                        message.error('取消失败！'+res.data.Message)
                                                    }
                                                })               
                                            }}>取消计划</a>
                                        </Space>}
        </>
    )
}

export default GuidePlanState