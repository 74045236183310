import { Button, Form, InputNumber, message, Modal } from 'antd'
import React, {useState } from 'react'
import { useDijieBaoJiaDanGuiGeAddMutation } from '../../../store/ReactWebSiteApi';
import CrowdForm from '../temp/crowd/CrowdForm';
function FileTypeAdd(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const [submitAdd] = useDijieBaoJiaDanGuiGeAddMutation()
    const [info,setInfo] = useState({bjdgg_name:''});   
    return (
        <>
            <Button size='small' onClick={() => {setOpen(true)}}>新增</Button>
            <Modal
                open={open}
                width={800}
                title='新增人数'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        bjdgg_bjd_id:props.bjdgg_bjd_id,
                        bjdgg_name:info.bjdgg_name,
                        bjdgg_num:info.bjdgg_num,
                        bjdgg_uid:localStorage.getItem('eu_id'),
                        bjdgg_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    style={{marginTop:'10px'}}
                >
                    <CrowdForm 
                        label='人群类型'
                        required
                        getType={(e) => {
                            setInfo(prevState=>({...prevState,bjdgg_name:e}))
                        }}
                        crowd={info.bjdgg_name}
                    />
                    <Form.Item label='数量' wrapperCol={{span:20}} required >
                        <InputNumber onChange={(e) => {setInfo(prevState=>({...prevState,bjdgg_num:e}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default FileTypeAdd