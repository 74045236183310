import React,{useState}from 'react'
import { Modal,message,Form} from 'antd';
import { useDijieBaoJiaDanZhuYiShiXiangAddMutation, } from '../../../store/ReactWebSiteApi';
import Notes from '../temp/notes/Notes';
function FileNotesAdd(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState({
        bjdzysx_name:'',
        bjdzysx_content:'',
    });
    const [submitAdd] = useDijieBaoJiaDanZhuYiShiXiangAddMutation()
    //模版
    const [openTemplate,setOpenTemplate] = useState(false)
    const getTemplate = <>
            <Form.Item  label='请选择' required>
                <a onClick={() => {setOpenTemplate(true)}}>注意事项模板</a>               
            </Form.Item>        
            <Modal
                open={openTemplate}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenTemplate(false);}}
                onOk={() => {}}
                footer={null}
                width={1000}
                title='注意事项模板'
            >
                <Notes
                    get={true}
                    ltbjd_dept_id={props.ltbjd_dept_id}
                    getNotes={(e) => {
                        setInfo(prevState=>({
                            ...prevState,
                            bjdzysx_name:e.ltzysx_name,
                            bjdzysx_content:e.ltzysx_content,
                        }))
                    }}
                    getOpen={(e) => {setOpenTemplate(e)}}
                />
            </Modal>
        </>
    return (
        <>
            <a onClick={() => {
                setInfo(prevState=>({
                    ...prevState,                   
                    bjdzysx_name:'',
                    bjdzysx_content:'',
                }))
                setOpen(true)
                }} >新增</a>
            <Modal
                open={open}
                title='新增注意事项'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        bjdzysx_bjd_id:props.bjdzysx_bjd_id,
                        bjdzysx_content:info.bjdzysx_content,
                        bjdzysx_name:info.bjdzysx_name,
                        bjdzysx_uid:localStorage.getItem('eu_id'),
                        bjdzysx_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 4 }}
                >                                           
                    {getTemplate}
                    <Form.Item label='标题' wrapperCol={{span:20}} >
                        {info.bjdzysx_name}
                    </Form.Item>
                    <Form.Item label='描述' wrapperCol={{span:20}} >
                        {info.bjdzysx_content}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default FileNotesAdd