import React, { useEffect, useState } from 'react'
import { message,  Space,  } from 'antd'
import TableCheck from '../../../../cpn/TableCheck'
import ModalDel from '../../../../cpn/ModalDel'
import GuidePlanRouteAdd from './GuidePlanRouteAdd'
import GuidePlanRouteEdit from './GuidePlanRouteEdit'
import { useDaoyoujihuadanxingchengDelMutation, useDaoyoujihuadanxingchengListQuery } from '../../../../store/ReactWebSiteApi'
import { CheckOutlined,CloseOutlined } from '@ant-design/icons';
function GuidePlanRoute(props) {
    const [list,setList] = useState()
    const {data,isSuccess} = useDaoyoujihuadanxingchengListQuery({dyjhdxc_jhd_id:props.dyjhdxc_jhd_id})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const newArr = data.Result.map((item,index) => {
                    // console.log(data);
                    const {dyjhdxc_id:key,dyjhdxc_wancan,dyjhdxc_ctime,dyjhdxc_anpai,dyjhdxc_mingcheng,dyjhdxc_zaocan,dyjhdxc_day,dyjhdxc_zhongcan,dyjhdxc_zhusu} = item
                    
                    return item={key,index,dyjhdxc_wancan,dyjhdxc_ctime,dyjhdxc_anpai,dyjhdxc_mingcheng,dyjhdxc_zaocan,dyjhdxc_day,dyjhdxc_zhongcan,dyjhdxc_zhusu}
                })
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.dyjhdxc_day;
                    let bTimeString = b.dyjhdxc_day;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return  aTime - bTime;     
                    },)                
                setList(sortArr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDaoyoujihuadanxingchengDelMutation()
   
    const column = [
        {
            key:'num',
            title:'天数',
            render:(_,record) => {
                return <>{record.index + 1}</>
            },
            width:'60px'
        },   
        {
            key:'1',
            title:'日期',
            dataIndex:'dyjhdxc_day',     
            width:'100px',      
        },
       
        {
            key:'13',
            title:'行程安排',
            dataIndex:'dyjhdxc_anpai',
        },           
        {
            key:'4',
            title:'早餐',
            dataIndex:'dyjhdxc_zaocan',
            width:'60px',
            render:(text) => {
                if(text==='1'){
                    return <CheckOutlined />
                }else{
                    return <CloseOutlined />
                }
            }
        },              
        {
            key:'14',
            title:'中餐',
            dataIndex:'dyjhdxc_zhongcan',
            width:'60px',
            render:(text) => {
                if(text==='1'){
                    return <CheckOutlined />
                }else{
                    return <CloseOutlined />
                }
            }
        },              
        {
            key:'24',
            title:'晚餐',
            dataIndex:'dyjhdxc_wancan',
            width:'60px',
            render:(text) => {
                if(text==='1'){
                    return <CheckOutlined />
                }else{
                    return <CloseOutlined />
                }
            }
        },              
        {
            key:'34',
            title:'住宿',
            dataIndex:'dyjhdxc_zhusu',
            width:'200px',
        },              
        {
            key:'5',
            title:'操作',            
            width:'120px',
            render:(_,record) => {
                return<Space>
                    <GuidePlanRouteEdit
                        dyjhdxc_id={record.key}
                        dyjhdxc_jhd_id={props.dyjhdxc_jhd_id}
                    />
                    <ModalDel onOk={() => {
                        del({dyjhdxc_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                </Space>
            }
        },        
    ]
    if(props.noAdd || props.preview){
        column.pop()
    }
    if(props.dyjhd_state!=='1'){
        column.splice(5)
    }
    return (    
        <TableCheck 
            title='行程安排'
            titleRight={props.dyjhd_state ==='1' && <GuidePlanRouteAdd
                dyjhdxc_jhd_id={props.dyjhdxc_jhd_id}
                />}
            columns={column}
            dataSource={list}
            size={props.customType==='1'?'middle':'small'}
        />
    )
}

export default GuidePlanRoute