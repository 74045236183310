import React,{useEffect, useRef, useState}from 'react'
import { Modal,message,Form,Input, DatePicker, Checkbox,} from 'antd';
import {useDaoyoujihuadanxingchengUpdateQuery, useDaoyoujihuadanxingchengUpdateSaveMutation, } from '../../../../store/ReactWebSiteApi';
import dayjs from 'dayjs'
const {TextArea} = Input
function GuidePlanRouteEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const {data,isSuccess} = useDaoyoujihuadanxingchengUpdateQuery({dyjhdxc_id:props.dyjhdxc_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                let arr = []
                if(data.Result.dyjhdxc_zaocan==='1'){
                    arr.push('1')
                };
                if(data.Result.dyjhdxc_zhongcan==='1'){
                    arr.push('2')
                };
                if(data.Result.dyjhdxc_wancan==='1'){
                    arr.push('3')
                };
                if(data.Result.dyjhdxc_zhusu==='1'){
                    arr.push('4')
                };
                editForm.current.setFieldsValue({
                    dyjhdxc_day:dayjs(data.Result.dyjhdxc_day),
                    dyjhdxc_anpai:data.Result.dyjhdxc_anpai,
                    meal:arr,                   
                   
                })
                setInfo(prevState=>({...prevState,
                    dyjhdxc_day:data.Result.dyjhdxc_day,
                    dyjhdxc_anpai:data.Result.dyjhdxc_anpai,
                    dyjhdxc_zaocan:data.Result.dyjhdxc_zaocan,                   
                    dyjhdxc_zhongcan:data.Result.dyjhdxc_zhongcan,
                    dyjhdxc_wancan:data.Result.dyjhdxc_wancan,
                    dyjhdxc_zhusu:data.Result.dyjhdxc_zhusu,
                }))
            }
        }
    },[data,isSuccess])
    const [info,setInfo] = useState({
        isChoose:false,
    });
    const [submitAdd] = useDaoyoujihuadanxingchengUpdateSaveMutation()
    
    return (
        <>
            <a onClick={() => {setOpen(true)}} >修改</a>
            <Modal
                open={open}
                title='修改行程安排'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        "dyjhdxc_anpai" : info.dyjhdxc_anpai,
                        "dyjhdxc_day" : info.dyjhdxc_day,
                        "dyjhdxc_id" : props.dyjhdxc_id,
                        "dyjhdxc_jhd_id" : props.dyjhdxc_jhd_id,
                        "dyjhdxc_update_uid" : localStorage.getItem('eu_id'),
                        "dyjhdxc_update_uname" : localStorage.getItem('eu_name'),
                        "dyjhdxc_wancan" : info.dyjhdxc_wancan,
                        "dyjhdxc_zaocan" : info.dyjhdxc_zaocan,
                        "dyjhdxc_zhongcan" : info.dyjhdxc_zhongcan,
                        "dyjhdxc_zhusu" : info.dyjhdxc_zhusu,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    ref={editForm}
                >                                           
                   <Form.Item name="dyjhdxc_day" label='日期' wrapperCol={{span:10}} required>
                        <DatePicker 
                            onChange={(e) => {setInfo(prevState=>({...prevState,dyjhdxc_day:e}))}}
                        />
                    </Form.Item>
                    <Form.Item name="dyjhdxc_anpai" label='行程安排' wrapperCol={{span:10}} required>
                        <TextArea 
                             onChange={(e) => {setInfo(prevState=>({...prevState,dyjhdxc_anpai:e.target.value}))}}
                        />
                    </Form.Item>                   
                    <Form.Item                         
                        label='用餐住宿' 
                        wrapperCol={{span:20}}   
                        name='meal'                       
                    >
                        <Checkbox.Group
                            onChange={(e) => {
                                if(e.includes('1')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_zaocan:'1'}))
                                };
                                if(e.includes('2')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_zhongcan:'1'}))
                                };
                                if(e.includes('3')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_wancan:'1'}))
                                };
                                if(e.includes('4')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_zhusu:'1'}))
                                };
                                if(!e.includes('1')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_zaocan:'2'}))
                                };
                                if(!e.includes('2')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_zhongcan:'2'}))
                                };
                                if(!e.includes('3')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_wancan:'2'}))
                                };
                                if(!e.includes('4')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_zhusu:'2'}))
                                };
                            }}
                        >
                            <Checkbox value='1'>早餐</Checkbox>
                            <Checkbox value='2'>中餐</Checkbox>
                            <Checkbox value='3'>晚餐</Checkbox>
                            <Checkbox value='4'>住宿</Checkbox>
                        </Checkbox.Group>                         
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GuidePlanRouteEdit