import React,{useState,useEffect,}from 'react'
import { Table, Flex, Descriptions, Space, Tag,Spin} from 'antd';
import {useHuoquxiazhangpingzhengListQuery, useIncomeExpenditureHxFinishLogListQuery,} from '../../store/ReactWebSiteApi';
import { balanceType } from '../balance/balanceType';
import ArapEnterDel from './ArapEnterDel';
import LedgerGroupBalanceCheck from '../ledger/LedgerBalanceCheck';
import FundCheck from '../fund/FundCheck';
function ArapCheck(props) {   
    //查看下账凭证
    const {data:dataDesc,isSuccess:isSuccessDesc} = useHuoquxiazhangpingzhengListQuery({eie_hx_number:props.eie_hx_number},{skip:!props.eie_hx_number})
    const [info,setInfo] = useState({
        ec_name:'',
        eie_bank_ctime:'',
        eie_hx_amount:'',
        eie_hx_uname:'',
        eie_hx_ctime:'',
        eie_bank_log_id:'',
        num:'',
        eie_bank_date:'',
    });
    useEffect(() => {
        if(isSuccessDesc){
            if(dataDesc.Status==='success'){
                console.log(dataDesc);
                setInfo(prevState=>({
                    ...prevState,
                    ec_name:dataDesc.Result[0].ec_name,
                    eie_bank_ctime:dataDesc.Result[0].eie_bank_ctime,
                    eie_hx_amount:dataDesc.Result[0].eie_hx_amount,
                    eie_hx_uname:dataDesc.Result[0].eie_hx_uname,
                    eie_hx_ctime:dataDesc.Result[0].eie_hx_ctime,
                    eie_bank_log_id:dataDesc.Result[0].eie_bank_log_id,
                    eie_hx_content:dataDesc.Result[0].eie_hx_content,
                    num:dataDesc.Result[0].num,                    
                    eie_bank_date:dataDesc.Result[0].eie_bank_date,                    
                }))
            }
        }
    },[dataDesc,isSuccessDesc])
    const items = [
        {
            key:"1",
            label:'客户',
            children:info.ec_name,
        },
        {
            key:"2",
            label:'下账数量',
            children:info.num,
        },
        {
            key:"3",
            label:'下账金额',
            children:info.eie_hx_amount,
        },
        {
            key:"4",
            label:'备注',
            children:info.eie_hx_content,
        },
        {
            key:"5",
            label:'操作时间',
            children:info.eie_hx_ctime + info.eie_hx_uname,
        },
        {
            key:"6",
            label:'编号',
            children:props.eie_hx_number,
        },
        {
            key:"7",
            label:'转账日期',
            children:info.eie_bank_date,
        },
        {
            key:"8",
            label:'转账凭证',
            children:<FundCheck ecal_id={info.eie_bank_log_id} />,
        },
        {
            key:"9",
            label:'操作',
            children:<>{!info.eie_bank_log_id && <ArapEnterDel eie_hx_number={info.eie_hx_number} />}</>,
        },
    ]


    //收支列表
    const {data,isSuccess} = useIncomeExpenditureHxFinishLogListQuery({eie_hx_number:props.eie_hx_number},{skip:!props.eie_hx_number,refetchOnMountOrArgChange:1})
    const [list, setList] = useState();
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log('balanceList',data);
                
                const newArr = data.Result.map(item => {
                    const {eie_id:key,guige,ebn_team_num,eie_arrive_year,eie_type_id,eie_egg_value,eie_arrive_month,eie_arrive_day,eie_finance_sub_type_name,eie_hx_ctime,ed_name,eie_jbr_uname,eie_audit1_ctime,eie_audit1_uname,eie_audit2_ctime,eie_audit2_uname,eie_hx_year,eie_hx_month,eie_hx_day,eie_amount,ec_name,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eg_name,eu_name} = item;
                    let type
                    let arr = balanceType.filter(obj => obj.value === eie_type_id)
                    if(arr[0]){
                        type = arr[0].label
                    }
                    return item = {key,guige,type,ebn_team_num,eie_arrive_year,eie_egg_value,eie_arrive_month,eie_arrive_day,eie_finance_sub_type_name,eie_hx_ctime,ed_name,eie_jbr_uname, eie_audit1_ctime,eie_audit1_uname,eie_audit2_ctime,eie_audit2_uname,eie_hx_year,eie_hx_month,eie_hx_day,eie_amount,ec_name,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eg_name,eu_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eie_ctime;
                    let bTimeString = b.eie_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
                
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key: 'id',
            title: '收支单',
            dataIndex: 'key',
            render:(_,record) => {
                return  <LedgerGroupBalanceCheck                         
                            eie_id={record.key}
                            title={record.type}
                        />
            }
        },        
        {
            key: 'pic',
            title: '类型',
            render:(_,record) => {
                return <>
                    {record.type}-{record.eie_finance_sub_type_name}
                </>
            }           
        }, 
        {
            key: 'client',
            title: '客户',
            dataIndex: 'ec_name',
        },                           
        {
            key: 'eie_amount',
            title: '金额',
            render:(_,record) => {
                return <>{record.eie_price} × {record.eie_count} = {record.eie_amount}</>
            }
        },        
        {
            key: 'guige',
            title: '数量',
            dataIndex:"guige",
            render:(text) => {
                let arr = []
                if(text){
                    arr = text.map((item) => {
                        return <div key={item.eiegg_egg_id}>{item.eiegg_egg_name+item.eiegg_egg_value}</div>
                    })
                }
                return <Space>{arr}</Space>
            }
        },                     
        {
            key: 'eie_content',
            title: '备注',
            dataIndex: 'eie_content',
        },  
        {
            key: 'eie_jbr_uname',
            title: '经办人',
            dataIndex:'eie_jbr_uname',
        },             
                                                 
        {
            key: 'ed_name',
            title: '部门',
            dataIndex: 'ed_name'
        },                                          
                                                
        {
            key: 'status',
            title: '审核',
            render:(_,record) => {
                return<>
                    {!record.eie_audit1_uname && <>待审核</>}
                    {(record.eie_audit1_uname && !record.eie_audit2_uname) && <Tag color='green'>已审核</Tag>}
                    {record.eie_audit2_uname && <Tag color="volcano">已复核</Tag>}
                </>
            }
        },                        
             
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.eie_ctime + record.eie_uname}</div>
        //             <div>修改时间：{record.eie_update_ctime + record.eie_update_uname}</div>
        //         </>
        //     }
        // },
       
    ]
    return (
            <>
                {info.eie_hx_ctime ?<div style={{border:'2px solid #eee',margin:"10px"}}>
                                        <Flex justify='center'><h2>下账凭证</h2></Flex>
                                        {info.ec_name && <Descriptions                                  
                                            items={items} 
                                            bordered
                                        />}
                                        <Table 
                                            columns={columns} 
                                            dataSource={list}
                                            pagination={{position:['none']}}
                                            style={{marginTop:'10px'}}
                                            title={() => '收支单明细'}
                                        />
                                    </div> : <Spin tip='生成中。。。' size='large' />} 
            </>
    )
}

export default ArapCheck