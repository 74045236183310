import React from 'react'
import Main from '../../../../cpn/Main'
import Page from './Page'
function CustomPage() {
    return (
        <Main t1='国内地接' n1='/LocalIndex' t2='定制游' n2='/CustomIndex' t3='模板' n3='/CustomTempIndex' t4='页面和确认章模板'>
            <Page />
        </Main>
    )
}

export default CustomPage