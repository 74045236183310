import React,{ useState}from 'react'
import {Form,Input, message} from 'antd';
import GetUserDept from '../../../../cpn/GetUserDept';
import ModalAdd from '../../../../cpn/ModalAdd';
import { useDijieTemplateKeHuAddMutation } from '../../../../store/ReactWebSiteApi';

function ClientAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        ltkh_content:'',        
    });
    console.log(info);
    const [open,setOpen] = useState(false)
    const [add] = useDijieTemplateKeHuAddMutation()
    return (
        <ModalAdd
            title='客户'
            onOk={(e) => {
                add({
                    ltkh_content: info.ltkh_content,
                    ltkh_dept_id: info.ltkh_dept_id,
                    ltkh_dept_name: info.ltkh_dept_name,
                    ltkh_e_id: localStorage.getItem('e_id'),
                    ltkh_e_name: localStorage.getItem('e_name'),
                    ltkh_name: info.ltkh_name,
                    ltkh_phone: info.ltkh_phone,
                    ltkh_lianxiren: info.ltkh_lianxiren,
                    ltkh_uid: localStorage.getItem('eu_id'),
                    ltkh_uname:localStorage.getItem('eu_name'), 
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,lttt_dept_id:'',lttt_image:'',lttt_phone:''}))
                    form.resetFields(); 
                }
            }}
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 5 }}
            >   
                <GetUserDept
                    label='部门' 
                    name='dept'
                    required 
                    wrapperCol={{span:18}}
                    getUserDept={(e,f) => {setInfo(prevsState=>({...prevsState,ltkh_dept_id:e,ltkh_dept_name:f}))}}
                />  
                                             
                <Form.Item name="remark" label='客户' wrapperCol={{span:18}} required>
                    <Input 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkh_name:e.target.value}))}} 
                    />  
                </Form.Item>                              
                <Form.Item name="name" label='联系人' wrapperCol={{span:18}} required>
                    <Input 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkh_lianxiren:e.target.value}))}}
                    />
                </Form.Item> 
                <Form.Item name="type" label='手机号码' wrapperCol={{span:18}} required>
                    <Input 
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkh_phone:e.target.value}))}}
                    />
                </Form.Item> 
                <Form.Item name="content" label='备注' wrapperCol={{span:18}}>
                    <Input  
                        onChange={(e) => {setInfo(prevsState=>({...prevsState,ltkh_content:e.target.value}))}} 
                    />
                </Form.Item>               
            </Form>
        </ModalAdd>
    )
}

export default ClientAdd