import { Button, Form, Input, Modal } from 'antd'
import React, { useState } from 'react'

function ClientQuery(props) {
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();  
    return (
        <>
            <Button onClick={() => { setOpen(true)}}>查询</Button>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            console.log(values);
                            props.getQuery({
                                ltkh_phone:values.ltkh_phone,
                                ltkh_name:values.ltkh_name,
                            })
                            setOpen(false)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });                    
                }}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {                                        
                            form.resetFields();  
                            setOpen(false)
                            props.getQuery({
                                ltkh_phone:'',
                                ltkh_name:'',
                            })
                            }}>重置</Button>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )} 
                title='查询客户'
            >
                <Form
                    layout="horizonal"
                    name="form_client"
                    labelCol={{ span: 6 }}
                    initialValues={{ltxl_dept_id: '',ltkh_name:'',ltkh_phone:''}}
                    form={form}
                >                                           
                    <Form.Item name="ltkh_name" label='名称' wrapperCol={{span:10}}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="ltkh_phone" label='手机号码' wrapperCol={{span:10}}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ClientQuery