import React ,{useState,useEffect}from 'react'
import {Modal,Flex,List,Space} from 'antd';
import Program from '../cpn/Program';
import update from '../svg/update.svg'
import { useAppSynthesisListQuery } from '../store/ReactWebSiteApi';

function MineUpdate() {
    //更新日志
    const [list,setList]  = useState()
    const [open, setOpen] = useState(false)
    const {data,isSuccess} = useAppSynthesisListQuery({
        "esvs_esv_id" : '2',
        "eu_id" : localStorage.getItem('eu_id'),
    },{skip:!open})
    useEffect(() => {
      if(isSuccess){
        if(data.Status==='success'){
            setList(data.Result)
        }
      }
    
    }, [data,isSuccess])
    //更新日志详情
    const [openDesc, setopenDesc] = useState(false)
    const [desc, setDesc] = useState(false)

    return (
        <>
            <Program title='更新日志' onClick={() => {setOpen(true)}} icon={<img alt='' src={update} width={40}/>}/>
            <Modal 
                open={open} 
                onCancel={() => {setOpen(false)}}
                footer={null}
                width={800}
            >
                <Flex justify='center'><h2>更新日志</h2></Flex>
                <List
                    dataSource={list}
                    renderItem={(item) => (
                        <List.Item>
                            <Space
                                onClick={() => {
                                    setopenDesc(true)
                                    setDesc(item)
                                }}
                                style={{cursor:"pointer"}}
                            >
                                <h3>{item.esvs_name}</h3>
                                <div>{item.esvs_ctime}</div>
                            </Space>
                        </List.Item>
                    )}
                />
            </Modal> 
            <Modal 
                open={openDesc} 
                onCancel={() => {setopenDesc(false)}}
                footer={null}
                width={1000}
            >
                <Flex justify='center'><h2>{desc.esvs_name}</h2></Flex>
                <div ></div>
            </Modal> 
        </>
    )
}

export default MineUpdate