import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../cpn/ModalEdit'
import { Form,Input,message,  Radio, Space } from 'antd'
import { useShangpinUpdateQuery, useShangpinUpdateSaveMutation,  } from '../../../store/ReactWebSiteApi';
import GetUserDept from '../../../cpn/GetUserDept';
import GetCountry from './GetCountry';
import GetProvince from './GetProvince';
import GetCity from './GetCity';
import MyEditor from '../../../cpn/MyEditor';
import UploadOne from '../../../cpn/UploadOne';

function CustomGoodsEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        sp_country_id:'',
        sp_country_name:'',
        sp_province_id:'',
        sp_province_name:'',
        sp_city_id:'',
        sp_city_name:'',
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useShangpinUpdateQuery({sp_id:props.sp_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){               
                editForm.current.setFieldsValue({
                    sp_country_name:data.Result.sp_country_name,                              
                    sp_province_name:data.Result.sp_province_name,                              
                    sp_city_name:data.Result.sp_city_name,                              
                    sp_dept_name:data.Result.sp_dept_name,                              
                    sp_name:data.Result.sp_name,                              
                    sp_spt1_id:data.Result.sp_spt1_id,                              
                    sp_spt3_id:data.Result.sp_spt3_id,                              
                    sp_content:data.Result.sp_content,                              
                })                                   
                setInfo(prevsState=>({...prevsState,
                    sp_country_id:data.Result.sp_country_id,                              
                    sp_country_name:data.Result.sp_country_name,                              
                    sp_province_id:data.Result.sp_province_id,                              
                    sp_province_name:data.Result.sp_province_name,                              
                    sp_city_id:data.Result.sp_city_id,                              
                    sp_city_name:data.Result.sp_city_name,                              
                    sp_dept_name:data.Result.sp_dept_name,                              
                    sp_name:data.Result.sp_name,                              
                    sp_spt1_id:data.Result.sp_spt1_id,                              
                    sp_spt1_name:data.Result.sp_spt1_name,                              
                    sp_spt2_id:data.Result.sp_spt2_id,                              
                    sp_spt2_name:data.Result.sp_spt2_name,                              
                    sp_spt3_id:data.Result.sp_spt3_id,                              
                    sp_spt3_name:data.Result.sp_spt3_name,                              
                    sp_spt4_id:data.Result.sp_spt4_id,                              
                    sp_spt4_name:data.Result.sp_spt4_name,                              
                    sp_content:data.Result.sp_content,
                    sp_image:data.Result.sp_image,
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useShangpinUpdateSaveMutation()
    
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        //获取产品人群类型
                        let arr = []
                        for (let key in values) {
                            if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                if(key.includes('egg')){
                                    arr.push(values[key])
                                }
                            }
                        } 
                        console.log(values);                       
                        updateSave({
                            sp_e_id:localStorage.getItem('e_id'),
                            sp_e_name:localStorage.getItem('e_name'),
                            sp_spt1_id:info.sp_spt1_id,
                            sp_spt1_name:info.sp_spt1_name,
                            sp_spt2_id:'1',
                            sp_spt2_name:'旅游',
                            sp_spt3_id:info.sp_spt3_id,
                            sp_spt3_name:info.sp_spt3_name,
                            sp_spt4_id:'1',
                            sp_spt4_name:'定制游',
                            sp_country_id:info.sp_country_id,
                            sp_country_name:info.sp_country_name,
                            sp_province_id:info.sp_province_id,
                            sp_province_name:info.sp_province_name,
                            sp_city_id:info.sp_city_id,
                            sp_city_name:info.sp_city_name,
                            sp_dept_name:info.sp_dept_name,
                            sp_dept_id:info.sp_dept_id,
                            sp_name:info.sp_name,
                            sp_content:info.sp_content,
                            sp_image:info.sp_image,
                            sp_update_uid:localStorage.getItem('eu_id'),
                            sp_update_uname:localStorage.getItem('eu_name'),
                            sp_id:props.sp_id,
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('修改成功！') 
                                setOpen(false)                                                 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改失败！'+res.data.Message)
                            }
                        })
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
                
            }}
            open={open}
            title='商品'
            width={1000}
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 4 }}
                ref={editForm}
            >
               <Form.Item label='出发城市' style={{marginBottom:"0"}} required>
                    <Space>
                        <GetCountry
                            style={{width:'120px'}}
                            getCountry={(e,f) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    sp_country_id:e,
                                    sp_country_name:f,
                                }))
                            }}
                            name='sp_country_name'
                        />
                        <GetProvince 
                            ctap_country_id={info.sp_country_id}
                            style={{width:'120px'}}
                            getProvince={(e,f) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    sp_province_id:e,
                                    sp_province_name:f,
                                }))
                            }}
                            name='sp_province_name'
                        />
                        <GetCity 
                            province_id={info.sp_province_id}
                            style={{width:'120px'}}
                            getCity={(e,f) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    sp_city_id:e,
                                    sp_city_name:f,
                                }))
                            }}
                            name='sp_city_name'
                        />
                    </Space>
                </Form.Item>
                <GetUserDept 
                    label='部门' 
                    name='sp_dept_name'
                    required 
                    wrapperCol={{span:5}}
                    getUserDept={(e,f) => {setInfo(prevsState=>({...prevsState,sp_dept_id:e,sp_dept_name:f}))}}
                />  
                <Form.Item name="sp_name" label='商品名称' wrapperCol={{span:20}} required>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,sp_name:e.target.value}))}} />  
                </Form.Item>
                <Form.Item name="sp_spt3_id" label='品类2' wrapperCol={{span:20}} required>
                    <Radio.Group onChange={(e) => {
                        let sp_spt3_name
                        if(e.target.value==='1'){
                            sp_spt3_name = '国内游'
                        }
                        if(e.target.value==='1'){
                            sp_spt3_name = '出境游'
                        }
                        if(e.target.value==='1'){
                            sp_spt3_name = '入境游'
                        }
                        setInfo(prevState=>({...prevState,sp_spt3_id:e.target.value,sp_spt3_name,}))
                    }}>
                        <Radio value='1'>国内游</Radio>
                        <Radio value='2'>出境游</Radio>
                        <Radio value='3'>入境游</Radio>
                    </Radio.Group>
                </Form.Item>                                
                <Form.Item name="sp_content" label='预定规则' wrapperCol={{span:20}} required>
                    <MyEditor onChange={(e) => {setInfo(prevsState=>({...prevsState,sp_content:e}))}} style={{width:"500px"}}/>    
                </Form.Item>
                <UploadOne
                    fileStr={(e) => {setInfo(prevsState=>({...prevsState,sp_image:e,}))}}
                    label='图片'
                    wrapperCol={{span:20}}
                />
                
            </Form>
        </ModalEdit>
    )
}

export default CustomGoodsEdit