import { Avatar,Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import {useTourGuideListQuery } from '../store/ReactWebSiteApi';
import GuideQuery from './GuideQuery';
import { imgSrcWhole } from '../cpn/imgSrc';
import { UserOutlined } from '@ant-design/icons';
import { getGuideIdLanguage, guideIdName } from './guideType';
function Guide(props) {
    const [query,setQuery] = useState({tgu_name:'',tgu_phone:''})  
    const [list, setList] = useState();
    const {data,isSuccess} = useTourGuideListQuery(query,{refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                const newArr = data.Result.map(item => {
                    const {tgu_id:key,tgu_name,tgu_phone,tgu_uname,tgu_ctime,tgu_touxiang,tgu_country_name,tgu_province_name,tgu_city_name,tgu_zhengshu,tgu_yuzhong,tgu_jibie} = item;
                    let img
                    if(tgu_touxiang){
                        img = tgu_touxiang.map((item) => {
                            return imgSrcWhole + item
                        })
                    }
                    return item = {key,tgu_name,tgu_phone,tgu_uname,tgu_ctime,img,tgu_country_name,tgu_province_name,tgu_city_name,tgu_zhengshu,tgu_yuzhong,tgu_jibie}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.tgu_ctime;
                    let bTimeString = b.tgu_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    const columns = [
        {
            key:'4',          
            title:'导游',
            dataIndex: 'img',
            render:(text,record) => {
                if(text){
                    return  <Space>
                                <Avatar 
                                    src={text[0]} 
                                    size={60}
                                    shape='square'
                                />
                                <Space direction='vertical'>
                                    <div style={{fontSize:"20px"}}>{record.tgu_name}</div>
                                    <div >{record.tgu_phone}</div>                                    
                                </Space>                                
                            </Space>
                }else{
                    return  <Space>
                               <Avatar 
                                    icon={<UserOutlined />}
                                    size={60}
                                    shape='square'
                                />
                               <Space direction='vertical'>
                                    <div style={{fontSize:"20px"}}>{record.tgu_name}</div>
                                    <div >{record.tgu_phone}</div>                                    
                                </Space> 
                            </Space>
                }
                
            }
        },        
        {
            key:'2',
            title:'',
            render:(_,record) => {                
                return  <Space direction='vertical'>
                            <div>{record.tgu_country_name + ' - ' + record.tgu_province_name + ' - ' + record.tgu_city_name}</div>
                            <div>{guideIdName(record.tgu_jibie)} {getGuideIdLanguage(record.tgu_yuzhong)}</div>
                        </Space>
            }
        },
        {
            key:'3',
            title:'',
            dataIndex: 'key',
        },
        {
            key:'5',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <a onClick={() => {
                            props.getGuide({tgu_id:record.key,tgu_name:record.tgu_name,tgu_phone:record.tgu_phone,})
                            props.getOpen(false)
                            }}>选择</a>
                </Space>
            }
        },
    ]
    return (
        <>
            <Space>
                <GuideQuery 
                    getGuery={(e) => {
                        setQuery(prevState=>({
                            ...prevState,
                            tgu_phone:e.tgu_phone,
                            tgu_name:e.tgu_name
                        }))
                    }}
                />
            </Space>
            <Table 
                dataSource={list} 
                columns={columns}
                pagination={{
                    showSizeChanger:true,
                    defaultPageSize:'20',
                    showTotal:(total) => `共${total}条`,
                }}
            />
        </>
    )
}

export default Guide