import React ,{useState} from 'react'
import { DatePicker, Form, Input, InputNumber, Flex, Modal, Select, } from 'antd';
import GuidePlanBasic from './GuidePlanBasic';
import GuidePlanRoute from './GuidePlanRoute';
import GuidePlanPay from './GuidePlanPay';
function GuidePlanDesc(props) {
    const [open, setopen] = useState(false)
    return (
        <>
            <a onClick={() => {setopen(true)}}>新增</a>
            <Modal 
                open={open} 
                onOk={() => {}} 
                onCancel={() => {setopen(false)}}
                width={1200}
                footer={null}
            >
                <img src='./img/index/yemei.png'  width='100%' alt=''/>
                    <Flex 
                        justify='center' 
                        style={{fontSize:'30px',lineHeight:"88px",fontWeight:'bold'}}
                    >导游计划单</Flex>
                <GuidePlanBasic />
                <GuidePlanRoute />
                <GuidePlanPay/>
            </Modal>
        </>
    )
}

export default GuidePlanDesc