import { Select,Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetProvinceQuery } from '../../../store/ReactWebSiteApi';

function GetProvince(props) {
    const [list,setList] = useState();
    const {data, isSuccess} = useGetProvinceQuery({search_key:'',action:'search',ctap_country_id:props.ctap_country_id});
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                const newArr = data.Result.map((item) => {
                    const {ctap_id,ctap_name,}=item;
                    return item = {value:ctap_id,label:ctap_name}
                })
                setList(newArr)
            }
        }
    },[data,isSuccess])
    return (
        <>
            <Form.Item 
                label={props.label} 
                wrapperCol={props.wrapperCol} 
                required={props.required} 
                name={props.name}           
            >
                <Select 
                    options={list} 
                    onChange={(_,f) => {
                        // console.log(f);
                        props.getProvince(f.value,f.label)
                    }}
                    defaultValue={props.defaultId}
                    placeholder={props.defaultId}
                    style={props.style}
                />
            </Form.Item>
        </>
    )
}

export default GetProvince