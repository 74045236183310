import React from 'react'
import BalanceTypeList from '../balance/BalanceTypeList'
import Main from '../../cpn/Main'
function FinanceElseIncomeType() {
    return (
        <Main t1='财务' n1='/TaFinanceIndex' t2='设置' n2='/TaFinanceSetIndex'  t3='其他收入单类型'>
            <BalanceTypeList type_id='4' title='其他收入单类型'/>
        </Main>
    )
}

export default FinanceElseIncomeType