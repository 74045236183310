import React, { useEffect, useState } from 'react'
import { message, Space, Table } from 'antd'
import StandardAdd from './StandardAdd'
import StandardEdit from './StandardEdit'
import ModalDel from '../../../../cpn/ModalDel'
import { useDijieTemplateJieDaiBiaoZhunDelMutation, useDijieTemplateJieDaiBiaoZhunListQuery } from '../../../../store/ReactWebSiteApi'
import { localCostType } from '../../customType'
import StandardQuery from './StandardQuery'
function Standard(props) {
    const [list,setList] = useState()
    const [query,setQuery] = useState({ltjdbz_e_id:localStorage.getItem('e_id')})
    const {data, isSuccess} = useDijieTemplateJieDaiBiaoZhunListQuery(query,{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data,'aaa');
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {ltjdbz_id:key,ltjdbz_name,ltjdbz_uname,ltjdbz_ctime,ed_name,ltjdbz_type_id} = item;      
                    let arr = localCostType.filter((item) => item.value===ltjdbz_type_id)
                    let type = arr[0].label                           
                    return item = {key,ltjdbz_name,ltjdbz_uname,ltjdbz_ctime,ed_name,type,ltjdbz_type_id}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltjdbz_ctime;
                    let bTimeString = b.ltjdbz_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //删除
    const [del] = useDijieTemplateJieDaiBiaoZhunDelMutation()
    const column = [ 
        {
            key:'13',
            title:'接待标准模板',
            dataIndex:'ltjdbz_name'
        },        
        {
            key:'12',
            title:'类型',
            dataIndex:'type'
        },              
        {
            key:'1',
            title:'部门',
            dataIndex:'ed_name'
        },   
        {
            key:'6',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <StandardEdit ltjdbz_id={record.key} />
                    <ModalDel onOk={() => {
                        del({ltjdbz_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                    {props.get && <a onClick={() => {                        
                        props.getStandard({
                            bjdjdbz_name:record.ltjdbz_name,
                            bjdjdbz_type_id:record.ltjdbz_type_id,
                        })                   
                        props.getOpen(false)                        
                        }}>选择</a>}
                </Space>
            }
        },
    ]
    return (
        <>
            <Space>
                <StandardAdd />
                <StandardQuery
                    getQuery={(e) => {
                        setQuery(prevState=>({
                            ...prevState,
                            ltjdbz_dept_id:e.ltjdbz_dept_id,
                            ltjdbz_type_id:e.ltjdbz_type_id,
                            ltjdbz_name:e.ltjdbz_name,
                        }))
                    }}
                />
            </Space>
            <Table 
                columns={column} 
                dataSource={list} 
                style={{marginTop:"10px"}}
            />
        </>
    )
}

export default Standard