export const guidePlanAgentType = [
    {value:'1',label:'导游代收'},
    {value:'2',label:'门票'},
    {value:'3',label:'餐费'},
    {value:'4',label:'房费'},
    {value:'5',label:'其他费用'},
]
export const localCostType = [
    {value:'1',label:'门票'},
    {value:'2',label:'住宿'},
    {value:'3',label:'用餐'},
    {value:'4',label:'大交通'},
    {value:'5',label:'导游'},
    {value:'6',label:'保险'},
    {value:'7',label:'购物'},
]
export const localPayType = [
    {value:'1',label:'预付款'},
    {value:'2',label:'团款'},
    {value:'3',label:'尾款'},
]

export const customGroupDocType = [
    {value:'5',label:'导游计划单'},
    {value:'6',label:'游客行程单'},
]
//定制游文件类型
export const customDocType = [
    {value:'1',label:'线路定制'},
    {value:'2',label:'确认单'},
    {value:'3',label:'确认件'},
    {value:'4',label:'结算单'},
]
//定制游文件类型
export const customPageType = [
    {value:'1',label:'确认单'},
    {value:'2',label:'导游计划单'},
]


//研学文件类型
export const studyTourFileTypes = [
    {value:'7',label:'研学方案'},
    {value:'8',label:'确认单'},
    {value:'9',label:'确认件'},
    {value:'10',label:'结算单'},
]
export const commodityContinent = [
    {value:'1',label:'亚洲'},
    {value:'2',label:'非洲'},
    {value:'3',label:'欧洲'},
    {value:'4',label:'北美洲'},
    {value:'5',label:'南美洲'},
    {value:'5',label:'大洋洲'},
]
export const tourType = [
    {value:'1',label:'定制游'},
    {value:'2',label:'研学'},
]
export const indexFinance = localStorage.getItem('e_soft_name')==='金鱼'?'/GfIndexFinance':'/TaFinanceIndex'
export const indexPersonnel = localStorage.getItem('e_soft_name')==='金鱼'?'/GfIndexPersonnel':'/TaIndexPersonnel'

export const  localCustomTypeName = (e) => {
    let arr = localCostType.filter((item) => item.value===e)
    let type = arr[0].label  
    return type
};
//导游计划单状态值
export const  guidePlanState = (e) => {
    let type
    switch (e) {
        case '1':
            type='待发送'
            break;
        case '2':
            type='待确认'
            break;
        case '3':
            type='待完成'
            break;
        case '4':
            type='已完成'
            break;
        case '5':
            type='已取消'
            break;
    
        default:
            break;
    }
    return type
};
//团队计划/团队类型
export const groupType = [
    {value:'1',label:'纯玩团'},
    {value:'2',label:'购物团'},
    {value:'3',label:'小包团'},
    {value:'4',label:'VIP团'},
]
//导游计划单/职务
export const guidePost = [
    {value:'1',label:'导游'},
    {value:'2',label:'总控'},
    {value:'3',label:'实习助理'},
    {value:'4',label:'摄影师'},
    {value:'5',label:'司机兼导游'},
]
//导游计划单代收代付类型
export default function guidePayType(e){
    let type
    let arr = guidePlanAgentType.filter((item) => item.value===e)
    if(arr.length>0){
        type = arr[0].label  
        return type
    }else{
        type = ''
        return type
    }
};

