import { message, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDijieBaoJiaDanGuiGeDelMutation, useDijieBaoJiaDanGuiGeListQuery } from '../../../store/ReactWebSiteApi'
import ModalDel from '../../../cpn/ModalDel'
import OpFileTypeEdit from './FileTypeEdit'
import FileTypeAdd from './FileTypeAdd'

function FileType(props) {
    const [list,setList] = useState([])
    const {data,isSuccess} = useDijieBaoJiaDanGuiGeListQuery({bjdgg_bjd_id:props.bjdgg_bjd_id},{refetchOnFocus:true})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const arr = data.Result.map((item) => {
                    const {...obj} = item
                    return item = obj
                })
                setList(arr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDijieBaoJiaDanGuiGeDelMutation()
    return (
        <Space>        
            {list.map((item) => {
                return  <Space key={item.bjdgg_id}>
                            {item.bjdgg_name + '×' + item.bjdgg_num}
                            {!props.preview && <OpFileTypeEdit bjdgg_id={item.bjdgg_id} bjdgg_bjd_id={props.bjdgg_bjd_id}/>}
                            {!props.preview && <ModalDel onOk={() => {
                                del({bjdgg_id:item.bjdgg_id}).then((res) => {
                                    if (res.data.Status === 'success') {
                                        message.success('删除成功！')                                                                              
                                    }
                                    if (res.data.Status === 'fail') {
                                        message.error('删除失败！'+res.data.Message)
                                    }
                                })
                            }} />}
                            ；
                        </Space>
            })}
            {!props.preview && <FileTypeAdd bjdgg_bjd_id={props.bjdgg_bjd_id} />}
        </Space>
    )
}

export default FileType