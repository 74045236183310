import React from 'react'
import Main from '../../../../cpn/Main'
import LineName from './LineName'
function CustomTemplateLineName() {
    return (
        <Main t1='国内地接' n1='/LocalIndex' t2='定制游' n2='/CustomIndex' t3='模板' n3='/CustomTempIndex' t4='线路名称'>
            <LineName />
        </Main>
    )
}

export default CustomTemplateLineName