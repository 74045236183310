import React, { useEffect, useState } from 'react'
import {message, Space, Table } from 'antd'
import { useDijieBaoJiaDanDelMutation, useDijieBaoJiaDanListQuery } from '../../../store/ReactWebSiteApi'
import ModalDel from '../../../cpn/ModalDel'
import FileCheck from './FileCheck'
import FileAdd from './FileAdd'
import FileEdit from './FileEdit'
import FileQuery from './FileQuery'
import FileCopy from './FileCopy'
import GroupCheck from '../group/GroupCheck'
import Main from '../../../cpn/Main'
function CustomFile() {
    const [list,setList] = useState()
    const [query,setQuery] = useState({
        ltbjd_e_id:localStorage.getItem('e_id'),
        ltbjd_type_id:'',
        ltbjd_client_name:'',
        ltbjd_ltxl_name:'',
        ltbjd_date:'',
        ltbjd_dept_id:'',
        ltbjd_tuanhao:'',
    })
    const {data, isSuccess} = useDijieBaoJiaDanListQuery(query,{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            // console.log(data);
            if(data.Status==='success'){                
                const arr = data.Result.map(item => {
                    const {ltbjd_id:key,ltbjd_phone,ltbjd_tuanhao,ltbjd_client_name,ltbjd_uname,ltbjd_dept_id,ltbjd_ctime,ltbjd_ltxl_name,ltbjd_date,ed_name,ltbjd_content,ltbjd_lth_id,ltbjd_lianxiren
                    } = item;
                     return item = {key,ltbjd_phone,ltbjd_tuanhao,ltbjd_client_name,ltbjd_uname,ltbjd_dept_id,ltbjd_ctime,ltbjd_ltxl_name,ltbjd_date,ed_name,ltbjd_content,ltbjd_lth_id,ltbjd_lianxiren
                     }
                })
                const sortArr = arr.sort( (a, b) => {      
                    let aTimeString = a.ltbjd_ctime;
                    let bTimeString = b.ltbjd_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
                
            }else{
                setList('')
            }            
        }
        
    }, [data, isSuccess, ])
    //删除
    const [del] = useDijieBaoJiaDanDelMutation()
    const column = [
        {
            key:'1',
            title:'确认单',
            dataIndex:'key',
            width:'160px',
            render:(_,record) => {                
                return  <FileCheck 
                            ltbjd_id={record.key}
                            text={record.key}
                            ltbjd_dept_id={record.ltbjd_dept_id}
                            customType={record.ltbjd_type_id}
                        />
            }
        },
        {
            key:'2',
            title:'客户',
            dataIndex:'ltbjd_client_name',
            render:(text,record) => {
                return <>{text+ ' - ' + record.ltbjd_lianxiren + ' - ' + record.ltbjd_phone}</>
            }

        },
        {
            key:'3',
            title:'线路名称',
            dataIndex:'ltbjd_ltxl_name'
        }, 
        {
            key:'4',
            title:'出发日期',
            dataIndex:'ltbjd_date'
        },
       
        {
            key:'5',
            title:'部门',
            dataIndex:'ed_name'
        },                     
        {
            key:'6',
            title:'备注',
            dataIndex:'ltbjd_content'
        },      
        {
            key:'7',
            title:'团号',
            dataIndex:'ltbjd_tuanhao',
            render:(text,record) => {
                return  <GroupCheck 
                            lth_num={text}
                            lth_id={record.ltbjd_lth_id}
                        />
            }
        },                                              
        {
            key:'10',
            title:'操作员',
            render:(_,record) => {
                return <>{record.ltbjd_ctime + record.ltbjd_uname}</>
            }
        },
        {
            key:'11',
            width:"160px",
            title:'操作',
            render:(_,record) => {
                return  <Space>                     
                           <FileEdit
                                ltbjd_id={record.key} 
                                ltbjd_dept_id={record.ltbjd_dept_id}
                            />
                            <ModalDel onOk={() => {
                                del({ltbjd_id:record.key}).then((res) => {
                                    console.log(res);
                                    if (res.data.Status === 'success') {
                                        message.success('删除成功！')                                                                              
                                    }
                                    if (res.data.Status === 'fail') {
                                        message.error('删除失败！'+res.data.Message)
                                    }
                                    })
                                }} 
                            />
                            <FileCopy 
                                ltbjd_id={record.key} 
                                ltbjd_tuanhao={record.ltbjd_tuanhao}
                                ltbjd_lth_id={record.ltbjd_lth_id}
                            />     
                        </Space>
            }
        },
    ]
    return (
        <Main  t1='国内地接' n1='/LocalIndex' t2='定制游' n2='/CustomIndex' t3='确认单'>
            <Space>
                <FileAdd />                
                <FileQuery 
                    getQuery={(e) => {
                        setQuery(prevState=>({
                            ...prevState,
                            ltbjd_client_name:e.ltbjd_client_name,
                            ltbjd_ltxl_name:e.ltbjd_ltxl_name,
                            ltbjd_date:e.ltbjd_date,
                            ltbjd_dept_id:e.ltbjd_dept_id,
                        }))
                    }}
                />
            </Space>
            <Table 
                columns={column} 
                dataSource={list} 
                style={{marginTop:"10px"}}
            />
           
        </Main>
    )
}

export default CustomFile