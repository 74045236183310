import { message, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDingzhiyouyijiandanTemplateDelMutation, useDingzhiyouyijiandanTemplateListQuery } from '../../../../store/ReactWebSiteApi';
import ModalDel from '../../../../cpn/ModalDel';
import FeedbackAdd from './FeedbackAdd';
import FeedbackEdit from './FeedbackEdit';

function Feedback(props) {
    const [list, setList] = useState();
    const {data,isSuccess} = useDingzhiyouyijiandanTemplateListQuery({ltyjd_e_id:localStorage.getItem('e_id')},{refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                const newArr = data.Result.map(item => {
                    const {ltyjd_id:key,ltyjd_name,ltyjd_phone,ltyjd_content,ltyjd_uname,ltyjd_ctime,ltyjd_dept_name,ect_name,ltyjd_update_ctime,ltyjd_update_uname,item:arr} = item;
                    let  names = arr.map((item) => {
                        return item.ltyjdi_name + ' , '
                    })
                    return item = {key,ltyjd_name,ltyjd_phone,ltyjd_content,ltyjd_uname,ltyjd_ctime,ltyjd_dept_name,ect_name,ltyjd_update_ctime,ltyjd_update_uname,names,arr}
                })                
                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltyjd_ctime;
                    let bTimeString = b.ltyjd_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const [del] = useDingzhiyouyijiandanTemplateDelMutation()    
    const columns = [
        {
            key:'1',
            title:'意见单模板',
            dataIndex: 'ltyjd_name',
        },    
        {
            key:'2',
            title:'评价项目',
            dataIndex: 'names',
        },
        {
            key:'4',
            title:'部门',
            dataIndex: 'ltyjd_dept_name',
        },
        {
            key:'5',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <FeedbackEdit 
                        ltyjd_id={record.key}
                    />
                    <ModalDel onOk={() => {
                            del({ltyjd_id:record.key}).then((res) => {
                                if (res.data.Status === 'success') {
                                    message.success('删除成功！')                                                                              
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('删除失败！'+res.data.Message)
                                }
                            })
                        }} />
                    {props.type==='select' && <a onClick={() => {
                            props.getFeedback(record.arr)
                            props.getOpen(false)
                            }}>选择</a>}   
                </Space>
            }
        },
    ]
    return (
        <>
            <FeedbackAdd />
            <Table dataSource={list} columns={columns}/>
        </>
    )
}

export default Feedback