import React from 'react'
import classes from './web.module.css'
import ImgPreviewCustom from '../cpn/ImgPreviewCustom'
import { imgSrcWhole } from '../cpn/imgSrc'
function TaPriceFinance() {
    return (
        <>
            <div className={classes.priceTitle1}>所有业务类型和规模的旅行社适用，功能强大的内账管理，6种单据，2种凭证，自动生成35种报表。</div>
            <div className={classes.priceTitle2}>1 团队收支</div>
            <div className={classes.priceTitle3}>
                1.1<ImgPreviewCustom  content='团队报账单' src={imgSrcWhole+'1725498908_8517916470caeb69e57e.png'}/>：
                记录每个团队（订单）的团号、部门、客户、产品、出发日期、备注、
                <ImgPreviewCustom  content='团队收入单' src={imgSrcWhole+'1725499820_5115304ac4c2eecc26c1.png'}/>、
                <ImgPreviewCustom  content='团队支出单' src={imgSrcWhole+'1725499917_388f0cb518e7fc47b18a.png'}/>、利润、审核状态。
            </div>
            <div className={classes.priceTitle3}>
                1.2<ImgPreviewCustom  content='团队收入单' src={imgSrcWhole+'1725499820_5115304ac4c2eecc26c1.png'}/>、
                <ImgPreviewCustom  content='团队支出单' src={imgSrcWhole+'1725499917_388f0cb518e7fc47b18a.png'}/>：
                记录团队每笔收支的部门、客户、类型、金额、备注，下账状态，转账状态，审核状态、下账状态、转账状态。</div>
            <div className={classes.priceTitle3}>
                1.3报表：
                <ImgPreviewCustom  content='部门利润表' src={imgSrcWhole+'1725502509_e09dba5ee2b93b00680c.png'}/>、
                <ImgPreviewCustom  content='客户利润表' src={imgSrcWhole+'1725502791_0a87929f354542a863cb.png'}/>、
                <ImgPreviewCustom  content='客户类型利润表' src={imgSrcWhole+'1725502963_d590dde62b2ab37489e4.png'}/>、
                <ImgPreviewCustom  content='产品利润表' src={imgSrcWhole+'1725502815_3f87dec1a1bf2fcbbd8f.png'}/>、
                <ImgPreviewCustom  content='产品类型利润表' src={imgSrcWhole+'1725502847_ef4d927823d42ea6fd41.png'}/>、
                <ImgPreviewCustom  content='销售员利润表' src={imgSrcWhole+'1725503038_cac7af3dc830c204dd94.png'}/>、
                <ImgPreviewCustom  content='操作员利润表' src={imgSrcWhole+'1725503063_c6604d486b7b081aed19.png'}/>、
                <ImgPreviewCustom  content='待下账部门表' src={imgSrcWhole+'1725503087_4cb6665cf214952d6d7b.png'}/>、
                <ImgPreviewCustom  content='待下账客户表' src={imgSrcWhole+'1725503105_f09fc5dd4d3f4a1bf7e3.png'}/>、
                <ImgPreviewCustom  content='待下账客户对账表' src={imgSrcWhole+'1725503120_63c015572685493b824c.png'}/>
            </div>
            <div className={classes.priceTitle4}>
               
            </div>
            <div className={classes.priceTitle2}>2 费用支出</div>
            <div className={classes.priceTitle3}>
                2.1<ImgPreviewCustom  content='费用支出单' src={imgSrcWhole+'1725504828_f8e020830c6c84a814bb.png'}/>：
                记录每笔费用支出的部门、客户、类型、金额、备注，下账状态，转账状态和审核状态。
            </div>
            <div className={classes.priceTitle3}>
                2.2报表：
                <ImgPreviewCustom  content='部门费用表' src={imgSrcWhole+'1725504969_54f4a51cdd97278df9e9.png'}/>、
                <ImgPreviewCustom  content='客户费用表' src={imgSrcWhole+'1725504987_b232e8a1c34cad02a185.png'}/>、
                <ImgPreviewCustom  content='类型费用表' src={imgSrcWhole+'1725505005_3929d022e7c077312f49.png'}/>、
                <ImgPreviewCustom  content='部门利润表' src={imgSrcWhole+'1725505023_2bc444d44d590ed8d4c8.png'}/>。
            </div>
            <div className={classes.priceTitle2}>3 其他收支</div>
            <div className={classes.priceTitle3}>
                3.1<ImgPreviewCustom  content='其他收入单' src={imgSrcWhole+'1725505419_adb13fd8455b569a46f2.png'}/>：
                记录每笔其他收入的部门、客户、类型、金额、备注，下账状态，转账状态和审核状态。
            </div>
            <div className={classes.priceTitle3}>
                3.2<ImgPreviewCustom  content='其他支出单' src={imgSrcWhole+'1725505470_2bd59daf0ecdf08710d3.png'}/>：
                记录每笔其他支出的部门、客户、类型、金额、备注，下账状态，转账状态和审核状态。
            </div>
            <div className={classes.priceTitle3}>
                3.3报表：
                <ImgPreviewCustom  content='部门其他收支表' src={imgSrcWhole+'1725505573_6e16592337dfb1e16360.png'}/>、
                <ImgPreviewCustom  content='客户其他收支表' src={imgSrcWhole+'1725505592_cc1d7ee5eda6c9d09eaa.png'}/>、
                <ImgPreviewCustom  content='其他收入类型表' src={imgSrcWhole+'1725505612_cc7f3ae364b528dbc645.png'}/>、
                <ImgPreviewCustom  content='其他支出类型表' src={imgSrcWhole+'1725505629_967aa9573924b21d3526.png'}/>。
            </div>
            <div className={classes.priceTitle2}>4 下账</div>
            <div className={classes.priceTitle3}>
                4.1<ImgPreviewCustom  content='下账凭证' src={imgSrcWhole+'1725539815_f479e1d48daaf1e05622.png'}/>：
                记录下账的收支单、下账时间、操作员和备注。
            </div>
            <div className={classes.priceTitle3}>
                4.2报表：
                <ImgPreviewCustom  content='部门利润表' src={imgSrcWhole+'1725539933_25d12947e1c267a1241c.png'}/>、
                <ImgPreviewCustom  content='客户利润表' src={imgSrcWhole+'1725539953_a0833b1f9718ca53c076.png'}/>、
                <ImgPreviewCustom  content='客户类型利润表' src={imgSrcWhole+'1725541361_b0303c42ef3d7e2a4c76.png'}/>、
                <ImgPreviewCustom  content='产品利润表' src={imgSrcWhole+'1725541263_9cb003cdceb5f0681856.png'}/>、
                <ImgPreviewCustom  content='产品类型利润表' src={imgSrcWhole+'1725541385_a0ad2823f6f1d0b0daac.png'}/>、
                <ImgPreviewCustom  content='销售员利润表' src={imgSrcWhole+'1725541536_ab62f5876b9ccdb2b26c.png'}/>、
                <ImgPreviewCustom  content='操作员利润表' src={imgSrcWhole+'1725541553_0c3acd1cb50d10f3a7ae.png'}/>。
            </div>
            <div className={classes.priceTitle2}>5 转账</div>
            <div className={classes.priceTitle3}>
                5.1<ImgPreviewCustom  content='转账凭证' src={imgSrcWhole+'1725541758_d37b58b53539db138726.png'}/>：
                记录下账的收支单、下账时间、操作员和备注。
            </div>
            <div className={classes.priceTitle3}>
                5.2<ImgPreviewCustom  content='资金账户转账明细' src={imgSrcWhole+'1725541769_913e61d1e631061626ef.png'}/>：
                记录资金账户余额和转账明细。
            </div>
            <div className={classes.priceTitle3}>
                5.3报表：
                <ImgPreviewCustom  content='部门利润表' src={imgSrcWhole+'1725541782_9cd20a5035f421196c47.png'}/>、
                <ImgPreviewCustom  content='客户利润表' src={imgSrcWhole+'1725541797_dfa00ac580d515ead5ba.png'}/>、
                <ImgPreviewCustom  content='客户类型利润表' src={imgSrcWhole+'1725541810_9b28e556fae600655837.png'}/>、
                <ImgPreviewCustom  content='产品利润表' src={imgSrcWhole+'1725541826_7eedbc742ce6d4e2cd86.png'}/>、
                <ImgPreviewCustom  content='产品类型利润表' src={imgSrcWhole+'1725541838_dfe72097c965384a74a6.png'}/>、
                <ImgPreviewCustom  content='销售员利润表' src={imgSrcWhole+'1725541852_a91e007b544f61ce98cd.png'}/>、
                <ImgPreviewCustom  content='操作员利润表' src={imgSrcWhole+'1725541866_d383f0e4c386f22637e8.png'}/>。
            </div> 
            <div className={classes.priceTitle1}>财务模块价格：100元/月（购买36个月），125元/月（购买24个月），150元/月（购买12个月）。</div>  
        </>
    )
}

export default TaPriceFinance