import { DatePicker, Form, Input, InputNumber, message, Space, Select, Button,Row,Col} from 'antd';
import React, { useState } from 'react'
import GuideForm from '../../../../guide/GuideForm';
import UploadOne from '../../../../cpn/UploadOne';
import { useDaoyoujihuadanAddMutation } from '../../../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { guidePost } from '../../customType';
const {TextArea} = Input
function GuidePlanBasic(props) {
    const navi = useNavigate()
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        dyjhd_tgu_name:'',
        dyjhd_tgu_phone:'',
        dyjhd_fenche:'1'
    })
    const [add] = useDaoyoujihuadanAddMutation()
    return (
        <Form
            form={form}
            layout="vertical"
            name="form_client"                    
            labelCol={{ span: 6 }}
            initialValues={{fenche:1}}
            onFinish={() => {
                add({
                    "dyjhd_daofufei" : info.dyjhd_daofufei,
                    "dyjhd_daofufeishuoming" : info.dyjhd_daofufeishuoming,
                    "dyjhd_fujian_name" : info.dyjhd_fujian_name,
                    "dyjhd_jietuandidian" :info.dyjhd_jietuandidian,
                    "dyjhd_jietuanshijian" :info.dyjhd_jietuanshijian,
                    "dyjhd_jietuanshuoming" :info.dyjhd_jietuanshuoming,
                    "dyjhd_lth_id" : props.dyjhd_lth_id,
                    "dyjhd_lth_num" : props.dyjhd_lth_num,
                    "dyjhd_songtuandidian" : info.dyjhd_songtuandidian,
                    "dyjhd_songtuanshijian" :info.dyjhd_songtuanshijian,
                    "dyjhd_tgu_id" : info.dyjhd_tgu_id,
                    "dyjhd_tgu_name" : info.dyjhd_tgu_name,
                    "dyjhd_tgu_phone" : info.dyjhd_tgu_phone,
                    "dyjhd_fenche" : info.dyjhd_fenche,
                    dyjhd_xianlu:props.lth_xianlu_name,
                    "dyjhd_uid" : localStorage.getItem('eu_id'),
                    "dyjhd_uname" :  localStorage.getItem('eu_name'),
                    "dyjhd_uphone" :  localStorage.getItem('eu_ba_phone'),
                    "dyjhd_e_id" : localStorage.getItem('e_id'),        
                    "dyjhd_e_name" : localStorage.getItem('e_name'),        
                    "dyjhd_dept_id" : props.lth_dept_id,        
                    "dyjhd_dept_name" :props.lth_dept_name,  
                    "dyjhd_lth_zhu_name" : props.lth_zhu_type_name,
                    "dyjhd_lth_zhu_type" : props.lth_zhu_type,
                    "dyjhd_lth_ci_type" : props.lth_ci_type,
                    "dyjhd_lth_ci_type_name" : props.lth_ci_type_name,
                    "dyjhd_lth_zi_type" : props.lth_zi_type,
                    "dyjhd_lth_zi_type_name" : props.lth_zi_type_name,  
                    "dyjhd_zhiwu_id" : info.dyjhd_zhiwu_id,  
                    "dyjhd_zhiwu_name" : info.dyjhd_zhiwu_name,  
                }).then((res) => {
                    // console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        navi('/CustomGroup')                                           
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })               
            }}
        >                                                              
            <Form.Item style={{marginBottom:"0"}}>
                <Row>
                    <Col span={4}>
                        <Form.Item name="fenche" label='分车序号' wrapperCol={{span:10}} required>
                            <InputNumber 
                                onChange={(e) => {
                                    setInfo(prevState=>({...prevState,dyjhd_fenche:e}))
                                }}
                                min={1}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <GuideForm 
                            required
                            tgu_name={info.dyjhd_tgu_name}
                            tgu_phone={info.dyjhd_tgu_phone}
                            getGuide={(e) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    dyjhd_tgu_id:e.tgu_id,
                                    dyjhd_tgu_name:e.tgu_name,
                                    dyjhd_tgu_phone:e.tgu_phone,
                                }))
                            }}
                        />
                    </Col>
                    <Col span={3}>
                        <Form.Item 
                            label='职务'
                            required 
                            name="post" 
                            wrapperCol={{span:8}} 
                        >
                            <Select
                                options={guidePost}
                                onChange={(e,f) => {
                                    setInfo(prevState=>({
                                        ...prevState,
                                        dyjhd_zhiwu_id:e,
                                        dyjhd_zhiwu_name:f.label
                                    }))
                                }}
                                style={{width:'120px'}}  
                            />
                        </Form.Item>   
                    </Col>
                    <Col span={4}>
                        <Form.Item 
                            label='导服费' 
                            name="pay" 
                            wrapperCol={{span:15}} 
                        >
                            <InputNumber     
                                onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_daofufei:e}))
                            }}                          
                            />
                        </Form.Item>      
                    </Col>
                    <Col span={8}>
                        <Form.Item 
                            label='导服费说明' 
                            name="payRemark" 
                            wrapperCol={{span:15}} 
                        >
                            <Input
                                onChange={(e) => {
                                    setInfo(prevState=>({...prevState,dyjhd_daofufeishuoming:e.target.value}))
                                }}      
                                style={{width:'360px'}}                   
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item style={{marginBottom:"0"}}>
                <Row>
                    <Col span={4}>
                        <Form.Item 
                            label='接团时间' 
                            name="s_time" 
                            wrapperCol={{span:15}} 
                        >
                            <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm"
                                onChange={(e) => {
                                    setInfo(prevState=>({...prevState,dyjhd_jietuanshijian:dayjs(e).format('YYYY-MM-DD HH:mm')}))
                                }}  
                                style={{width:'160px'}}  
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item 
                            label='接团地点' 
                            name="s_site" 
                            wrapperCol={{span:15}} 
                        >
                            <Input
                                onChange={(e) => {
                                    setInfo(prevState=>({...prevState,dyjhd_jietuandidian:e.target.value}))                                
                                }}  
                                style={{width:'360px'}}  
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item 
                            label='送团时间' 
                            name="e_time" 
                            wrapperCol={{span:15}} 
                        >
                                <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm"
                                onChange={(e) => {
                                    // console.log(e);
                                    setInfo(prevState=>({...prevState,dyjhd_songtuanshijian:dayjs(e).format('YYYY-MM-DD HH:mm')}))
                                }}  
                                style={{width:'160px'}}  
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item 
                            label='送团地点' 
                            name="e_site" 
                            wrapperCol={{span:15}} 
                        >
                            <Input
                                onChange={(e) => {
                                    setInfo(prevState=>({...prevState,dyjhd_songtuandidian:e.target.value}))
                                }}
                                style={{width:'360px'}}    
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item style={{marginBottom:"0"}}>
                <Row>
                    <Col span={16}>
                        <Form.Item 
                            label='接团说明' 
                            name="remark" 
                            wrapperCol={{span:15}}                         
                        >
                            <Input
                                autoSize={{
                                    minRows: 1,                                
                                }}
                                onChange={(e) => {
                                    setInfo(prevState=>({...prevState,dyjhd_jietuanshuoming:e.target.value}))
                                }}   
                                style={{width:'730px'}} 
                            />
                            </Form.Item>
                    </Col>
                    <Col span={4}>
                        <UploadOne 
                            name='pic'
                            label='附件' 
                            fileStr={(e) => {setInfo(prevsState=>({...prevsState,dyjhd_fujian_name:e}))}}
                        />
                    </Col>
                    <Col span={4}>
                        <Form.Item 
                            label='提交基本信息'
                            wrapperCol={{span:15}} 
                        >
                            <Button type='primary'>提交</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
            
        </Form>
    )
}

export default GuidePlanBasic