import React, { useEffect, useState } from 'react'
import { Form,  Select,} from 'antd'
import {  useYewuTuanHaoListQuery } from '../../../store/ReactWebSiteApi'
function GroupSelect(props) {
    const [list,setList] = useState()
    const {data, isSuccess} = useYewuTuanHaoListQuery({lth_e_id:localStorage.getItem('e_id')},{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {lth_id:key,lth_num,} = item;                    
                    return item = {value:key,label:lth_num,}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.label.lth_num;
                    let bTimeString = b.label.lth_num;
                    return bTimeString - aTimeString;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    return (
       <Form.Item
            name={props.name}
            label={props.label}
            required={props.required}
            wrapperCol={props.wrapperCol}            
       >
            <Select 
                showSearch
                options={list}
                onChange={(_,f) => {
                    props.getGroup(f)
                }}
                optionFilterProp="label"
            />
       </Form.Item>
    )
}

export default GroupSelect