import { message, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import GuidePlanAdd from './GuidePlanAdd'
import GuidePlanCheck from './GuidePlanCheck'
import GuidePlanCopy from './GuidePlanCopy'
import { useDaoyoujihuadanDelMutation, useDaoyoujihuadanListQuery } from '../../../../store/ReactWebSiteApi'
import GuidePlanEdit from './GuidePlanEdit'
import ModalDel from '../../../../cpn/ModalDel'
import { guidePlanState } from '../../customType'
import GuidePlanDesc from './GuidePlanDesc'

function GuidePlan(props) {
    const [list,setList] = useState()
    const {data, isSuccess} = useDaoyoujihuadanListQuery({dyjhd_lth_id:props.dyjhd_lth_id},{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {dyjhd_id:key,dyjhd_ltdy_name,dyjhd_jietuanshuoming,lth_uname,lth_ctime,lth_date,lth_kehu_name,lth_xianlu_name,lth_dept_name,lth_phone,lth_ltkh_id,dyjhd_ltdy_phone,dyjhd_fenche,dyjhd_state} = item;                    
                    return item = {key,dyjhd_ltdy_name,dyjhd_jietuanshuoming,lth_uname,lth_ctime,lth_date,lth_kehu_name,lth_xianlu_name,lth_dept_name,lth_phone,lth_ltkh_id,dyjhd_ltdy_phone,dyjhd_fenche,dyjhd_state}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.dyjhd_fenche;
                    let bTimeString = b.dyjhd_fenche;
                    return aTimeString - bTimeString;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //删除
    const [del] = useDaoyoujihuadanDelMutation()
    const column = [             
        {
            key:'1',
            title:'导游计划单',
            dataIndex:'lth_num',  
            width:'200px',
            render:(_,record) => {
                return <GuidePlanCheck 
                            dyjhd_id={record.key}
                            dyjhd_ltdy_name={record.dyjhd_ltdy_name}
                            dyjhd_ltdy_phone={record.dyjhd_ltdy_phone}
                        />
            }         
        },    
        {
            key:'2',
            title:'分车序号', 
            width:'100px',  
            dataIndex:'dyjhd_fenche'         
        },          
        {
            key:'4',
            title:'备注',
            dataIndex:'dyjhd_jietuanshuoming'            
        },
        {
            key:'5',
            title:'状态',
            dataIndex:'dyjhd_state',
            width:'80px',
            render:(text) => {
                return guidePlanState(text)
            }           
        },
        {
            key:'6',
            title:'操作',
            width:"200px",
            render:(_,record) => {
                return  <Space>
                            <GuidePlanEdit
                                dyjhd_id={record.key}
                                dyjhd_state={record.dyjhd_state}
                                lth_dept_id={props.lth_dept_id}
                                lth_dept_name={props.lth_dept_name}
                                lth_zhu_type={props.lth_zhu_type}
                                lth_zhu_type_name={props.lth_zhu_type_name}
                                lth_ci_type={props.lth_ci_type}
                                lth_ci_type_name={props.lth_ci_type_name}
                                lth_zi_type={props.lth_zi_type}
                                lth_zi_type_name={props.lth_zi_type_name}
                            />
                            {record.dyjhd_state==='1' && <ModalDel onOk={() => {
                                del({dyjhd_id:record.key}).then((res) => {
                                    console.log(res);
                                    if (res.data.Status === 'success') {
                                        message.success('删除成功！')                                                                              
                                    }
                                    if (res.data.Status === 'fail') {
                                        message.error('删除失败！'+res.data.Message)
                                    }
                                })
                            }} />}
                            
                            <GuidePlanCopy
                                dyjhd_id={record.key}
                            />
                        </Space>
            }
        },
    ]
    return (
        <>
            <Space style={{marginLeft:'20px',marginTop:'10px'}}>
                <GuidePlanAdd 
                    dyjhd_lth_id={props.dyjhd_lth_id}
                    dyjhd_lth_num={props.dyjhd_lth_num}
                    lth_xianlu_name={props.lth_xianlu_name}
                    lth_dept_id={props.lth_dept_id}
                    lth_dept_name={props.lth_dept_name}
                    lth_zhu_type={props.lth_zhu_type}
                    lth_zhu_type_name={props.lth_zhu_type_name}
                    lth_ci_type={props.lth_ci_type}
                    lth_ci_type_name={props.lth_ci_type_name}
                    lth_zi_type={props.lth_zi_type}
                    lth_zi_type_name={props.lth_zi_type_name}
                />
                <GuidePlanDesc />
            </Space>
            <Table 
                columns={column}
                dataSource={list}
                pagination={{position:['none']}}
            />
        </>
    )
}

export default GuidePlan