import React from 'react'
import Main from '../../../../cpn/Main'
import Price from './Price'
function CustomPrice() {
    return (
        <Main t1='定制游' n1='/CustomIndex' t2='模板' n2='/CustomTempIndex' t3='收费明细'>
            <Price />
        </Main>
    )
}

export default CustomPrice