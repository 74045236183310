import React,{useState}from 'react'
import { Button, DatePicker, Form,message, Modal,Input, Select} from 'antd';
import {  useYewuTuanHaoAddMutation,} from '../../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import GetUserDept from '../../../cpn/GetUserDept';
import ClientForm from '../temp/client/ClientForm';
import { groupType } from '../customType';
import LineNameForm from '../temp/lineName/LineNameForm';
import GroupDesc from './GroupDesc';
const {TextArea} = Input
function GroupAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        lth_kehu_name:'',
        lth_phone:'',
        lth_content:"",
        lth_lianxiren:'',
    });
    const [submitAdd] = useYewuTuanHaoAddMutation()
    const [open,setOpen] = useState(false)
    //返回详情
    const [openCheck, setopenCheck] = useState({id:'',open:false})
    
    return (
        <>
            <Button type='primary'  onClick={() => {setOpen(true)}}>新增</Button>
            <Modal 
                title='新增团队计划'
                onOk={(e) => {
                    submitAdd({
                        lth_e_id:localStorage.getItem('e_id'),
                        lth_e_name:localStorage.getItem('e_name'),                   
                        lth_dept_id:props.lth_dept_id?props.lth_dept_id:info.lth_dept_id,
                        lth_dept_name:props.lth_dept_name?props.lth_dept_name:info.lth_dept_name,
                        lth_kehu_name:props.lth_kehu_name?props.lth_kehu_name:info.lth_kehu_name,
                        lth_ltkh_id:props.lth_ltkh_id?props.lth_ltkh_id:info.lth_ltkh_id,
                        lth_xianlu_name:props.lth_xianlu_name?props.lth_xianlu_name:info.lth_xianlu_name,
                        lth_date:props.lth_date?props.lth_date:info.lth_date,
                        lth_phone:props.lth_phone?props.lth_phone:info.lth_phone,
                        lth_content:props.lth_content?props.lth_content:info.lth_content,
                        lth_uid:localStorage.getItem('eu_id'),
                        lth_uname:localStorage.getItem('eu_name'),
                        "lth_zhu_type" : '1',
                        "lth_zhu_type_name" : '国内地接',
                        "lth_ci_type" : '2',
                        "lth_ci_type_name" : '定制游',
                        "lth_zi_type" : info.lth_zi_type,
                        "lth_zi_type_name" : info.lth_zi_type_name,
                        "lth_lianxiren" : info.lth_lianxiren,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！') 
                            setOpen(false)                
                            setopenCheck({id:res.data.Result,open:true})
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
                open={open}
                getOpen={(e) => {
                    setOpen(prevsState=>e)                
                }}
                onCancel={() => {setOpen(false)}}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public'}}
                    labelCol={{ span: 6 }}
                >                                           
                     {props.lth_dept_name?<Form.Item name="dept" label='部门' wrapperCol={{span:10}} required>
                        {props.lth_dept_name}
                    </Form.Item>:<GetUserDept label='部门'  wrapperCol={{span:10}} required getUserDept={(e,f) => {setInfo(prevState=>({...prevState,lth_dept_id:e,lth_dept_name:f}))}}/>}   
                    <ClientForm
                        label='客户'
                        getClient={(e) => {
                            setInfo(prevState=>({
                                ...prevState,
                                lth_ltkh_id:e.ltbjd_ltkh_id,
                                lth_kehu_name:e.ltbjd_client_name,
                                lth_phone:e.ltkh_phone,
                                lth_lianxiren:e.ltkh_lianxiren,
                            }))                                    
                        }}
                        ltbjd_client_name={info.lth_kehu_name}
                        ltbjd_phone={info.lth_phone}
                        ltbjd_lianxiren={info.lth_lianxiren}
                    />
                    <LineNameForm
                        getLineName={(e) => {
                            setInfo(prevState=>({...prevState,lth_xianlu_name:e}))
                        }}
                        lineName={info.lth_xianlu_name}
                        label='线路名称'
                        required
                        wrapperCol={{span:18}}
                        inputWidth={{width:'250px'}}
                    />
                    {/* <Form.Item  label='线路名称' required >
                        <Space>
                            <div>{info.lth_xianlu_name}</div>
                            <a onClick={() => {setOpenName(true)}}>请选择</a>
                        </Space>               
                        <Modal
                            open={openName}
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenName(false);}}
                            onOk={() => {}}
                            footer={null}
                            width={1000}
                            title='线路名称模板'
                        >
                            <LineName
                                get={true}
                                ltbjd_dept_id={props.ltbjd_dept_id}
                                getName={(e) => {setInfo(prevState=>({...prevState,lth_xianlu_name:e}))}}
                                getOpen={(e) => {setOpenName(e)}}
                            />
                        </Modal>
                    </Form.Item>     */}
                    <Form.Item label='出发日期' wrapperCol={{span:10}} required name='date'>
                        {props.lth_date?dayjs(props.lth_date).format('YYYY-MM-DD'):<DatePicker onChange={(e) => {setInfo(prevState=>({...prevState,lth_date:dayjs(e).format('YYYY-MM-DD')}))}}/>}
                    </Form.Item>
                    <Form.Item label='团队类型' wrapperCol={{span:10}} required name='type'>
                        <Select
                            options={groupType}
                            onChange={(e,f) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    "lth_zi_type" : e,
                                    "lth_zi_type_name" : f.label,
                                }))
                            }}
                        />
                    </Form.Item>
                    <Form.Item 
                        name="remark" 
                        label='备注' 
                        wrapperCol={{span:18}}
                    >
                        <TextArea
                            onChange={(e) => {
                                setInfo(prevState=>({...prevState,lth_content:e.target.value}))
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal 
                open={openCheck.open} 
                onOk={() => {}} 
                onCancel={() => {setopenCheck({open:false})}}
                width={1200}
                footer={null}
            >
                <GroupDesc lth_id={openCheck.id}/>
            </Modal>
        </>
       
    )
}

export default GroupAdd