import React from 'react'
import BalanceTypeList from '../balance/BalanceTypeList'
import Main from '../../cpn/Main'
function FinanceExpenseType() {
    return (
        <Main t1='财务' n1='/TaFinanceIndex' t2='设置' n2='/TaFinanceSetIndex'  t3='费用支出单类型'>
            <BalanceTypeList type_id='3' title='费用支出单类型'/>
        </Main>
    )
}

export default FinanceExpenseType