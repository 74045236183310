import React from 'react'
import Main from '../../cpn/Main'

import GoodsList from '../goods/GoodsList'

function FinanceGoods() {
    return (
        <Main  t1='财务' n1='/TaFinanceIndex' t2='设置' n2='/TaFinanceSetIndex' t3='产品'>
            <GoodsList />
        </Main>
    )
}

export default FinanceGoods