import React,{useState}from 'react'
import { Modal,message,Form} from 'antd';
import { useDijieBaoJiaDanCopyListMutation, useDijieBaoJiaDanDaoYouCopyListMutation, } from '../../../store/ReactWebSiteApi';
import { useNavigate } from 'react-router-dom';
import { customDocType, customGroupDocType } from '../customType';
import GetFileType from './GetFileType';
function FileCopy(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState();
    const [submitAdd] = useDijieBaoJiaDanCopyListMutation();
    const [guideAdd] = useDijieBaoJiaDanDaoYouCopyListMutation();
    const navi = useNavigate()
    return (
        <>
            <a onClick={() => {
                    setInfo(prevState=>({
                        ...prevState,                   
                        ltbjd_type_id:'',
                    }))
                    setOpen(true)
                }} 
            >复制新增</a>
            <Modal
                open={open}
                title='复制新增'
                okText="提交"
                cancelText="取消"
                width={800}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    if(props.type==='doc'){
                        submitAdd({
                            ltbjd_id:props.ltbjd_id,
                            ltbjd_type_id:info.ltbjd_type_id,
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('复制新增成功！')                              
                                setOpen(false)                         
                                if(props.type==='doc'){
                                    navi('/OpFileDoc')
                                }
                                
                            }
                            if (res.data.Status === 'fail') {
                                message.error('复制新增失败！'+res.data.Message)
                            }
                        })
                    }else{
                        guideAdd({
                            ltbjd_id:props.ltbjd_id,
                            ltbjd_type_id:info.ltbjd_type_id,
                            ltbjd_lth_id:props.ltbjd_lth_id,
                            ltbjd_tuanhao:props.ltbjd_tuanhao,
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('复制新增成功！')                              
                                setOpen(false)                         
                                if(props.type==='doc'){
                                    navi('/OpFileDoc')
                                }
                                
                            }
                            if (res.data.Status === 'fail') {
                                message.error('复制新增失败！'+res.data.Message)
                            }
                        })
                    }
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_copy"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 4 }}
                >                                           
                    <GetFileType
                        label='文件类型' 
                        wrapperCol={{span:20}} 
                        name='fileType'
                        options={props.type==='doc'?customDocType:customGroupDocType}
                        getOpFileType={(e) => {setInfo(prevsState=>({...prevsState,ltbjd_type_id:e}))}}
                    />
                </Form>
            </Modal>
        </>
    )
}

export default FileCopy