import React,{useState,useEffect}from 'react'
import { Table,Space} from 'antd';
import Main from '../../cpn/Main'
import { useIncomeExpenditureHxListQuery,} from '../../store/ReactWebSiteApi';
import LedgerBalanceCheck from './LedgerBalanceCheck';
import LedgerBalanceQuery from './LedgerBalanceQuery';
import { balanceType, } from '../../finance/balance/balanceType';
import LedgerCheck from './LedgerCheck';
import {FileTextOutlined} from '@ant-design/icons';
function LedgerBalance() {    
     //当前页码
     const [current, setCurrent] = useState(localStorage.getItem('LedgerBalance_query_page')?localStorage.getItem('LedgerBalance_query_page'):'1');
     //当前每页数量
     const [pageSize,setPageSize] = useState(localStorage.getItem('LedgerBalance_query_page_size')?localStorage.getItem('LedgerBalance_query_page_size'):"20")
    const [infoQuery,setInfoQuery] = useState({
        eie_e_id:localStorage.getItem('e_id'),
        eie_client_id:localStorage.getItem('LedgerBalance_query_client')?localStorage.getItem('LedgerBalance_query_client'):'',
        action:localStorage.getItem('LedgerBalance_query_action')?localStorage.getItem('LedgerBalance_query_action'):'',
        hx_type:'2',
        eie_arrive_date_s:localStorage.getItem('LedgerBalance_query_arrive_date_s')?localStorage.getItem('LedgerBalance_query_arrive_date_s'):'',
        eie_arrive_date_e:localStorage.getItem('LedgerBalance_query_arrive_date_e')?localStorage.getItem('LedgerBalance_query_arrive_date_e'):'',
        eie_dept_id:localStorage.getItem('LedgerBalance_query_dept')?localStorage.getItem('LedgerBalance_query_dept'):'', 
        ebn_team_num:localStorage.getItem('LedgerBalance_query_team_num')?localStorage.getItem('LedgerBalance_query_team_num'):'',
        ebn_date_s:localStorage.getItem('LedgerBalance_query_date_s')?localStorage.getItem('LedgerBalance_query_date_s'):'',
        ebn_date_e:localStorage.getItem('LedgerBalance_query_date_e')?localStorage.getItem('LedgerBalance_query_date_e'):'',
        eie_jbr_uid:'',
        eu_id:localStorage.getItem('eu_id'),
    });
    const getQuery = (e) => {
        setInfoQuery(prevState=>({
            ...prevState,            
            action:e.action,
            eie_client_id:e.eie_client_id?e.eie_client_id:'',
            ebn_team_num:e.ebn_team_num?e.ebn_team_num:'',
            eie_dept_id:e.eie_dept_id?e.eie_dept_id:'',
            eie_arrive_date_s:e.eie_arrive_date_s?e.eie_arrive_date_s:'',
            eie_arrive_date_e:e.eie_arrive_date_s?e.eie_arrive_date_s:'',
            ebn_date_s:e.ebn_date_s?e.ebn_date_s:'',
            ebn_date_e:e.ebn_date_e?e.ebn_date_e:'',

        }))
    }
    
    const {data,isSuccess} = useIncomeExpenditureHxListQuery(infoQuery,{refetchOnMountOrArgChange:1})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eie_id:key,eie_esv_id,ebn_client_id,ebn_id,ebn_dept_id,ebn_date,eg_name,ebn_yw_name,ed_name,eie_type_id,eie_finance_sub_type_name,ec_id,ebn_team_num,eie_amount,ec_name,eie_hx_amount,eie_hx_ctime,eie_hx_number,eie_hx_uname,eie_year,eie_eg_id,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eu_name} = item;
                    let type
                    let arr = balanceType.filter(obj => obj.value === eie_type_id)
                    if(arr[0]){
                        type = arr[0].label
                    }
                    return item = {key,eie_esv_id,ebn_client_id,ebn_id,ebn_dept_id,ebn_date,eg_name,ebn_yw_name,ed_name,eie_type_id,type,eie_finance_sub_type_name,ec_id,ebn_team_num,eie_amount,ec_name,eie_hx_amount,eie_hx_ctime,eie_hx_number,eie_hx_uname,eie_year,eie_eg_id,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eu_name}
                })
                const filteredArray = newArr.filter(item => +item.eie_hx_number < 1);                
                const sortArr = filteredArray.sort( (a, b) => {      
                    let aTimeString = a.eie_ctime;
                    let bTimeString = b.eie_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key:'1',
            title:'团队收支单',
            render:(_,record) => {
                return  <LedgerBalanceCheck 
                            eie_id={record.key}
                            title={record.type} 
                            eie_type_id={record.eie_type_id}
                            eie_finance_sub_type_id={record.eie_finance_sub_type_id}
                            eie_finance_sub_type_name={record.eie_finance_sub_type_name}
                            eie_ebn_id={record.eie_ebn_id}
                            eie_esv_id={record.eie_esv_id}
                            eie_eg_id={record.eie_eg_id}
                        />
            }
        },
        {
            key: 'ebn_team_num',
            title: '团队报账单',
            dataIndex: 'ebn_team_num',
            sorter: {
                compare: (a, b) => a.ebn_team_num - b.ebn_team_num,
            },
            render:(text,record) => {
                return  <LedgerCheck 
                            text={<Space><FileTextOutlined />BZD{record.ebn_team_num}</Space>} 
                            ebn_id={record.ebn_id} 
                            ebn_dept_id={record.ebn_dept_id}
                            ebn_team_num={record.ebn_team_num}
                        />
            }
        },         
        {
            key: 'ebn_date',
            title: '出发日期',
            dataIndex: 'ebn_date',
        },  
        {
            key: 'ed_name',
            title: '部门',
            dataIndex: 'ed_name',
        },
           
        {
            key: 'ebn_yw_name',
            title: '销售员',
            dataIndex: 'ebn_yw_name',
        },        
        {
            key: 'eg_name',
            title: '产品',
            dataIndex: 'eg_name',
        },  
        {
            key: 'client',
            title: '客户',
            dataIndex: 'ec_name',
        },      
        {
            key: 'pic',
            title: '类型',
            render:(_,recorde) => {
                return <>
                    {recorde.type}-{recorde.eie_finance_sub_type_name}
                </>
            }           
        },    
            
        // {
        //     key: 'eie_count',
        //     title: '数量',
        //     dataIndex: 'eie_count',
        // },            
        {
            key: 'eie_amount',
            title: '金额',
            dataIndex: 'eie_amount',
        },            
        {
            key: 'eie_content',
            title: '备注',
            dataIndex: 'eie_content',
        },                    
        // {
        //     key: 'id',
        //     title: '编号',
        //     dataIndex: 'key'
        // }, 
        // {
        //     key: 'action',
        //     title: '操作' ,
        //     render: (_, record) => {
        //         return <>
        //              <Space>    
        //                 <LedgerBalanceCheck 
        //                     eie_id={record.key}
        //                     title={record.type} 
        //                     eie_type_id={record.eie_type_id}
        //                     eie_finance_sub_type_id={record.eie_finance_sub_type_id}
        //                     eie_finance_sub_type_name={record.eie_finance_sub_type_name}
        //                     eie_ebn_id={record.eie_ebn_id}
        //                     eie_esv_id={record.eie_esv_id}
        //                     eie_eg_id={record.eie_eg_id}
        //                 />
        //             </Space>
        //         </>
        //     }
        // },              
    ]
    
    return (
        <Main  t1='财务' n1='/TaFinanceIndex' t2='团队收支' n2='/LedgerIndex' t3='团队收支单'>
            <div>
                <LedgerBalanceQuery getQuery={getQuery}/>
            </div>    
            <Table 
                columns={columns} 
                style={{marginTop:'10px'}} 
                dataSource={list} 
                pagination={{
                    showSizeChanger:true,
                    showTotal:(total) => `共${total}条`,
                    current:current,
                    onChange:(e,f) => {
                        setCurrent(e)
                        setPageSize(f)
                        localStorage.setItem('LedgerBalance_query_page',e);
                        localStorage.setItem('LedgerBalance_query_page_size',f);
                    },
                    pageSize:pageSize,
                    showTitle:true
                }}
                summary={(pageData) => {
                    let total = 0;
                    pageData.forEach(({eie_amount}) => {
                        total += +eie_amount;
                    });
                    return <Table.Summary>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>{total.toFixed(2)}</Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                }}
            />
        </Main>
    )
}

export default LedgerBalance