import React, { useEffect, useState } from 'react'
import Main from '../../cpn/Main'
import { Space, Table } from 'antd'
import PhoneShopGoodsAdd from './PhoneShopGoodsAdd'

function PhoneShopGoods() {
    const [list,setList] = useState([
        {
            sp_name:'三峡千古情一日游',
            from:'自营/经销',
            goods:'旅游/国内游/定制游',
            id:'门市一部'
        }
    ])
    useEffect(() => {setList()},[])
    const column = [
        {
            key:'8',
            title:'商品名称',
            dataIndex:'sp_name',           
        },     
        {
            key:'2',
            title:'来源',
            dataIndex:'from'
        },                 
        {
            key:'3',
            title:'类型',
            dataIndex:'goods'
        },                 
        {
            key:'4',
            title:'部门',
            dataIndex:'id'
        },      
        {
            key:'5',
            title:'操作',
            render:() => {
                return <Space> 
                    <a>修改</a>
                    <a>删除</a>
                </Space>
            }
        },
    ]
    return (
        <Main t1='计调' n1='/TaOpIndex' t2='手机门店' n2='/PhoneShop' t3='商品管理'>
            <Space>
                <PhoneShopGoodsAdd />
            </Space>
            <Table columns={column} dataSource={list} style={{marginTop:"10px"}}/>
        </Main>
    )
}

export default PhoneShopGoods