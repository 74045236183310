import {Form,InputNumber, message, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDaoyoujihuadanGuiGeUpdateQuery, useDaoyoujihuadanGuiGeUpdateSaveMutation,} from '../../../../store/ReactWebSiteApi';
import CrowdForm from '../../temp/crowd/CrowdForm';
function GuidePlanCrowdEdit(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const {data,isSuccess} = useDaoyoujihuadanGuiGeUpdateQuery({jhdgg_id:props.jhdgg_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                editForm.current.setFieldsValue({
                    jhdgg_name:data.Result.jhdgg_name,
                    jhdgg_num:data.Result.jhdgg_num
                })
                setInfo(prevState=>({...prevState,
                    jhdgg_name:data.Result.jhdgg_name,
                    jhdgg_num:data.Result.jhdgg_num, 
                }))
            }
        }
    },[data,isSuccess])
    const [submitAdd] = useDaoyoujihuadanGuiGeUpdateSaveMutation()
    const [info,setInfo] = useState({jhdgg_name:''});
    const editForm = useRef();
    useEffect(() => {
        if(editForm.current){
            editForm.current.setFieldsValue({        
                jhdgg_num:info.jhdgg_num,               
            })
        }
    },[info.jhdgg_name,info.jhdgg_num])

    return (
        <>
            <a onClick={() => {setOpen(true)}}>修改</a>
            <Modal
                open={open}
                width={800}
                title='修改人群类型数量'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        jhdgg_jhd_id:props.jhdgg_jhd_id,
                        jhdgg_name:info.jhdgg_name,
                        jhdgg_num:info.jhdgg_num,
                        jhdgg_update_uid:localStorage.getItem('eu_id'),
                        jhdgg_update_uname:localStorage.getItem('eu_name'),
                        jhdgg_id:props.jhdgg_id,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')                              
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    style={{marginTop:'10px'}}
                    ref={editForm}
                >
                   <CrowdForm 
                        label='人群类型'
                        name='jhdgg_name'
                        required
                        getType={(e) => {
                            setInfo(prevState=>({...prevState,jhdgg_name:e}))
                        }}
                        crowd={info.jhdgg_name}
                    />
                    <Form.Item label='数量' wrapperCol={{span:20}} required >
                        <InputNumber value={info.jhdgg_num} onChange={(e) => {setInfo(prevState=>({...prevState,jhdgg_num:e}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GuidePlanCrowdEdit