import React, { useEffect, useState } from 'react'
import { Image, Space, Table,message } from 'antd'
import { useDijieTemplateTaiTouDelMutation, useDijieTemplateTaiTouListQuery } from '../../../../store/ReactWebSiteApi'
import ModalDel from '../../../../cpn/ModalDel'
import PageAdd from './PageAdd'
import PageQuery from './PageQuery'
import PageEdit from './PageEdit'
import { imgSrc } from '../../../../cpn/imgSrc'
function Page(props) {
    const [list,setList] = useState()
    const [query,setQuery] = useState({lttt_e_id:localStorage.getItem('e_id')})
    const {data, isSuccess} = useDijieTemplateTaiTouListQuery(query,{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {lttt_id:key,lttt_content,lttt_uname,lttt_ctime,lttt_yejiao,lttt_yemei,ed_name,lttt_name,lttt_gongzhang} = item;              
                           
                    let lttt_qurenzhang = lttt_gongzhang.map((item) => {
                        return imgSrc + item
                    })                 
                    return item = {key,lttt_yemei,lttt_content,lttt_uname,lttt_ctime,lttt_yejiao,ed_name,lttt_name,lttt_qurenzhang}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.lttt_ctime;
                    let bTimeString = b.lttt_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }            
        }
    }, [data, isSuccess])
    //删除
    const [del] = useDijieTemplateTaiTouDelMutation()
    const column = [       
        {
            key:'lttt_name',
            title:'文件类型',
            dataIndex:'lttt_name',            
        },       
        {
            key:'11',
            title:'页面顶部图片',
            dataIndex:'lttt_yemei',
            render:(text) => {
                return   <Image
                            width={60}
                            src={imgSrc+text}
                        />
            }
        },                  
        {
            key:'15',
            title:'确认章图片',
            dataIndex:'lttt_qurenzhang',
            render:(text) => {
                return  <Image.PreviewGroup
                            items={text}
                        >
                            <Image
                                width={60}
                                src={text?text[0]:''}
                            />
                        </Image.PreviewGroup>
            }
        },                  
        {
            key:'12',
            title:'页面底部文字',
            dataIndex:'lttt_yejiao'
        },    
        {
            key:'11',
            title:'部门',
            dataIndex:'ed_name'
        },                   
        {
            key:'6',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <PageEdit lttt_id={record.key} />
                    <ModalDel onOk={() => {
                        del({lttt_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                    {props.get && <a onClick={() => {
                            props.getPage({
                                lttt_yemei:record.lttt_yemei,
                                lttt_yejiao:record.lttt_yejiao,
                            })
                            props.getOpen(false)     
                        }}>选择</a>}
                </Space>
            }
        },
    ]
    
    return (
        <>
            <Space>
                <PageAdd />
                <PageQuery
                        getQuery={(e) => {
                        setQuery(prevState=>({
                            ...prevState,
                            lttt_dept_id:e.lttt_dept_id,
                        }))
                    }}
                />
            </Space>
            <Table 
                columns={column} 
                dataSource={list} 
                style={{marginTop:"10px"}} 
            />
        </>
    )
}

export default Page