import React from 'react'
import Main from '../../cpn/Main'
import { Row} from 'antd';
import Folder from '../../cpn/Folder';

function FundIndex() {
    return (
        <Main t1='财务' n1='TaFinanceIndex' t2='转账'>            
            <Row>                          
                <Folder title='转账凭证'  link='/FundList' />             
                <Folder title='资金账户'  link='/FundAccountIndex' />             
                <Folder title='报表'  link='/FundTable' />         
            </Row>
        </Main>
    )
}

export default FundIndex