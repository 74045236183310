import React  from 'react'
import Main from '../../cpn/Main'
import {Row } from 'antd'
import Folder from '../../cpn/Folder'
function PhoneShopIndex() {
    return (
        <Main t1='计调' n1='/TaOpIndex' t2='手机门店'>
            <Row>
                <Folder title='门店管理' link='/PhoneShop'/>                                    
                <Folder title='商品管理' link='/PhoneShopGoods'/> 
            </Row>
        </Main>
    )
}

export default PhoneShopIndex