import React from 'react'
import 'antd/dist/reset.css';
import { Route, Routes } from 'react-router-dom'
import AlbumGroup from './album/AlbumGroup'
import AlbumIndex from './album/AlbumIndex'
import AlbumGroupDetail from './album/AlbumGroupDetail'
import AlbumSection from './album/AlbumSection'
import AlbumPic from './album/AlbumPic'
import AlbumMan from './album/AlbumMan'
import ArapAddOld from './finance/arap/ArapAddOld'
import ArapList from './finance/arap/ArapList'
import ArapIndex from './finance/arap/ArapIndex'
import ArapStatementSend from './finance/arap/ArapStatementSend'
import ArapStatementReceive from './finance/arap/ArapStatementReceive'
import ArapStatementReceiveCover from './finance/arap/ArapStatementReceiveCover'
import ArapTableDept from './finance/arap/ArapTableDept'
import ArapTable from './finance/arap/ArapTable'
import BalanceIndex from './finance/balance/BalanceIndex'
import BalanceTypeList from './finance/balance/BalanceTypeList'        
import Clocking from './wage/Clocking'
import ClientIndex from './finance/client/ClientIndex'
import Client from './finance/client/Client'
import CustomFeedback from './local/custom/temp/feedback/CustomFeedback'
import CustomFile from './local/custom/file/CustomFile'
import CustomFileIndex from './local/custom/CustomFileIndex'
import CustomGoodsLocal from './local/custom/goods/CustomGoodsLocal'
import CustomGoodsOrgan from './local/custom/goods/CustomGoodsOrgan'
import CustomGoodsSelf from './local/custom/goods/CustomGoodsSelf'
import CustomGroup from './local/custom/group/CustomGroup'
import CustomIndex from './local/custom/CustomIndex'
import CustomClient from './local/custom/temp/client/CustomClient'
import CustomCrowd from './local/custom/temp/crowd/CustomCrowd'
import CustomTempIndex from './local/custom/temp/CustomTempIndex'
import CustomLineName from './local/custom/temp/lineName/CustomLineName'
import CustomStandard from './local/custom/temp/standard/CustomStandard'
import CustomNotes from './local/custom/temp/notes/CustomNotes'
import CustomPage from './local/custom/temp/page/CustomPage'
import CustomPrice from './local/custom/temp/price/CustomPrice'
import CustomRoute from './local/custom/temp/route/CustomRoute'
import CustomSelf from './local/custom/temp/self/CustomSelf'
import ElseIncome from './finance/else/ElseIncome'
import ElseIncomeSumType from './finance/else/ElseIncomeSumType'
import ElseIndex from './finance/else/ElseIndex'
import ElsePaid from './finance/else/ElsePaid'
import ElsePaidSumType from './finance/else/ElsePaidSumType'
import ElseSumClient from './finance/else/ElseSumClient'
import ElseSumDept from './finance/else/ElseSumDept'
import ElseTable from './finance/else/ElseTable'
import EvaluateIndex from './evaluate/EvaluateIndex'
import EvaluateGroup from './evaluate/EvaluateGroup'
import EvaluateGroupDetail from './evaluate/EvaluateGroupDetail'
import EvaluateGroupPoint from './evaluate/EvaluateGroupPoint'
import Expense from './finance/expense/Expense'
import ExpenseIndex from './finance/expense/ExpenseIndex'
import ExpenseProfitDept from './finance/expense/ExpenseProfitDept'
import ExpenseSumClient from './finance/expense/ExpenseSumClient'
import ExpenseSumDept from './finance/expense/ExpenseSumDept'
import ExpenseSumType from './finance/expense/ExpenseSumType'
import ExpenseTable from './finance/expense/ExpenseTable'
import FinanceAccount from './finance/set/FinanceAccount'
import FinanceClient from './finance/set/FinanceClient'
import FinanceClientType from './finance/set/FinanceClientType'
import FinanceElseCostType from './finance/set/FinanceElseCostType'
import FinanceElseIncomeType from './finance/set/FinanceElseIncomeType'
import FundAccount from './finance/fund/FundAccount'
import FundAccountIndex from './finance/fund/FundAccountIndex'
import FundAccountPage from './finance/set/FinanceAccount'
import FundIndex from './finance/fund/FundIndex'
import FundList from './finance/fund/FundList'
import FundTable from './finance/fund/FundTable'
import GoodsIndex from './finance/goods/GoodsIndex'
import GoodsList from './finance/goods/GoodsList'
import GoodsTypeList from './finance/goods/GoodsTypeList'
import IndexTable from './cpn/IndexTable'
import LedgerBalance from './finance/ledger/LedgerBalance'
import Ledger from './finance/ledger/Ledger'
import LedgerIndex from './finance/ledger/LedgerIndex'
import LedgerStartdate from './finance/ledger/LedgerStartdate'
import LedgerTable from './finance/ledger/LedgerTable'
import LocalIndex from './local/LocalIndex'
import LocalShop from './local/LocalShop'
import LoginGate from './cpn/LoginGate'
import Login from './web/Login'
import MineIndex from './mine/MineIndex'
import MineInfo from './mine/MineInfo'
import MinePassword from './mine/MinePassword'
import Notice from './web/Notice'
import NoPower from './sys/NoPower'
import OrganIndex from './organ/OrganIndex'
import OrganCustomIndex from './organ/custom/OrganCustomIndex'
import PhoneShop from './organ/phoneShop/PhoneShop'
import PhoneShopGoods from './organ/phoneShop/PhoneShopGoods'
import PhoneShopIndex from './organ/phoneShop/PhoneShopIndex'
import Register from './web/Register'
import SetAppPayed from './set/SetAppPayed'
import SetAppLimits from './set/SetAppLimits'
import SetAppNotPay from './set/SetAppNotPay'
import SetCourse from './set/SetCourse'
import SetDept from './set/SetDept'
import SetIndex from './set/SetIndex'
import SetPost from './set/SetPost'
import SetRole from './set/SetRole'
import SetSubscription from './set/SetSubscription'
import StudyTourFile from './local/studyTour/file/StudyTourFile'
import StudyTourGroup from './local/studyTour/group/StudyTourGroup'
import StudyTourIndex from './local/studyTour/StudyTourIndex'
import StudyTourTemplateIndex from './local/studyTour/temp/StudyTourTemplateIndex'
import StudyTourCourse from './local/studyTour/temp/course/StudyTourCourse'
import TaCourse from './web/TaCourse'
import FinanceExpenseType from './finance/set/FinanceExpenseType'
import FinanceGoods from './finance/set/FinanceGoods'
import FinanceGoodsType from './finance/set/FinanceGoodsType'
import FinanceIndex from './finance/set/FinanceIndex'
import FinanceLedgerCostType from './finance/set/FinanceLedgerCostType'
import FinanceLedgerDate from './finance/set/FinanceLedgerDate'
import FinanceLedgerIncomeType from './finance/set/FinanceLedgerIncomeType'
import FinanceSetIndex from './finance/set/FinanceSetIndex'
import TaHome from './web/TaHome'
import TaIndex from './sys/TaIndex'
import TaIndexPersonnel from './sys/TaIndexPersonnel'
import TaPrice from './web/TaPrice'
import TaPriceIndex from './web/TaPriceIndex'
import TaPriceFinance from './web/TaPriceFinance'
import TaPriceCustom from './web/TaPriceCustom'
import TaPriceUpdate from './web/TaPriceUpdate'
import TrainCharge from './train/TrainCharge'
import TrainIndex from './train/TrainIndex'
import TrainJoin from './train/TrainJoin'
import TrainLimits from './train/TrainLimits'
import TrainPlan from './train/TrainPlan'
import TrainQuestion from './train/TrainQuestion'
import TrainTask from './train/TrainTask'
import Wage from './wage/Wage'
import WageBank from './wage/WageBank'
import WageLimits from './wage/WageLimits'
import WageDetail from './wage/WageDetail'

const RouteItem = [        
    {
        path:'/Client',
        element:<Client />,
        ess_menu_id:'49',
    },
    {
        path:'/GoodsList',
        element:<GoodsList />,
        ess_menu_id:'50',
    },
]
function App() {    
    return (    
        <Routes>        
            <Route path='/ArapStatementReceiveCover' element={<ArapStatementReceiveCover />}></Route>              
            <Route path='/Login' element={<Login />}></Route> 
            <Route path='/Notice' element={<Notice />}></Route> 
            <Route path='/Register' element={<Register />}></Route>
            <Route path='/TaCourse' element={<TaCourse />}></Route>
            <Route path='/' element={<TaHome />}></Route>
            <Route path='/TaPrice' element={<TaPrice />}>
                <Route path='/TaPrice/TaPriceIndex' element={<TaPriceIndex />}></Route>
                <Route path='/TaPrice/TaPriceCustom' element={<TaPriceCustom />}></Route>
                <Route path='/TaPrice/TaPriceFinance' element={<TaPriceFinance />}></Route>
                <Route path='/TaPrice/TaPriceUpdate' element={<TaPriceUpdate />}></Route>                  
            </Route> 
            <Route element={<IndexTable />}>
                <Route   path='/
                '  element={<ArapTableDept />}></Route>             
            </Route>                       
            <Route path='/' element={<LoginGate />}> 
                <Route path='/' element={<TaIndex/>}></Route>
                    {RouteItem.map((item,index) =>{
                        if(localStorage.getItem('ess_menu_id')){
                            return <Route 
                            key={index}
                            path={item.path} 
                            element={(localStorage.getItem('eu_role')==='2' || localStorage.getItem('ess_menu_id').includes(item.ess_menu_id)) ? item.element :<NoPower/>}
                            ></Route>
                        }else{
                            return <></>
                        }
                    }                 
                )}
                <Route key='9999' path='/NoPower' element={<NoPower />}></Route>            
                <Route path='/AlbumGroup' element={<AlbumGroup />}></Route>
                <Route path='/AlbumIndex' element={<AlbumIndex />}></Route>        
                <Route path='/AlbumGroupDetail' element={<AlbumGroupDetail />}></Route>
                <Route path='/AlbumSection' element={<AlbumSection />}></Route>
                <Route path='/AlbumPic' element={<AlbumPic />}></Route>
                <Route path='/AlbumMan' element={<AlbumMan />}></Route>
                <Route path='/ArapAddOld' element={<ArapAddOld />}></Route>
                <Route path='/ArapList' element={<ArapList />}></Route>
                <Route path='/ArapIndex' element={<ArapIndex />}></Route>            
                <Route path='/ArapStatementSend' element={<ArapStatementSend />}></Route>
                <Route path='/ArapStatementReceive' element={<ArapStatementReceive />}></Route>
                <Route path='/ArapTable' element={<ArapTable />}></Route>
                <Route path='/BalanceTypeList' element={<BalanceTypeList />}></Route>
                <Route path='/BalanceIndex' element={<BalanceIndex />}></Route>
                <Route path='/ClientIndex' element={<ClientIndex />}></Route>                   
                <Route path='/CustomFeedback' element={<CustomFeedback />}></Route>        
                <Route path='/CustomFile' element={<CustomFile />}></Route>        
                <Route path='/CustomFileIndex' element={<CustomFileIndex />}></Route>        
                <Route path='/CustomGoodsLocal' element={<CustomGoodsLocal />}></Route>    
                <Route path='/CustomGoodsOrgan' element={<CustomGoodsOrgan />}></Route>    
                <Route path='/CustomGoodsSelf' element={<CustomGoodsSelf />}></Route>    
                <Route path='/CustomGroup' element={<CustomGroup />}></Route>   
                <Route path='/CustomIndex' element={<CustomIndex />}></Route>        
                <Route path='/CustomClient' element={<CustomClient />}></Route>      
                <Route path='/CustomCrowd' element={<CustomCrowd />}></Route>       
                <Route path='/CustomIndex' element={<CustomIndex />}></Route>      
                <Route path='/CustomLineName' element={<CustomLineName />}></Route> 
                <Route path='/CustomNotes' element={<CustomNotes />}></Route> 
                <Route path='/CustomPage' element={<CustomPage />}></Route>       
                <Route path='/CustomPrice' element={<CustomPrice />}></Route>       
                <Route path='/CustomRoute' element={<CustomRoute />}></Route>       
                <Route path='/CustomSelf' element={<CustomSelf />}></Route>        
                <Route path='/CustomStandard' element={<CustomStandard />}></Route>       
                <Route path='/CustomTempIndex' element={<CustomTempIndex />}></Route>      
                <Route path='/ElseIncome' element={<ElseIncome />}></Route>
                <Route path='/ElseIncomeSumType' element={<ElseIncomeSumType />}></Route>
                <Route path='/ElseIndex' element={<ElseIndex />}></Route>
                <Route path='/ElsePaid' element={<ElsePaid />}></Route>    
                <Route path='/ElsePaidSumType' element={<ElsePaidSumType />}></Route>    
                <Route path='/ElseSumClient' element={<ElseSumClient />}></Route>    
                <Route path='/ElseSumDept' element={<ElseSumDept />}></Route>    
                <Route path='/ElseTable' element={<ElseTable />}></Route>
                <Route path='/EvaluateIndex' element={<EvaluateIndex />}></Route>
                <Route path='/EvaluateGroup' element={<EvaluateGroup />}></Route>
                <Route path='/EvaluateGroupDetail' element={<EvaluateGroupDetail />}></Route>
                <Route path='/EvaluateGroupPoint' element={<EvaluateGroupPoint />}></Route>
                <Route path='/Expense' element={<Expense />}></Route>
                <Route path='/ExpenseIndex' element={<ExpenseIndex />}></Route>
                <Route path='/ExpenseProfitDept' element={<ExpenseProfitDept />}></Route>
                <Route path='/ExpenseSumClient' element={<ExpenseSumClient />}></Route>
                <Route path='/ExpenseSumDept' element={<ExpenseSumDept />}></Route>
                <Route path='/ExpenseSumType' element={<ExpenseSumType />}></Route>
                <Route path='/ExpenseTable' element={<ExpenseTable />}></Route>  
                {/* f */}
                <Route path='/FinanceAccount' element={<FinanceAccount />}></Route>
                <Route path='/FinanceClient' element={<FinanceClient/>}></Route>  
                <Route path='/FinanceClientType' element={<FinanceClientType/>}></Route>  
                <Route path='/FinanceElseCostType' element={<FinanceElseCostType />}></Route>
                <Route path='/FinanceElseIncomeType' element={<FinanceElseIncomeType />}></Route>
                <Route path='/FundAccount' element={<FundAccount />}></Route>
                <Route path='/FundAccountIndex' element={<FundAccountIndex />}></Route>
                <Route path='/FundAccountPage' element={<FundAccountPage />}></Route>    
                <Route path='/FundList' element={<FundList />}></Route>        
                <Route path='/FundIndex' element={<FundIndex />}></Route> 
                <Route path='/FundTable' element={<FundTable />}></Route>        
                <Route path='/GoodsIndex' element={<GoodsIndex />}></Route>         
                <Route path='/GoodsTypeList' element={<GoodsTypeList />}></Route>   
                <Route path='/LedgerBalance' element={<LedgerBalance />}></Route>         
                <Route path='/LedgerIndex' element={<LedgerIndex />}></Route>                
                <Route path='/Ledger' element={<Ledger />}></Route>
                <Route path='/LedgerStartdate' element={<LedgerStartdate />}></Route>
                <Route path='/LedgerTable' element={<LedgerTable />}></Route>       
                <Route path='/LocalIndex' element={<LocalIndex />}></Route>       
                <Route path='/LocalShop' element={<LocalShop />}></Route>       
                {/* <Route path='/MineIndex' element={<MineIndex />}></Route> */}
                <Route path='/MineIndex' element={<MineIndex />}></Route>
                <Route path='/MineInfo' element={<MineInfo />}></Route>
                <Route path='/MinePassword' element={<MinePassword />}></Route>
                <Route path='/OrganIndex' element={<OrganIndex />}></Route>
                <Route path='/OrganCustomIndex' element={<OrganCustomIndex />}></Route>
                <Route path='/PhoneShop' element={<PhoneShop />}></Route>
                <Route path='/PhoneShopGoods' element={<PhoneShopGoods />}></Route>
                <Route path='/PhoneShopIndex' element={<PhoneShopIndex />}></Route>
                <Route path='/SetAppNotPay' element={<SetAppNotPay />}></Route>
                <Route path='/SetAppPayed' element={<SetAppPayed />}></Route>        
                <Route path='/SetAppLimits' element={<SetAppLimits />}></Route>         
                <Route path='/SetCourse' element={<SetCourse />}></Route>
                <Route path='/SetDept' element={<SetDept />}></Route>           
                <Route path='/SetIndex' element={<SetIndex />}></Route> 
                <Route path='/SetPost' element={<SetPost />}></Route>
                <Route path='/SetRole' element={<SetRole />}></Route>
                <Route path='/SetSubscription' element={<SetSubscription />}></Route>
                <Route path='/StudyTourIndex' element={<StudyTourIndex />}></Route>
                <Route path='/StudyTourFile' element={<StudyTourFile />}></Route>        
                <Route path='/StudyTourGroup' element={<StudyTourGroup />}></Route>        
                <Route path='/StudyTourTemplateIndex' element={<StudyTourTemplateIndex />}></Route>
                <Route path='/StudyTourCourse' element={<StudyTourCourse />}></Route>
                <Route path='/TaFinanceExpenseType' element={<FinanceExpenseType />}></Route>
                <Route path='/TaFinanceGoods' element={<FinanceGoods />}></Route>
                <Route path='/TaFinanceGoodsType' element={<FinanceGoodsType />}></Route>
                <Route path='/TaFinanceIndex' element={<FinanceIndex />}></Route>  
                <Route path='/TaFinanceLedgerCostType' element={<FinanceLedgerCostType />}></Route>
                <Route path='/TaFinanceLedgerDate' element={<FinanceLedgerDate />}></Route>
                <Route path='/TaFinanceLedgerIncomeType' element={<FinanceLedgerIncomeType />}></Route>
                <Route path='/TaFinanceSetIndex' element={<FinanceSetIndex />}></Route>
                <Route path='/TaIndex' element={<TaIndex />}></Route>                    
                <Route path='/TaIndexPersonnel' element={<TaIndexPersonnel />}></Route>                
                <Route path='/Task' element={<sk />}></Route>
                <Route path='/TrainCharge' element={<TrainCharge />}></Route>
                <Route path='/TrainIndex' element={<TrainIndex />}></Route>
                <Route path='/TrainJoin' element={<TrainJoin />}></Route>
                <Route path='/TrainLimits' element={<TrainLimits />}></Route>            
                <Route path='/TrainPlan' element={<TrainPlan />}></Route>
                <Route path='/TrainQuestion' element={<TrainQuestion />}></Route>                
                <Route path='/TrainTask' element={<TrainTask />}></Route>
                <Route path='/Wage' element={<Wage />}></Route>
                <Route path='/WageBank' element={<WageBank />}></Route>
                <Route path='/WageLimits' element={<WageLimits />}></Route>
                <Route path='/WageDetail' element={<WageDetail />}></Route>
                <Route path='/Clocking' element={<Clocking />}></Route>     
            </Route>   
        </Routes>   
    )
}

export default App