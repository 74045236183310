import React, { useEffect, useState } from 'react'
import { message,  Space,  } from 'antd'
import TableCheck from '../../../cpn/TableCheck'
import ModalDel from '../../../cpn/ModalDel'
import { useDijieBaoJiaDanXingChengDelMutation, useDijieBaoJiaDanXingChengListQuery } from '../../../store/ReactWebSiteApi'
import { CheckOutlined,CloseOutlined } from '@ant-design/icons';
import FileScheduleAdd from './FileScheduleAdd'
import FileScheduleEdit from './FileScheduleEdit'
function FileSchedule(props) {
    const [list,setList] = useState()
    const {data,isSuccess} = useDijieBaoJiaDanXingChengListQuery({bjdxc_bjd_id:props.bjdxc_bjd_id})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const newArr = data.Result.map((item,index) => {
                    // console.log(data);
                    const {bjdxc_id:key,bjdxc_wancan,bjdxc_ctime,bjdxc_anpai,bjdxc_mingcheng,bjdxc_zaocan,bjdxc_day,bjdxc_zhongcan,bjdxc_zhusu} = item
                    
                    return item={key,index,bjdxc_wancan,bjdxc_ctime,bjdxc_anpai,bjdxc_mingcheng,bjdxc_zaocan,bjdxc_day,bjdxc_zhongcan,bjdxc_zhusu}
                })
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.bjdxc_day;
                    let bTimeString = b.bjdxc_day;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return  aTime - bTime;     
                    },)                
                setList(sortArr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDijieBaoJiaDanXingChengDelMutation()
   
    const column = [
        {
            key:'num',
            title:'天数',
            render:(_,record) => {
                return <>{record.index + 1}</>
            },
            width:'60px'
        },   
        {
            key:'1',
            title:'日期',
            dataIndex:'bjdxc_day',     
            width:'100px',      
        },
       
        {
            key:'13',
            title:'行程安排',
            dataIndex:'bjdxc_anpai',
        },           
        {
            key:'4',
            title:'早餐',
            dataIndex:'bjdxc_zaocan',
            width:'60px',
            render:(text) => {
                if(text==='1'){
                    return <CheckOutlined />
                }else{
                    return <CloseOutlined />
                }
            }
        },              
        {
            key:'14',
            title:'中餐',
            dataIndex:'bjdxc_zhongcan',
            width:'60px',
            render:(text) => {
                if(text==='1'){
                    return <CheckOutlined />
                }else{
                    return <CloseOutlined />
                }
            }
        },              
        {
            key:'24',
            title:'晚餐',
            dataIndex:'bjdxc_wancan',
            width:'60px',
            render:(text) => {
                if(text==='1'){
                    return <CheckOutlined />
                }else{
                    return <CloseOutlined />
                }
            }
        },              
        {
            key:'34',
            title:'住宿',
            dataIndex:'bjdxc_zhusu',
            width:'200px',
        },              
        {
            key:'5',
            title:'操作',            
            width:'120px',
            render:(_,record) => {
                return<Space>
                    <FileScheduleEdit
                        bjdxc_id={record.key}
                        bjdxc_bjd_id={props.bjdxc_bjd_id}
                    />
                    <ModalDel onOk={() => {
                        del({bjdxc_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                </Space>
            }
        },        
    ]
    if(props.noAdd || props.preview){
        column.pop()
    }

    return (    
        <TableCheck 
            title='行程安排'
            titleRight={(!props.noAdd && !props.preview) && <FileScheduleAdd
                bjdxc_bjd_id={props.bjdxc_bjd_id}
                />}
            columns={column}
            dataSource={list}
            size={props.customType==='1'?'middle':'small'}
        />
    )
}

export default FileSchedule