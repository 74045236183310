import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../../cpn/ModalEdit'
import { Form, Input, message,Button, Space } from 'antd';
import GetUserDept from '../../../../cpn/GetUserDept';
import { useDingzhiyouyijiandanTemplateUpdateQuery, useDingzhiyouyijiandanTemplateUpdateSaveMutation, } from '../../../../store/ReactWebSiteApi';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

function FeedbackEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        ltyjd_e_id:localStorage.getItem('e_id'),
        ltyjd_update_uid:localStorage.getItem('eu_id'),
        ltyjd_update_uname:localStorage.getItem('eu_name'),
        ltyjd_id:props.ltyjd_id,
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useDingzhiyouyijiandanTemplateUpdateQuery({ltyjd_id:props.ltyjd_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                
                editForm.current.setFieldsValue({
                    ltyjd_dept_name:data.Result.ltyjd_dept_name,                    
                    ltyjd_name:data.Result.ltyjd_name,
                    names:data.Result.item,
                })
                setInfo(prevsState=>({...prevsState,
                    ltyjd_dept_id:data.Result.ltyjd_dept_id,
                    ltyjd_name:data.Result.ltyjd_name,
                    "item" : data.Result.item,
                    "ltyjd_dept_name" : data.Result.ltyjd_dept_name,
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useDingzhiyouyijiandanTemplateUpdateSaveMutation()
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {                       
                        console.log(values);     
                        const arr = values.names.map((item) => {
                            return item.ltyjdi_name
                        })
                        updateSave({
                            "item" : arr,
                            "ltyjd_dept_id" : info.ltyjd_dept_id,
                            "ltyjd_dept_name" : info.ltyjd_dept_name,
                            "ltyjd_e_id" : localStorage.getItem('e_id'),
                            "ltyjd_e_name" : localStorage.getItem('e_name'),
                            "ltyjd_id" : props.ltyjd_id,
                            "ltyjd_name" : info.ltyjd_name,
                            "ltyjd_update_uid" : localStorage.getItem('eu_id'),
                            "ltyjd_update_uname" : localStorage.getItem('eu_name'),
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('修改成功！') 
                                setOpen(false)                                                 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改失败！'+res.data.Message)
                            }
                        })
                    })
                }}
            open={open}
            title='人群类型模板'
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 6 }}
                ref={editForm}
            >                                           
                <GetUserDept
                    label='部门' 
                    name='ltyjd_dept_name'
                    required 
                    wrapperCol={{span:18}}
                    getUserDept={(e,f) => {setInfo(prevsState=>({...prevsState,ltyjd_dept_id:e,ltyjd_dept_name:f}))}}
                />
                <Form.Item name="ltyjd_name" label='模板名称' wrapperCol={{span:18}} required>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,ltyjd_name:e.target.value}))}} />  
                </Form.Item>             
                <Form.Item
                    label='评价项目'
                    required
                    wrapperCol={{span:18}}
                >
                    <Form.List 
                        name='names'
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'ltyjdi_name']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请填写评价项目！',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="填写评价项目" />
                                        </Form.Item>                                   
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button 
                                        type="dashed" 
                                        onClick={() => add()} block icon={<PlusOutlined />}
                                    >增加评价项目</Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>       
                </Form.Item>
        </Form>
        </ModalEdit>
    )
}

export default FeedbackEdit