import React,{useState,}from 'react'
import { Modal,Space, } from 'antd';
import {FileTextOutlined} from '@ant-design/icons';
import LedgerBalanceDesc from './LedgerBalanceDesc';
function LedgerBalanceCheck(props) {
    const [open,setOpen] = useState()   
    return (
        <>
            <a onClick={() => {
                setOpen(true)}}>
                    <Space><FileTextOutlined/> SZD{props.eie_id}</Space>
                </a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                width={1190}
                onCancel={() => {setOpen(false);}} 
                footer={null}                                      
            >
                <LedgerBalanceDesc 
                     eie_id={props.eie_id}
                     title={props.title}
                />            
            </Modal>
        </>
    )
}

export default LedgerBalanceCheck