import React, { useEffect, useState } from 'react'
import { Form, InputNumber, Modal, Space, Table ,message} from 'antd'
import { useYewuTuanHaoDelMutation, useYewuTuanHaoListQuery } from '../../../store/ReactWebSiteApi'
import ModalDel from '../../../cpn/ModalDel'
import GroupAdd from './GroupAdd'
import GroupCheck from './GroupCheck'
import GroupEdit from './GroupEdit'
function Group(props) {
    const [list,setList] = useState()
    const [form] = Form.useForm();  
    const {data, isSuccess} = useYewuTuanHaoListQuery({lth_e_id:localStorage.getItem('e_id')},{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {lth_id:key,lth_num,lth_fenche,lth_uname,lth_ctime,lth_date,lth_kehu_name,lth_xianlu_name,lth_dept_name,lth_type,lth_ltkh_id,} = item;                    
                    return item = {key,lth_num,lth_fenche,lth_uname,lth_ctime,lth_date,lth_kehu_name,lth_xianlu_name,lth_dept_name,lth_type,lth_ltkh_id,}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.lth_ctime;
                    let bTimeString = b.lth_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //删除
    const [del] = useYewuTuanHaoDelMutation()
    const column = [             
        {
            key:'1',
            title:'团号',
            dataIndex:'lth_num',
            render:(text,record) => {
                return  <GroupCheck 
                            lth_num={text} 
                            lth_id={record.key}
                            lth_date={record.lth_date}
                        />
            }
        },     
        {
            key:'2',
            title:'出发日期',
            dataIndex:'lth_date'
        },     
             
        {
            key:'12',
            title:'客户名称',
            dataIndex:'lth_kehu_name'
        },                        
        {
            key:'13',
            title:'线路名称',
            dataIndex:'lth_xianlu_name'
        },                        
        {
            key:'14',
            title:'部门',
            dataIndex:'lth_dept_name'
        },                                               
        {
            key:'16',
            title:'分车数量',
            dataIndex:'lth_fenche',
        },                        
        {
            key:'6',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <GroupEdit 
                        lth_id={record.key}
                        lth_type={record.lth_type}
                    />                    
                    <ModalDel onOk={() => {
                        del({lth_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                    {props.get && <a onClick={() => {
                            props.getNum({
                                ltbjd_tuanhao:record.lth_num,
                                ltbjd_lth_id:record.key
                            })
                            setOpen(true)  
                        }}>选择</a>}
                </Space>
            }
        },
    ]
    const [open,setOpen] = useState(false)

    return (
        <>
            <Space>
                <GroupAdd
                    lth_type={props.lth_type}
                    lth_kehu_name={props.lth_kehu_name}
                    lth_xianlu_name={props.lth_xianlu_name}
                    lth_date={props.lth_date}
                    lth_dept_id={props.lth_dept_id}
                    lth_dept_name={props.lth_dept_name}
                    type={props.type}
                />                
            </Space>
            <Table columns={column} dataSource={list} style={{marginTop:"10px"}}/>
            <Modal
                title='选择分车'
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {                       
                            form.resetFields();                   
                            props.getSerial(values.serial)
                            setOpen(false) 
                            props.getOpen(false)       
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                        
                }}
                onCancel={() => {setOpen(false)}}
                open={open}                
                width={800}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{serial: '1',}}
                    labelCol={{ span: 4 }}
                >
                    <Form.Item name="serial" label='分车序号' wrapperCol={{span:19}} required>
                        <InputNumber />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default Group