import React from 'react'
import TaTop from './TaTop'
import Foot from './Foot'
import classes from './web.module.css'
function Notice() {
    return (
        <>
            <TaTop/>
            <div className={classes.noticeBack}>
                <div className={classes.loginCenter}>
                    <div className={classes.loginLeft}>
                        <div>系统通知</div>
                    </div>
                    <div style={{fontSize:"24px",padding:"80px"}}>
                        因服务器维护需要，2024年10月10日周四上午09:00-10:00，系统暂时无法使用，感谢配合！
                    </div>
                </div>     
            </div> 
            <Foot />
        </>
    )
}

export default Notice