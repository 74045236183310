import React, { useEffect, useState } from 'react'
import { message, Space,} from 'antd'
import TableCheck from '../../../cpn/TableCheck'
import { useDijieBaoJiaDanJieDaiBiaoZhunDelMutation, useDijieBaoJiaDanJieDaiBiaoZhunListQuery } from '../../../store/ReactWebSiteApi'
import { localCostType } from '../customType'
import ModalDel from '../../../cpn/ModalDel'
import OpFileStandardEdit from './FileStandardEdit'
import FileStandardAdd from './FileStandardAdd'

function FileStandard(props) {
    const [list,setList] = useState()
    const {data,isSuccess} = useDijieBaoJiaDanJieDaiBiaoZhunListQuery({bjdjdbz_bjd_id:props.bjdjdbz_bjd_id},{refetchOnFocus:true})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                // console.log(data);
                const arr = data.Result.map((item) => {
                    const {bjdjdbz_id:key,bjdjdbz_name,bjdjdbz_ctime,bjdjdbz_type_id,bjdjdbz_uname} = item
                    return item = {key,bjdjdbz_name,bjdjdbz_ctime,bjdjdbz_type_id,bjdjdbz_uname}
                })
                setList(arr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDijieBaoJiaDanJieDaiBiaoZhunDelMutation()
    const column = [
        {
            key:'1',
            title:'类型',
            dataIndex:'bjdjdbz_type_id',
            render:(text) => {
                let arr = localCostType.filter((item) => item.value===text)
                let type = arr[0].label    
                return type
            },
            width:'120px'

        },
        {
            key:'2',
            title:'接待标准',
            dataIndex:'bjdjdbz_name'
        },            
        {
            key:'5',
            title:'操作',
            width:'120px',
            render:(_,record) => {
                return<Space>
                    <OpFileStandardEdit 
                        bjdjdbz_id={record.key} 
                        bjdjdbz_bjd_id={props.bjdjdbz_bjd_id}
                    />
                    <ModalDel onOk={() => {
                        del({bjdjdbz_id:record.key}).then((res) => {
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                </Space>
            }
        },        
    ]
    if(props.noAdd || props.preview){
        column.pop()
    }
    return (
        <>
            <TableCheck 
                columns={column}
                dataSource={list}
                style={{marginTop:"10px"}}
                title='接待标准'
                titleRight={(!props.noAdd && !props.preview) && <FileStandardAdd
                    bjdjdbz_bjd_id={props.bjdjdbz_bjd_id}
                    ltbjd_dept_id={props.ltbjd_dept_id}
                    />}
                size={props.customType==='1'?'middle':'small'}
            />
        </>
    )
}

export default FileStandard