import React ,{useEffect,useState} from 'react'
import Main from '../cpn/Main'
import {Row,Modal,Flex,message} from 'antd'
import Folder from '../cpn/Folder'
import { useAppSynthesisUserNotReadListQuery, useAppSynthesisUserReadLogMutation } from '../store/ReactWebSiteApi'
function TaIndex() {
    const [open,setOpen] = useState(false)
    const [info,setInfo]  = useState({esvs_name:''})
    const {data,isSuccess} = useAppSynthesisUserNotReadListQuery({
        "esv_id" : '2',
        "eusl_uid" : localStorage.getItem('eu_id'),
        type_id:"1",
    })
    useEffect(() => {
      if(isSuccess){
        if(data.Status==='success'){
            setOpen(true)
            setInfo(data.Result[0])
        }
      }
    
    }, [data,isSuccess])
    const [read] = useAppSynthesisUserReadLogMutation()
    return (
        <Main >
            <Row>
            {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('60'))) && <Folder title='国内地接' link='/LocalIndex'/>}                      
            {(localStorage.getItem('eu_name')==='"林未来"' ||localStorage.getItem('eu_name')==='"柏菁"'||localStorage.getItem('eu_name')==='"张宇"') && <Folder title='国内组团' link='/OrganIndex'/>}                     
            {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('47'))) && <Folder title='财务' link='/TaFinanceIndex'/>}                     
            {(localStorage.getItem('eu_role')==='2' ||(localStorage.getItem('ess_menu_id').includes('48'))) && <Folder title='人事' link='/TaIndexPersonnel'/>}     
            {localStorage.getItem('eu_role') ==='2' && <Folder title='系统设置' link='/SetIndex'/>}
                <Folder title='我的' link='/MineIndex?esv_id=8'/>
            </Row>
            <Modal 
                title='更新通知' 
                open={open} 
                okText='我已知晓'
                onCancel={() => {setOpen(false)}}
                onOk={() => {
                    read({
                        "eusl_esvs_id" : info.esvs_id,
                        "eusl_uid" : localStorage.getItem('eu_id'),
                        "eusl_uname" : localStorage.getItem('eu_name'),                                    
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('如果需要查询更多日志，请到"我的——更新日志"查询')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Flex justify='center'><h2>{info.esvs_name}</h2></Flex>
                <div dangerouslySetInnerHTML={{__html:info.esvs_content}} ></div>
            </Modal>
        </Main>
    )
}

export default TaIndex