import { Form, Modal, Space,Input,Button} from 'antd';
import React, { useState } from 'react'
import Feedback from '../../temp/feedback/Feedback';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
function FeedbackForm(props) {
    const [open,setOpen] = useState(false)    
    return (
        <Form.Item
            label='评价项目'                    
            required={props.required}   
            wrapperCol={{span:18}}                 
        >
            <Space direction='vertical'>                
                <Form.List 
                    name='names'
                >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'ltyjdi_name']}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请填写评价项目！',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="填写评价项目" />
                                    </Form.Item>                                   
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button 
                                    type="dashed" 
                                    onClick={() => add()} block icon={<PlusOutlined />}
                                >增加评价项目</Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>                   
                <a onClick={() => {setOpen(true)}}>模板</a>
                <Modal
                    open={open}
                    okText="提交"
                    cancelText="取消"
                    onCancel={() => {setOpen(false);}}
                    onOk={() => {}}
                    footer={null}
                    width={800}
                    title='意见单模板'
                >
                    <Feedback 
                        type='select'
                        getFeedback={(e) => {
                            props.getFeedback(e)                            
                        }}
                        getOpen={(e) => {setOpen(false)}}
                    />
                </Modal> 
            </Space>
        </Form.Item>   
    )
}

export default FeedbackForm