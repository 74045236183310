import React from 'react'
import Main from '../../../../cpn/Main'
import Notes from './/Notes'
function CustomNotes() {
    return (
        <Main t1='定制游' n1='/CustomIndex' t2='模板' n2='/CustomTempIndex' t3='注意事项'>
            <Notes />
        </Main>
    )
}

export default CustomNotes