import { Space, Table } from 'antd'
import React, { useState,useEffect } from 'react'
import GroupFeedbackCheck from './GroupFeedbackCheck'
import {useTuanhaoyijiandanListQuery, } from '../../../../store/ReactWebSiteApi';
import GroupFeedbackEdit from './GroupFeedbackEdit';
import GroupFeedbackAdd from './GroupFeedbackAdd'

function GroupFeedback(props) {
    const [list, setList] = useState();
    const {data,isSuccess} = 
    useTuanhaoyijiandanListQuery({ltthyjd_lth_id:props.dyjhd_lth_id},{refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                const newArr = data.Result.map(item => {
                    const {ltthyjd_id:key,ltthyjd_name,ltthyjd_phone,ltthyjd_content,ltthyjd_uname,ltthyjd_ctime,ltthyjd_dept_name,ltthyjd_fenche,ltthyjd_update_ctime,ltthyjd_update_uname,ltthyjd_yjd_item} = item;
                    let names
                    if(ltthyjd_yjd_item){
                        names = ltthyjd_yjd_item.map((item) => {
                            return item+ ' , '
                        })
                    }
                    return item = {key,ltthyjd_name,ltthyjd_phone,ltthyjd_content,ltthyjd_uname,ltthyjd_ctime,ltthyjd_dept_name,ltthyjd_fenche,ltthyjd_update_ctime,ltthyjd_update_uname,names,ltthyjd_yjd_item}
                })                
                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltthyjd_ctime;
                    let bTimeString = b.ltthyjd_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const column = [             
        {
            key:'1',
            title:'意见单',
            dataIndex:'lth_num',  
            width:'200px',
            render:() => {
                return <GroupFeedbackCheck />
            }         
        },              
        {
            key:'2',
            title:'分车序号',    
            width:'100px', 
            dataIndex:'ltthyjd_fenche',       
        },
        {
            key:'3',
            title:'评价项目',    
            width:'100px', 
            dataIndex:"names"       
        },
        {
            key:'4',
            title:'二维码',            
        },        
        {
            key:'5',
            title:'操作',
            width:"120px",
            render:(_,record) => {
                return  <Space>
                            <GroupFeedbackEdit 
                                ltthyjd_id={record.key}
                            />
                            <a>删除</a>
                        </Space>
            }
        },
    ]
    return (
        <>
            <div style={{marginLeft:'20px',marginTop:'10px'}}>
                <GroupFeedbackAdd 
                    dyjhd_lth_id={props.dyjhd_lth_id}
                    dyjhd_lth_num={props.dyjhd_lth_num}
                />
            </div>
            <Table 
                columns={column}
                dataSource={list}
                pagination={{position:['none']}}
            />
        </>
    )
}

export default GroupFeedback