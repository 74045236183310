import { Table,DatePicker,Space,Modal,Col,Flex} from 'antd'
import React,{useState,useEffect} from 'react'
import dayjs from 'dayjs';
import {useWlUidProfitQuery,} from '../../store/ReactWebSiteApi';
import classes from '../../sys/index.module.css'
import table from '../../svg/table.svg'
const {RangePicker} = DatePicker
function ArapTableGoodsType(props) {
    const [open, setOpen] = useState(false)
    const [query,setQuery] = useState({
        s_date:dayjs().startOf('month').format('YYYY-MM-DD'),
        e_date:dayjs().endOf('month').format('YYYY-MM-DD'),
        e_id:localStorage.getItem('e_id'),
        eie_type_id:'1,2,3'
    })    
    const {data,isSuccess} = useWlUidProfitQuery({
        e_id:localStorage.getItem('e_id'),
        eie_type_id:'1,2,3',
        s_date:query.s_date,
        e_date:query.e_date,
    },{refetchOnMountOrArgChange:1,skip:!open})    
    const [list, setList] = useState();    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){    
                const newArr = data.Result.map(item => {
                    const {eie_uid:key,eie_uname,type1,type2,type3} = item;
                    return item = {key,eie_uname,type1,type2,type3}
                })                
                setList(newArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])        
    const columns = [
        {
            key: 'id',
            title: '操作员',
            dataIndex: 'eie_uname'
        },         
        {
            key: 'type1',
            title: '团队收入单',
            dataIndex: 'type1'
        },         
        {
            key: 'type2',
            title: '团队支出单',
            dataIndex: 'type2'
        },         
        {
            key: 'type3',
            title: '费用支出单',
            dataIndex: 'type3'
        },         
        {
            key: 'ar',
            title: '利润',
            render:(_,record) => {
                return<>{+record.type1 + +record.type2 + +record.type3}</>
            }
        },                                  
    ]
    return (
        <>
            <Col span={2} className={classes.mainItem} onClick={() => {
                    setOpen(true)                
                }}>
                <img src={table} width={40} style={{marginBottom:"10px"}} alt=''/>
                <div style={{textAlign:'center'}}>操作员利润表</div>
            </Col>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1200}
                footer={null}
            >            
                <Flex justify='center'>
                    <h2>操作员利润表</h2>
                </Flex>
                <Space style={{marginBottom:"10px"}}>
                    <span>下账日期：</span>
                    <RangePicker 
                        defaultValue={[dayjs().startOf('month'),dayjs().endOf('month')]} 
                        onChange={(e) => {
                            if(e){
                                setQuery(prevState=>({...prevState,s_date:dayjs(e[0]).format('YYYY-MM-DD'),e_date:dayjs(e[1]).format('YYYY-MM-DD')})) 
                            }else{
                                setQuery(prevState=>({...prevState,s_date:'',date_e:''}))
                            }
                        }}
                    />
                </Space>
                <Table 
                    columns={columns} 
                    dataSource={list} 
                    style={{marginTop:'10px'}}
                    summary={(pageData) => {
                        console.log(pageData);
                        let totaltype1 = 0;
                        let totaltype2 = 0;
                        let totaltype3 = 0;
                        pageData.forEach(({type1,type2,type3 }) => {                        
                            totaltype1 += +type1;
                            totaltype2 += +type2;
                            totaltype3 += +type3;
                        });
                        return <Table.Summary>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>{(totaltype1)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={2}>{(totaltype2)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}>{(totaltype3)}</Table.Summary.Cell>
                                        <Table.Summary.Cell index={4}>{(totaltype1 + totaltype2 + totaltype3)}</Table.Summary.Cell>                                    
                                    </Table.Summary.Row>
                                </Table.Summary>
                    }}
                />
                <div style={{lineHeight:'50px'}}>报表用途：按下账日期，统计每个操作员的团队收入单、团队支出单和费用支出单已下账金额之和、已下账利润。</div>         
            </Modal>
       </>
    )
}

export default ArapTableGoodsType