import React,{useState,useEffect,}from 'react'
import { Table, Flex, Descriptions, Space, Tag} from 'antd';
import {useJizhangshouzhiListQuery, useZhuanzhangpinpingzhengListQuery,} from '../../store/ReactWebSiteApi';
import { balanceType } from '../balance/balanceType';
import LedgerGroupBalanceCheck from '../ledger/LedgerBalanceCheck';
import ArapCheck from '../arap/ArapCheck';
function FundDesc(props) {
    //查看转账凭证
    const {data:dataDesc,isSuccess:isSuccessDesc} = useZhuanzhangpinpingzhengListQuery({ecal_id:props.ecal_id},{skip:!props.ecal_id})
    const [info,setInfo] = useState({
        eca_amount:'',
        eca_type_id:'',
        eca_bank_crad:'',
        eca_bank_kh:'',
        eca_bank_zh:'',
        ecal_bank_detail_date:'',
        ecal_content:'',
        ecal_ec_name:'',
        eca_wx_phone:'',
        eca_wx_account:'',
        eca_wx_content:'',
        eca_alipay_phone:'',
        eca_alipay_account:'',
        eca_alipay_content:'',
        ecal_ctime:'',
        ecal_uname:'',
        ecal_amount:'',
        eie_hx_number:'',
    });
    useEffect(() => {
        if(isSuccessDesc){
            if(dataDesc.Status==='success'){
                console.log(dataDesc);
                setInfo(prevState=>({
                    ...prevState,
                    eca_amount:dataDesc.Result.eca_amount,
                    eca_type_id:dataDesc.Result.eca_type_id,
                    eca_bank_crad:dataDesc.Result.eca_bank_crad,
                    eca_bank_kh:dataDesc.Result.eca_bank_kh,
                    eca_bank_zh:dataDesc.Result.eca_bank_zh,
                    ecal_bank_detail_date:dataDesc.Result.ecal_bank_detail_date,
                    ecal_content:dataDesc.Result.ecal_content,
                    ecal_ec_name:dataDesc.Result.ecal_ec_name,
                    eca_wx_phone:dataDesc.Result.eca_wx_phone,
                    eca_wx_account:dataDesc.Result.eca_wx_account,
                    eca_wx_content:dataDesc.Result.eca_wx_content,
                    eca_alipay_phone:dataDesc.Result.eca_alipay_phone,
                    eca_alipay_account:dataDesc.Result.eca_alipay_account,
                    eca_alipay_content:dataDesc.Result.eca_alipay_content,
                    ecal_ctime:dataDesc.Result.ecal_ctime,
                    ecal_uname:dataDesc.Result.ecal_uname,
                    ecal_amount:dataDesc.Result.ecal_amount,
                    ecal_id:dataDesc.Result.ecal_id,
                    eie_hx_number:dataDesc.Result.eie_hx_number,
                }))
            }
        }
    },[dataDesc,isSuccessDesc])
    //查看明细=========================================================================================================================查看明细//
    
    const [list,setList] = useState();
    const {data,isSuccess} = useJizhangshouzhiListQuery({ecal_id:props.ecal_id},{skip:!props.ecal_id,refetchOnMountOrArgChange:1})
    
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                const arr = data.Result.map((item) => {
                    const {eie_id:key,ec_name,eie_ebn_id,eg_name,eie_price,eie_finance_sub_type_name,eie_hx_ctime,eie_hx_uname,ebn_team_num,ed_name,ebn_yw_name,eie_type_id,eie_amount,eie_content} = item
                    let type
                    let arr = balanceType.filter(obj => obj.value === eie_type_id)
                    if(arr[0]){
                        type = arr[0].label
                    }
                    return item = {key,ec_name,eie_ebn_id,eg_name,eie_price,eie_finance_sub_type_name,eie_hx_ctime,eie_hx_uname,ebn_team_num,ed_name,ebn_yw_name,type,eie_amount,eie_content}
                })
                setList(arr)
            }
        }

    },[data,isSuccess])
    //资金账户
    const [bank,setBank] = useState('')
    useEffect(() => {
        if(info.eca_type_id==='1'){
            setBank(<>
                        <div>开户行：{info.eca_bank_kh}</div>
                        <div>户&emsp;名：{info.eca_bank_zh}</div>
                        <div>账&emsp;号：{info.eca_bank_crad}</div>
                    </>)
        }
        if(info.eca_type_id==='2'){
            setBank(<>
            <div>手机号码：{info.eca_wx_phone}</div>
            <div>微信号：{info.eca_wx_account}</div>
            </>)
        }
        if(info.eca_type_id==='3'){
            setBank(<>
            <div>手机号码：{info.eca_alipay_phone}</div>
            <div>支付宝账号：{info.eca_alipay_account}</div>
            </>)
        }
    },[info.eca_alipay_account, info.eca_alipay_content, info.eca_alipay_phone, info.eca_bank_content, info.eca_bank_crad, info.eca_bank_kh, info.eca_bank_zh, info.eca_type_id, info.eca_wx_account, info.eca_wx_content, info.eca_wx_phone, props])
    const columns = [
        {
            key: 'id',
            title: '收支单',
            dataIndex: 'key',
            render:(_,record) => {
                return  <LedgerGroupBalanceCheck                         
                            eie_id={record.key}
                            title={record.type}
                        />
            }
        },        
        {
            key: 'pic',
            title: '类型',
            render:(_,record) => {
                return <>
                    {record.type}-{record.eie_finance_sub_type_name}
                </>
            }           
        }, 
        {
            key: 'client',
            title: '客户',
            dataIndex: 'ec_name',
        },                           
        {
            key: 'eie_amount',
            title: '金额',
            render:(_,record) => {
                return <>{record.eie_price} × {record.eie_count} = {record.eie_amount}</>
            }
        },        
        {
            key: 'guige',
            title: '数量',
            dataIndex:"guige",
            render:(text) => {
                let arr = []
                if(text){
                    arr = text.map((item) => {
                        return <div key={item.eiegg_egg_id}>{item.eiegg_egg_name+item.eiegg_egg_value}</div>
                    })
                }
                return <Space>{arr}</Space>
            }
        },                     
        {
            key: 'eie_content',
            title: '备注',
            dataIndex: 'eie_content',
        },  
        {
            key: 'eie_jbr_uname',
            title: '经办人',
            dataIndex:'eie_jbr_uname',
        },             
                                                 
        {
            key: 'ed_name',
            title: '部门',
            dataIndex: 'ed_name'
        },                                          
                                                
        {
            key: 'status',
            title: '审核',
            render:(_,record) => {
                return<>
                    {!record.eie_audit1_uname && <>待审核</>}
                    {(record.eie_audit1_uname && !record.eie_audit2_uname) && <Tag color='green'>已审核</Tag>}
                    {record.eie_audit2_uname && <Tag color="volcano">已复核</Tag>}
                </>
            }
        },                        
             
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.eie_ctime + record.eie_uname}</div>
        //             <div>修改时间：{record.eie_update_ctime + record.eie_update_uname}</div>
        //         </>
        //     }
        // },
       
    ]
    const items = [
        {
            key:"1",
            label:'客户',
            children:info.ecal_ec_name,
        },
        {
            key:"2",
            label:'资金账户',
            children:bank,
        },
        {
            key:"3",
            label:'转账金额',
            children:info.ecal_amount,
        },
        {
            key:"4",
            label:'备注',
            children:info.ecal_content,
        },
        {
            key:"5",
            label:'操作时间',
            children:info.ecal_ctime + info.ecal_uname,
        },
        {
            key:"6",
            label:'编号',
            children:info.ecal_id,
        },
        {
            key:"7",
            label:'转账日期',
            children:info.ecal_bank_detail_date,
        },
        {
            key:"8",
            label:'账户余额',
            children:info.eca_amount,
        },
        {
            key:"9",
            label:'下账凭证',
            children:<ArapCheck eie_hx_number={info.eie_hx_number}/>,
        },
    ]
    return (
        <div style={{border:'2px solid #eee',margin:"10px"}}>
            <Flex justify='center'><h2>转账凭证</h2></Flex>
            {bank&&<Descriptions                                  
                items={items} 
                bordered
            />}
            <Table 
                columns={columns} 
                dataSource={list}
                style={{marginTop:'10px'}}
                pagination={{position:['none']}}
                title={() => '收支单明细'}
            />
        </div>  
    )
}

export default FundDesc