import React, { useState } from 'react'
import { Button, DatePicker, Input, Modal, Form} from 'antd'
import GetUserDept from '../../../cpn/GetUserDept';


function FileQuery(props) {    
    const [open,setOpen] = useState(false)
    const [form] = Form.useForm();
    return (
        <>
            <Button onClick={() => {setOpen(true)}}>查询</Button>
            <Modal
                open={open}
                title='查询文件'
                okText="提交"
                cancelText="关闭"
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {                                        
                            form.resetFields();  
                            }}>重置</Button>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            console.log(values);
                            props.getQuery(values)
                            setOpen(false)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        ltbjd_dept_id:'',
                        ltbjd_ltxl_name:'',
                        ltbjd_client_name:'',
                        ltbjd_date:'',
                    }}
                    labelCol={{ span: 5 }}
                >
                    <GetUserDept 
                        name='ltbjd_dept_id' 
                        label='部门'
                        wrapperCol={{span:15}}
                        getUserDept={() => {}}
                    />
                    <Form.Item 
                        label='线路名称' 
                        wrapperCol={{span:15}} 
                        name='ltbjd_ltxl_name'
                    >
                        <Input onChange={() => {}} />
                    </Form.Item>                                                   
                    {props.customType!=='1' && <>
                        <Form.Item 
                            label='收件人' 
                            wrapperCol={{span:15}} 
                            name='ltbjd_client_name'
                        >
                            <Input  onChange={() => {}} />
                        </Form.Item> 
                        <Form.Item 
                            name='ltbjd_date' 
                            label='出发日期' 
                            wrapperCol={{span:15}}
                        >
                            <DatePicker />
                        </Form.Item> 
                    </>}                               
                </Form>
            </Modal>
        </>
    )
}

export default FileQuery