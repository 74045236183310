import React, { useState }  from 'react'
import {Modal,} from 'antd'
import LedgerDesc from './LedgerDesc';
function LedgerCheck(props) {   
    const [open,setOpen] = useState(false)

    return (
        <>            
            <a onClick={() => {setOpen(true)}}>{props.text}</a>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1440}
                footer={null}                
            > 
                <LedgerDesc 
                    ebn_id={props.ebn_id}
                />
            </Modal>
        </>
    )
}

export default LedgerCheck