import React, { useEffect, useRef, useState } from 'react'
import ModalEdit from '../../../cpn/ModalEdit'
import { DatePicker, Form,Input,message, Modal,Space } from 'antd'
import { useDijieBaoJiaDanUpdateQuery, useDijieBaoJiaDanUpdateSaveMutation, } from '../../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import Client from '../temp/client/Client'
import LineName from '../temp/lineName/LineName';
const { TextArea } = Input;
function FileEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef()
    const [info,setInfo] = useState({
        ltbjd_e_id:localStorage.getItem('e_id'),
        ltbjd_update_uid:localStorage.getItem('eu_id'),
        ltbjd_update_uname:localStorage.getItem('eu_name'),
        ltbjd_id:props.ltbjd_id,       
        ltbjd_date:'',
        ltbjd_client_name:'',
    });
    const [open,setOpen] = useState()
    //获取
    const {data,isSuccess} = useDijieBaoJiaDanUpdateQuery({ltbjd_id:props.ltbjd_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){               
                editForm.current.setFieldsValue({
                    ltbjd_dept_id:data.Result.ed_name,                              
                    ltbjd_date:dayjs(data.Result.ltbjd_date),    
                    ltbjd_ltxl_name:data.Result.ltbjd_ltxl_name,                   
                    ltbjd_client_name:data.Result.ltbjd_client_name,                   
                    ltbjd_content:data.Result.ltbjd_content,                   
                    ltbjd_phone:data.Result.ltbjd_phone,                   
                })                                   
                setInfo(prevsState=>({...prevsState,
                    ltbjd_dept_id:data.Result.ltbjd_dept_id,                  
                    ltbjd_ltxl_name:data.Result.ltbjd_ltxl_name,
                    ltbjd_date:data.Result.ltbjd_date,
                    ltbjd_client_name:data.Result.ltbjd_client_name,
                    ltbjd_content:data.Result.ltbjd_content,      
                    ed_name:data.Result.ed_name,        
                    ltbjd_phone:data.Result.ltbjd_phone,    
                }))
            }
        }
    },[data,isSuccess,])
    const [updateSave] = useDijieBaoJiaDanUpdateSaveMutation()    
    //模版
    const [openName,setOpenName] = useState(false)    
    //客户
    const [openClient,setOpenClient] = useState(false)
    
    return (
        <ModalEdit
            getOpen={(e) => {setOpen(prevsState=>e );}}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        //获取产品人群类型
                        let arr = []
                        for (let key in values) {
                            if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                if(key.includes('egg')){
                                    arr.push(values[key])
                                }
                            }
                        } 
                        console.log(values);                       
                        updateSave({
                            ltbjd_id:props.ltbjd_id,
                            ltbjd_e_id:localStorage.getItem('e_id'),                        
                            ltbjd_e_name:localStorage.getItem('e_name'),
                            ltbjd_client_name:info.ltbjd_client_name,
                            ltbjd_update_uid:localStorage.getItem('eu_id'),
                            ltbjd_update_uname:localStorage.getItem('eu_name'),
                            ltbjd_dept_id:localStorage.getItem('ed_id'),
                            ltbjd_dept_name:localStorage.getItem('ed_name'),
                            ltbjd_ltxl_name:info.ltbjd_ltxl_name,
                            ltbjd_date:info.ltbjd_date,
                            ltbjd_content:info.ltbjd_content, 
                            ltbjd_phone:info.ltbjd_phone, 
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('修改成功！') 
                                setOpen(false)                                                 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改失败！'+res.data.Message)
                            }
                        })
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
                
            }}
            open={open}
            title='文件'
        >
            <Form
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 4 }}
                ref={editForm}
            >
                
                <Form.Item
                    label='客户'                    
                    required                    
                >
                    <Space align='center'>
                        <div>{info.ltbjd_client_name + ' - ' + info.ltbjd_phone}</div>                     
                        <a onClick={() => {setOpenClient(true)}}>请选择</a>
                        <Modal
                            open={openClient}
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpenClient(false);}}
                            onOk={() => {}}
                            footer={null}
                            width={800}
                            title='选择客户'
                        >
                            <Client
                                type='select'
                                getClient={(e) => {
                                    setInfo(prevState=>({
                                        ...prevState,
                                        ltbjd_ltkh_id:e.ltkh_id,
                                        ltbjd_client_name:e.ltkh_name,
                                        ltbjd_phone:e.ltkh_phone,
                                    }))                                    
                                }}
                                getOpen={(e) => {setOpenClient(false)}}
                            />
                        </Modal> 
                    </Space>
                </Form.Item>
                <Form.Item  label='线路名称' required >
                    <Space>
                        <div>{info.ltbjd_ltxl_name}</div>
                        <a onClick={() => {setOpenName(true)}}>请选择</a>
                    </Space>               
                    <Modal
                        open={openName}
                        okText="提交"
                        cancelText="取消"
                        onCancel={() => {setOpenName(false);}}
                        onOk={() => {}}
                        footer={null}
                        width={1000}
                        title='线路名称模板'
                    >
                        <LineName
                            get={true}
                            ltbjd_dept_id={props.ltbjd_dept_id}
                            getName={(e) => {setInfo(prevState=>({...prevState,ltbjd_ltxl_name:e}))}}
                            getOpen={(e) => {setOpenName(e)}}
                        />
                    </Modal>
                </Form.Item>
                <Form.Item name="ltbjd_date" label='出发日期' wrapperCol={{span:19}} required>
                    <DatePicker  
                        value={info.ltbjd_date}
                        onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_date:dayjs(e).format('YYYY-MM-DD')}))}}
                    />
                </Form.Item> 
                <Form.Item  label='备注' wrapperCol={{span:19}} name='ltbjd_content'>
                    <TextArea 
                        onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_content:e.target.value}))}}
                        autoSize={{
                            minRows: 2,                                
                            }}
                    />
                </Form.Item>
            </Form>
        </ModalEdit>
    )
}

export default FileEdit