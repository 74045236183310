import React,{useEffect, useRef, useState}from 'react'
import { Modal,message,Form,InputNumber, TimePicker, Space, Image,} from 'antd';
import {useDijieBaoJiaDanXingChengUpdateQuery, useDijieBaoJiaDanXingChengUpdateSaveMutation,  } from '../../../store/ReactWebSiteApi';
import dayjs from 'dayjs'
import { localCostType } from '../customType';
import { imgSrcWhole } from '../../../cpn/imgSrc';
import typeName from './typeName';
import CustomRoute from '../temp/route/CustomRoute';
function FileRouteEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const {data,isSuccess} = useDijieBaoJiaDanXingChengUpdateQuery({bjdxc_id:props.bjdxc_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                editForm.current.setFieldsValue({
                    bjdxc_day_num:data.Result.bjdxc_day_num,
                    bjdxc_ck_time:dayjs(data.Result.bjdxc_ck_time,'HH:mm'),
                    bjdxc_type_id:data.Result.bjdxc_type_id,
                    bjdxc_name:data.Result.bjdxc_name,
                    pic:[{
                        uid:'1',
                        url:imgSrcWhole+data.Result.bjdxc_image,
                        thumbUrl:imgSrcWhole+data.Result.bjdxc_image,
                        status: 'done',
                        name:data.Result.bjdxc_image
                    }],
                    bjdxc_content:data.Result.bjdxc_content,
                })
                let arr = localCostType.filter((item) => item.value===data.Result.bjdxc_type_id)
                let type = arr[0].label    
                setInfo(prevState=>({...prevState,
                    bjdxc_day_num:data.Result.bjdxc_day_num,
                    bjdxc_ck_time:data.Result.bjdxc_ck_time,
                    bjdxc_type_id:data.Result.bjdxc_type_id,
                    bjdxc_name:data.Result.bjdxc_name,
                    bjdxc_image:data.Result.bjdxc_image,
                    bjdxc_content:data.Result.bjdxc_content,                    
                    bjdxc_daoyoujihua:data.Result.bjdxc_daoyoujihua,                    
                    type,
                }))
            }
        }
    },[data,isSuccess])
    const [info,setInfo] = useState({
        isChoose:false,
    });
    const [submitAdd] = useDijieBaoJiaDanXingChengUpdateSaveMutation()
    //模版
    const [openTemplate,setOpenTemplate] = useState(false)
    const getTemplate = <>
            <Form.Item  label='请选择' required>
                <a onClick={() => {setOpenTemplate(true)}}>行程安排模板</a>               
            </Form.Item>        
            <Modal
                open={openTemplate}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenTemplate(false);}}
                onOk={() => {}}
                footer={null}
                width={1000}
                title='行程安排模板'
            >
                <CustomRoute
                    get={true}
                    ltbjd_dept_id={props.ltbjd_dept_id}
                    getRoute={(e) => {
                        const type = typeName(e.ltxc_type_id)
                        setInfo(prevState=>({
                            ...prevState,
                            bjdxc_name:e.ltxc_name,
                            bjdxc_image:e.ltxc_image,
                            bjdxc_content:e.ltxc_content,
                            bjdxc_type_id:e.ltxc_type_id,                            
                            bjdxc_daoyoujihua:e.ltxc_daoyoujihua,
                            type,
                        }))
                    }}
                    getOpen={(e) => {setOpenTemplate(e)}}
                />
            </Modal>
        </>
    return (
        <>
            <a onClick={() => {setOpen(true)}} >修改</a>
            <Modal
                open={open}
                title='修改行程安排'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        bjdxc_bjd_id:props.bjdxc_bjd_id,
                        bjdxc_day_num:info.bjdxc_day_num,
                        bjdxc_ck_time:info.bjdxc_ck_time,
                        bjdxc_type_id:info.bjdxc_type_id,
                        bjdxc_name:info.bjdxc_name,
                        bjdxc_image:info.bjdxc_image,
                        bjdxc_content:info.bjdxc_content,   
                        bjdxc_daoyoujihua:info.bjdxc_daoyoujihua,                        
                        bjdxc_update_uid:localStorage.getItem('eu_id'),
                        bjdxc_update_uname:localStorage.getItem('eu_name'),
                        bjdxc_id:props.bjdxc_id,

                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    ref={editForm}
                >                                           
                    <Form.Item name="bjdxc_day_num" label='天数' wrapperCol={{span:10}} required>
                        <InputNumber  
                            onChange={(e) => {setInfo(prevState=>({...prevState,bjdxc_day_num:e}))}}
                            min={1}
                        />
                    </Form.Item>
                    <Form.Item 
                        name="bjdxc_ck_time" 
                        label='参考时间' 
                        wrapperCol={{span:10}} 
                        required
                    >
                        <TimePicker 
                            onChange={(e) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    bjdxc_ck_time:dayjs(e).format('HH:mm')
                                }))}}
                            format='HH:mm'
                        />
                    </Form.Item>
                    {getTemplate}
                    <Form.Item                         
                        label='类型' 
                        wrapperCol={{span:20}}                          
                    >
                        {info.type}                     
                    </Form.Item>
                    <Form.Item  label='名称' wrapperCol={{span:10}} >
                        {info.bjdxc_name}
                    </Form.Item>
                    <Form.Item label='图片' wrapperCol={{span:10}} >
                        <Space>
                            {info.bjdxc_image && info.bjdxc_image.map((item) => {
                                return <Image src={item} alt='' width={60}/>
                            })}
                        </Space>
                    </Form.Item>
                    <Form.Item label='描述' wrapperCol={{span:15}} >
                        {info.bjdxc_content}
                    </Form.Item> 
                    <Form.Item label='导游计划' wrapperCol={{span:15}} >
                        {info.bjdxc_daoyoujihua}
                    </Form.Item> 
                </Form>
            </Modal>
        </>
    )
}

export default FileRouteEdit