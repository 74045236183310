import { message, Modal, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDijieBaoJiaDanListQuery, useDijieBaoJiaDanUpdateSaveMutation } from '../../../store/ReactWebSiteApi';
import CustomCheck from '../file/FileCheck';

function GroupFileAdd(props) {
    const [open, setOpen] = useState(false);
    const {data, isSuccess} = useDijieBaoJiaDanListQuery({
        ltbjd_e_id:localStorage.getItem('e_id'),
        ltbjd_date:props.lth_date,
        ltbjd_ltkh_id:props.lth_ltkh_id,
    },{skip:!open});
    const [list,setList] = useState()
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const arr = data.Result.map(item => {
                    const {ltbjd_id:key,ltbjd_tuanhao,ltbjd_daoyou,ltbjd_jietuan_content,ltbjd_fenchexuhao,ltbjd_client_name,ltbjd_uname,ltbjd_dept_id,ltbjd_ctime,ltbjd_ltxl_name,ltbjd_date,ed_name,ltbjd_content,ltbjd_type_id,ltbjd_sp_id,ltbjd_sp_name,ltbjd_lth_id,ltbjd_yemei,ltbjd_yejiao} = item;
                     return item = {key,ltbjd_tuanhao,ltbjd_daoyou,ltbjd_jietuan_content,ltbjd_fenchexuhao,ltbjd_client_name,ltbjd_uname,ltbjd_dept_id,ltbjd_ctime,ltbjd_ltxl_name,ltbjd_date,ed_name,ltbjd_content,ltbjd_type_id,ltbjd_sp_id,ltbjd_sp_name,ltbjd_lth_id,ltbjd_yemei,ltbjd_yejiao}
                })     
                let newArr =  arr.filter(item => ['1','2','3','4'].includes(item.ltbjd_type_id))           
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltbjd_ctime;
                    let bTimeString = b.ltbjd_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }            
        }
        
    }, [data, isSuccess])
    //修改保存
    const [updateSave] = useDijieBaoJiaDanUpdateSaveMutation()
    const column = [
        {
            key:'1',
            title:'编号',
            dataIndex:'key',
            width:'160px',
            render:(_,record) => {
                let typeName
                if(record.ltbjd_type_id==='1'){typeName='行程设计' }
                if(record.ltbjd_type_id==='2'){typeName='确认单'}
                if(record.ltbjd_type_id==='3'){typeName='确认件'}
                if(record.ltbjd_type_id==='4'){typeName='结算单'}
                return  <CustomCheck 
                            ltbjd_id={record.key}
                            text={typeName + ' - ' +record.key}
                            ltbjd_dept_id={record.ltbjd_dept_id}
                            customType={record.ltbjd_type_id}
                        />
            }
        },
        {
            key:'2',
            title:'客户',
            dataIndex:'ltbjd_client_name'
        },
        {
            key:'3',
            title:'线路名称',
            dataIndex:'ltbjd_ltxl_name'
        }, 
        {
            key:'4',
            title:'出发日期',
            dataIndex:'ltbjd_date'
        },
       
        {
            key:'5',
            title:'部门',
            dataIndex:'ed_name'
        },                     
        {
            key:'6',
            title:'备注',
            dataIndex:'ltbjd_content'
        },                                 
        {
            key:'11',
            title:'操作',
            render:(_,record) => {
                return <Space>                     
                    <a onClick={() => {
                    console.log(record);
                        updateSave({
                            ltbjd_id:record.key,
                            ltbjd_e_id:localStorage.getItem('e_id'),                            
                            ltbjd_e_name:localStorage.getItem('e_name'),
                            ltbjd_client_name:record.ltbjd_client_name,
                            ltbjd_update_uid:localStorage.getItem('eu_id'),
                            ltbjd_update_uname:localStorage.getItem('eu_name'),
                            ltbjd_sp_id:record.ltbjd_sp_id,
                            ltbjd_sp_name:record.ltbjd_sp_name,
                            ltbjd_dept_id:record.ltbjd_dept_id,
                            ltbjd_dept_name:record.ed_name,
                            ltbjd_ltxl_name:record.ltbjd_ltxl_name,
                            ltbjd_date:record.ltbjd_date,
                            ltbjd_type_id:record.ltbjd_type_id,
                            ltbjd_content:record.ltbjd_content, 
                            ltbjd_yemei:record.ltbjd_yemei,
                            ltbjd_tuanhao:props.lth_num,
                            ltbjd_yejiao:record.ltbjd_yejiao,
                            ltbjd_lth_id:props.ltbjd_lth_id,
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('修改成功！') 
                                setOpen(false)                                                 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('修改失败！'+res.data.Message)
                            }
                        })
                    }}>添加</a>                                      
                </Space>
            }
        },
    ]
    return (
        <>
            <a onClick={() => {setOpen(true);}}>导入确认单</a>
            <Modal
                open={open}
                width={1000}
                footer={null}
                title='导入销售文件'
                onCancel={() => {setOpen(false)}}
            >
                <Table 
                    dataSource={list} 
                    columns={column} 
                    pagination={{position:['none']}}
                />
            </Modal>
        </>
    )
}

export default GroupFileAdd