import React from 'react'
import Main from '../../cpn/Main'
import {Row} from 'antd';
import Folder from '../../cpn/Folder';
function LedgerIndex() {
    return (
        <Main t1='财务' n1='/TaFinanceIndex' t2='团队收支'>            
            <Row>                
                <Folder title='团队报账单'  link='/Ledger' />            
                <Folder title='团队收支单'  link='/LedgerBalance' />            
                <Folder title='报表'  link='/LedgerTable' />        
            </Row>
        </Main>
    )
}

export default LedgerIndex