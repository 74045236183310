import React from 'react'
import Main from '../../../cpn/Main'
import Group from './Group'

function StudyTourGroup() {
    return (
        <Main t1='研学旅行' n1='/StudyTourIndex' t2='团队计划'>
            <Group 
                type='1'
                fileTypesId={['1','2','3','4']}
            />
        </Main>
    )
}

export default StudyTourGroup