import React from 'react'
import Main from '../../cpn/Main'
import { Row} from 'antd';
import FundTableDept from './FundTableDept';
import FundTableClient from './FundTableClient';
import FundTableClientType from './FundTableClientType';
import FundTableGoods from './FundTableGoods';
import FundTableGoodsType from './FundTableGoodsType';
import FundTableOperator from './FundTableOperator';
import FundTableSalesman from './FundTableSalesman';

function FundTable() {
    return (
        <Main t1='财务' n1='/TaFinanceIndex' t2='转账' n2='/FundIndex' t3='报表'>            
            <Row>                           
                <FundTableDept />
                <FundTableClient />
                <FundTableClientType />
                <FundTableGoods />
                <FundTableGoodsType />
                <FundTableOperator />
                <FundTableSalesman />
            </Row>
        </Main>
    )
}

export default FundTable