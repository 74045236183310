import React,{useEffect, useRef, useState}from 'react'
import { Modal,message,Form,Input, DatePicker, Checkbox,} from 'antd';
import {useDijieBaoJiaDanXingChengUpdateQuery, useDijieBaoJiaDanXingChengUpdateSaveMutation, } from '../../../store/ReactWebSiteApi';
import dayjs from 'dayjs'
const {TextArea} = Input
function FileScheduleEdit(props) {
    const [form] = Form.useForm();  
    const editForm = useRef();
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const {data,isSuccess} = useDijieBaoJiaDanXingChengUpdateQuery({bjdxc_id:props.bjdxc_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                let arr = []
                if(data.Result.bjdxc_zaocan==='1'){
                    arr.push('1')
                };
                if(data.Result.bjdxc_zhongcan==='1'){
                    arr.push('2')
                };
                if(data.Result.bjdxc_wancan==='1'){
                    arr.push('3')
                };
                editForm.current.setFieldsValue({
                    bjdxc_day:dayjs(data.Result.bjdxc_day),
                    bjdxc_anpai:data.Result.bjdxc_anpai,
                    bjdxc_zhusu:data.Result.bjdxc_zhusu,
                    meal:arr,                   
                   
                })
                setInfo(prevState=>({...prevState,
                    bjdxc_day:data.Result.bjdxc_day,
                    bjdxc_anpai:data.Result.bjdxc_anpai,
                    bjdxc_zaocan:data.Result.bjdxc_zaocan,                   
                    bjdxc_zhongcan:data.Result.bjdxc_zhongcan,
                    bjdxc_wancan:data.Result.bjdxc_wancan,
                    bjdxc_zhusu:data.Result.bjdxc_zhusu,
                }))
            }
        }
    },[data,isSuccess])
    const [info,setInfo] = useState({
        isChoose:false,
    });
    const [submitAdd] = useDijieBaoJiaDanXingChengUpdateSaveMutation()
    
    return (
        <>
            <a onClick={() => {setOpen(true)}} >修改</a>
            <Modal
                open={open}
                title='修改行程安排'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        "bjdxc_anpai" : info.bjdxc_anpai,
                        "bjdxc_day" : info.bjdxc_day,
                        "bjdxc_id" : props.bjdxc_id,
                        "bjdxc_bjd_id" : props.bjdxc_bjd_id,
                        "bjdxc_update_uid" : localStorage.getItem('eu_id'),
                        "bjdxc_update_uname" : localStorage.getItem('eu_name'),
                        "bjdxc_wancan" : info.bjdxc_wancan,
                        "bjdxc_zaocan" : info.bjdxc_zaocan,
                        "bjdxc_zhongcan" : info.bjdxc_zhongcan,
                        "bjdxc_zhusu" : info.bjdxc_zhusu,
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('修改成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('修改失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    ref={editForm}
                >                                           
                   <Form.Item name="bjdxc_day" label='日期' wrapperCol={{span:10}} required>
                        <DatePicker 
                            onChange={(e) => {setInfo(prevState=>({...prevState,bjdxc_day:e}))}}
                        />
                    </Form.Item>
                    <Form.Item name="bjdxc_anpai" label='行程安排' wrapperCol={{span:10}} required>
                        <TextArea 
                             onChange={(e) => {setInfo(prevState=>({...prevState,bjdxc_anpai:e.target.value}))}}
                        />
                    </Form.Item>                   
                    <Form.Item                         
                        label='用餐住宿' 
                        wrapperCol={{span:20}}   
                        name='meal'                       
                    >
                        <Checkbox.Group
                            onChange={(e) => {
                                if(e.includes('1')){
                                    setInfo(prevState=>({...prevState,bjdxc_zaocan:'1'}))
                                };
                                if(e.includes('2')){
                                    setInfo(prevState=>({...prevState,bjdxc_zhongcan:'1'}))
                                };
                                if(e.includes('3')){
                                    setInfo(prevState=>({...prevState,bjdxc_wancan:'1'}))
                                };
                                if(!e.includes('1')){
                                    setInfo(prevState=>({...prevState,bjdxc_zaocan:'2'}))
                                };
                                if(!e.includes('2')){
                                    setInfo(prevState=>({...prevState,bjdxc_zhongcan:'2'}))
                                };
                                if(!e.includes('3')){
                                    setInfo(prevState=>({...prevState,bjdxc_wancan:'2'}))
                                };
                            }}
                        >
                            <Checkbox value='1'>早餐</Checkbox>
                            <Checkbox value='2'>中餐</Checkbox>
                            <Checkbox value='3'>晚餐</Checkbox>
                        </Checkbox.Group>                         
                    </Form.Item>
                    <Form.Item name="bjdxc_zhusu" label='住宿安排' wrapperCol={{span:10}}>
                        <Input 
                             onChange={(e) => {setInfo(prevState=>({...prevState,bjdxc_zhusu:e.target.value}))}}
                        />
                    </Form.Item> 
                </Form>
            </Modal>
        </>
    )
}

export default FileScheduleEdit