import React,{useState}from 'react'
import {Modal,message,Form,Button,Input,} from 'antd';
import {  useIncomeExpenditureHxSaveMutation,} from '../../store/ReactWebSiteApi';
import ArapWait from './ArapWait';
import ArapDesc from './ArapDesc';
function ArapAdd() {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        ecal_uid:localStorage.getItem('eu_id'),
        ecal_uname:localStorage.getItem('eu_name'),
        eie_hx_content:'',
        eie_hx_amount:0,
    });
    const [submitAudit] = useIncomeExpenditureHxSaveMutation()
    const [open, setOpen] = useState(false);
    const [openCheck, setopenCheck] = useState({eie_hx_number:'',open:false})
    return (
        <>
            <Button 
                onClick={() => {setOpen(true)}}
                type='primary'
            >新增</Button> 
            <Modal
                open={open}
                title='新增下账凭证'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            submitAudit({
                                eie_id:info.eie_id,
                                eie_hx_uid:localStorage.getItem('eu_id'),
                                eie_hx_uname:localStorage.getItem('eu_name'),
                                eie_hx_amount:info.eie_hx_amount,
                                eie_hx_content:info.eie_hx_content,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    setOpen(false)
                                    setopenCheck({eie_hx_number:res.data.Result,open:true})
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });                               

                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_in_modal"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 6 }}                                
                >                                
                    <ArapWait 
                        getKeep={(e) => {
                            setInfo(prevState=>({...prevState,eie_id:e.eie_id,eie_hx_amount:e.eie_hx_amount}))
                        }}
                    />                   
                    <Form.Item name="content" label='备注' wrapperCol={{span:15}} >
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,eie_hx_content:e.target.value}))}}/>
                    </Form.Item>                                                       
                </Form>
            </Modal> 
            <Modal 
                open={openCheck.open} 
                onOk={() => {}} 
                onCancel={() => {setopenCheck({open:false})}}
                width={1200}
                footer={null}
            >
                <ArapDesc
                    eie_hx_number={openCheck.eie_hx_number}
                />
            </Modal>
        </>
    )
}

export default ArapAdd