import React from 'react'
import { useShangpinStateMutation } from '../../../store/ReactWebSiteApi'
import { message } from 'antd'

function CustomGoodsAudit(props) {
    const [change] = useShangpinStateMutation()
    return (
        <>
            {props.state==='1' ? <a 
                onClick={() => {
                    change({sp_id:props.sp_id,sp_state:'1'}).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('下架成功！')                                                                              
                        }
                        if (res.data.Status === 'fail') {
                            message.error('下架失败！'+res.data.Message)
                        }
                    })
                }}
            >下架</a> :<a
                onClick={() => {
                    change({sp_id:props.sp_id,sp_state:'2'}).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('上架成功！')                                                                              
                        }
                        if (res.data.Status === 'fail') {
                            message.error('上架失败！'+res.data.Message)
                        }
                    })
                }}
            >上架</a>}
        </>
    )
}

export default CustomGoodsAudit