import { Image, message, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDijieTemplateKeChengDelMutation, useDijieTemplateKeChengListQuery } from '../../../../store/ReactWebSiteApi';
import ModalDel from '../../../../cpn/ModalDel';
import OpCourseEdit from './OpCourseEdit';
import OpCourseAdd from './OpCourseAdd';

function Course(props) {
    const [list, setList] = useState();
    const {data,isSuccess} = useDijieTemplateKeChengListQuery({ltkc_e_id:localStorage.getItem('e_id')},{refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                const newArr = data.Result.map(item => {
                    const {ltkc_id:key,ltkc_name,ltkc_phone,ltkc_leirong,ltkc_uname,ltkc_ctime,ltkc_dept_name,ltkc_mubiao,ltkc_update_ctime,ltkc_update_uname,ltkc_type_id,ltkc_image} = item;
                    let img = ltkc_image.map((item) => {
                        return item
                    })    
                    return item = {key,ltkc_name,ltkc_phone,ltkc_leirong,ltkc_uname,ltkc_ctime,ltkc_dept_name,ltkc_mubiao,ltkc_update_ctime,ltkc_update_uname,ltkc_type_id,img,ltkc_image}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltkc_ctime;
                    let bTimeString = b.ltkc_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])  
    const [del] = useDijieTemplateKeChengDelMutation()    
    const columns = [
        {
            key:'1',
            title:'课程名称',
            dataIndex: 'ltkc_name',
        },
        {
            key:'2',
            title:'图片',
            dataIndex:'img',
            render:(text) => {
                return  <Image.PreviewGroup
                            items={text}
                        >
                            <Image
                                width={60}
                                src={text[0]}
                            />
                        </Image.PreviewGroup>
            }
        },
        {
            key:'3',
            title:'课程内容',
            dataIndex: 'ltkc_leirong',
        },
        {
            key:'13',
            title:'课程目标',
            dataIndex: 'ltkc_mubiao',
        },
        {
            key:'4',
            title:'部门',
            dataIndex: 'ltkc_dept_name',
        },
        {
            key:'5',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <OpCourseEdit ltkc_id={record.key}/>
                    <ModalDel onOk={() => {
                            del({ltkc_id:record.key}).then((res) => {
                                if (res.data.Status === 'success') {
                                    message.success('删除成功！')                                                                              
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('删除失败！'+res.data.Message)
                                }
                            })
                        }} />
                    {props.get && <a onClick={() => {
                            props.getCourse({
                                ltbjdkc_image:record.ltkc_image,
                                ltbjdkc_leirong:record.ltkc_leirong,
                                "ltbjdkc_didian" : record.ltkc_didian,
                                "ltbjdkc_guimo" : record.ltkc_guimo,
                                "ltbjdkc_liucheng" : record.ltkc_liucheng,
                                "ltbjdkc_mubiao" : record.ltkc_mubiao,
                                "ltbjdkc_name" : record.ltkc_name,
                                "ltbjdkc_shichang" : record.ltkc_shichang,
                                "ltbjdkc_shizi" : record.ltkc_shizi,
                                "ltbjdkc_xueduan" : record.ltkc_xueduan,
                                "ltbjdkc_yongju" : record.ltkc_yongju,
                            })
                            props.getOpen(false)
                            }}>选择</a>}   
                </Space>
            }
        },
    ]
    return (
        <>
            <OpCourseAdd/>
            <Table dataSource={list} columns={columns}/>
        </>
    )
}

export default Course