import React from 'react'
import Main from '../../cpn/Main'
import { Row} from 'antd';
import Folder from '../../cpn/Folder';
import { indexFinance } from '../../finance/balance/balanceType';
function ArapIndex() {
    return (
        <Main t1='财务' n1={indexFinance} t2='下账'>
            <Row>                          
                <Folder title='下账凭证'  link='/ArapList' />            
                <Folder title='报表'  link='/ArapTable' />                                       
                <Folder title='新增下账凭证(旧)'  link='/ArapAddOld' />                                       
            </Row>            
        </Main>
    )
}

export default ArapIndex