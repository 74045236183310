import { Button, Form, Input, Modal } from 'antd'
import React, { useState } from 'react'
import GetUserDept from '../../../../cpn/GetUserDept';

function CrowdQuery(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const [info,setInfo] = useState({ltgg_dept_id:'',ltgg_name:''})
    return (
        <>
            <Button onClick={() => { setOpen(true)}}  >查询</Button>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            props.getQuery(values)
                            setOpen(false)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });                    
                }}
                title='查询人群类型'
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {                                        
                            form.resetFields();  
                            }}>重置</Button>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )}                
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{ltgg_dept_id: '',ltgg_name:''}}
                    labelCol={{ span: 6 }}
                >                                           
                    <GetUserDept 
                        label='部门' 
                        name='ltgg_dept_id'
                        wrapperCol={{span:10}}
                        getUserDept={(e) => {setInfo(prevState=>({...prevState,ltgg_dept_id:e}))}}
                        value={info.ltgg_dept_id}
                    />
                    <Form.Item name="ltgg_name" label='名称' wrapperCol={{span:10}}>
                        <Input onChange={(e) => {setInfo(prevState=>({...prevState,ltgg_name:e.target.value}))}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CrowdQuery