import React, { useEffect, useState } from 'react'
import {message, Space,} from 'antd'
import TableCheck from '../../../cpn/TableCheck'
import { useDijieBaoJiaDanFeiYongBuHanDelMutation, useDijieBaoJiaDanFeiYongBuHanListQuery } from '../../../store/ReactWebSiteApi'
import ModalDel from '../../../cpn/ModalDel'
import OpFileSelfAdd from './FileSelfAdd'
import typeName from './typeName'
import FileSelfEdit from './FileSelfEdit'

function FileSelf(props) {
    const [list,setList] = useState()
    const {data,isSuccess} = useDijieBaoJiaDanFeiYongBuHanListQuery({bjdfybh_bjd_id:props.bjdfybh_bjd_id},{refetchOnFocus:true})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const arr = data.Result.map((item) => {
                    const {bjdfybh_id:key,bjdfybh_name,bjdfybh_type_id,bjdfybh_ctime} = item
                    return item ={key,bjdfybh_name,bjdfybh_type_id,bjdfybh_ctime}
                })
                setList(arr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDijieBaoJiaDanFeiYongBuHanDelMutation()
    const column = [
        {
            key:'1',
            title:'类型',
            dataIndex:'bjdfybh_type_id',
            render:(text) => {
                const type = typeName(text)
                return type
            },
            width:'120px',
        },
        {
            key:'2',
            title:'费用不含',
            dataIndex:'bjdfybh_name'
        },            
        {
            key:'5',
            title:'操作',
            render:(_,record) => {
                // console.log(record);
                return<Space>
                    <FileSelfEdit
                        bjdfybh_id={record.key} 
                        bjdfybh_bjd_id={props.bjdfybh_bjd_id}
                    />
                    <ModalDel onOk={() => {
                        del({bjdfybh_id:record.key}).then((res) => {
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                </Space>
            },
            width:'120px',
        },        
    ]
    if(props.noAdd || props.preview){
        column.pop()
    }
    return (
        <>
            <TableCheck 
                columns={column}
                dataSource={list}
                style={{marginTop:"10px"}}
                title='费用不含'
                titleRight={(!props.noAdd && !props.preview) && <OpFileSelfAdd
                    bjdfybh_bjd_id={props.bjdfybh_bjd_id}
                    ltbjd_dept_id={props.ltbjd_dept_id}
                    />}
                size={props.customType==='1'?'middle':'small'}
            />
        </>
    )
}

export default FileSelf