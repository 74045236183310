import React,{ useState}from 'react'
import { DatePicker, Form,Input,message,Space, Modal} from 'antd';
import { useDijieBaoJiaDanAddMutation,} from '../../../store/ReactWebSiteApi';
import ModalAdd from '../../../cpn/ModalAdd';
import dayjs from 'dayjs';
import LineNameForm from '../temp/lineName/LineNameForm';
import ClientForm from '../temp/client/ClientForm';
import UploadOne from '../../../cpn/UploadOne';
import FileDesc from './FileDesc';
const { TextArea } = Input;
function FileAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        ltbjd_date:'',
        ltbjd_client_name:'',
        ltbjd_content:'',
        ltbjd_kehu_type_id:'',
        ltbjd_tuanhao:'',
        ltbjd_lth_id:'',
        ltbjd_yemei:'1',
        ltbjd_yejiao:'1',
        ltbjd_type_id:'',
        ltbjd_ltxl_name:'',
        ltbjd_lianxiren:'',
        ltbjd_youke_name:'',
        ltbjd_quanpei:'',
        ltbjd_fanchengjiaotong:'',
        ltbjd_chufajiaotong:'',
    });
    const [submitAdd] = useDijieBaoJiaDanAddMutation()
    const [open,setOpen] = useState(false)       
    //返回详情
    const [openCheck, setopenCheck] = useState({id:'',open:false})
    return (
        <>
            <ModalAdd 
                title='确认单'
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {                       
                            form.resetFields();                       
                            //获取产品人群类型
                            let arr = []
                            for (let key in values) {
                                if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                    if(key.includes('egg')){
                                        arr.push(values[key])
                                    }
                                }
                            }                        
                            submitAdd({
                                ltbjd_client_name:info.ltbjd_client_name,
                                ltbjd_date:info.ltbjd_date,
                                ltbjd_dept_id:localStorage.getItem('ed_id'),
                                ltbjd_dept_name:localStorage.getItem('ed_name'),
                                ltbjd_e_id:localStorage.getItem('e_id'),
                                ltbjd_e_name:localStorage.getItem('e_name'),
                                ltbjd_content:info.ltbjd_content,                            
                                ltbjd_ltxl_name: info.ltbjd_ltxl_name,
                                ltbjd_uid: localStorage.getItem('eu_id'),
                                ltbjd_uname: localStorage.getItem('eu_name'),                
                                ltbjd_tuanhao:info.ltbjd_tuanhao,
                                ltbjd_lth_id:info.ltbjd_lth_id,
                                ltbjd_ltkh_id:info.ltbjd_ltkh_id,
                                ltbjd_phone:info.ltbjd_phone,
                                ltbjd_chufajiaotong:info.ltbjd_chufajiaotong,
                                ltbjd_fanchengjiaotong:info.ltbjd_fanchengjiaotong,
                                ltbjd_youke_name:info.ltbjd_youke_name,
                                ltbjd_quanpei:info.ltbjd_quanpei,
                                ltbjd_lianxiren:info.ltbjd_lianxiren,
                            }).then((res) => {
                                console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！') 
                                    setOpen(false)
                                    setopenCheck({id:res.data.Result,open:true})                                                 
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    
                }}
                open={open}
                getOpen={(e) => {
                    setOpen(prevsState=>e)
                    if(e){
                        setInfo(prevState=>({
                            ...prevState,
                            ltbjd_dept_id:'',
                            ltbjd_image:'',
                            ltbjd_phone:''
                        }))
                        form.resetFields(); 
                    }
                }}
                width={800}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 4 }}
                >
                    <ClientForm
                        label='收件单位'
                        getClient={(e) => {
                            setInfo(prevState=>({
                                ...prevState,
                                ltbjd_ltkh_id:e.ltbjd_ltkh_id,
                                ltbjd_client_name:e.ltbjd_client_name,
                                ltbjd_phone:e.ltkh_phone,
                                ltbjd_lianxiren:e.ltkh_lianxiren,
                            }))                                    
                        }}
                        ltbjd_client_name={info.ltbjd_client_name}
                        ltbjd_phone={info.ltbjd_phone}
                        ltbjd_lianxiren={info.ltbjd_lianxiren}
                    />              
                    <LineNameForm
                        getLineName={(e) => {
                            setInfo(prevState=>({...prevState,ltbjd_ltxl_name:e}))
                        }}
                        lineName={info.ltbjd_ltxl_name}
                        label='线路名称'
                        required
                        wrapperCol={{span:18}}
                        inputWidth={{width:'250px'}}
                    />                      
                    <Form.Item name="date" label='出发日期' wrapperCol={{span:19}} required>
                        <DatePicker  
                            value={info.ltbjd_date}
                            onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_date:dayjs(e).format('YYYY-MM-DD')}))}}
                        />
                    </Form.Item>
                    <Form.Item 
                        label='游客姓名' 
                        wrapperCol={{span:19}} 
                        style={{marginBottom:'0'}}
                    >
                        <Space style={{width:'100%'}}>
                            <Form.Item 
                                wrapperCol={{span:19}} 
                                style={{width:'250px'}}
                            >
                                <Input 
                                    onChange={(e) => {setInfo(prevState=>({
                                        ...prevState,
                                        ltbjd_youke_name:e.target.value
                                    }))}}
                                />
                            </Form.Item>
                            <Form.Item 
                                wrapperCol={{span:16}} 
                                label='全陪或领队'
                                style={{width:'320px'}}
                            >
                                <Input 
                                    onChange={(e) => {setInfo(prevState=>({
                                        ...prevState,
                                        ltbjd_quanpei:e.target.value
                                    }))}}
                                />
                            </Form.Item>
                        </Space>    
                    </Form.Item>
                    <Form.Item 
                        label='出发大交通' 
                        wrapperCol={{span:19}} 
                        style={{marginBottom:'0'}}
                    >
                        <Space style={{width:'100%'}}>
                            <Form.Item 
                                wrapperCol={{span:19}} 
                                style={{width:'250px'}}
                            >
                                <Input 
                                    onChange={(e) => {setInfo(prevState=>({
                                        ...prevState,
                                        ltbjd_chufajiaotong:e.target.value
                                    }))}}
                                />
                            </Form.Item>
                            <Form.Item 
                                wrapperCol={{span:16}} 
                                label='到达大交通'
                                style={{width:'320px'}}
                            >
                                <Input 
                                    onChange={(e) => {setInfo(prevState=>({
                                        ...prevState,
                                        ltbjd_fanchengjiaotong:e.target.value
                                    }))}}
                                />
                            </Form.Item>
                        </Space>    
                    </Form.Item>        
                    <Form.Item  label='备注' wrapperCol={{span:19}}>
                        <TextArea 
                            onChange={(e) => {setInfo(prevState=>({...prevState,ltbjd_content:e.target.value}))}}
                            autoSize={{
                                minRows: 2,                                
                                }}
                        />
                    </Form.Item>                
                    <Form.Item  label='附件' wrapperCol={{span:19}}>
                        <UploadOne/>
                    </Form.Item>                
                </Form>
            </ModalAdd>
            <Modal 
                open={openCheck.open} 
                onOk={() => {}} 
                onCancel={() => {setopenCheck({open:false})}}
                width={1200}
                footer={null}
            >
                <FileDesc ltbjd_id={openCheck.id}/>
            </Modal>
        </>
    )
}

export default FileAdd