import { Col,Modal, Flex,} from 'antd'
import React,{useState,useEffect} from 'react'
import classes from '../sys/index.module.css'
import table from '../svg/table.svg'
function IndexTable(props) {
    const [open,setOpen] = useState(false)
    useEffect(() => {
      
    }, [open])
    
    return (
        <>
            <Col span={2} className={classes.mainItem} onClick={() => {
                setOpen(true)                
            }}>
                <img src={table} width={40} style={{marginBottom:"10px"}} alt=''/>
                <div style={{textAlign:'center'}}>{props.title}</div>
            </Col>
            <Modal
                open={open}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false)}}
                width={1200}
                footer={props.footer?props.footer:null}
            >            
                <Flex justify='center'>
                    <h2>{props.title}</h2>
                </Flex>
                {React.Children.map(props.children, child =>
                    React.cloneElement(child, {open:open})
                )}  
                <div style={{lineHeight:'50px'}}>报表用途：{props.remark}</div>         
            </Modal>
        </>
    )
}

export default IndexTable