import React from 'react'
import Main from '../../../../cpn/Main'
import Self from './Self'
function CustomSelf() {
    return (
        <Main t1='定制游' n1='/CustomIndex' t2='模板' n2='/CustomTempIndex' t3='费用自理'>
            <Self />
        </Main>
    )
}

export default CustomSelf