import React,{useState,useEffect} from 'react'
import { Table,DatePicker, Button, Form, Space} from 'antd'
import { useIncomeExpenditureHxListQuery, } from '../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import IndexTable from '../../cpn/IndexTable';
import {FileTextOutlined} from '@ant-design/icons';
import GetClient from '../client/GetClient';
import exportToExcel from '../../hooks/excel';
const {RangePicker} = DatePicker
function LedgerTableArapStatement() {
    const [query,setQuery] = useState({
        ebn_date_s:'',
        ebn_date_e:'',
        eie_e_id:localStorage.getItem('e_id'),
        eu_id:localStorage.getItem('eu_id'),
        hx_type:'2',
        action:'search',
        eie_jbr_uid:'',
    })    
    const {data,isSuccess} = useIncomeExpenditureHxListQuery({
        ebn_date_s:query.ebn_date_s,
        ebn_date_e:query.ebn_date_e,
        eie_e_id:localStorage.getItem('e_id'),
        eu_id:localStorage.getItem('eu_id'),
        hx_type:'2',
        action:'search',
        eie_jbr_uid:'',
        eie_client_id:query.eie_client_id,
    },{refetchOnMountOrArgChange:1,skip:!query.ebn_date_e})    
    const [list, setList] = useState();       
    const [excel,setExcel] = useState()      
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                const arr = data.Result.map((item) => {
                    const {eie_id:key,ec_name,ed_name,eg_name,eie_amount,ebn_team_num,eie_content,ebn_date,ebn_yw_name} = item
                    return item = {key,ebn_team_num,ebn_date,ed_name,ebn_yw_name,eg_name,ec_name,eie_amount,eie_content,}
                })
                setList(arr)
                const sortArr = arr.sort( (a, b) => {      
                    let aTimeString = a.ebn_team_num;
                    let bTimeString = b.ebn_team_num;
                    return aTimeString - bTimeString;     
                    },)
                setList(sortArr)
                const excel = sortArr.map((item) => {
                    const {eie_id:团队收支单,ebn_team_num:团队报账单,ebn_date:出发日期,ed_name:部门,ebn_yw_name:销售员,eg_name:产品,ec_name:客户,eie_amount:金额,eie_content:备注,} = item
                    return item = {团队收支单,团队报账单,出发日期,部门,销售员,产品,客户,金额,备注,}
                })                
                setExcel(excel)
            }
        }
    },[data,isSuccess])
    const column = [
        {
            key: 'id',
            title: '团队收支单',
            dataIndex: 'key',
            render:(text) => {
                return<Space><FileTextOutlined/> SZD{text}</Space>
            }
        },                                  
        {
            key: 'id',
            title: '团队报账单',
            dataIndex: 'ebn_team_num',
            render:(text) => {
                return<Space><FileTextOutlined/> BZD{text}</Space>
            }
        },                                  
        {
            key: 'ebn_date',
            title: '出发日期',
            dataIndex: 'ebn_date'
        },                                  
        {
            key: 'ed_name',
            title: '部门',
            dataIndex: 'ed_name'
        },                                  
        {
            key: 'ebn_yw_name',
            title: '销售员',
            dataIndex: 'ebn_yw_name'
        },                                  
        {
            key: 'eg_name',
            title: '产品',
            dataIndex: 'eg_name',
        },   
        {
            key: 'ec_name',
            title: '客户',
            dataIndex: 'ec_name',
        },   
        
        {
            key: 'eie_amount',
            title: '金额',
            dataIndex: 'eie_amount',
        },   
        {
            key: 'eie_content',
            title: '备注',
            dataIndex: 'eie_content',
        },            
            
    ]
    
    return (
        <IndexTable 
            title='待下账客户对账表'
            remark='按指定客户的团队报账单出发日期，列出所有待下账的团队收入单和团队支出单。'
            footer={<Button type='primary' onClick={() => {exportToExcel(excel,`《待下账客户对账表》${query.ec_name}-${query.ebn_date_s}至${query.ebn_date_e}-旅当家旅行社管理系统`)}}>下载EXCEL</Button>}
        >
            <Space style={{marginBottom:"10px"}}>
                <Form layout='inline' labelCol={{span:5}}>
                    <GetClient 
                        FormStyle={{marginBottom:'0',width:"400px"}}
                        label='客户' 
                        wrapperCol={{span:19}} 
                        name='edz_ec_id' 
                        style={{width:'190px'}} 
                        getClient={(e,f) => {setQuery(prevState=>({...prevState,eie_client_id:e,ec_name:f}))}} 
                    />
                    <Form.Item label='团队报账单的出发日期' labelCol={{span:10}}>
                        <RangePicker  
                            onChange={(e) => {
                                if(e){
                                    setQuery(prevState=>({...prevState,ebn_date_s:dayjs(e[0]).format('YYYY-MM-DD'),ebn_date_e:dayjs(e[1]).format('YYYY-MM-DD')})) 
                                }else{
                                    setQuery(prevState=>({...prevState,ebn_date_s:'',ebn_date_e:''}))
                                }
                            }}
                        />
                    </Form.Item>             
                </Form>
            </Space>
            <Table 
                columns={column} 
                dataSource={list} 
                style={{marginTop:"10px"}} 
                pagination={{position:['none']}}
                summary={(pageData) => {
                    let total = 0;
                    pageData.forEach(({eie_amount}) => {                        
                        total += +eie_amount;
                    });
                    return <Table.Summary>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>{total.toFixed(2)}</Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                }}
            />
        </IndexTable>
    )
}

export default LedgerTableArapStatement