import React,{useState,useEffect}from 'react'
import { Descriptions,Image,Space, Flex,Spin} from 'antd';
import { useIncomeExpenditureUpdateQuery} from '../../store/ReactWebSiteApi';
import LedgerBalanceEdit from './LedgerBalanceEdit';
import LedgerBalanceDel from './LedgerBalanceDel';
import LedgerBalanceAudit from './LedgerBalanceAudit';
function LedgerBalanceDesc(props) {
    const [info,setInfo] = useState({
        ebn_team_num:'',
        ec_name:'',
        eie_audit1_ctime:'',
        eie_audit1_uname:'',
        ebn_date:'',
        eg_name:'',
        eie_audit2_ctime:'',
        eie_audit2_uname:'',
        ed_name:'',
        eie_finance_sub_type_name:'',
        eie_price:'',
        ebn_yw_name:'',
        eie_amount:'',
        eie_count:"",
        eie_ctime:'',
        eie_uname:'',
        eie_content:'',
        eie_id:'',
        eie_eci_id:"",
        eie_update_ctime:'',
        eie_update_uname:'',
        eie_hx_ctime:'',
        eie_hx_uname:"",
        eie_hx_number:'',
        eie_bank_ctime:'',
        eie_bank_uname:'',
        eie_bank_log_id:'',
        eie_jbr_uname:'',
        eie_jbr_uid:'',
        eie_type_id:'',
        eie_finance_sub_type_id:'',
        eie_ebn_id:'',
        eie_eg_id:'',
        eie_egg_value:'',
    })
    const {data,isSuccess} = useIncomeExpenditureUpdateQuery({eie_id:props.eie_id},{skip:!props.eie_id})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                console.log(data);
                let arr =[]
                if(data.Result.eie_image_file && data.Result.eie_image_file !== ['null']){
                    console.log(arr);
                    arr = data.Result.eie_image_file.map((item,index) => {                    
                        return  <Image width={60} key={index} src={`/api/assets/images/application/${item}`} />     
                    })
                }                 
                let guigeArr = []
                if(data.Result.eie_egg_value){
                       console.log(data.Result.eie_egg_value,'222');
                       guigeArr = data.Result.eie_egg_value.map((item) => {
                        return <span key={item.eiegg_egg_id} eiegg_egg_name={item.eiegg_egg_name}>{item.eiegg_egg_name + ' × ' +item.eiegg_egg_value}</span>
                    })
                }               
                setInfo({
                    ebn_team_num:data.Result.ebn_team_num,
                    ec_name:data.Result.ec_name,
                    eie_audit1_ctime:data.Result.eie_audit1_ctime,
                    eie_audit1_uname:data.Result.eie_audit1_uname,
                    ebn_date:data.Result.ebn_date,
                    eg_name:data.Result.eg_name,
                    eie_audit2_ctime:data.Result.eie_audit2_ctime,
                    eie_audit2_uname:data.Result.eie_audit2_uname,
                    ed_name:data.Result.ed_name,
                    eie_finance_sub_type_name:data.Result.eie_finance_sub_type_name,
                    eie_price:data.Result.eie_price,
                    ebn_yw_name:data.Result.ebn_yw_name,
                    eie_amount:data.Result.eie_amount,
                    eie_count:data.Result.eie_count,
                    eie_ctime:data.Result.eie_ctime,
                    eie_uname:data.Result.eie_uname,
                    eie_content:data.Result.eie_content,
                    eie_id:data.Result.eie_id,
                    eie_eci_id:data.Result.eie_eci_id,
                    eie_update_ctime:data.Result.eie_update_ctime,
                    eie_update_uname:data.Result.eie_update_uname,
                    eie_hx_ctime:data.Result.eie_hx_ctime,
                    eie_hx_uname:data.Result.eie_hx_uname,
                    eie_hx_number:data.Result.eie_hx_number,
                    eie_bank_ctime:data.Result.eie_bank_ctime,
                    eie_bank_uname:data.Result.eie_bank_uname,
                    eie_bank_log_id:data.Result.eie_bank_log_id,
                    eie_jbr_uname:data.Result.eie_jbr_uname,
                    eie_jbr_uid:data.Result.eie_jbr_uid,
                    eie_type_id:data.Result.eie_type_id,
                    eie_finance_sub_type_id:data.Result.eie_finance_sub_type_id,
                    eie_ebn_id:data.Result.eie_ebn_id,
                    eie_eg_id:data.Result.eie_eg_id,
                    img:arr,
                    guige:guigeArr,
                })
                // console.log(array);
                
            }
        }
    },[data, isSuccess])

    const  items = [
        {
            key:"11",
            label: '团号',
            children: info.ebn_team_num,
        },
        {
            label: '客户',
            children: info.ec_name,        
            key:"4",
        },
        {
            label: '审核',
            children: info.eie_audit1_ctime && <>{info.eie_audit1_ctime+info.eie_audit1_uname}</>,
            key:"16",
        }, 
        {
            key:"12",
            label: '出发日期',
            children: info.ebn_date,
        },
        {
            label: '产品',
            children: info.eg_name,      
            key:"134",
        },
        {
            label: '复核',
            children: info.eie_audit2_ctime && <>{info.eie_audit2_ctime+info.eie_audit2_uname}</>,
            key:"18",
        },
        {
            label: '部门',
            children: info.ed_name,
            key:"2",
        },
        {
            label: '类型',
            children: <>{info.eie_finance_sub_type_name}</>,
            key:"21",
        },
        {
            label: '单价',
            children: info.eie_price,
            key:"6",
        },
        {
            label: '销售员',
            children: info.ebn_yw_name,   
            key:"22",
        },        
        {
            label: '金额',
            children: info.eie_amount,
            key:"8",
        },
        {
            label: '数量',
            children: info.eie_count,
            key:"7",
        }, 
        {
            label: '操作员',
            children: <>{info.eie_ctime+info.eie_uname}</>,
            key:"11",
        }, 
        {
            label: '备注',
            children: info.eie_content,
            key:"9",
        },
        {
            key:"1",
            label: '编号',
            children: info.eie_id,
        },     
        {
            label: '附件',
            children: info.img && <Image.PreviewGroup>{info.img}</Image.PreviewGroup>,
            key:"10",
        },
        {
            label: '合同编号',
            children: info.eie_eci_id,
            key:"111",
        },       
        {
            label: '修改',
            children: info.eie_update_ctime && <>{info.eie_update_ctime+info.eie_update_uname}</>,
            key:"14",
        },
        {
            label: '下账时间',
            children: info.eie_hx_ctime && <>{info.eie_hx_ctime+info.eie_hx_uname}</>,
            key:"20",
        },    
        {
            label: '转账时间',
            children: info.eie_bank_ctime && <>{info.eie_bank_ctime+info.eie_bank_uname}</>,
            key:"23",
        },      
        {
            label: '规格数量',
            children: <Space direction='vertical'>{info.guige}</Space>,
            key:"26",
        },
        {
            label: '下账凭证',
            children: info.eie_hx_number,
            key:"22",
        },
       
        {
            label: '转账凭证',
            children: info.eie_bank_log_id,
            key:"24",
        },
        {
            label: '操作',
            children: <Space>
                <LedgerBalanceEdit
                    eie_id={props.eie_id}
                    title={props.title}
                    eie_jbr_uname={info.eie_jbr_uname} 
                    eie_jbr_uid={info.eie_jbr_uid}
                    eie_type_id={info.eie_type_id}
                    eie_finance_sub_type_id={info.eie_finance_sub_type_id}
                    eie_finance_sub_type_name={info.eie_finance_sub_type_name}                             
                    eie_ebn_id={info.eie_ebn_id}                            
                    eie_eg_id={info.eie_eg_id}
                    guige={props.guige}
                    eie_audit1_ctime={info.eie_audit1_ctime}
                    eie_hx_ctime={info.eie_hx_ctime}
                    fromDesc
                />
                <LedgerBalanceDel 
                    eie_audit1_ctime={info.eie_audit1_ctime}
                    eie_hx_ctime={info.eie_hx_ctime}
                    eie_id={props.eie_id}
                />
                <LedgerBalanceAudit 
                    eie_audit1_ctime={info.eie_audit1_ctime}
                    eie_audit2_ctime={info.eie_audit2_ctime}
                    eie_id={props.eie_id}
                />
            </Space>,
            key:"25",
        },
      
    ]
    // const newArr = items.filter(item => {
    //     if(item.children !== '' && item.children !== null && item.children !== '0' && item.children !== false){
    //         return true
    //     }else{
    //         return false
    //     }
    // });
    return (
        <>
            {info.eie_ctime ? <div 
                                    style={{border:'2px solid #eee',margin:"10px"}}
                                >
                                    <Flex justify='center'><h2>{props.title}</h2></Flex>
                                    <Descriptions                                  
                                        items={items} 
                                        bordered
                                    />
                                </div>:  <Spin tip='生成中。。。' size='large' />}
        </> 
    )
}

export default LedgerBalanceDesc