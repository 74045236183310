import React,{useState,useEffect}from 'react'
import { Table,Space,message,} from 'antd';
import { useDijieTemplateKeHuDelMutation,useDijieTemplateKeHuListQuery,} from '../../../../store/ReactWebSiteApi';
import ClientAdd from './ClientAdd';
import ClientEdit from './ClientEdit';
import ModalDel from '../../../../cpn/ModalDel';
import ClientQuery from './ClientQuery';
function Client(props) {    
    const [del] = useDijieTemplateKeHuDelMutation()    
    const [query,setQuery] = useState({ltkh_e_id:localStorage.getItem('e_id')})
    const {data,isSuccess} = useDijieTemplateKeHuListQuery(query,{refetchOnMountOrArgChange:1})
    const [list, setList] = useState();
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                const newArr = data.Result.map(item => {
                    const {ltkh_id:key,ltkh_name,ltkh_lianxiren,ltkh_num,ltkh_content,ltkh_uname,ltkh_ctime,ltkh_dept_name,ect_name,ltkh_update_ctime,ltkh_update_uname,ltkh_phone} = item;
                    return item = {key,ltkh_name,ltkh_lianxiren,ltkh_num,ltkh_content,ltkh_uname,ltkh_ctime,ltkh_dept_name,ect_name,ltkh_update_ctime,ltkh_update_uname,ltkh_phone}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltkh_ctime;
                    let bTimeString = b.ltkh_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])       
    const columns = [              
        {
            key: 'type',
            title: '客户',
            dataIndex: 'ltkh_name',
        },
        {
            key: 'ltkh_lianxiren',
            title: '联系人',
            dataIndex: 'ltkh_lianxiren',           
        },
        {
            key: 'ltkh_phone',
            title: '手机号吗',
            dataIndex: 'ltkh_phone',           
        },
        {
            key: 'room',
            title: '备注',
            dataIndex: 'ltkh_content',
        },
        {
            key: 'ltkh_dept_name',
            title: '部门',
            dataIndex: 'ltkh_dept_name',
        },       
        {
            key: 'id',
            title: '编号',
            dataIndex: 'key'
        }, 
        // {
        //     key: 'time',
        //     title: '记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>{record.ltkh_ctime + record.ltkh_uname}新建</div>
        //             {record.ltkh_update_ctime && <div>{record.ltkh_update_ctime + record.ltkh_update_uname}修改</div>}
        //         </>
        //     }
        // },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                    <Space>                        
                        <ClientEdit ltkh_id={record.key}/>
                        <ModalDel onOk={() => {
                            del({ltkh_id:record.key}).then((res) => {
                                if (res.data.Status === 'success') {
                                    message.success('删除成功！')                                                                              
                                }
                                if (res.data.Status === 'fail') {
                                    message.error('删除失败！'+res.data.Message)
                                }
                            })
                        }} />
                        {props.type==='select' && <a onClick={() => {
                            props.getClient({
                                ltkh_name:record.ltkh_name,
                                ltkh_id:record.key,
                                ltkh_phone:record.ltkh_phone,
                                ltkh_lianxiren:record.ltkh_lianxiren
                            })
                            props.getOpen(false)
                            }}>选择</a>}                        
                    </Space>
                </>
            }
        },
    ]


    return (
        <>
            <Space style={{marginBottom:"10px"}}>                
                <ClientAdd getNewClient={() => {}} type='primary'/> 
                <ClientQuery 
                    getQuery={(e) => {
                        setQuery(prevState=>({
                            ...prevState,
                            ltkh_phone:e.ltkh_phone,
                            ltkh_name:e.ltkh_name
                        }))
                    }}
                />   
            </Space>
            <Table 
                columns={columns} 
                dataSource={list} 
                pagination={{
                    showSizeChanger:true,
                    defaultPageSize:'20',
                    showTotal:(total) => `共${total}条`,
                }}
            /> 
        </>
    )
}

export default Client