import { Form, InputNumber, Modal,message } from 'antd';
import React, { useState ,useRef,useEffect} from 'react'
import UploadOne from '../../../../cpn/UploadOne';
import FeedbackForm from '../../temp/feedback/FeedbackForm';
import { useTuanhaoyijiandanUpdateQuery,useTuanhaoyijiandanUpdateSaveMutation } from '../../../../store/ReactWebSiteApi';
function GroupFeedbackEdit(props) {
    const [open,setOpen] = useState(false)
    const editForm= useRef()
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        ltbjd_daoyou:'',
        ltbjd_daoyou_phone:''
    })
    //获取
    const {data,isSuccess} = useTuanhaoyijiandanUpdateQuery({ltthyjd_id:props.ltthyjd_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){               
                editForm.current.setFieldsValue({
                          
                })                                   
                setInfo(prevsState=>({...prevsState,
                    "dyjhd_daofufei" : data.Result.dyjhd_daofufei,
                    "dyjhd_daofufeishuoming" : data.Result.dyjhd_daofufeishuoming,
                    "dyjhd_fujian_name" : data.Result.dyjhd_fujian_name,
                    "dyjhd_jietuandidian" :data.Result.dyjhd_jietuandidian,
                    "dyjhd_jietuanshijian" :data.Result.dyjhd_jietuanshijian,
                    "dyjhd_jietuanshuoming" :data.Result.dyjhd_jietuanshuoming,
                    "dyjhd_lth_id" : data.Result.dyjhd_lth_id,
                    "dyjhd_lth_num" : data.Result.dyjhd_lth_num,
                    "dyjhd_songtuandidian" : data.Result.dyjhd_songtuandidian,
                    "dyjhd_songtuanshijian" :data.Result.dyjhd_songtuanshijian,
                    "dyjhd_ltdy_name" :data.Result.dyjhd_ltdy_name,
                    "dyjhd_ltdy_phone" :data.Result.dyjhd_ltdy_phone,                   
                    "dyjhd_fenche" : data.Result.dyjhd_fenche,              
                    "dyjhd_xianlu" : data.Result.dyjhd_xianlu,  
                    "dyjhd_dept_id" : data.Result.dyjhd_dept_id,        
                    "dyjhd_dept_name" :data.Result.dyjhd_dept_name,                                    
                    "dyjhd_lth_zhu_name" : data.Result.dyjhd_lth_zhu_name,
                    "dyjhd_lth_zhu_type" : data.Result.dyjhd_lth_zhu_type,
                    "dyjhd_lth_ci_type" : data.Result.dyjhd_lth_ci_type,
                    "dyjhd_lth_ci_type_name" : data.Result.dyjhd_lth_ci_type_name,
                    "dyjhd_lth_zi_type" : data.Result.dyjhd_lth_zi_type,
                    "dyjhd_lth_zi_type_name" : data.Result.dyjhd_lth_zi_type_name,                               
                    "dyjhd_zhiwu_id" : data.Result.dyjhd_zhiwu_id,          
                    "dyjhd_zhiwu_name" : data.Result.dyjhd_zhiwu_name,          
                }))
            }
        }
    },[data,isSuccess])
    const [add] = useTuanhaoyijiandanUpdateSaveMutation()
    useEffect(() => {
      if(info.arr){
        editForm.current.setFieldsValue({
            names:info.arr
        })
      }
    }, [editForm, info.arr])    
    return (
        <>
            <a onClick={() => {setOpen(true);}}>修改</a>
            <Modal
                title='修改意见单'
                onOk={(e) => {   
                    form
                    .validateFields()
                    .then((values) => {                       
                        console.log(values);     
                        const arr = values.names.map((item) => {
                            return item.ltyjdi_name
                        })
                        add({                        
                            "ltthyjd_dept_id" : info.ltthyjd_dept_id,
                            "ltthyjd_dept_name" : info.ltthyjd_dept_name,
                            "ltthyjd_e_id" : localStorage.getItem('e_id'),
                            "ltthyjd_e_name" : localStorage.getItem('e_name'),
                            "ltthyjd_fenche" : info.ltthyjd_fenche,
                            "ltthyjd_lth_id" : props.dyjhd_lth_id,
                            "ltthyjd_lth_num" : props.dyjhd_lth_num,
                            "ltthyjd_mingdan" : info.ltthyjd_mingdan,
                            "ltthyjd_uid" : localStorage.getItem('eu_id'),
                            "ltthyjd_uname" : localStorage.getItem('eu_name'),
                            "ltthyjd_yjd_item" :arr,
                        }).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('新增成功！') 
                                setOpen(false)                                                 
                            }
                            if (res.data.Status === 'fail') {
                                message.error('新增失败！'+res.data.Message)
                            }
                        })
                    })                
                }}
                open={open}
                onCancel={() => {setOpen(false)}}
            >
               <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{
                        modifier: 'public',
                        fenche:'1',
                    }}
                    labelCol={{ span: 6 }}
                    ref={editForm}
                >                                                              
                    
                    <FeedbackForm 
                        required
                        ltbjd_daoyou={info.ltbjd_daoyou}
                        getFeedback={(e) => {
                            setInfo(prevState=>({...prevState,arr:e}))
                        }}
                    />
                    <Form.Item name="fenche" label='分车序号' wrapperCol={{span:10}}>
                        <InputNumber
                        />
                    </Form.Item>
                    <Form.Item 
                        label='游客名单' 
                        name="file" 
                        wrapperCol={{span:15}} 
                        extra={<>上传游客名单，具体格式<a>点击</a></>}
                    >
                        <UploadOne                      
                        />
                    </Form.Item>                    
                </Form>
            </Modal>
        </>
    )
}

export default GroupFeedbackEdit