import { Form, InputNumber, message, Modal } from 'antd';
import React, { useState } from 'react'
import GuideForm from '../../../../guide/GuideForm';
import { useDaoyoujihuadanCopyMutation } from '../../../../store/ReactWebSiteApi';
import GroupSelect from '../GroupSelect';
function GuidePlanCopy(props) {
    const [open,setOpen] = useState(false)
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        dyjhd_tgu_name:'',
        dyjhd_tgu_phone:'',
        dyjhd_tgu_id:'',
    })
    const [copy] = useDaoyoujihuadanCopyMutation()
    return (
        <>
            <a onClick={() => {setOpen(true);}}>复制新增</a>
            <Modal
                title='复制新增导游计划单'
                onOk={(e) => { 
                    if(!info.dyjhd_fenche){
                        message.error('分车序号必填！');
                        return false
                    }
                    copy({
                        "dyjhd_fenche" : info.dyjhd_fenche,
                        "dyjhd_id" : props.dyjhd_id,
                        "dyjhd_lth_id" : info.dyjhd_lth_id,
                        "dyjhd_lth_num" : info.dyjhd_lth_num,
                        "dyjhd_tgu_id" : info.dyjhd_tgu_id,
                        "dyjhd_tgu_name" : info.dyjhd_tgu_name,
                        "dyjhd_tgu_phone" : info.dyjhd_tgu_phone,
                        "dyjhd_uid" : localStorage.getItem('eu_id'),
                        "dyjhd_uname" :  localStorage.getItem('eu_name'),
                        "dyjhd_uphone" :  localStorage.getItem('eu_ba_phone'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')                             
                            setOpen(false)                                                 
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })                                 
                }}
                open={open}
                onCancel={() => {setOpen(false)}}
            >
               <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{
                        modifier: 'public',
                        fenche:'1',
                    }}
                    labelCol={{ span: 6 }}
                >                                                              
                    <GroupSelect
                        label='团号'
                        name='group'
                        required
                        wrapperCol={{span:8}}
                        getGroup={(e) => {
                            setInfo(prevState=>({
                                ...prevState,
                                dyjhd_lth_id:e.value,
                                dyjhd_lth_num:e.label,
                            }))
                        }}
                    />
                    <Form.Item 
                        name="fenche" 
                        label='分车序号' 
                        wrapperCol={{span:10}} 
                        required
                    >
                        <InputNumber 
                            onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_fenche:e}))
                            }}
                            min={1}
                        />
                    </Form.Item>
                    <GuideForm 
                        required
                        tgu_name={info.dyjhd_tgu_name}
                        tgu_phone={info.dyjhd_tgu_phone}
                        getGuide={(e) => {
                            setInfo(prevState=>({
                                ...prevState,
                                dyjhd_tgu_id:e.tgu_id,
                                dyjhd_tgu_name:e.tgu_name,
                                dyjhd_tgu_phone:e.tgu_phone,
                            }))
                        }}
                    />                   
                </Form>
            </Modal>
        </>
    )
}

export default GuidePlanCopy