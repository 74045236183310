import React,{useState,useEffect} from 'react'
import { Table,Space,DatePicker,} from 'antd'
import { useZjDeptProfitQuery, } from '../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import IndexTable from '../../cpn/IndexTable';
const {RangePicker} = DatePicker
function FundTableDept() {
    const [query,setQuery] = useState({
        s_date:'',
        e_date:'',
        e_id:localStorage.getItem('e_id'),
        eie_type_id:'1,2,3'
    })
    const {data,isSuccess} = useZjDeptProfitQuery(query,{refetchOnMountOrArgChange:1,skip:!query.e_date})    
    const [list, setList] = useState();    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);                
                const newArr = data.Result.map(item => {
                    const {ed_id:key,ed_name,type1,type2,type3} = item;
                    return item = {key,ed_name,type1,type2,type3}
                })                
                setList(newArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    
    const columns = [
        {
            key: 'id',
            title: '部门',
            dataIndex: 'ed_name'
        },         
        {
            key: 'type1',
            title: '团队收入单金额合计',
            dataIndex: 'type1'
        },         
        {
            key: 'type2',
            title: '团队支出单金额合计',
            dataIndex: 'type2'
        },         
        {
            key: 'type3',
            title: '费用支出单金额合计',
            dataIndex: 'type3'
        },         
        {
            key: 'ar',
            title: '利润',
            render:(_,record) => {
                return<>{+record.type1 + +record.type2 + +record.type3}</>
            }
        },                                  
    ]
    
    return (
        <IndexTable 
            title='部门利润表'
            remark='按转账日期，统计每个部门的团队收入单金额之和、团队支出单金额之和、费用支出单金额之和、利润之和。'
        >
           <Space style={{marginBottom:"10px"}}>
                <span>转账日期：</span>
                <RangePicker
                    onChange={(e) => {
                        if(e){
                            setQuery(prevState=>({...prevState,s_date:dayjs(e[0]).format('YYYY-MM-DD'),e_date:dayjs(e[1]).format('YYYY-MM-DD')})) 
                        }else{
                            setQuery(prevState=>({...prevState,s_date:'',e_date:''}))
                        }
                    }}
                />
            </Space>
            <Table 
                columns={columns} 
                dataSource={list} 
                style={{marginTop:'10px'}}
                pagination={{position:['none']}}
                summary={(pageData) => {
                    console.log(pageData);
                    let totaltype1 = 0;
                    let totaltype2 = 0;
                    let totaltype3 = 0;
                    pageData.forEach(({type1,type2,type3 }) => {                        
                        totaltype1 += +type1;
                        totaltype2 += +type2;
                        totaltype3 += +type3;
                    });
                    return <Table.Summary>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>{(totaltype1)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>{(totaltype2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>{(totaltype3)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{(totaltype1 + totaltype2 + totaltype3)}</Table.Summary.Cell>
                                
                                </Table.Summary.Row>
                            </Table.Summary>
                }}
            />
        </IndexTable>
    )
}

export default FundTableDept