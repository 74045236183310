import React, { useEffect, useState } from 'react'
import { message, Space, Table } from 'antd'
import PriceAdd from './PriceAdd'
import { useDijieTemplateFeiYongMingXiDelMutation, useDijieTemplateFeiYongMingXiListQuery } from '../../../../store/ReactWebSiteApi'
import { localCostType } from '../../customType'
import ModalDel from '../../../../cpn/ModalDel'
import PriceEdit from './PriceEdit'
import PriceQuery from './PriceQuery'
function Price(props) {
    const [list,setList] = useState()
    const [query,setQuery] = useState({ltfymx_e_id:localStorage.getItem('e_id')})
    const {data, isSuccess} = useDijieTemplateFeiYongMingXiListQuery(query,{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {ltfymx_id:key,ltfymx_name,ltfymx_uname,ltfymx_ctime,ed_name,ltfymx_type_id,ltfymx_price,ltfymx_unit} = item;      
                    let arr = localCostType.filter((item) => item.value===ltfymx_type_id)
                    let type = arr[0].label                           
                    return item = {key,ltfymx_name,ltfymx_uname,ltfymx_ctime,ed_name,type,ltfymx_price,ltfymx_unit,ltfymx_type_id}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltfymx_ctime;
                    let bTimeString = b.ltfymx_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //删除
    const [del] = useDijieTemplateFeiYongMingXiDelMutation()
    const column = [             
        {
            key:'13',
            title:'收费明细模板',
            dataIndex:'ltfymx_name'
        },   
       
        {
            key:'12',
            title:'类型',
            dataIndex:'type'
        },   
                      
        {
            key:'14',
            title:'单价',
            dataIndex:'ltfymx_price'
        },                  
        {
            key:'15',
            title:'单位',
            dataIndex:'ltfymx_unit'
        },      
        {
            key:'1',
            title:'部门',
            dataIndex:'ed_name'
        },                
        {
            key:'6',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <PriceEdit ltfymx_id={record.key} />
                    <ModalDel onOk={() => {
                        del({ltfymx_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                    {props.get && <a onClick={() => {
                        props.getPrice({
                            ltfymx_type_id:record.ltfymx_type_id,
                            ltfymx_name:record.ltfymx_name,
                            ltfymx_price:record.ltfymx_price,
                            ltfymx_unit:record.ltfymx_unit,                            
                        })
                        props.getOpen(false)     
                        }}>选择</a>}
                </Space>
            }
        },
    ]
    return (
        <>
            <Space>
                <PriceAdd />
                <PriceQuery
                    getQuery={(e) => {
                        setQuery(prevState=>({
                            ...prevState,
                            ltfymx_name:e.ltfymx_name,
                            ltfymx_type_id:e.ltfymx_type_id,
                            ltfymx_dept_id:e.ltfymx_dept_id,
                        }))
                    }}
                />
            </Space>
            <Table columns={column} dataSource={list} style={{marginTop:"10px"}}/>
        </>
    )
}

export default Price