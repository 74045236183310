import { message, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDaoyoujihuadanGuiGeDelMutation, useDaoyoujihuadanGuiGeListQuery,} from '../../../../store/ReactWebSiteApi'
import ModalDel from '../../../../cpn/ModalDel'
import OpFileTypeEdit from './GuidePlanCrowdEdit'
import FileTypeAdd from './GuidePlanCrowdAdd'

function FileType(props) {
    const [list,setList] = useState([])
    const {data,isSuccess} = useDaoyoujihuadanGuiGeListQuery({jhdgg_jhd_id:props.jhdgg_jhd_id},{refetchOnFocus:true})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const arr = data.Result.map((item) => {
                    const {...obj} = item
                    return item = obj
                })
                setList(arr)
            }else{
                setList([])
            }
        }
    },[data,isSuccess])
    //删除
    const [del] = useDaoyoujihuadanGuiGeDelMutation()
    return (
        <Space>        
            {list.map((item) => {
                return  <Space key={item.jhdgg_id}>
                            {item.jhdgg_name + '×' + item.jhdgg_num}
                            {!props.preview && <OpFileTypeEdit jhdgg_id={item.jhdgg_id} jhdgg_jhd_id={props.jhdgg_jhd_id}/>}
                            {!props.preview && <ModalDel onOk={() => {
                                del({jhdgg_id:item.jhdgg_id}).then((res) => {
                                    if (res.data.Status === 'success') {
                                        message.success('删除成功！')                                                                              
                                    }
                                    if (res.data.Status === 'fail') {
                                        message.error('删除失败！'+res.data.Message)
                                    }
                                })
                            }} />}
                            ；
                        </Space>
            })}
            {!props.preview && <FileTypeAdd jhdgg_jhd_id={props.jhdgg_jhd_id} />}
        </Space>
    )
}

export default FileType