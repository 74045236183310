//导游证级别
export const  guideIdName = (e) => {
    let type
    switch (e) {
        case '1':
            type = '初级导游'
            break;
        case '2':
            type = '中级导游'
            break;
        case '3':
            type = '高级导游'
            break;
        case '4':
            type = '特级导游'
            break;
        default:
            break;
    }    
    return type
};
//导游证语种
export const guideIdLanguage = [
    {value:'1',label:'粤语'},
    {value:'2',label:'英语'},
    {value:'3',label:'日语'},
    {value:'4',label:'俄语'},
    {value:'5',label:'法语'},
    {value:'6',label:'德语'},
    {value:'7',label:'西班牙语'},
    {value:'8',label:'朝鲜语'},
    {value:'9',label:'泰语'},
    {value:'10',label:'意大利语'},
    {value:'11',label:'葡萄牙语'},
]
//获取导游证语种
export const  getGuideIdLanguage = (e) => {    
    let type = []
    type = guideIdLanguage.map((item) => {
        if(e.includes(item.value)){
            return item.label
        }else{
            return ''
        }
    })
    return type
};

