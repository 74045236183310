import React,{ useState, useEffect,useRef }  from 'react'
import { Form, Input, Button,message,Space} from 'antd'
import { useUserUpdatePwdCodeMutation, useUserUpdatePwdMutation } from '../store/ReactWebSiteApi';
import { MobileOutlined ,MessageOutlined,KeyOutlined} from '@ant-design/icons'
import password from '../svg/password.svg'
import Program from '../cpn/Program';
import ModalEdit from '../cpn/ModalEdit';
function MinePassword() {
    const [form] = Form.useForm();
    const [info,setInfo] = useState({})
    //验证码倒计时
    const [isNote, setIsNote] = useState(false);
    const [time, setTime] = useState(null);
    const timeRef = useRef();
    useEffect(() => {
        if (time && time !== 0) {
            timeRef.current = setTimeout(() => {
                setTime((time) => time - 1);
            }, 1000);
        } else {
            setIsNote(false);
        }
        return () => {
            clearInterval(timeRef.current);
        };
    }, [time]);
  
    //获取验证码数据
    const [getCode] = useUserUpdatePwdCodeMutation();
    //获取验证码
    const getCodeBtn = () => {
        setTime(60);
        setIsNote(true);
        getCode({
            phone:localStorage.getItem('eu_ba_phone'),
        }).then((res) => {
            console.log(res);
            if(res.data.Status==='success'){
                setTime(60);
                setIsNote(true);
            }
            if (res.data.Status === 'fail') {
                message.error('该手机号码未注册！'+ res.data.Message)    
            }
        })    
    }
    const [submit] = useUserUpdatePwdMutation()
    const [open,setOpen] = useState(false)
    return (
        <>
            <Program title='修改密码' onClick={() => {setOpen(true)}} icon={<img alt='' src={password} width={48}/>}/>
            <ModalEdit 
                open={open} 
                getOpen={(e) => {setOpen(prevsState=>e );}}
                title='登录密码' 
                link='false'
                onOk={() => {
                    submit({
                        ba_id:localStorage.getItem('eu_ba_id'),
                        eu_ba_phone:localStorage.getItem('eu_ba_phone'),
                        ba_pwd:info.ba_pwd,
                        phone_code:info.phone_code,
                    }).then((res) => {
                        console.log(res);
                        if(res.data.Status==='success'){
                            message.success('修改成功，请使用新密码登录！')
                            form.resetFields();
                            setOpen(false)
                        }
                        if (res.data.Status === 'fail') {
                            message.error('该手机号码未注册！'+ res.data.Message)    
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}                    
                >
                    <Form.Item
                        label='新密码'
                        name="newPassword"
                        validateTrigger="onBlur"
                        rules={[{
                            required: true,
                            message: '请填写新密码!',
                        },
                       ]}
                        wrapperCol={{span:15}}
                    >
                        <Input 
                            prefix={<KeyOutlined className="site-form-item-icon" />} 
                            placeholder="请输入新密码" 
                            onChange={(e) => {setInfo(prevState=>({...prevState,ba_pwd:e.target.value}))}}   
                            type='password'                          
                        />
                    </Form.Item>                    
                    <Form.Item
                        label='手机号'
                        name="phoneAdd"
                        validateTrigger="onBlur"
                        required
                        wrapperCol={{span:10}}
                    >
                        <Input 
                            prefix={<MobileOutlined className="site-form-item-icon" />} 
                            placeholder={localStorage.getItem('eu_ba_phone')} 
                            onChange={(e) => {setInfo(prevState=>({...prevState,eu_ba_phone:e.target.value}))}} 
                            disabled
                        />
                    </Form.Item>
                    <Form.Item
                        label='验证码'
                        wrapperCol={{span:15}}
                    >
                        <Space>
                                <Form.Item
                                    name="codeAdd"
                                    
                                    validateTrigger="onBlur"    
                                    noStyle
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入短信验证码!',
                                        },
                                        {
                                            pattern: new RegExp('^\\d{6}$'),
                                            message: '请输入正确的6位数字验证码!',
                                        },
                                    ]}
                                    >
                                        <Input 
                                            placeholder='请输入短信验证码' 
                                            prefix={<MessageOutlined className="site-form-item-icon" />} 
                                            onChange={(e) => {setInfo(prevState=>({...prevState,phone_code:e.target.value}))}} 
                                        />
                                </Form.Item>
                            
                                {isNote ? <Button disabled style={{width:'100%'}} >{time}秒后重新发送</Button> : <Button style={{width:'100%'}}  onClick={getCodeBtn}>获取验证码</Button>}
                                </Space>
                    </Form.Item>                    
                </Form>
            </ModalEdit>
        </>
    )
}

export default MinePassword