import React from 'react'
import Main from '../../../../cpn/Main'
import Self from './Self'
function CustomSelf() {
    return (
        <Main t1='国内地接' n1='/LocalIndex' t2='定制游' n2='/CustomIndex' t3='模板' n3='/CustomTempIndex' t4='费用自理'>
            <Self />
        </Main>
    )
}

export default CustomSelf