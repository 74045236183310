import React from 'react'
import Main from '../../cpn/Main'
import FundAccount from '../fund/FundAccount'

function FinanceAccount() {
    return (
        <Main  t1='财务' n1='/TaFinanceIndex' t2='设置' n2='/TaFinanceSetIndex' t3='资金账户'>
            <FundAccount />
        </Main>
    )
}

export default FinanceAccount