import React,{ useState}from 'react'
import { Modal,message,Form,DatePicker, Input, Checkbox,} from 'antd';
import { useDaoyoujihuadanxingchengAddMutation } from '../../../../store/ReactWebSiteApi';
import dayjs from 'dayjs'
const {TextArea} = Input
function GuidePlanRouteAdd(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const [info,setInfo] = useState();
    const [submitAdd] = useDaoyoujihuadanxingchengAddMutation()
    
    
    return (
        <>
            <a onClick={() => {setOpen(true)}} >新增</a>
            <Modal
                open={open}
                title='新增行程安排'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        dyjhdxc_jhd_id:props.dyjhdxc_jhd_id,
                        dyjhdxc_day:info.dyjhdxc_day,
                        dyjhdxc_anpai:info.dyjhdxc_anpai,
                        dyjhdxc_zaocan:info.dyjhdxc_zaocan,
                        dyjhdxc_zhongcan:info.dyjhdxc_zhongcan,
                        dyjhdxc_wancan:info.dyjhdxc_wancan,
                        dyjhdxc_zhusu:info.dyjhdxc_zhusu,                       
                        dyjhdxc_uid:localStorage.getItem('eu_id'),
                        dyjhdxc_uname:localStorage.getItem('eu_name'),
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                >                                           
                    <Form.Item name="day" label='日期' wrapperCol={{span:10}} required>
                        <DatePicker  
                            onChange={(e) => {setInfo(prevState=>({...prevState,dyjhdxc_day:dayjs(e).format('YYYY-MM-DD')}))}}
                        />
                    </Form.Item>
                    <Form.Item name="route" label='行程安排' wrapperCol={{span:10}} required>
                        <TextArea 
                             onChange={(e) => {setInfo(prevState=>({...prevState,dyjhdxc_anpai:e.target.value}))}}
                        />
                    </Form.Item>                   
                    <Form.Item                         
                        label='用餐' 
                        wrapperCol={{span:20}}                          
                    >
                        <Checkbox.Group
                            onChange={(e) => {
                                if(e.includes('1')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_zaocan:'1'}))
                                };
                                if(e.includes('2')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_zhongcan:'1'}))
                                };
                                if(e.includes('3')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_wancan:'1'}))
                                };
                                if(!e.includes('1')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_zaocan:'2'}))
                                };
                                if(!e.includes('2')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_zhongcan:'2'}))
                                };
                                if(!e.includes('3')){
                                    setInfo(prevState=>({...prevState,dyjhdxc_wancan:'2'}))
                                };
                            }}
                        >
                            <Checkbox value='1'>早餐</Checkbox>
                            <Checkbox value='2'>中餐</Checkbox>
                            <Checkbox value='3'>晚餐</Checkbox>
                        </Checkbox.Group>                         
                    </Form.Item>
                    <Form.Item name="hotel" label='住宿安排' wrapperCol={{span:10}}>
                        <Input 
                             onChange={(e) => {setInfo(prevState=>({...prevState,dyjhdxc_zhusu:e.target.value}))}}
                        />
                    </Form.Item> 
                </Form>
            </Modal>
        </>
    )
}

export default GuidePlanRouteAdd