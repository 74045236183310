import React from 'react'
import Main from '../cpn/Main'
import { Button, Form, Input } from 'antd'
import MyEditor from '../cpn/MyEditor'

function LocalShop() {
    return (
        <Main t1='国内地接' n1='/LocalIndex' t2='地接店铺'>
            <Form
                labelCol={{span:3}}
            >
                <Form.Item
                    label='公司名称'
                    wrapperCol={{span:10}}
                    required
                >
                    <Input
                        disabled
                        value={localStorage.getItem('e_name')}  
                    />
                </Form.Item>
                <Form.Item
                    label='店铺简称'
                    wrapperCol={{span:5}}
                    required
                >
                    <Input
                        placeholder='请填写店铺简称，10个字以内。'
                    />
                </Form.Item>
                <Form.Item
                    label='业务范围'
                    wrapperCol={{span:10}}
                    required
                >
                    <MyEditor
                        onChange={() => {}}
                        placeholder='请介绍国内地接范围'
                    />
                </Form.Item>
                <Form.Item
                    wrapperCol={{span:10,offset:3}}
                >
                    <Button type='primary'>提交</Button>
                </Form.Item>
            </Form>
        </Main>
    )
}

export default LocalShop