import React from 'react';
import {message} from 'antd'
import {useBusinessNumAuditMutation,useBusinessNumAuditCancelMutation } from '../../store/ReactWebSiteApi';

function LedgerAudit(props) {
    //审核台账-----------------------------------------------------------------------------------------------------------------------------审核台账
    const [submitAudit] = useBusinessNumAuditMutation();
    const [submitAuditCancle] = useBusinessNumAuditCancelMutation();
    return (
        <>
            {!props.ebn_audit2_ctime && (props.ebn_audit_ctime ? <>                                
                                <a 
                                    onClick={() => {
                                        submitAuditCancle({
                                            ebn_id:props.ebn_id,
                                            rankNum:'1',
                                        }).then((res) => {
                                            //console.log(res);
                                            if (res.data.Status === 'success') {
                                                message.success('取消审核成功！')  
                                            }
                                            if (res.data.Status === 'fail') {
                                                message.error('取消审核失败！'+res.data.Message)
                                            }
                                        })
                                    }}>取消审核</a></>:<a  
                                        onClick={() => {
                                            submitAudit({
                                                ebn_id:props.ebn_id,
                                                ebn_audit_uid:localStorage.getItem('eu_id'),
                                                ebn_audit_uname:localStorage.getItem('eu_name'),
                                                rankNum:'1',
                                            }).then((res) => {
                                                //console.log(res);
                                                if (res.data.Status === 'success') {
                                                    message.success('审核成功！') 
                                                }
                                                if (res.data.Status === 'fail') {
                                                    message.error('审核失败！'+res.data.Message)
                                                }
                                            })
                                        }}>审核</a>)}
                                        <> {props.ebn_audit_ctime && (props.ebn_audit2_ctime ? <>
                                            <a 
                                                onClick={() => {
                                                submitAuditCancle({
                                                    ebn_id:props.ebn_id,
                                                    rankNum:'2',
                                                }).then((res) => {
                                                    //console.log(res);
                                                    if (res.data.Status === 'success') {
                                                        message.success('取消复核成功！')  
                                                    }
                                                    if (res.data.Status === 'fail') {
                                                        message.error('取消复核失败！'+res.data.Message)
                                                    }
                                                })
                                            }}>取消复核</a></>:<a  
                                            onClick={() => {
                                                submitAudit({
                                                    ebn_id:props.ebn_id,
                                                    ebn_audit_uid:localStorage.getItem('eu_id'),
                                                    ebn_audit_uname:localStorage.getItem('eu_name'),
                                                    rankNum:'2',
                                                }).then((res) => {
                                                    //console.log(res);
                                                    if (res.data.Status === 'success') {
                                                        message.success('复核成功！')  
                                                    }
                                                    if (res.data.Status === 'fail') {
                                                        message.error('复核失败！'+res.data.Message)
                                                    }
                                                })
                                            }}>复核</a>)}</>
        </>
    )
}

export default LedgerAudit