import React,{useState,useEffect} from 'react'
import { useIncomeExpenditureListQuery, useGetIncomeExpenditureUidQuery, useFinanceSubTypeListQuery,} from '../../store/ReactWebSiteApi';
import { Space,Table,Tag,Modal,Form,Button,Input,Select,InputNumber} from 'antd';
import LedgerBalanceCheck from './LedgerBalanceCheck';
import GetDept from '../../cpn/GetDept'
import GetClient from '../client/GetClient';
import LedgerBalanceEdit from './LedgerBalanceEdit';
import LedgerBalanceDel from './LedgerBalanceDel';
import LedgerBalanceChange from './LedgerBalanceChange';
import ArapCheck from '../arap/ArapCheck';
import FundCheck from '../fund/FundCheck';

function LedgerBalanceList(props) {
    const [form] = Form.useForm();
    //获取子类型id和名称 
    const [subType,setSubType] = useState([{efst_id:'',efst_name:''}])
    const {data:dataType,isSuccess:isSuccessType} = useFinanceSubTypeListQuery({
        efst_e_id:localStorage.getItem('e_id'),
        efst_finance_type_id:props.eie_type_id,
        efst_name:props.efst_name?props.efst_name:'',
        efst_mode:props.efst_name?'2':''
    })
    useEffect(() => {
        if(isSuccessType){
            if(dataType.Status==='success'){
                // console.log('dataType',dataType);
                setSubType(dataType.Result)
            }
        }
    },[dataType,isSuccessType])
    //查询===============================================================================================查询//
    const [open,setOpen] = useState();
    const getClientQuery = (e) => {setQuery(prevState=>({...prevState,eie_client_id:e}))}
    const {data:dataUid,isSuccess:isSuccessUid} = useGetIncomeExpenditureUidQuery({eie_e_id:localStorage.getItem("e_id")},{skip:!open})
    const [handler,setHandler] = useState()
    useEffect(() => {
        if(isSuccessUid){
            if(dataUid.Status==='success'){
                const arr = dataUid.Result.map((item) => {
                    const {ec_uid,ec_uname} = item;
                    return item = {value:ec_uid,label:ec_uname}
                })
                setHandler(arr)
            }
        }
    },[dataUid,isSuccessUid])

    const [query,setQuery] = useState({
        eie_e_id:localStorage.getItem('e_id'),
        action:props.action,
        eie_type_id:props.eie_type_id,
        eie_finance_sub_type_id:subType[0].efst_id,
        eie_ebn_id:props.eie_ebn_id,
        eie_jbr_id:'',
        eie_dept_id:props.ebn_dept_id?props.ebn_dept_id:'',
    })
    //收支列表
    const {data,isSuccess} = useIncomeExpenditureListQuery(query,{refetchOnMountOrArgChange:1})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log('balanceList',data);                
                const newArr = data.Result.map(item => {
                    const {eie_id:key,guige,eie_hx_number,eie_bank_ctime,ebn_team_num,eie_arrive_year,eie_egg_value,eie_arrive_month,eie_arrive_day,eie_finance_sub_type_name,eie_hx_ctime,ed_name,eie_jbr_uname,eie_audit1_ctime,eie_audit1_uname,eie_audit2_ctime,eie_audit2_uname,eie_hx_year,eie_hx_month,eie_hx_day,eie_amount,ec_name,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eg_name,eu_name,eie_bank_log_id} = item;
                    return item = {key,guige,eie_hx_number,eie_bank_ctime,ebn_team_num,eie_arrive_year,eie_egg_value,eie_arrive_month,eie_arrive_day,eie_finance_sub_type_name,eie_hx_ctime,ed_name,eie_jbr_uname, eie_audit1_ctime,eie_audit1_uname,eie_audit2_ctime,eie_audit2_uname,eie_hx_year,eie_hx_month,eie_hx_day,eie_amount,ec_name,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eg_name,eu_name,eie_bank_log_id}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.eie_ctime;
                    let bTimeString = b.eie_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
                
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key: 'id',
            title: props.title,
            dataIndex: 'key',
            render:(_,record) => {
                return  <LedgerBalanceCheck                         
                            eie_id={record.key}
                            title={props.title}
                            jbr_label={props.jbr_label}
                            eie_jbr_uname={props.eie_jbr_uname} 
                            eie_esv_id={props.eie_esv_id}
                            eie_jbr_uid={props.eie_jbr_uid}
                            eie_type_id={props.eie_type_id}
                            eie_finance_sub_type_id={subType.eie_finance_sub_type_id}
                            eie_finance_sub_type_name={subType.eie_finance_sub_type_name}
                            eie_ebn_id={props.eie_ebn_id}
                            eie_eg_id={props.ebn_eg_id}
                            guige={props.guige}
                        />
            },
            width:'10%'
        },   
        {
            key: 'eie_finance_sub_type_name',
            title: '类型',
            dataIndex: 'eie_finance_sub_type_name',
            width:'10%'
        },  
        {
            key: 'client',
            title: '客户',
            dataIndex: 'ec_name',
            width:'10%'
        },                           
        {
            key: 'eie_amount',
            title: '金额',
            render:(_,record) => {
                return <>{record.eie_price} × {record.eie_count} = {record.eie_amount}</>
            },
            width:'13%'
        },        
        {
            key: 'guige',
            title: '规格数量',
            dataIndex:"guige",
            render:(text) => {
                let arr = []
                if(text){
                    arr = text.map((item) => {
                        return <div key={item.eiegg_egg_id}>{item.eiegg_egg_name + '×' +item.eiegg_egg_value}</div>
                    })
                }
                return <Space direction='vertical'>{arr}</Space>
            },
            width:'10%'
        },        
        {
            key: 'eie_content',
            title: '备注',
            dataIndex: 'eie_content',
        },         
                                               
        {
            key: 'ed_name',
            title: '部门',
            dataIndex: 'ed_name',
            width:'7%'
        },                                          
                                                
        {
            key: 'status',
            title: '审核',
            render:(_,record) => {
                return<>
                    {!record.eie_audit1_uname && <>待审核</>}
                    {(record.eie_audit1_uname && !record.eie_audit2_uname) && <Tag color='green'>已审核</Tag>}
                    {record.eie_audit2_uname && <Tag color="volcano">已复核</Tag>}
                </>
            },
            width:'6%'
        },             
        {
            key: 'hx',
            title: '下账转账',
            render:(_,record) => {
                return<>
                    {!record.eie_hx_ctime && <>待下账</>}
                    {(record.eie_hx_ctime && !record.eie_bank_ctime) && <ArapCheck eie_hx_number={record.eie_hx_number} />}
                    {record.eie_bank_ctime && <FundCheck ecal_id={record.eie_bank_log_id} />}
                </>
            },
            width:'6%'
        },             
             
        // {
        //     key: 'time',
        //     title: '操作记录',
        //     render: (_,record) => {
        //         return <>
        //             <div>新建时间：{record.eie_ctime + record.eie_uname}</div>
        //             <div>修改时间：{record.eie_update_ctime + record.eie_update_uname}</div>
        //         </>
        //     }
        // },
        {
            key: 'action',
            title: '操作',
            render: (_, record) => {
                return <>
                     <Space>
                        <LedgerBalanceEdit 
                            eie_id={record.key}
                            title={props.title}
                            jbr_label={props.jbr_label}
                            eie_jbr_uname={props.eie_jbr_uname} 
                            eie_esv_id={props.eie_esv_id}
                            eie_jbr_uid={props.eie_jbr_uid}
                            eie_type_id={props.eie_type_id}
                            eie_finance_sub_type_id={subType.eie_finance_sub_type_id}
                            eie_finance_sub_type_name={subType.eie_finance_sub_type_name}                             
                            eie_ebn_id={props.eie_ebn_id}                            
                            eie_eg_id={props.ebn_eg_id}
                            eg_name={props.eg_name}
                            guige={props.guige}
                            eie_audit1_ctime={record.eie_audit1_ctime}
                            eie_hx_ctime={record.eie_hx_ctime}
                            fromLedger={props.fromLedger}
                        />
                        <LedgerBalanceDel 
                            eie_audit1_ctime={record.eie_audit1_ctime}
                            eie_hx_ctime={record.eie_hx_ctime}
                            eie_id={record.key}
                        />
                        {/* <LedgerBalanceAudit 
                            eie_audit1_ctime={record.eie_audit1_ctime}
                            eie_audit2_ctime={record.eie_audit2_ctime}
                            eie_id={record.key}
                        /> */}
                        <LedgerBalanceChange
                            eie_id={record.key}                            
                            eie_audit1_ctime={record.eie_audit1_ctime}
                            eie_hx_ctime={record.eie_hx_ctime}
                            title={props.title}
                        />                       
                    </Space>
                </>
            },
            width:'10%'
        },
    ]
    
    
    return (
        <>
            <Table 
                columns={columns} 
                dataSource={list} 
                pagination={props.pagination} 
                summary={(pageData) => {
                    let total = 0;                 
                    pageData.forEach(({ eie_amount}) => {
                        total += +eie_amount;
                    });
                    // props.getTotal(total)
                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{total.toFixed(2)}</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    )
                }}
            />
            <Modal
                open={open}
                title='查询'
                okText="提交"
                cancelText="关闭"
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button onClick={() => {                                        
                            form.resetFields();
                            setQuery({
                                eie_e_id:localStorage.getItem('e_id'),
                                eie_esv_id:props.eie_esv_id,
                                action:'',
                                eie_type_id:props.eie_type_id,
                                eie_finance_sub_type_id:subType.efst_id,
                                eie_jbr_id:props.eie_jbr_id,
                                eie_dept_id:localStorage.getItem('ed_id'),
                            });
                            setOpen(false)
                            }}>重置</Button>
                        <CancelBtn />
                    </>
                )}
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            setQuery(prevState=>({...prevState,ec_action:'search'}))
                            setOpen(false)
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        });
                    
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_in_modal"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 5 }}
                >
                    <GetDept label='部门' getDept={(e) => {setQuery(prevState=>({...prevState,eie_dept_id:e,action:'search'}))}} name='eie_dept_id' required/>
                    <GetClient label='客户' getClient={getClientQuery} />
                    <Form.Item  wrapperCol={{span:15}} label='金额'>
                        <InputNumber 
                            onChange={(e) => {                            
                                setQuery(prevState=>({...prevState,eie_amount:e}))                           
                            }}
                            />
                    </Form.Item>
                    <Form.Item name="ec_content" label='备注' wrapperCol={{span:15}}>
                        <Input
                            value={query.ec_content} 
                            onChange={(e) => {                            
                                setQuery(prevState=>({...prevState,eie_content:e.target.value}))                           
                            }}
                        />
                    </Form.Item>
                    <Form.Item name='ec_handler' label='操作员' wrapperCol={{span:15}}>
                        <Select 
                            options={handler} 
                            onChange={(e) => {setQuery(prevState=>({...prevState,eie_uid:e}))}}
                        />
                    </Form.Item>                                                        
                </Form>
            </Modal>
        </>
    )
}

export default LedgerBalanceList