import { Form, Modal, Space,Input } from 'antd';
import React, { useState } from 'react'
import LineName from './LineName';

function LineNameForm(props) {
    console.log(props,'11');
    const [open,setOpen] = useState(false)    
    return (
        <Form.Item
            label={props.label}                    
            required={props.required}
            name={props.name}
            wrapperCol={props.wrapperCol}                    
        >
            <Space align='center'>
                <Input
                    value={props.lineName}
                    onChange={(e) => {
                        props.getLineName(e.target.value)
                    }}
                    style={props.inputWidth}
                />                   
                <a onClick={() => {setOpen(true)}}>模板</a>
                <Modal
                    open={open}
                    okText="提交"
                    cancelText="取消"
                    onCancel={() => {setOpen(false);}}
                    onOk={() => {}}
                    footer={null}
                    width={800}
                    title='选择组团社'
                >
                    <LineName 
                        select
                        getName={(e) => {
                            props.getLineName(e)                            
                        }}
                        getOpen={(e) => {setOpen(false)}}
                    />
                </Modal> 
            </Space>
        </Form.Item>   
    )
}

export default LineNameForm