import React,{useRef, useState}from 'react'
import {Form,Input,message, Radio, Space} from 'antd';
import { useShangpinAddMutation,} from '../../../store/ReactWebSiteApi';
import ModalAdd from '../../../cpn/ModalAdd';
import GetUserDept from '../../../cpn/GetUserDept';
import MyEditor from '../../../cpn/MyEditor';
import GetCountry from './GetCountry';
import GetProvince from './GetProvince';
import GetCity from './GetCity';
import UploadOne from '../../../cpn/UploadOne';
function CustomGoodsAdd(props) {
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        sp_country_id:'',
        sp_country_name:'',
        sp_province_id:'',
        sp_province_name:'',
        sp_city_id:'',
        sp_city_name:'',
    });
    const [submitAdd] = useShangpinAddMutation()
    const [open,setOpen] = useState(false)
    const editForm = useRef()
    return (
        <ModalAdd 
            title='商品'
            onOk={(e) => {
                let sp_spt1_name
                if(props.type==='1'){
                    sp_spt1_name = '组团商品'
                }
                if(props.type==='2'){
                    sp_spt1_name = '地接商品'
                }
                if(props.type==='3'){
                    sp_spt1_name = '仅自营组团商品'
                }
                submitAdd({
                    sp_e_id:localStorage.getItem('e_id'),
                    sp_e_name:localStorage.getItem('e_name'),
                    sp_spt1_id:props.type,
                    sp_spt1_name:sp_spt1_name,
                    sp_spt2_id:'1',
                    sp_spt2_name:'旅游',
                    sp_spt3_id:info.sp_spt3_id,
                    sp_spt3_name:info.sp_spt3_name,
                    sp_spt4_id:'1',
                    sp_spt4_name:'定制游',
                    sp_country_id:info.sp_country_id,
                    sp_country_name:info.sp_country_name,
                    sp_province_id:info.sp_province_id,
                    sp_province_name:info.sp_province_name,
                    sp_city_id:info.sp_city_id,
                    sp_city_name:info.sp_city_name,
                    sp_dept_name:info.sp_dept_name,
                    sp_dept_id:info.sp_dept_id,
                    sp_name:info.sp_name,
                    sp_content:info.sp_content,
                    sp_image:info.sp_image,
                    sp_uid:localStorage.getItem('eu_id'),
                    sp_uname:localStorage.getItem('eu_name'),
                }).then((res) => {
                    console.log(res);
                    if (res.data.Status === 'success') {
                        message.success('新增成功！') 
                        setOpen(false)                                                 
                    }
                    if (res.data.Status === 'fail') {
                        message.error('新增失败！'+res.data.Message)
                    }
                })
            }}
            open={open}
            getOpen={(e) => {
                setOpen(prevsState=>e)
                if(e){
                    setInfo(prevState=>({...prevState,lttt_dept_id:'',lttt_image:'',lttt_phone:''}))
                    form.resetFields(); 
                }
            }}
            width={1000}
        >
            <Form
                ref={editForm}
                form={form}
                layout="horizonal"
                name="form_client"
                initialValues={{modifier: 'public',}}
                labelCol={{ span: 3 }}
            >   
                 <UploadOne
                    fileStr={(e) => {setInfo(prevsState=>({...prevsState,sp_image:e,}))}}
                    label='图片'
                    wrapperCol={{span:20}}
                    required
                />
                <Form.Item label='出发城市' style={{marginBottom:"0"}} required>
                    <Space>
                        <GetCountry 
                            style={{width:'120px'}}
                            getCountry={(e,f) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    sp_country_id:e,
                                    sp_country_name:f,
                                }))
                            }}
                            
                        />
                        <GetProvince 
                            ctap_country_id={info.sp_country_id}
                            style={{width:'120px'}}
                            getProvince={(e,f) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    sp_province_id:e,
                                    sp_province_name:f,
                                }))
                            }}
                        />
                        <GetCity 
                            province_id={info.sp_province_id}
                            style={{width:'120px'}}
                            getCity={(e,f) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    sp_city_id:e,
                                    sp_city_name:f,
                                }))
                            }}
                        />
                    </Space>
                </Form.Item>
                <GetUserDept 
                    label='部门' 
                    name='dept'
                    required 
                    wrapperCol={{span:5}}
                    getUserDept={(e,f) => {setInfo(prevsState=>({...prevsState,sp_dept_id:e,sp_dept_name:f}))}}
                />  
                <Form.Item name="remark" label='商品名称' wrapperCol={{span:20}} required>
                    <Input onChange={(e) => {setInfo(prevsState=>({...prevsState,sp_name:e.target.value}))}} style={{width:"500px"}}/>  
                </Form.Item>               
                <Form.Item name="country" label='品类' wrapperCol={{span:20}} required>
                    <Radio.Group onChange={(e) => {
                        let sp_spt3_name
                        if(e.target.value==='1'){
                            sp_spt3_name = '国内游'
                        }
                        if(e.target.value==='1'){
                            sp_spt3_name = '出境游'
                        }
                        if(e.target.value==='1'){
                            sp_spt3_name = '入境游'
                        }
                        setInfo(prevState=>({...prevState,sp_spt3_id:e.target.value,sp_spt3_name,}))
                    }}>
                        <Radio value='1'>国内游</Radio>
                        <Radio value='2'>出境游</Radio>
                        <Radio value='3'>入境游</Radio>
                    </Radio.Group>
                </Form.Item>                              
                <Form.Item name="content" label='预定规则' wrapperCol={{span:20}} required>
                    <MyEditor onChange={(e) => {setInfo(prevsState=>({...prevsState,sp_content:e}))}} style={{width:"500px"}}/>    
                </Form.Item>
               
            </Form>
        </ModalAdd>
    )
}

export default CustomGoodsAdd