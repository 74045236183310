import React,{useEffect, useState}from 'react'
import { Modal,message,Form} from 'antd';
import { useDijieBaoJiaDanFeiYongBuHanUpdateQuery, useDijieBaoJiaDanFeiYongBuHanUpdateSaveMutation,} from '../../../store/ReactWebSiteApi';
import typeName from './typeName';
import CustomSelf from '../temp/self/CustomSelf';
function FileSelfEdit(props) {
    const [form] = Form.useForm();  
    const [open, setOpen] = useState(false);
    const onCreateClient = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };
    const {data,isSuccess} = useDijieBaoJiaDanFeiYongBuHanUpdateQuery({bjdfybh_id:props.bjdfybh_id},{skip:!open})
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                const type = typeName(data.Result.bjdfybh_type_id) 
                setInfo(prevState=>({...prevState,
                    bjdfybh_name:data.Result.bjdfybh_name,
                    bjdfybh_type_id:data.Result.bjdfybh_type_id,
                    bjdxc_image:data.Result.bjdxc_image,
                    bjdxc_content:data.Result.bjdxc_content,                    
                    type,
                }))
            }
        }
    },[data,isSuccess])
    const [info,setInfo] = useState({
        eg_content:'',
    });
    const [submitAdd] = useDijieBaoJiaDanFeiYongBuHanUpdateSaveMutation()
    //模版
    const [openTemplate,setOpenTemplate] = useState(false)
    const getTemplate = <>
            <Form.Item  label='请选择' required>
                <a onClick={() => {setOpenTemplate(true)}}>费用不含模板</a>               
            </Form.Item>        
            <Modal
                open={openTemplate}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpenTemplate(false);}}
                onOk={() => {}}
                footer={null}
                width={1000}
                title='费用不含模板'
            >
                <CustomSelf
                    get={true}
                    ltbjd_dept_id={props.ltbjd_dept_id}
                    getStandard={(e) => {
                        const type = typeName(e.bjdfybh_type_id)
                        setInfo(prevState=>({
                            ...prevState,
                            bjdfybh_type_id:e.bjdfybh_type_id,
                            bjdfybh_name:e.bjdfybh_name,
                            type,
                        }))
                    }}
                    getOpen={(e) => {setOpenTemplate(e)}}
                />
            </Modal>
        </>
    return (
        <>
            <a onClick={() => {
                setInfo(prevState=>({
                    ...prevState,
                    bjdfybh_type_id:'',
                    bjdfybh_name:'',
                }))
                setOpen(true)
                }} >修改</a>
            <Modal
                open={open}
                title='修改费用不含'
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreateClient(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                    submitAdd({
                        bjdfybh_bjd_id:props.bjdfybh_bjd_id,
                        bjdfybh_type_id:info.bjdfybh_type_id,
                        bjdfybh_name:info.bjdfybh_name,
                        bjdfybh_update_uid:localStorage.getItem('eu_id'),
                        bjdfybh_update_uname:localStorage.getItem('eu_name'),
                        bjdfybh_id:props.bjdfybh_id
                    }).then((res) => {
                        console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！')  
                            
                            setOpen(false)                         
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })
                }}
            >
                <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 6 }}
                >                                           
                    {getTemplate}
                    <Form.Item label='类型' wrapperCol={{span:10}} >
                        {info.type}
                    </Form.Item>
                    <Form.Item label='名称' wrapperCol={{span:10}} >
                        {props.bjdfybh_name?props.bjdfybh_name:info.bjdfybh_name}
                    </Form.Item>                     
                </Form>
            </Modal>
        </>
    )
}

export default FileSelfEdit