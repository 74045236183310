import React  from 'react'
import Main from '../../cpn/Main'
import {Row } from 'antd'
import Folder from '../../cpn/Folder'
function OrganIndex() {
    return (
        <Main t1='组团业务' n1='/OrganIndex' t2='定制游'>
            <Row>                
                <Folder title='报价单' link='/PhoneShop'/> 
                <Folder title='团队计划' link='/OrganCustomIndex'/> 
                <Folder title='组团协议' link='/OrganCustomIndex'/> 
                <Folder title='模板' link='/OrganCustomIndex'/> 
            </Row>
        </Main>
    )
}

export default OrganIndex