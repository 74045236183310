import React, {useState,useEffect} from 'react';
import { useNavigate,} from 'react-router-dom';
import classes from './main.module.css';
import { Modal,List,message,} from 'antd';
import { useEntSwitchoverQuery,  } from '../store/ReactWebSiteApi';
import { setLogin,} from '../store/loginSlice'
import { useDispatch } from 'react-redux'
function MainSwitch() {
    const navi = useNavigate();  
    const dispatch = useDispatch();
    //企业列表
    const [list, setList] = useState();
    //切换企业对话框
    const [open, setOpen] = useState(false);
    //连接服务器数据，切换企业
    const { data, isSuccess } = useEntSwitchoverQuery({ eu_ba_id: localStorage.getItem('eu_ba_id') },{skip:!open,});
    //点击切换企业
    useEffect(() => {
        if (isSuccess) {          
            // //console.log(data);  
            try {
                const listData = data.Result.map(item => {
                    let {e_name:title,ess_menu_id,eu_token,e_soft_name,eu_ba_phone,eu_ba_id,eu_name,eu_role,e_id,e_id:key,e_name,ed_id,ed_name,eu_id,eu_menu_id,eu_operate_id,eu_esv_id} = item
                    return item = { title,ess_menu_id,eu_token,e_soft_name,eu_ba_phone, eu_ba_id, eu_name, eu_role, e_id,key, e_name, ed_id, ed_name, eu_id,eu_menu_id,eu_operate_id,eu_esv_id};
                })
                setList(listData);                
            } catch (error) {
                
            }
        }
    }, [data,isSuccess])
    return (
        <>
            <span  
                onClick={() => {
                    setOpen(true)
                }}  
                className={classes.mine}
            > 
                {localStorage.getItem('e_name').replace(/"/g,'')} / 
            </span> 
            <Modal
                title="切换企业"
                open={open}
                onCancel={() => {
                    setOpen(false)
                }}
                footer={null}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta title={<a onClick={() => {
                                dispatch(setLogin({
                                    token: item.eu_token,
                                    eu_ba_phone: +item.eu_ba_phone,
                                    eu_ba_id: +item.eu_ba_id,
                                    eu_name:item.eu_name,
                                    eu_role:+item.eu_role,
                                    e_id:+item.e_id,
                                    e_name:item.e_name,
                                    ed_id:+item.ed_id,
                                    ed_name:item.ed_name,
                                    eu_id: +item.eu_id,  
                                    eu_menu_id:item.eu_menu_id,                                            
                                    eu_operate_id:item.eu_operate_id,                                            
                                    eu_esv_id:item.eu_esv_id,                                           
                                    e_soft_name:item.e_soft_name,                                           
                                    ess_menu_id:item.ess_menu_id,                                           
                                    islog: true
                                    }))
                                message.success('切换成功！')    
                                navi('/TaIndex')
                                setOpen(false);
                            }} >{item.title}</a>} />
                    </List.Item>
                    )}
                />
            </Modal>
        </>
    )
}

export default MainSwitch