import React  from 'react'
import Main from '../cpn/Main'
import {Row} from 'antd';
import Program from '../cpn/Program';
import fastKnow from '../svg/fastKnow.svg'
import about from '../svg/about.svg'
import MinePassword from './MinePassword';
import MineInfo from './MineInfo';
import MineUpdate from './MineUpdate';
function MineIndex() {
    
    return (
        <Main t1='我的'>
            <Row>
                <MinePassword />
                <MineInfo />
                <Program title='快速上手' onClick={() => {window.open('https://i.bnsrj.com/TaCourse', '_blank');}} icon={<img alt='' src={fastKnow} width={42}/>}/>
                <MineUpdate/>
                <Program title='关于我们' onClick={() => {window.open('https://i.bnsrj.com', '_blank');}} icon={<img alt='' src={about} width={40}/>}/>                
            </Row>  
           
                                        
        </Main>
    )
}

export default MineIndex