import React, { useEffect, useState } from 'react'
import {Space, Table,message } from 'antd'
import { useDijieTemplateGuiGeDelMutation, useDijieTemplateGuiGeListQuery,} from '../../../../store/ReactWebSiteApi'
import ModalDel from '../../../../cpn/ModalDel'
import CrowdAdd from './CrowdAdd'
import CrowdEdit from './CrowdEdit'
import CrowdQuery from './CrowdQuery'
function Crowd(props) {
    const [list,setList] = useState()
    const [query,setQuery] = useState({ltgg_e_id:localStorage.getItem('e_id')})
    const {data, isSuccess} = useDijieTemplateGuiGeListQuery(query,{refetchOnMountOrArgChange:1});
    useEffect(() => {
        if (isSuccess) {
            console.log(data);
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {ltgg_id:key,ltgg_name,ltgg_uname,ltgg_ctime,ltgg_phone,ltgg_image,ed_name} = item;                    
                    return item = {key,ltgg_name,ltgg_uname,ltgg_ctime,ltgg_phone,ltgg_image,ed_name}
                })                
                const sortArr = newArr.sort( (a, b) => {      
                    let aTimeString = a.ltgg_ctime;
                    let bTimeString = b.ltgg_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])
    //删除
    const [del] = useDijieTemplateGuiGeDelMutation()
    const column = [             
        {
            key:'12',
            title:'人群类型模板',
            dataIndex:'ltgg_name'
        },      
        {
            key:'11',
            title:'部门',
            dataIndex:'ed_name'
        },                      
        {
            key:'6',
            title:'操作',
            render:(_,record) => {
                return <Space>
                    <CrowdEdit ltgg_id={record.key} />
                    <ModalDel onOk={() => {
                        del({ltgg_id:record.key}).then((res) => {
                            console.log(res);
                            if (res.data.Status === 'success') {
                                message.success('删除成功！')                                                                              
                            }
                            if (res.data.Status === 'fail') {
                                message.error('删除失败！'+res.data.Message)
                            }
                        })
                    }} />
                     {props.get && <a onClick={() => {                        
                        props.getType({
                            bjdgg_name:record.ltgg_name,
                        })                  
                        props.getOpen(false)                        
                        }}>选择</a>}
                </Space>
            }
        },
    ]
    
    return (
        <>
            <Space>
                <CrowdAdd />
                <CrowdQuery 
                    getQuery={(e) => {
                        setQuery(prevState=>({
                            ...prevState,
                            ltgg_dept_id:e.ltgg_dept_id,
                            ltgg_name:e.ltgg_name,
                        }))
                    }}
                />
            </Space>
            <Table 
                columns={column} 
                dataSource={list} 
                style={{marginTop:"10px"}} 
            />
        </>
    )
}

export default Crowd