import { Form, Modal, Space,Input} from 'antd';
import React, { useState } from 'react'
import Guide from './Guide';

function GuideForm(props) {
    const [open,setOpen] = useState(false)    
    return (
        <Form.Item
            label='导游'                    
            required={props.required} 
            style={{marginBottom:"0"}}                  
        >
            <Space align='center'>
                <Form.Item
                    name={props.nameGuideName}
                >
                    <Input 
                        value={props.guideName}
                        onChange={(e) => {
                             props.getGuideName(e.target.value)
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name={props.nameGuidePhone}
                >
                    <Input 
                        value={props.guidePhone}
                        onChange={(e) => {
                             props.getGuidePhone(e.target.value)
                        }}
                    />
                </Form.Item>
                <a onClick={() => {setOpen(true)}}>模板</a>
                <Modal
                    open={open}
                    okText="提交"
                    cancelText="取消"
                    onCancel={() => {setOpen(false);}}
                    onOk={() => {}}
                    footer={null}
                    width={800}
                    title='选择导游'
                >
                    <Guide 
                        type='select'
                        getGuide={(e) => {
                            props.getGuideName(e.ltdy_name);
                            props.getGuidePhone(e.ltdy_phone);
                        }}
                        getOpen={(e) => {setOpen(false)}}
                    />
                </Modal> 
            </Space>
        </Form.Item>   
    )
}

export default GuideForm