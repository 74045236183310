import React,{useState,useEffect} from 'react'
import { Table,Space,DatePicker, Radio,} from 'antd'
import { useGoodsTypeStatisticsQuery, } from '../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import IndexTable from '../../cpn/IndexTable';
const {RangePicker} = DatePicker
function LedgerTableGoodsType() {
    const [info,setInfo] = useState({
        date_s:'',
        date_e:'',
        ebn_audit:'0',
        name:'全部'
    })    
    const {data,isSuccess} = useGoodsTypeStatisticsQuery({
        e_id:localStorage.getItem('e_id'),
        date_s:info.date_s,
        date_e:info.date_e,
        ebn_audit:info.ebn_audit,        
        eu_id:localStorage.getItem('eu_id'),
    },{refetchOnMountOrArgChange:1,skip:!info.date_e});    
    const [list, setList] = useState();    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){                
                const newArr = data.Result.map(item => {
                    const {ebn_egt_id:key,ebn_egt_name,expenditure_num,income_num,ebn_num} = item;
                    return item = {key,ebn_egt_name,expenditure_num,income_num,ebn_num}
                })                                
                setList(newArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    const columns = [
                  
        {
            key: '1',
            title: '产品类型',
            dataIndex: 'ebn_egt_name',
        },     
        {
            key: '2',
            title: '团队报账单数量',
            dataIndex:'ebn_num',
        },     
        {
            key: '3',
            title: '团队收入单合计',
            dataIndex:'income_num'
        },     
        {
            key: '4',
            title: '团队支出单合计',
            dataIndex:'expenditure_num',
        },     
        {
            key: '5',
            title: '利润',
            render:(_,record) => {
                return <>{(+record.income_num + +record.expenditure_num).toFixed(2)}</>
            }
        },     
        {
            key: '6',
            title: '利润率',
            render:(_,record) => {
                return <>{(+record.income_num !== +record.expenditure_num && +record.income_num !== 0)?(((+record.income_num + +record.expenditure_num)/+record.income_num)*100).toFixed(2)+'%':''}</>
            }
        },  
    ]
    return (
        <IndexTable 
            title='产品类型利润表'
            remark='按团队报账单的出发日期和审核状态，统计每个产品类型的团队报账单数量、团队收入单金额之和、团队支出单金额之和、利润之和、利润率。'
        >
           <Space>
                <span>团队报账单的出发日期：</span>
                <RangePicker
                    onChange={(e) => {
                        if(e){
                            setInfo(prevState=>({...prevState,date_s:dayjs(e[0]).format('YYYY-MM-DD'),date_e:dayjs(e[1]).format('YYYY-MM-DD')})) 
                        }else{
                            setInfo(prevState=>({...prevState,date_s:'',date_e:''}))
                        }
                    }}
                />
                <Radio.Group 
                    optionType='button' 
                    onChange={(e) => {
                        let name 
                        if(e.target.value==='0'){
                            name='全部'
                        }else if(e.target.value==='1'){
                            name='已审核'
                        }else{
                            name='未审核'
                        }
                        setInfo(prevState=>({...prevState,ebn_audit:e.target.value,name}))
                    }}
                    value={info.ebn_audit}
                >
                    <Radio value='0'>全部</Radio>
                    <Radio value='1'>已审核</Radio>
                    <Radio value='2'>未审核</Radio>
                </Radio.Group>
            </Space>
            <Table 
                columns={columns} 
                dataSource={list} 
                pagination={{position:['none'],defaultPageSize:100}}
                summary={(pageData) => {
                    console.log(pageData);
                    let totalebn_num = 0;
                    let totalincome_num = 0;
                    let totalnexpenditure_num = 0;
                    pageData.forEach(({ebn_num, income_num,expenditure_num, }) => {
                        totalebn_num += +ebn_num;
                        totalincome_num += +income_num;
                        totalnexpenditure_num += +expenditure_num;
                    });
                    return <Table.Summary>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>{totalebn_num}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>{(totalincome_num).toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>{(totalnexpenditure_num).toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{(totalincome_num+totalnexpenditure_num).toFixed(2)}</Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                }}
            />   
        </IndexTable>
    )
}

export default LedgerTableGoodsType