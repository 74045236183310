import React, { useEffect, useState }  from 'react'
import TaTopPrice from './TaTopPrice'
import {  Layout, Menu,} from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import Foot from './Foot';
import { useGetGoodsTreeQuery } from '../store/ReactWebSiteApi';
const {  Content,  Sider } = Layout;

function findAllKeys(node) {
    if (!node) return [];
    const keys = [node.key];
    if (node.children) {
        node.children.forEach(child => {
            keys.push(...findAllKeys(child));
        });
    }
    return keys;
}

function TaPrice(props) {
    const navi = useNavigate() 
    const [list,setList] = useState()
    const [defaultArr,setDefaultArr] = useState()
    const {data,isSuccess} = useGetGoodsTreeQuery({eowg_e_id:'218'},{refetchOnMountOrArgChange:1})
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){                
                setList(data.Result)                
                const arr = findAllKeys(data.Result)
                console.log(arr);
                setDefaultArr(arr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])    
    //
    return (
        <>
            <TaTopPrice activeKey='1'></TaTopPrice>
            <Content style={{padding: '0 10px',}}>                
                <Layout
                    style={{padding: '24px 0',background: '#fff',borderRadius:'20px',}}
                >
                    <Sider                        
                        width={300}
                    >
                        <Menu
                            defaultOpenKeys={defaultArr}
                            mode="inline"
                            items={list}
                            onClick={(e) => {
                                if(e.key==='1'){
                                }                                
                                if(e.key==='11'){
                                    navi('/TaPrice/TaPriceFinance')
                                }                                
                                if(e.key==='13'){
                                    navi('/TaPrice/TaPriceUpdate')
                                }
                                if(e.key==='21'){
                                    navi('/TaPriceCustom')                                    
                                }
                            }}     
                            theme='dark'                                    
                        />
                    </Sider>
                    <Content
                        style={{
                            padding: '0 50px',
                            minHeight: 280,
                        }}
                    >
                       <Outlet></Outlet>                      
                    </Content>
                </Layout>
            </Content>
            <Foot />
        </>
    )
}

export default TaPrice