import { DatePicker, Form, Input, InputNumber, Modal, } from 'antd';
import React, { useState } from 'react'
import GuideForm from '../../temp/guide/GuideForm';
import UploadOne from '../../../../cpn/UploadOne';
const {TextArea} = Input
function GuidePlanAdd() {
    const [open,setOpen] = useState(false)
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        ltbjd_daoyou:'',
        ltbjd_daoyou_phone:''
    })
    return (
        <>
            <a onClick={() => {setOpen(true);}}>新增导游计划单</a>
            <Modal
                title='新增导游计划单'
                onOk={(e) => {                   
                }}
                open={open}
                onCancel={() => {setOpen(false)}}
            >
               <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"
                    initialValues={{
                        modifier: 'public',
                        fenche:'1',
                    }}
                    labelCol={{ span: 6 }}
                >                                                              
                     <Form.Item name="fenche" label='分车序号' wrapperCol={{span:10}} required>
                        <InputNumber 
                        />
                    </Form.Item>
                    <GuideForm 
                        required
                        ltbjd_daoyou={info.ltbjd_daoyou}
                        ltbjd_daoyou_phone={info.ltbjd_daoyou_phone}
                        getGuide={(e) => {
                            setInfo(prevState=>({
                                ...prevState,
                                ltbjd_daoyou_phone:e.ltbjd_daoyou_phone,
                                ltbjd_daoyou:e.ltbjd_daoyou
                            }))
                        }}
                    />
                    <Form.Item 
                        label='接团时间' 
                        name="s_time" 
                        wrapperCol={{span:15}} 
                    >
                        <DatePicker
                            showTime
                        />
                    </Form.Item>
                    <Form.Item 
                        label='接团地点' 
                        name="s_site" 
                        wrapperCol={{span:15}} 
                    >
                        <Input
                            
                        />
                    </Form.Item>
                    <Form.Item 
                        label='送团时间' 
                        name="e_time" 
                        wrapperCol={{span:15}} 
                    >
                        <DatePicker
                            showTime
                        />
                    </Form.Item>
                    <Form.Item 
                        label='送团地点' 
                        name="e_site" 
                        wrapperCol={{span:15}} 
                    >
                        <Input
                            
                        />
                    </Form.Item>
                    <Form.Item 
                        label='接团说明' 
                        name="remark" 
                        wrapperCol={{span:15}}                         
                    >
                        <TextArea 
                            autoSize={{
                                minRows: 4,                                
                            }}
                        />
                    </Form.Item>
                    <Form.Item 
                        label='导服费' 
                        name="pay" 
                        wrapperCol={{span:15}} 
                    >
                        <InputNumber                            
                        />
                    </Form.Item>
                    <Form.Item 
                        label='导服费说明' 
                        name="pay" 
                        wrapperCol={{span:15}} 
                    >
                        <Input                       
                        />
                    </Form.Item>
                    <Form.Item 
                        label='附件' 
                        name="file" 
                        wrapperCol={{span:15}} 
                    >
                        <UploadOne                       
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GuidePlanAdd