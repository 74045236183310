import React, { useEffect, useState } from 'react'
import { Button, Descriptions, message, Space,} from 'antd';
import { useDijieBaoJiaDanDelMutation, useDijieBaoJiaDanUpdateQuery, useDijieTemplateTaiTouListQuery } from '../../../store/ReactWebSiteApi';
import FileStandard from './FileStandard';
import FileType from './FileType';
import FileEdit from './FileEdit';
import ModalDel from '../../../cpn/ModalDel';
import FilePrice from './FilePrice';
import FileSelf from './FileSelf';
import FilePay from './FilePay';
import { usePDF } from 'react-to-pdf';
import FileNotes from './FileNotes';
import FileSchedule from './FileSchedule';
import DocTitle from '../../../cpn/DocTitle';

function FileDesc(props) {    
    const {data,isSuccess} = useDijieBaoJiaDanUpdateQuery({ltbjd_id:props.ltbjd_id},{skip:!props.ltbjd_id})
    const [info,setInfo] = useState({
        ltbjd_e_id:localStorage.getItem('e_id'),
        ltbjd_update_uid:localStorage.getItem('eu_id'),
        ltbjd_update_uname:localStorage.getItem('eu_name'),
        ltbjd_id:props.ltbjd_id,
        preview:false,
        ltbjd_content:'',    
        ltbjd_yemei:[],    
    });
    useEffect(() => {
        if(isSuccess){
            if(data.Status==='success'){
                //下载PDF的文件名称
                let documentName
                if(props.customType==='1'){
                    documentName = `《行程方案-${data.Result.ltbjd_ltxl_name}》` + localStorage.getItem('e_name')
                }
                if(props.customType==='2'){
                    documentName = `《确认单-${data.Result.ltbjd_client_name}》` + localStorage.getItem('e_name')
                }
                if(props.customType==='3'){
                    documentName = `《确认件-${data.Result.ltbjd_client_name}》` + localStorage.getItem('e_name')
                }
                if(props.customType==='4'){
                    documentName = `《结算单-${data.Result.ltbjd_ltxl_name}》` + localStorage.getItem('e_name')
                }
                //客户的标签名称
                let name;
                switch (data.Result.ltbjd_kehu_type_id) {
                    case '1':
                        name='客户'
                        break;
                    case '2':
                        name='游客'
                        break;
                    case '3':
                        name='单位'
                        break;                
                    default:
                        break;
                }
                
                // console.log(data);                                 
                setInfo(prevsState=>({...prevsState,                    
                    ltbjd_dept_id:data.Result.ltbjd_dept_id,
                    ed_name:data.Result.ed_name,                    
                    ltbjd_ltxl_name:data.Result.ltbjd_ltxl_name,
                    ltbjd_date:data.Result.ltbjd_date,                    
                    ltbjd_client_name:data.Result.ltbjd_client_name,                    
                    ltbjd_content:data.Result.ltbjd_content,           
                    ltbjd_yemei:data.Result.ltbjd_yemei,
                    ltbjd_yejiao:data.Result.ltbjd_yejiao,         
                    ltbjd_daoyou:data.Result.ltbjd_daoyou,         
                    ltbjd_daoyou_phone:data.Result.ltbjd_daoyou_phone,         
                    ltbjd_fenchexuhao:data.Result.ltbjd_fenchexuhao,         
                    ltbjd_type_id:data.Result.ltbjd_type_id,         
                    ltbjd_lianxiren:data.Result.ltbjd_lianxiren,         
                    ltbjd_chufajiaotong:data.Result.ltbjd_chufajiaotong,         
                    ltbjd_fanchengjiaotong:data.Result.ltbjd_fanchengjiaotong,         
                    ltbjd_youke_name:data.Result.ltbjd_youke_name,         
                    ltbjd_quanpei:data.Result.ltbjd_quanpei,         
                    ltbjd_phone:data.Result.ltbjd_phone,         
                    documentName,
                    name,
                }))
            }
        }
    },[data,isSuccess,props])
    const { toPDF, targetRef } = usePDF({filename:info.documentName});  
    //删除
    const [del] = useDijieBaoJiaDanDelMutation()
    const items = [        
        {
            key: '1',
            label: '收件单位',
            children: info.ltbjd_client_name,
            labelStyle:{width:'120px'},
            contentStyle:{fontSize:"16px"},
        },          
        {
            key: '2',
            label: '收件人',
            children: info.ltbjd_lianxiren,
            labelStyle:{width:'120px'},
            contentStyle:{fontSize:"16px"},
        },          
        {
            key: '3',
            label: '电话',
            children: info.ltbjd_phone,
            labelStyle:{width:'90px'},
            contentStyle:{fontSize:"16px",width:"140px"}
        },          
        {
            key: '4',
            label: '发件单位',
            children: localStorage.getItem('e_name').replace(/"/g,''),
            contentStyle:{fontSize:"16px"}
        },     
        {
            key: '5',
            label: '发件人',
            children: localStorage.getItem('eu_name').replace(/"/g,''),
            contentStyle:{fontSize:"16px"}
        },     
        {
            key: '6',
            label: '电话',
            children: localStorage.getItem('eu_ba_phone'),
            contentStyle:{fontSize:"16px"}
        },     
        {
            key: '7',
            label: '线路名称',
            children: info.ltbjd_ltxl_name,
            contentStyle:{fontSize:"16px"}
        },         
        {
            key: '8',
            label: '出发日期',
            children: info.ltbjd_date,
            contentStyle:{fontSize:"16px"}
        },  
        {
            key: '9',
            label: '游客',
            children: info.ltbjd_youke_name,
        },              
        {
            key: '10',
            label: '出发大交通',
            children: info.ltbjd_chufajiaotong,
        },              
        {
            key: '11',
            label: '返程大交通',
            children: info.ltbjd_fanchengjiaotong,
        },           
        {
            key: '12',
            label: '全陪',
            children: info.ltbjd_quanpei,
        },        
        {
            key: '13',
            label: '人数',
            children: <FileType bjdgg_bjd_id={props.ltbjd_id} preview={info.preview}/>,
            span:2,
            contentStyle:{fontSize:"16px"}
        },      
        {
            key: '14',
            label: '确认章',
            children: '',
            contentStyle:{fontSize:"16px"}
        },            
        {
            key: '14',
            label: '备注',
            children: info.ltbjd_content,
            span:2,
            contentStyle:{fontSize:"16px"}
        },                  
        {
            key: '15',
            label: '操作',
            contentStyle:{fontSize:"16px"},
            children:   <Space>                            
                            <FileEdit
                                ltbjd_id={props.ltbjd_id} 
                                ltbjd_dept_id={props.ltbjd_dept_id}
                                customType={props.customType} 
                                customName={props.customName}
                            />
                            <ModalDel onOk={() => {
                                del({ltbjd_id:props.key}).then((res) => {
                                    console.log(res);
                                    if (res.data.Status === 'success') {
                                        message.success('删除成功！')                                                                              
                                    }
                                    if (res.data.Status === 'fail') {
                                        message.error('删除失败！'+res.data.Message)
                                    }
                                })
                            }} />
                        </Space>,
        }
    ]
    if(info.preview){
        items.pop()
    }

    //底部按钮
    let footerBtn    
    if(info.preview){
        footerBtn = [
                        <Button 
                            key='1'
                            type='primary' 
                            onClick={() => {
                                setInfo(prevState=>({...prevState,preview:false}))
                            }}
                        >退出预览</Button>,
                        <Button 
                            key='2'
                            onClick={() => {toPDF()}}
                        >下载PDF</Button>
                    ]
        }
    if(!info.preview){
        footerBtn = [
                        <Button 
                            key='3'
                            type='primary' 
                            onClick={() => {
                                setInfo(prevState=>({...prevState,preview:true}))
                            }}
                        >预览PDF</Button>
                    ]
        }
    if(info.preview){
        footerBtn = [
                        <Button 
                            key='1'
                            type='primary' 
                            onClick={() => {
                                setInfo(prevState=>({...prevState,preview:false}))
                            }}
                        >退出预览</Button>,
                        <Button 
                            key='2'
                            onClick={() => {toPDF()}}
                        >下载PDF</Button>,
                    ]
    }
    if(!info.preview){
        footerBtn = [
                        <Button 
                            key='1'
                            type='primary' 
                            onClick={() => {
                                setInfo(prevState=>({...prevState,preview:true}))
                            }}
                        >预览PDF</Button>,
                    ]
    }
    //页眉页脚
    const {data:dataPage, isSuccess:isSuccessPage} = useDijieTemplateTaiTouListQuery({
        lttt_e_id:localStorage.getItem('e_id'),
        lttt_dept_id:localStorage.getItem('ed_id'),
        lttt_name:info.ltbjd_type_id,
    },{refetchOnMountOrArgChange:1,skip:!info.ltbjd_type_id});
    useEffect(() => {
        if (isSuccessPage) {
            console.log(dataPage);
            if(dataPage.Status==='success'){                
                const newArr = dataPage.Result.map(item => {
                    const {lttt_id:key,lttt_content,lttt_uname,lttt_ctime,lttt_phone,lttt_image,ed_name,lttt_name} = item; 
                    return item = {key,lttt_content,lttt_uname,lttt_ctime,lttt_phone,lttt_image,ed_name,lttt_name}
                }) 
                //页面页脚图片和文字
                setInfo(prevState=>({...prevState,ltbjd_yemei:newArr[0].lttt_image,ltbjd_yejiao:newArr[0].lttt_phone}))
                
            }else{
                setInfo(prevState=>({...prevState,ltbjd_yemei:''.lttt_image,ltbjd_yejiao:''}))
            }            
        }
    }, [dataPage, isSuccessPage])
    console.log(props);
    return (
        <div  style={{border:'2px solid #eee',margin:"10px",padding:'20px'}}  ref={targetRef}>
            {info.ltbjd_yemei && info.ltbjd_yemei.map((item) => {
                return <img src={item}  width='100%' alt=''/>
            })}
            <DocTitle
                name='确认单'
                num={props.ltbjd_id}
            />
            {props.customType !=='6' && <Descriptions items={items} bordered size='small' labelStyle={{fontSize:"16px"}}/>}
            
            {/* <FileRoute
                    bjdxc_bjd_id={props.ltbjd_id}
                    ltbjd_dept_id={info.ltbjd_dept_id}
                    customType={props.customType}
                    preview={info.preview}
                /> */}
            <FileSchedule 
                bjdxc_bjd_id={props.ltbjd_id}
                preview={info.preview}
            />
            <FileStandard  
                bjdjdbz_bjd_id={props.ltbjd_id}
                ltbjd_dept_id={info.ltbjd_dept_id}
                preview={info.preview}
                customType={props.customType}
            />                
            <FilePrice
                bjdfymx_bjd_id={props.ltbjd_id}
                ltbjd_dept_id={info.ltbjd_dept_id}
                preview={info.preview}
                customType={props.customType}
            />
            <FileSelf
                bjdfybh_bjd_id={props.ltbjd_id}
                ltbjd_dept_id={info.ltbjd_dept_id}
                preview={info.preview}
            />
            <FileNotes
                bjdzysx_bjd_id={props.ltbjd_id}
                ltbjd_dept_id={info.ltbjd_dept_id}
                preview={info.preview}
            />        
            <FilePay
                bjdjsfs_bjd_id={props.ltbjd_id}
                ltbjd_dept_id={info.ltbjd_dept_id}
                preview={info.preview}
            />
            <div style={{lineHeight:"50px",fontSize:"18px"}}>{info.ltbjd_yejiao}</div>                   
            {info.preview && <div style={{textAlign:'right',marginTop:"20px"}}>旅当家旅行社管理系统 技术支持 https://i.bnsrj.com</div>}
        </div>    
    )
}

export default FileDesc