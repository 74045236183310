import { Col,} from 'antd'
import React from 'react'
import classes from '../sys/index.module.css'
import { useNavigate } from 'react-router-dom';
import folder from '../svg/folder.svg'
function Folder(props) {
    const navi = useNavigate()
    return (
        <Col span={2} className={classes.mainItem} onClick={() => {navi(props.link)}}>
            <img src={folder} width={40} style={{marginBottom:"10px"}} alt=''/>
            <div style={{textAlign:'center'}}>{props.title}</div>
        </Col>
    )
}

export default Folder