import { DatePicker, Form, Input, InputNumber, message, Modal, Select, } from 'antd';
import React, { useState } from 'react'
import GuideForm from '../../temp/guide/GuideForm';
import UploadOne from '../../../../cpn/UploadOne';
import { useDaoyoujihuadanAddMutation } from '../../../../store/ReactWebSiteApi';
import dayjs from 'dayjs';
import { guidePost } from '../../customType';
const {TextArea} = Input
function GuidePlanAdd(props) {
    const [open,setOpen] = useState(false)
    const [form] = Form.useForm();  
    const [info,setInfo] = useState({
        dyjhd_tgu_name:'',
        dyjhd_tgu_phone:'',
        dyjhd_fenche:'1'
    })
    const [add] = useDaoyoujihuadanAddMutation()
    return (
        <>
            <a onClick={() => {setOpen(true);}}>新增导游计划单</a>
            <Modal
                title='新增导游计划单'
                onOk={(e) => {    
                    add({
                        "dyjhd_daofufei" : info.dyjhd_daofufei,
                        "dyjhd_daofufeishuoming" : info.dyjhd_daofufeishuoming,
                        "dyjhd_fujian_name" : info.dyjhd_fujian_name,
                        "dyjhd_jietuandidian" :info.dyjhd_jietuandidian,
                        "dyjhd_jietuanshijian" :info.dyjhd_jietuanshijian,
                        "dyjhd_jietuanshuoming" :info.dyjhd_jietuanshuoming,
                        "dyjhd_lth_id" : props.dyjhd_lth_id,
                        "dyjhd_lth_num" : props.dyjhd_lth_num,
                        "dyjhd_songtuandidian" : info.dyjhd_songtuandidian,
                        "dyjhd_songtuanshijian" :info.dyjhd_songtuanshijian,                     
                        "dyjhd_fenche" : info.dyjhd_fenche,
                        dyjhd_xianlu:props.lth_xianlu_name,
                        "dyjhd_uid" : localStorage.getItem('eu_id'),
                        "dyjhd_uname" :  localStorage.getItem('eu_name'),
                        "dyjhd_uphone" :  localStorage.getItem('eu_ba_phone'),
                        "dyjhd_e_id" : localStorage.getItem('e_id'),        
                        "dyjhd_e_name" : localStorage.getItem('e_name'),        
                        "dyjhd_dept_id" : props.lth_dept_id,        
                        "dyjhd_dept_name" :props.lth_dept_name,  
                        "dyjhd_lth_zhu_name" : props.lth_zhu_type_name,
                        "dyjhd_lth_zhu_type" : props.lth_zhu_type,
                        "dyjhd_lth_ci_type" : props.lth_ci_type,
                        "dyjhd_lth_ci_type_name" : props.lth_ci_type_name,
                        "dyjhd_lth_zi_type" : props.lth_zi_type,
                        "dyjhd_lth_zi_type_name" : props.lth_zi_type_name,  
                        "dyjhd_zhiwu_id" : info.dyjhd_zhiwu_id,  
                        "dyjhd_zhiwu_name" : info.dyjhd_zhiwu_name,  
                        "dyjhd_ltdy_name" : info.dyjhd_ltdy_name,  
                        "dyjhd_ltdy_phone" : info.dyjhd_ltdy_phone,  
                    }).then((res) => {
                        // console.log(res);
                        if (res.data.Status === 'success') {
                            message.success('新增成功！') 
                            setOpen(false)                                                 
                        }
                        if (res.data.Status === 'fail') {
                            message.error('新增失败！'+res.data.Message)
                        }
                    })               
                }}
                open={open}
                onCancel={() => {setOpen(false)}}
                width={600}
            >
               <Form
                    form={form}
                    layout="horizonal"
                    name="form_client"                    
                    labelCol={{ span: 6 }}
                    initialValues={{fenche:1}}
                >                                                              
                    <Form.Item name="fenche" label='分车序号' wrapperCol={{span:10}} required>
                        <InputNumber 
                            onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_fenche:e}))
                            }}
                            min={1}
                        />
                    </Form.Item>
                    <GuideForm 
                        required
                        guideName={info.dyjhd_ltdy_name}
                        guidePhone={info.dyjhd_ltdy_phone}
                        getGuideName={(e) => {setInfo(prevState=>({...prevState,dyjhd_ltdy_name:e}))}}
                        getGuidePhone={(e) => {setInfo(prevState=>({...prevState,dyjhd_ltdy_phone:e}))}}
                    />
                    <Form.Item 
                        label='职务'
                        required 
                        name="post" 
                        wrapperCol={{span:8}} 
                    >
                        <Select
                            options={guidePost}
                            onChange={(e,f) => {
                                setInfo(prevState=>({
                                    ...prevState,
                                    dyjhd_zhiwu_id:e,
                                    dyjhd_zhiwu_name:f.label
                                }))
                            }}  
                        />
                    </Form.Item>
                    <Form.Item 
                        label='接团时间' 
                        name="s_time" 
                        wrapperCol={{span:15}} 
                    >
                        <DatePicker
                            showTime
                            format="YYYY-MM-DD HH:mm"
                            onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_jietuanshijian:dayjs(e).format('YYYY-MM-DD HH:mm')}))
                            }}  
                        />
                    </Form.Item>
                    <Form.Item 
                        label='接团地点' 
                        name="s_site" 
                        wrapperCol={{span:15}} 
                    >
                         <Input
                             onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_jietuandidian:e.target.value}))
                            }}  
                        />
                    </Form.Item>
                    <Form.Item 
                        label='送团时间' 
                        name="e_time" 
                        wrapperCol={{span:15}} 
                    >
                         <DatePicker
                            showTime
                            format="YYYY-MM-DD HH:mm"
                            onChange={(e) => {
                                // console.log(e);
                                setInfo(prevState=>({...prevState,dyjhd_songtuanshijian:dayjs(e).format('YYYY-MM-DD HH:mm')}))
                            }}  
                        />
                    </Form.Item>
                    <Form.Item 
                        label='送团地点' 
                        name="e_site" 
                        wrapperCol={{span:15}} 
                    >
                        <Input
                             onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_songtuandidian:e.target.value}))
                            }}  
                        />
                    </Form.Item>
                    <Form.Item 
                        label='接团说明' 
                        name="remark" 
                        wrapperCol={{span:15}}                         
                    >
                        <TextArea 
                            autoSize={{
                                minRows: 4,                                
                            }}
                            onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_jietuanshuoming:e.target.value}))
                            }}   
                        />
                    </Form.Item>
                    <Form.Item 
                        label='导服费' 
                        name="pay" 
                        wrapperCol={{span:15}} 
                    >
                        <InputNumber     
                          onChange={(e) => {
                            setInfo(prevState=>({...prevState,dyjhd_daofufei:e}))
                        }}                          
                        />
                    </Form.Item>
                    <Form.Item 
                        label='导服费说明' 
                        name="payRemark" 
                        wrapperCol={{span:15}} 
                    >
                        <Input
                            onChange={(e) => {
                                setInfo(prevState=>({...prevState,dyjhd_daofufeishuoming:e.target.value}))
                            }}                       
                        />
                    </Form.Item>
                    <UploadOne 
                        name='pic'
                        label='附件' 
                        fileStr={(e) => {setInfo(prevsState=>({...prevsState,dyjhd_fujian_name:e}))}}
                        />
                </Form>
            </Modal>
        </>
    )
}

export default GuidePlanAdd