import React,{useEffect, useRef, useState} from 'react'
import { useIncomeExpenditureAddMutation,} from '../../store/ReactWebSiteApi';
import { Modal,message,Form,Input,Button,InputNumber,Upload,Space} from 'antd';
import { UploadOutlined} from '@ant-design/icons';
import GetClient from '../client/GetClient';
import dayjs from 'dayjs';
import GetBalanceType from './GetBalanceType';
import useInAndOut from './useInAndOut';
import GetUserDept from '../../cpn/GetUserDept';
import GetUser from '../../cpn/GetUser';
import LedgerBalanceDesc from '../ledger/LedgerBalanceDesc';
const { TextArea } = Input;
function BalanceElseAdd(props) {
    // console.log(props);
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const inAndOut = useInAndOut(props.efst_finance_type_id)
    //上传图片
    const handleChange = (e) => {
        if (e.file.status === 'done'||e.file.status==='removed') {
             const fileArr = e.fileList.map((item) => {
                 const { response } = item
                 return response.Result
             })
            //  console.log(e);
             let fileStr = fileArr + "";  
             if(fileStr === null){
                 fileStr = ''
             }
             const nameArr = e.fileList.map((item) => {
                 const { name} = item
                 return name
             })
             let nameStr = nameArr + ""
             setInfo(prevState=>({...prevState,eie_image_name:nameStr,eie_image_file:fileStr}))
         }
     }
    const onCreate = (values) => {
        //  console.log('Received values of form: ', values);
         setOpen(false);
     };
    const [info,setInfo] = useState({
        eie_price:'',
        guigeForm:'',
        eie_count:1,
        eie_content:'',
        eie_image_file:'',
        eie_image_name:"",
        eie_year:dayjs().format('YYYY-MM-DD'),
         eie_yw_name:'',
    });     
    const [submitAdd] = useIncomeExpenditureAddMutation();
    const editForm = useRef()
    useEffect(() => {
        if(props.ebn_dept_id && open){
            editForm.current.setFieldsValue({
                dept:props.ebn_dept_id,
                date:dayjs()
            })
        }
        if(props.guige){
            const arr = props.guige.map((item,index) => {
                return <Form.Item key={item.egg_id} name={`eg${item.egg_id}`} initialValue={0}>
                    <InputNumber addonBefore={item.egg_name} />
                </Form.Item>
            })
            setInfo(prevState=>({...prevState,guigeForm:arr}))
        }
        
    },[open, props.ebn_dept_id, props.guige])
    //返回详情
    const [openCheck, setopenCheck] = useState({id:'',open:false})
    return (
        <>
            {!props.ebn_audit_ctime && <Button type='primary' onClick={() => {setOpen(true);}} style={props.style}>新增</Button>}
            <Modal
                open={open}
                title={`新增${props.main_type_name}`}
                okText="提交"
                cancelText="取消"
                onCancel={() => {setOpen(false);}}
                width={1000}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            // form.resetFields();
                            onCreate(values);
                            // console.log(values);
                            let arr = []
                            for (let key in values) {
                                if (values.hasOwnProperty(key)) { // 确保key是对象自身的属性
                                    if(key.includes('eg')){
                                        arr.push(values[key])
                                    }
                                }
                            }
                            // console.log(arr);
                            if(props.eie_ebn_id){
                                if(!props.eie_eg_id){
                                    message.error('新增失败，没有产品id！');
                                    return false;
                                }                                    
                            }
                            if(!info.eie_client_id){
                                message.error('新增失败，客户必填！');
                                return false
                            };
                            if(!values.price){
                                message.error('新增失败，价格必填！');
                                return false
                            };
                            if(!values.type){
                                message.error('新增失败，类型必填！');
                                return false
                            };
                            submitAdd({
                                eie_e_id:localStorage.getItem('e_id'),
                                eie_dept_id:info.eie_dept_id,
                                eie_client_id:info.eie_client_id,
                                eie_eci_id:'',
                                eie_eg_id:info.eie_eg_id,
                                eie_ebn_id:'',
                                eie_price:info.eie_price,
                                eie_count:info.eie_count,
                                eie_amount:info.eie_price*info.eie_count*inAndOut,
                                eie_jbr_uid:info.eie_jbr_uid,
                                eie_content:info.eie_content,
                                eie_uid:localStorage.getItem('eu_id'),
                                eie_uname:localStorage.getItem('eu_name'),
                                eie_image_name:info.eie_image_name,
                                eie_image_file:info.eie_image_file,
                                eie_type_id:props.efst_finance_type_id,
                                eie_finance_sub_type_id:info.eie_finance_sub_type_id,
                                eie_finance_sub_type_name:info.eie_finance_sub_type_name,
                                eie_year:info.eie_year,
                                eie_egg_value:'',
                                eie_yw_id:info.eie_yw_id,
                                eie_yw_name:info.eie_yw_name,
                            }).then((res) => {
                                // console.log(res);
                                if (res.data.Status === 'success') {
                                    message.success('新增成功！')  
                                    setOpen(false)
                                    setInfo(prevState=>({...prevState,eie_price:'0.01',eie_count:'1'}))                      
                                }
                                    setopenCheck({id:res.data.Result,open:true})      
                                if (res.data.Status === 'fail') {
                                    message.error('新增失败！'+res.data.Message)
                                }
                            })               
                            
                            form.resetFields();                        
                        })
                        .catch((info) => {
                            // console.log('Validate Failed:', info);
                        });
                        
                       
                    }}
                >
                <Form
                    form={form}
                    layout="horizonal"
                    name="addForm"
                    initialValues={{modifier: 'public',}}
                    labelCol={{ span: 3 }}
                    ref={editForm}
                >
                    <GetUserDept 
                        label='部门' 
                        getUserDept={(e,f) => {
                            setInfo(prevState=>({...prevState,eie_dept_id:e}))
                        }}
                        wrapperCol={{span:10}}
                        required
                        name='dept'
                    />
                    <GetBalanceType 
                        label='类型' 
                        required 
                        efst_finance_type_id={props.efst_finance_type_id}
                        getBalanceType={(e,f) => {setInfo(prevState=>({...prevState,eie_finance_sub_type_id:e,eie_finance_sub_type_name:f}))}}
                        wrapperCol={{span:10}}
                        name='type'
                        title={props.main_type_name}
                    />
                    
                    <GetClient 
                        label='客户' 
                        getClient={(e,f) => {
                            // console.log(e,f);
                            setInfo(prevState=>({...prevState,eie_client_id:e}))
                        }} 
                        name='client' 
                        required
                        wrapperCol={{span:15}}
                        style={{width:"300px"}}
                        clientAdd='true'
                    />
                    <Form.Item label='金额' wrapperCol={{span:15}} required style={{marginBottom:"0"}}>
                        <Space>
                            <Form.Item name="price" wrapperCol={{span:24}} required initialValue={0.01}>
                                <InputNumber 
                                    addonBefore='单价'
                                    min={0.01}
                                    onChange={(e) => {                            
                                        setInfo(prevState=>({...prevState,eie_price:e}))                           
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name="count" wrapperCol={{span:24}} required initialValue={1}>
                                <InputNumber 
                                    addonBefore='数量'
                                    min={1}
                                    onChange={(e) => {                            
                                        setInfo(prevState=>({...prevState,eie_count:e}))                           
                                    }}
                                />
                            </Form.Item>
                            <Form.Item  wrapperCol={{span:24}} required>
                                <InputNumber 
                                    addonBefore='合计'
                                    min={0.01}
                                    value={info.eie_price*info.eie_count}
                                    readOnly
                                />
                            </Form.Item>
                        </Space>
                    </Form.Item>
                    {/* <Form.Item label='日期' name='date'>
                        <DatePicker value={info.eie_year} onChange={(e) => {setInfo(prevState=>({...prevState,eie_year:dayjs(e).format('YYYY-MM-DD')}))}}/>
                    </Form.Item>                            */}
                    <Form.Item name="remark" label='备注' wrapperCol={{span:15}}>
                        <TextArea rows={4}  onChange={(e) => {setInfo(prevState=>({...prevState,eie_content:e.target.value}))}}/>
                    </Form.Item>
                    <Form.Item label="附件"                         
                        valuePropName='fileList'
                        getValueFromEvent={e => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                    >
                        <Upload
                            name="image_file"
                            action="/api/ReactApi/zd_single_upload_image"
                            listType="picture"
                            className="upload-list-inline"
                            onChange={handleChange}
                            >
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                        </Upload>
                    </Form.Item>
                    <GetUser  
                        wrapperCol={{span:5}}  
                        label='销售员' 
                        name='yw' 
                        required={false} 
                        getUser={(e,f) => {
                            console.log(e,f);
                            setInfo(prevState=>({...prevState,eie_yw_id:e,eie_yw_name:f}))
                        }}
                    />         
                </Form>
            </Modal>
            <Modal 
                open={openCheck.open} 
                onOk={() => {}} 
                onCancel={() => {setopenCheck({open:false})}}
                width={1200}
                cancelText='关闭'
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <Button
                            onClick={() => {
                                setopenCheck(false);
                                setOpen(true)
                            }}
                        >再次新增</Button>
                        <CancelBtn />
                    </>
                )}
            >
                <LedgerBalanceDesc 
                    eie_id={openCheck.id}
                    title={props.main_type_name}
                />
            </Modal> 
        </>
    )
}

export default BalanceElseAdd