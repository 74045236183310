import React from 'react'
import Main from '../../../../cpn/Main'
import Feedback from './Feedback'
function CustomFeedback() {
    return (
        <Main t1='国内地接' n1='/LocalIndex' t2='定制游' n2='/CustomIndex' t3='模板' n3='/CustomTempIndex' t4='意见单'>
            <Feedback />
        </Main>
    )
}

export default CustomFeedback