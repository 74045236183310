import React,{useState,useEffect}from 'react'
import { Table,Modal,message,Form,Button,Input, Space} from 'antd';
import { useIncomeExpenditureHxListQuery, useIncomeExpenditureHxSaveMutation,} from '../../store/ReactWebSiteApi';
import { useNavigate } from 'react-router-dom';
import LedgerGroupBalanceCheck from '../ledger/LedgerBalanceCheck';
import ArapQuery from './ArapQuery';
import { balanceType, } from '../../finance/balance/balanceType';
import LedgerCheck from '../../finance/ledger/LedgerCheck';
import {FileTextOutlined} from '@ant-design/icons';
function ArapAdd(props) {
    const [form] = Form.useForm();  
    //新增下账--------------------------------------------------------------------------------------------------------------------------------------------新增下账//
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const start = () => {
        setLoading(true);
        // ajax request after empty completing
        setTimeout(() => {
        setSelectedRowKeys([]);
        setLoading(false);
        }, 1000);
    };
    const onSelectChange = (newSelectedRowKeys,f) => {
        console.log(newSelectedRowKeys,f);
        if(f.length>1){
            if(f[f.length - 1].ec_id !== f[f.length - 2].ec_id){
                message.error('下账凭证的客户必须相同！')
                return false;
            }
        }

        let sum3 
        sum3 = f.reduce((sum3, obj) => (sum3 += +obj.eie_amount), 0)
        setInfo(prevState=>({...prevState,eie_hx_amount:sum3,eie_id:newSelectedRowKeys+'',ec_name:f[0]?f[0].ec_name:''}))        
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: (record.eie_hx_amount && record.eie_hx_amount !== '0.00'),
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    const hasSelected = selectedRowKeys.length > 0;
    const [info,setInfo] = useState({
        ecal_uid:localStorage.getItem('eu_id'),
        ecal_uname:localStorage.getItem('eu_name'),
        eie_hx_content:'',
        eie_hx_amount:0,
        ec_name:'未选择'
    });
    const [submitAudit] = useIncomeExpenditureHxSaveMutation()
    const [open, setOpen] = useState(false);
    const navi = useNavigate();
    const modalCheck = <Modal
                            open={open}
                            title='新增下账凭证'
                            okText="提交"
                            cancelText="取消"
                            onCancel={() => {setOpen(false);}}
                            onOk={() => {
                                form
                                    .validateFields()
                                    .then((values) => {
                                        form.resetFields();
                                        submitAudit({
                                            eie_id:info.eie_id,
                                            eie_hx_uid:localStorage.getItem('eu_id'),
                                            eie_hx_uname:localStorage.getItem('eu_name'),
                                            eie_hx_amount:info.eie_hx_amount,
                                            eie_hx_content:info.eie_hx_content,
                                        }).then((res) => {
                                            console.log(res);
                                            if (res.data.Status === 'success') {
                                                setOpen(false)
                                                Modal.confirm({
                                                    title: '新增成功，是否前往下账凭证？',
                                                    content: '点击取消，留在本页',
                                                    onOk:() => {navi('/ArapList?indexType=1&indexName=财务&esv_id=12')},                                                    
                                                });
                                                //                      
                                            }
                                            if (res.data.Status === 'fail') {
                                                message.error('新增失败！'+res.data.Message)
                                            }
                                        })
                                    })
                                    .catch((info) => {
                                        console.log('Validate Failed:', info);
                                    });                               

                            }}
                        >
                            <Form
                                form={form}
                                layout="horizonal"
                                name="form_in_modal"
                                initialValues={{modifier: 'public',}}
                                labelCol={{ span: 5 }}                                
                            >                                
                                <Form.Item label='客户' wrapperCol={{span:15}}>
                                    {info.ec_name}
                                </Form.Item>
                                <Form.Item label='下账数量' wrapperCol={{span:15}}>
                                    {selectedRowKeys.length}
                                </Form.Item>
                                <Form.Item label='下账金额' wrapperCol={{span:15}}>
                                    {info.eie_hx_amount.toFixed(2)}
                                </Form.Item>
                                <Form.Item name="content" label='备注' wrapperCol={{span:15}} >
                                    <Input onChange={(e) => {setInfo(prevState=>({...prevState,eie_hx_content:e.target.value}))}}/>
                                </Form.Item>                                                       
                            </Form>
                        </Modal> 
    
     //当前页码
     const [current, setCurrent] = useState(localStorage.getItem('arapWait_query_page')?localStorage.getItem('arapWait_query_page'):'1');
     //当前每页数量
     const [pageSize,setPageSize] = useState(localStorage.getItem('arapWait_query_page_size')?localStorage.getItem('arapWait_query_page_size'):"50")

    const [infoQuery,setInfoQuery] = useState({
        eie_e_id:localStorage.getItem('e_id'),
        eie_client_id:localStorage.getItem('arapWait_query_client')?localStorage.getItem('arapWait_query_client'):'',
        action:localStorage.getItem('arapWait_query_action')?localStorage.getItem('arapWait_query_action'):'',
        hx_type:'2',
        eie_arrive_date_s:'',
        eie_arrive_date_e:'',
        eie_dept_id:localStorage.getItem('arapWait_query_dept')?localStorage.getItem('arapWait_query_dept'):'', 
        ebn_team_num:localStorage.getItem('arapWait_query_team_num')?localStorage.getItem('arapWait_query_team_num'):'',
        ysyf_type:localStorage.getItem('arapWait_query_ysyf_type')?localStorage.getItem('arapWait_query_ysyf_type'):'',
        ebn_date_s:localStorage.getItem('arapWait_query_date_s')?localStorage.getItem('arapWait_query_date_s'):'',
        ebn_date_e:localStorage.getItem('arapWait_query_date_e')?localStorage.getItem('arapWait_query_date_e'):'',
        eie_jbr_uid:'',
        eu_id:localStorage.getItem('eu_id'),
    });
    const getQuery = (e) => {
        setInfoQuery(prevState=>({
            ...prevState,            
            action:e.action,
            eie_client_id:e.eie_client_id?e.eie_client_id:'',
            ebn_team_num:e.ebn_team_num?e.ebn_team_num:'',
            ysyf_type:e.ysyf_type?e.ysyf_type:'',
            eie_dept_id:e.eie_dept_id?e.eie_dept_id:'',
            ebn_date_s:e.ebn_date_s?e.ebn_date_s:'',
            ebn_date_e:e.ebn_date_e?e.ebn_date_e:'',

        }))
    }
    
    const {data,isSuccess} = useIncomeExpenditureHxListQuery(infoQuery,{refetchOnMountOrArgChange:1})
    
    const [list, setList] = useState();
    
    useEffect(() => {
        if(isSuccess){            
            if(data.Status==='success'){
                console.log(data);
                
                const newArr = data.Result.map(item => {
                    const {eie_id:key,eie_esv_id,ebn_client_id,ebn_id,ebn_dept_id,ebn_date,eg_name,ebn_yw_name,ed_name,eie_type_id,eie_finance_sub_type_name,ec_id,ebn_team_num,eie_amount,ec_name,eie_hx_amount,eie_hx_ctime,eie_hx_number,eie_hx_uname,eie_year,eie_eg_id,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eu_name,} = item;
                    let type
                    let arr = balanceType.filter(obj => obj.value === eie_type_id)
                    if(arr[0]){
                        type = arr[0].label
                    }
                    return item = {key,eie_esv_id,ebn_client_id,ebn_id,ebn_dept_id,ebn_date,eg_name,ebn_yw_name,ed_name,eie_type_id,type,eie_finance_sub_type_name,ec_id,ebn_team_num,eie_amount,ec_name,eie_hx_amount,eie_hx_ctime,eie_hx_number,eie_hx_uname,eie_year,eie_eg_id,eie_ebn_id,eie_content,eie_uname,eie_ctime,eie_update_ctime,eie_update_uname,eie_price,eie_image_file,eie_count,eci_name,eu_name}
                })
                const filteredArray = newArr.filter(item => +item.eie_hx_number < 1);                
                const sortArr = filteredArray.sort( (a, b) => {      
                    let aTimeString = a.eie_ctime;
                    let bTimeString = b.eie_ctime;
                    let aTime = new Date(aTimeString).getTime();
                    let bTime = new Date(bTimeString).getTime();
                    return bTime - aTime;     
                    },)
                setList(sortArr)
            }else{
                setList('')
            }
            
        }
    }, [data, isSuccess])   
    const columns = [
        {
            key: 'action',
            title: '收支单' ,
            render: (_, record) => {
                return <>
                     <Space>    
                        <LedgerGroupBalanceCheck 
                            eie_id={record.key}
                            title={record.type} 
                            eie_type_id={record.eie_type_id}
                            eie_finance_sub_type_id={record.eie_finance_sub_type_id}
                            eie_finance_sub_type_name={record.eie_finance_sub_type_name}
                            eie_ebn_id={record.eie_ebn_id}
                            eie_esv_id={record.eie_esv_id}
                            eie_eg_id={record.eie_eg_id}
                        />
                    </Space>
                </>
            }
        },   
        {
            key: 'client',
            title: '客户',
            dataIndex: 'ec_name',
        }, 
        {
            key: 'pic',
            title: '类型',
            render:(_,recorde) => {
                return <>
                    {recorde.type}-{recorde.eie_finance_sub_type_name}
                </>
            }           
        }, 
        {
            key: 'ebn_team_num',
            title: '团队报账单',
            dataIndex: 'ebn_team_num',
            sorter: {
                compare: (a, b) => a.ebn_team_num - b.ebn_team_num,
            },
            render:(text,record) => {
                return  <LedgerCheck 
                            text={<Space><FileTextOutlined />BZD{record.ebn_team_num}</Space>} 
                            ebn_id={record.ebn_id} 
                            ebn_dept_id={record.ebn_dept_id}
                            ebn_team_num={record.ebn_team_num}
                        />
            }
        },   
        {
            key: 'ebn_date',
            title: '出发日期',
            dataIndex: 'ebn_date',
        },  
        {
            key: 'ed_name',
            title: '部门',
            dataIndex: 'ed_name',
        },             
        {
            key: 'eg_name',
            title: '产品',
            dataIndex: 'eg_name',
        },  
        // {
        //     key: 'eie_count',
        //     title: '数量',
        //     dataIndex: 'eie_count',
        // },            
        {
            key: 'eie_amount',
            title: '金额',
            dataIndex: 'eie_amount',
        },            
        {
            key: 'ebn_yw_name',
            title: '销售员',
            dataIndex: 'ebn_yw_name',
        },                    
        {
            key: 'eie_content',
            title: '备注',
            dataIndex: 'eie_content',
        },                    
    ]
    //弹出框
    const [openAdd, setOpenAdd] = useState(false)
    return (
        <>
            <Form.Item
                label='待下账收支单'
                required
            >
                <Space>
                    {info.ec_name + ' - 共' + selectedRowKeys.length + '笔 - 合计' + info.eie_hx_amount.toFixed(2) + '元'}
                    <a onClick={() => {
                        setOpenAdd(true)
                    }}>选择</a>
                </Space>
            </Form.Item>
            <Modal 
                title='待下账收支单' 
                open={openAdd} 
                onOk={() => {}} 
                onCancel={() => {
                    setOpenAdd(setOpenAdd(false))
                }}
                width={1400}
            >
                <div
                    style={{
                    fontSize:16,
                    display:'flex'
                    }}
                >
                    <Space>
                        {hasSelected ? `已选${selectedRowKeys.length}笔，合计${info.eie_hx_amount.toFixed(2)}元` : ''}
                        <Button 
                            type="primary" 
                            onClick={() => { 
                                if(!hasSelected){
                                    message.error('请选择需要下账的收支单！')
                                }else{
                                    setOpenAdd(false)
                                    props.getKeep({eie_id:info.eie_id,eie_hx_amount:info.eie_hx_amount})
                                }
                            }}  
                            style={{marginLeft:'10px'}}
                        >
                            选择
                        </Button>
                      
                        <Button onClick={start} disabled={!hasSelected} loading={loading} style={{marginLeft:'10px'}}>
                            重选
                        </Button>                
                    </Space>
                    
                    <ArapQuery getQuery={getQuery}/>
                </div>    
                <Table 
                    columns={columns} 
                    style={{marginTop:'20px'}} 
                    dataSource={list} 
                    rowSelection={rowSelection}
                    pagination={{
                        showSizeChanger:true,
                        showTotal:(total) => `共${total}条`,
                        current:current,
                        onChange:(e,f) => {
                            setCurrent(e)
                            setPageSize(f)
                            localStorage.setItem('arapWait_query_page',e);
                            localStorage.setItem('arapWait_query_page_size',f);
                        },
                        pageSize:pageSize,
                        showTitle:true,
                    }}
                />
            </Modal>
            {modalCheck}    
        </>
    )
}

export default ArapAdd